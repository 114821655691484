import style from './reminderButton.module.css';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@nextui-org/react';
import React, { useMemo, useState } from 'react';
import useSWR from 'swr';
import useCustomToasts from '@/utils/useCustomToasts';
import { useLead } from '@/utils/useLead';
import { remindersService } from '@/services/reminders.service';
import Selector from '@/components/ui/Select/select';
import dayjs from 'dayjs';
import { spaceService } from '@/services/space.service';
import { ChevronDown, ChevronUp, Plus } from 'react-feather';
import IconAddReminder from '@/components/icons/addReminder';


export default function ReminderButton({ type, showLabel = true, iconOnly, customTrigger }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function changeHandler() {
    setIsOpen(!isOpen);
  }

  if (type === 'modal') {
    return (
      <>
        <Modal
          closeButton
          aria-labelledby='Create reminder'
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <ModalHeader>
            <h5 className={style.modalTitle}>
              Create Task
            </h5>
          </ModalHeader>
          <ModalBody>
            <ReminderForm onClose={() => setShowModal(false)} />
          </ModalBody>
        </Modal>
        <Button variant={'light'} startContent={<IconAddReminder size={14} />} size={'sm'}
                onClick={() => setShowModal(true)}>
          Task
        </Button>
      </>
    );
  }


  if (type === 'popover' && customTrigger) {
    return (
      <Popover isOpen={isOpen} onOpenChange={changeHandler}>
        <PopoverTrigger>
          {customTrigger}
        </PopoverTrigger>
        <PopoverContent className={'p-4 min-w-[350px]'}>
          <ReminderForm onClose={changeHandler} />
        </PopoverContent>
      </Popover>
    );
  }

  if (type === 'popover' && !customTrigger) {
    return (
      <Popover isOpen={isOpen} onOpenChange={changeHandler}>
        <PopoverTrigger>
            <Button isIconOnly={iconOnly} className={'h-6'} color={'primary'} size={'sm'} radius={'full'}
                    startContent={<IconAddReminder size={iconOnly ? 18 : 16} />}>
              {(showLabel && !iconOnly) && <span className={style.modalButtonText}>Task</span>}
            </Button>
        </PopoverTrigger>
        <PopoverContent className={'p-4 min-w-[350px]'}>
          <ReminderForm onClose={changeHandler} />
        </PopoverContent>
      </Popover>
    );
  }


  return (
    <ReminderForm />
  );
}


const repeatOptions = [
  {
    'name': 'Do not repeat',
    id: 'none'
  },
  {
    'name': 'Daily',
    id: 'daily',
    cron: '0 0 * * *'
  },
  {
    'name': 'Weekly',
    id: 'weekly',
    cron: '0 0 * * 0'
  },
  {
    'name': 'Monthly',
    id: 'monthly',
    cron: '0 0 1 * *'
  }
];

function ReminderForm({ onClose }) {
  const [name, setName] = useState('');
  const [repeat, setRepeat] = useState(['none']);
  const [date, setDate] = useState(null);
  const [notify, setNotify] = useState([]);
  const {
    uniqueLead,
    updateLead
  } = useLead();
  const { setToast } = useCustomToasts();
  const [loading, setLoading] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const {
    data: members
  } = useSWR(`/space/members`, spaceService.fetchWrapperGetEmpty, { revalidateOnFocus: true });

  const formatMembers = useMemo(() => {
    if (!members) return [];
    return members.map(m => ({ name: m?.handle || m?.full_name || m?.email, id: m.id, avatar: m?.avatar_url }));
  }, [members]);


  function handleSubmit(e) {
    if (e?.preventDefault) {
      e.preventDefault();
    }


    if (!name) {
      setToast({
        text: 'Name is required',
        type: 'error'
      });
      return;
    }

    if (loading) return;

    setLoading(true);

    const cron = repeatOptions.find(r => r.id === repeat[0])?.cron;
    remindersService.createReminder({
      id_lead: uniqueLead ? uniqueLead?.id : undefined,
      name: name,
      users: notify,
      ...(date && { date: dayjs(date).toISOString() }),
      ...((cron && date) && { cron: cron })
    }).then(r => {
      setToast({
        text: 'Reminder created',
        type: 'success'
      });
      updateLead({
        id: uniqueLead?.id,
        reminders: [...(uniqueLead?.reminders || []), r]
      });
      setName('');
      setNotify([]);
      setDate(dayjs().format('YYYY-MM-DD'));
      setRepeat(['none']);
      onClose && onClose();
    }).catch(e => {
      console.log(e);
      setToast({
        text: 'Error creating reminder',
        type: 'error'
      });
    }).finally(() => {
      setLoading(false);
    });
  }

  function quickAddDate(addDays) {
    setDate(dayjs().add(addDays, 'days').format('YYYY-MM-DD'));
  }

  return (
    <form onSubmit={handleSubmit} className={style.form}>
      <div className={style.line}>
        <Input labelPlacement='outside' size={'sm'} variant={'bordered'} value={name} onChange={r => setName(r?.target?.value)} mandatory={true}
               placeholder={'Ask for a video testimonial'}
               label={'Name'}
               type='text' id='name' />
      </div>
      <div className={style.line}>
        <Selector showAvatar={true} label={'Notify who?'} unit={'users'} multiple={true} items={formatMembers}
                  onChange={r => setNotify(r)} value={notify} emptyLabel={'Select user(s)'} />
      </div>
      {showMoreOptions && (
        <>
          <div className={style.line}>
            <Input labelPlacement='outside' size={'sm'} variant={'bordered'}
                   value={date} onChange={r => setDate(r?.target?.value)} placeholder={'Date'} label={date ?
              <span>When? <span onClick={() => setDate('')} className={style.labelRight}>Clear</span></span> : 'When?'}
                   type='date'
                   id='date' />
            <div className={style.quicks}>
              <div onClick={() => quickAddDate(1)} className={style.quick}>Tomorrow</div>
              <div onClick={() => quickAddDate(7)} className={style.quick}>Next week</div>
              <div onClick={() => quickAddDate(30)} className={style.quick}>Next Month</div>
              <div onClick={() => quickAddDate(365)} className={style.quick}>Next Year</div>
            </div>
          </div>
          <div className={style.line}>
            <Selector disabled={!date} label={'Repeat'} unit={''} multiple={false} items={repeatOptions}
                      onChange={r => setRepeat(r)}
                      value={repeat} emptyLabel={'Select schedule'} />
          </div>
        </>
      )}
      <div className={style.line}>
        <Button startContent={showMoreOptions ? <ChevronUp size={16} /> : <ChevronDown size={16} />} variant={'light'}
                size={'sm'} onClick={() => setShowMoreOptions(!showMoreOptions)}>
          {showMoreOptions ? 'Show less' : 'Show more'} options
        </Button>
      </div>
      <div className={style.actions}>
        <Button size={'sm'} color={'primary'} startContent={<Plus size={16} />} isDisabled={!name || name?.length < 2}
                radius={'full'} type='submit' isLoading={loading}>
          Add {date ? 'reminder' : 'task'}
        </Button>
      </div>
    </form>
  );

}
