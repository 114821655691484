import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';
import useSWR from 'swr';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/lead`;

export const leadService = {
    createLead,
    updateLead,
    updateManyLeads,
    trackLead,
    unTrackLead,
    addLeadsToList,
    removeLeadsToList,
    getOneRandomLead,
    likeLinkedinPost,
    replyLinkedinPost,
    connectListToCampaign,
    disconnectListToCampaign,
    deleteLead,
    bulkTrack,
    deleteAllLeads,
    exportLeads,
    retryDmLinkedin,
    likeTweet,
    replyTweet,
    retweetTweet,
    sendEmail,
    sendDmTwitter,
    sendDmLinkedin,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
    fetchWrapperDelete: url => fetchWrapper.delete(`${baseUrl}${url}`),
}



function getOneRandomLead(id_space, campaignId) {
    const payload = `id_space=${id_space}&campaignId=${campaignId}`;

    return fetchWrapper.get(`${baseUrl}/getOneRandomLead?${payload}`)
}

function addLeadsToList({ id_space, id_list, filter, all_leads, leads_ids }){
    const payload = `id_space=${id_space}&id_list=${id_list}`;

    return fetchWrapper.post(`${baseUrl}s/list?${payload}`, {
        filter: filter,
        leads_ids: leads_ids,
        all_leads: all_leads,
    });
}


function removeLeadsToList({ id_space, id_list, filter, all_leads, leads_ids }){
    const payload = `id_space=${id_space}&id_list=${id_list}`;

    return fetchWrapper.delete(`${baseUrl}s/list?${payload}`, {
        filter: filter,
        leads_ids: leads_ids,
        all_leads: all_leads,
    });
}

function createLead(data) {
    return fetchWrapper.post(`${baseUrl}`, {
        ...data,
    });
}

function deleteAllLeads({filter, all_leads, leads_ids}) {
    return fetchWrapper.delete(`${baseUrl}s`, {
        filter: filter,
        leads_ids: leads_ids,
        all_leads: all_leads,
    });
}

function deleteLead(id_space, id_lead) {
    const payload = `id_space=${id_space}`;

    return fetchWrapper.delete(`${baseUrl}/${id_lead}?${payload}`);
}

function updateLead(id_lead, data, extended = false) {
    const payload = `id_lead=${id_lead}&extended=${extended}`;

    return fetchWrapper.patch(`${baseUrl}/${id_lead}?${payload}`, {
        ...data,
    });
}

function updateManyLeads({ filter, all_leads, leads_ids, data, options }){
    return fetchWrapper.patch(`${baseUrl}s/update`,{
        filter: filter,
        data: data,
        options: options,
        leads_ids: leads_ids,
        all_leads: all_leads
    })

}


function connectListToCampaign(id_campaign, id_list){
    const payload = `id_campaign=${id_campaign}&id_list=${id_list}`;

    return fetchWrapper.post(`${baseUrl}/list-campaign?${payload}`);
}

function disconnectListToCampaign(id_campaign, id_list){
    const payload = `id_campaign=${id_campaign}&id_list=${id_list}`;

    return fetchWrapper.delete(`${baseUrl}/list-campaign?${payload}`);
}


function trackLead(id_lead){

    return fetchWrapper.post(`${baseUrl}/${id_lead}/track`);
}

function unTrackLead(id_lead){

    return fetchWrapper.delete(`${baseUrl}/${id_lead}/track`);
}


function bulkTrack({ filter, all_leads, leads_ids, remove_tracking }){
    return fetchWrapper.post(`${baseUrl}s/track`, {
        filter: filter,
        all_leads: all_leads,
        leads_ids: leads_ids,
        ...(remove_tracking && {remove_tracking: remove_tracking}),
    });
}


function exportLeads(id_space, query){
    return fetchWrapper.get(`${baseUrl}s/export?${query}`);
}


function likeTweet(data){
    return fetchWrapper.post(`${baseUrl}/${data?.id_lead}/twitter/${data?.id_post}/like?id_twitter_account=${data.id_twitter_account}`);
}

function retweetTweet(data){
    return fetchWrapper.post(`${baseUrl}/${data?.id_lead}/twitter/${data?.id_post}/retweet?id_twitter_account=${data.id_twitter_account}`);
}

function replyTweet(data){

    return fetchWrapper.post(`${baseUrl}/${data?.id_lead}/twitter/${data?.id_post}/reply?id_twitter_account=${data.id_twitter_account}`,{
        reply: data.reply,
    });
}

function likeLinkedinPost(data){

    return fetchWrapper.post(`${baseUrl}/${data?.id_lead}/linkedin/${data?.id_post}/like?id_linkedin_account=${data.id_linkedin_account}`);
}

function replyLinkedinPost(data){
    let payload = data.id_linkedin_account ? `&id_linkedin_account=${data.id_linkedin_account}` : '';

    return fetchWrapper.post(`${baseUrl}/${data?.id_lead}/linkedin/${data?.id_post}/reply?${payload}`,{
        reply: data.reply,
    });
}

function sendDmTwitter(id_lead, data){
    let payload = data.id_twitter_account ? `&id_twitter_account=${data.id_twitter_account}` : ``;

    return fetchWrapper.post(`${baseUrl}/${id_lead}/twitter/dm?${payload}`,{
        message: data?.message,
    });
}

function sendDmLinkedin(id_lead, data){
    let payload = data.id_linkedin_account ? `&id_linkedin_account=${data.id_linkedin_account}` : ``;

    return fetchWrapper.post(`${baseUrl}/${id_lead}/linkedin/dm?${payload}`, {
        message: data?.message,
    });
}


function retryDmLinkedin(id_lead, id_message){

    return fetchWrapper.post(`${baseUrl}/${id_lead}/linkedin/${id_message}/retry`)
}


function sendEmail(id_lead, data){

    return fetchWrapper.post(`${baseUrl}/${id_lead}/mail`,{
        from: data?.from,
        to: data?.to,
        cc: data?.cc,
        bcc: data?.bcc,
        subject: data?.subject,
        id_message: data?.id_message,
        content: data?.content,
    });
}


function addReminder({id_space, data}){
    let payload = `id_space=${id_space}`;

    return fetchWrapper.post(`${baseUrl}/reminders?${payload}`,data);
}
