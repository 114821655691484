import useTheme from '@/utils/useTheme';

const CommentIcon = ({ size, fill, isCommented, color, ...props }) => {
  // width={size} height={size} {...props}
  const { isDark } = useTheme();

  if (isCommented) {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width={size+1} height={size+1} {...props} viewBox='0 0 16 16' fill='none'>
        <path
          d='M12.3745 10.4103C11.524 11.1303 8.72803 12.9641 7.29553 13.8926V11.5023C7.29553 11.1918 7.04428 10.9398 6.73303 10.9398H6.43603C3.69103 10.9398 1.69753 9.08283 1.69753 6.52533C1.69753 3.87483 3.77353 1.79883 6.42253 1.79883L9.53278 1.80633H9.53428C12.1833 1.80633 14.2593 3.88083 14.2608 6.52833C14.2585 7.96083 13.5535 9.41133 12.3745 10.4103Z'
          fill='#1D9BF0' />
      </svg>
    );
  }
  return (
    <svg width={size} height={size} {...props} viewBox='0 0 24 24' aria-hidden='true'>
      <g>
        <path fill={isDark ? 'white' : 'black'} d='M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z' />
      </g>
    </svg>
  );
};

export default CommentIcon;
