import React, { Component } from 'react';
import style from '../../styles.module.css';
import { Avatar } from '@nextui-org/react';
import clsx from 'clsx';

class CommandList extends Component {
  constructor(props) {
    super(props);
    const orderedItems = this.orderItems(props.items);
    this.itemsRef = React.createRef();
    this.state = {
      selectedIndex: 0,
      items: orderedItems,
      selectedValue: orderedItems?.[0]?.value
    };
  }

  orderItems(items) {
    const customVariables = items?.filter(item => item.isCustom);
    const variables = items?.filter(item => !item.isCustom);
    return customVariables?.length > 0 ? [...variables, ...customVariables] : variables;
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      const orderedItems = this.orderItems(this.props.items);
      this.setState({
        selectedIndex: 0,
        items: orderedItems,
        selectedValue: orderedItems?.[0]?.value
      });
    }
  }

  onKeyDown({ event }) {
    switch (event.key) {
      case 'ArrowUp':
        //stop propagation to avoid scrolling
        event.stopPropagation();
        this.handleArrowUp();
        return true;
      case 'ArrowDown':
        //stop propagation to avoid scrolling
        event.stopPropagation();
        this.handleArrowDown();
        return true;
      case 'Enter':
        //stop propagation to avoid submitting the form
        event.stopPropagation();
        this.handleEnter();
        return true;
      default:
        return false;
    }
  }

  upHandler() {
    const selectedIndex = this.state.items.findIndex(
      item => item.value === this.state.selectedValue
    );
    const itemsCount = this.state.items.length; //this.countItems(this.props.items);
    let newSelectedIndex = selectedIndex <= 0 ? this.state.items.length - 1 : (selectedIndex - 1) % itemsCount;
    let newItemSelectedIndex = newSelectedIndex;
    if (newSelectedIndex !== 0 && this.itemsRef?.current?.children[newSelectedIndex].tagName === 'DIV') {
      newSelectedIndex = (newSelectedIndex - 1) % itemsCount;
    }

    this.setState({
      selectedValue: this.state.items[newItemSelectedIndex].value
    });
    //focus on the item
    this.itemsRef?.current?.children[newSelectedIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
  }

  downHandler() {
    const selectedIndex = this.state.items.findIndex(
      item => item.value === this.state.selectedValue
    );
    const itemsCount = this.state.items.length; //this.countItems(this.props.items);
    let newSelectedIndex = (selectedIndex + 1) % itemsCount;
    let newItemSelectedIndex = newSelectedIndex;

    //we check if next item is a <li> or a <div> if it's a <div> we skip it and increment the index
    if (this.itemsRef?.current?.children[newSelectedIndex].tagName === 'DIV') {
      newSelectedIndex = (newSelectedIndex + 1) % itemsCount;
    }
    this.setState({
      // selectedIndex: (this.state.selectedIndex + 1) % this.state.items.length,
      selectedValue: this.state.items[newItemSelectedIndex].value
    });
    //focus on the item
    this.itemsRef?.current?.children[newSelectedIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
  }

  handleArrowUp() {
    const { items, selectedValue } = this.state;
    const selectedIndex = items.findIndex(item => item.value === selectedValue);
    const newSelectedIndex = selectedIndex <= 0 ? items.length - 1 : (selectedIndex - 1) % items.length;
    this.updateSelectedItem(newSelectedIndex);
  }

  handleArrowDown() {
    const { items, selectedValue } = this.state;
    const selectedIndex = items.findIndex(item => item.value === selectedValue);
    const newSelectedIndex = (selectedIndex + 1) % items.length;
    this.updateSelectedItem(newSelectedIndex);
  }

  updateSelectedItem(newSelectedIndex) {
    const { items } = this.state;
    const itemsCount = items.length;
    let newItemSelectedIndex = newSelectedIndex;

    if (this.itemsRef?.current?.children[newSelectedIndex].tagName === 'DIV') {
      newSelectedIndex = (newSelectedIndex + 1) % itemsCount;
    }

    this.setState({
      selectedValue: items[newItemSelectedIndex].value
    });

    this.itemsRef?.current?.children[newSelectedIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
  }

  handleEnter() {
    this.selectItem(this.state.selectedValue);
  }

  selectItem(value) {
    const { items } = this.state;
    const index = items.findIndex(item => item.value === value);
    const item = items[index];

    if (item) {
      this.props.command(item);
    }
  }

  render() {
    const { items, selectedValue } = this.state;
    const isUser = items?.find(item => item.isUser);
    const customVariables = items?.filter(item => item.isCustom);
    const variables = items?.filter(item => !item.isCustom);


    return (
      <ul ref={this.itemsRef} className={clsx(
        'z-10 relative inline-flex flex-col items-center justify-start subpixel-antialiased box-border outline-none text-small bg-content1 rounded-2xl shadow-medium w-full p-2 min-w-[200px] max-w-[250] max-h-[300px] overflow-auto')
      }>
        {items?.length === 0 && (
          <>
            {isUser && (
              <div className={style.emptyUser}>
                <span>No users found</span>
              </div>
            )}
            {!isUser && (
              <div className={style.empty}>
                <span>No variables found</span>
              </div>
            )}
          </>

        )}
        {variables?.length > 0 && (
          <>
            <div
              className={'w-full flex text-xs items-center justify-start px-1 pt-1.5 pb-1 text-black/80 dark:text-white/80'}>
              {isUser ? 'Team members' : 'Default variables'}
            </div>
            {variables.map((item) => {
              return (
                <li
                  id={`slashCommand_${item.value}`}
                  key={item.value}
                  onClick={() => this.selectItem(item.value)}
                  className={clsx(
                    'flex-row w-full border-1 rounded-lg px-2 py-1.5 flex items-center justify-start space-x-2 cursor-pointer transition-all duration-100 ease-in-out',
                    'hover:bg-stone-100 hover:border-stone-200 hover:cursor-pointer dark:hover:bg-stone-800 dark:hover:border-stone-700',
                    selectedValue === item.value && 'bg-stone-100 border-stone-200 dark:bg-stone-800 dark:border-stone-700',
                    selectedValue !== item.value && 'border-transparent bg-transparent'
                  )}
                >
                  {(item?.avatar_url || item?.handle) && (
                    <Avatar radius={'sm'} text={item?.handle} src={item.avatar_url} size={'sm'} />
                  )}
                  {item?.isUser && (
                    <div className={'flex flex-col items-start'}>
                      <span className={'flex-1 text-xs font-medium truncate'}>{item.handle}</span>
                      <span
                        className={'flex-1 text-[10px] font-normal truncate text-black/80 dark:text-white/80'}>{item.email}</span>
                    </div>
                  )}
                  {!item?.isUser && (
                    <span className={'flex-1 text-small font-normal truncate'}>
                              {item.title}
                          </span>
                  )}
                </li>
              );
            })}
          </>
        )}
        {customVariables?.length > 0 && (
          <>
            <div
              className={'w-full text-xs flex items-center justify-start px-1 pb-1 pt-1.5 mt-2 text-black/80 dark:text-white/80'}>
              Customs variables
            </div>
            {customVariables.map((item) => {
              return (
                <li
                  id={`slashCommand_${item.value}`}
                  key={item.value}
                  onClick={() => this.selectItem(item.value)}
                  className={clsx(
                    'gap-2 flex-row flex w-full border-1 rounded-lg px-2 py-1.5 items-center justify-start space-x-2 cursor-pointer transition-all duration-100 ease-in-out',
                    'hover:bg-stone-100 hover:border-stone-200 hover:cursor-pointer hover:dark:bg-stone-800 hover:dark:border-stone-700',
                    selectedValue === item.value && 'bg-stone-100 border-stone-200 dark:bg-stone-800 dark:border-stone-700',
                    selectedValue !== item.value && 'border-transparent bg-transparent'
                  )}
                >
                  {(item?.avatar_url || item?.handle) && (
                    <div className={style.icon}>
                      <Avatar squared={true} text={item?.handle} src={item.avatar_url} size={18} />
                    </div>
                  )}
                  {item?.isUser && (
                    <div className={'flex flex-col'}>
                      <span className={'flex-1 text-small font-normal truncate'}>{item.handle}</span>
                      <span
                        className={'flex-1 text-xs font-normal truncate text-black/80 dark:text-white/80'}>{item.email}</span>
                    </div>
                  )}
                  {!item?.isUser && (
                    <span className={'flex-1 text-small font-normal truncate'}>
                              {item.title}
                          </span>
                  )}
                </li>
              );
            })}
          </>
        )}
      </ul>
    );
  }
}

export default CommandList;
