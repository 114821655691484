import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/sequence`;
const baseUrlEmpty = `${apiUrl}`;

export const sequenceService = {
    createSequenceStep,
    updateSequenceStep,
    updateSequenceUserStep,
    updateSequenceUser,
    retrySequencesLeadsInError,
    updateSequence,
    sendTestSequence,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrlEmpty}${url}`),
}

function createSequenceStep({ id_space, id_campaign, id_sequence, data }) {
    const payload = `id_space=${id_space}`;

    return fetchWrapper.post(`${baseUrlEmpty}/campaign/${id_campaign}/sequence/${id_sequence}/step?${payload}`, {
        ...data,
    });
}

function updateSequence({ id_space, id_campaign, data }){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.put(`${baseUrlEmpty}/campaign/${id_campaign}/sequence?${payload}`, {
        ...data,
    })

}

function updateSequenceStep({ id_campaign, id_space, id_step, id_sequence, data }) {
    const payload = `id_space=${id_space}`;

    return fetchWrapper.patch(`${baseUrlEmpty}/campaign/${id_campaign}/sequence/${id_sequence}/step/${id_step}?${payload}`, {
        ...data,
    });
}


function updateSequenceUserStep({ id_campaign, id_step, data }) {
    return fetchWrapper.patch(`${baseUrlEmpty}/campaign/${id_campaign}/step-lead/${id_step}`, {
        ...data,
    });
}


function updateSequenceUser({ id_campaign, id_sequence_user, data }) {
    return fetchWrapper.patch(`${baseUrlEmpty}/campaign/${id_campaign}/sequence-lead/${id_sequence_user}`, {
        ...data,
    });
}

function sendTestSequence({ id_campaign, id_space, id_email_account, id_sequence_step, email }){
    let payload = `id_space=${id_space}&email=${email}&id_email_account=${id_email_account}`;

    return fetchWrapper.post(`${baseUrlEmpty}/campaign/${id_campaign}/sequence/${id_sequence_step}/test?${payload}`);
}


function retrySequencesLeadsInError(id_campaign){
    return fetchWrapper.post(`${baseUrlEmpty}/campaign/${id_campaign}/sequences-leads/retry-in-error`);
}
