import React from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import useTheme from '@/utils/useTheme';
import {Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react";

export default function EmojiPicker({ onEmojiSelect, placement, children }) {
  const { isDark } = useTheme();

  return (
    <Popover placement={placement}>
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent className={'p-0'}>
        <Picker data={data} theme={isDark ? 'dark' : 'light'} onEmojiSelect={onEmojiSelect} />
      </PopoverContent>
    </Popover>
  );
}
