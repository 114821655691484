import style from '../style.module.css';
import { X } from 'react-feather';
import NoteEditor from '@/components/editorV2/note';
import { renderNoteContent } from '@/components/activity/lines/note';
import {Button} from '@nextui-org/react';

export default function ActivityInput({ onSubmit, replyNote, editNote, onRemoveReplyNote }) {

  return (
    <div className={style.inputWrap}>
      {replyNote && (
        <div className={style.editNote}>
          <span className={style.renderContent}
                dangerouslySetInnerHTML={{ __html: renderNoteContent(replyNote?.data) }} />
          <div className={style.editActions}>
            <Button isIconOnly onClick={onRemoveReplyNote} variant={'light'} size={'sm'}>
              <X size={16} />
            </Button>
          </div>
        </div>
      )}
      <div className={'relative w-full'}>
        {editNote && (
          <div className={'absolute top-0 right-0 z-10'}>
            <Button isIconOnly onClick={onRemoveReplyNote} variant={'light'} size={'sm'}>
                <X size={16} />
            </Button>
          </div>
        )}
        <NoteEditor onReset={() => null} isEditMode={!!editNote} content={editNote?.data?.content}
                    onSubmit={onSubmit} />
      </div>
    </div>
  );
}
