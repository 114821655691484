import {Spinner} from "@nextui-org/react";

const Loading = ({size}) => {
  return (
    <span className={'flex items-center justify-center'}>
      <Spinner size={size}/>
    </span>
  );
};

export default Loading;
