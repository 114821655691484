import style from '@/styles/leadLayer.module.css';
import { MoreHorizontal, X } from 'react-feather';
import React, { useCallback, useEffect, useState } from 'react';
import { leadService } from '@/services/lead.service';
import { useLead } from '@/utils/useLead';
import { useSpace } from '@/utils/useSpace';
import { useRouter } from 'next/router';
import useKeyPress from '@/utils/useKeyPress';
import Feed from '@/components/feed';
import useCustomToasts from '@/utils/useCustomToasts';
import AutosizeInput from 'react-input-autosize';
import dynamic from 'next/dynamic';
import LeadForm from '@/components/ui/leadForm/leadForm';
import ContentLoader from 'react-content-loader';
import TagsCell from '@/components/ui/tagsCell';
import StatusCell from '@/components/ui/statusCell/statusCell';
import LeadValue from '@/components/pipeline/leadValue';
import dayjs from 'dayjs';
import ReminderButton from '@/components/ui/reminderButton/reminderButton';
import ListsCell from '@/components/ui/listsCell';
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Kbd,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spacer,
  Tab,
  Tabs,
  Tooltip
} from '@nextui-org/react';
import useTheme from '@/utils/useTheme';
import clsx from 'clsx';

const CompanyProfile = dynamic(() => import('@/components/ui/companyProfile/companyProfile'));
const TabIcebreakers = dynamic(() => import('@/components/ui/icebreaker/tabIcebreakers'));
const TabLeadActivity = dynamic(() => import('@/components/activity'));


function LeadProfile({ mode }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [iceBreaker, setIceBreaker] = useState('');
  const [iceBreaker2, setIceBreaker2] = useState('');
  const [iceBreaker3, setIceBreaker3] = useState('');
  const [iceBreaker4, setIceBreaker4] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [selectedTab, setSelectedTab] = useState('connect');
  const [leftNavValue, setLefNavValue] = useState('personal');
  const [createData, setCreateData] = useState(null);

  const arrowRight = useKeyPress('ArrowRight', { inputMode: true });
  const arrowLeft = useKeyPress('ArrowLeft', { inputMode: true });

  //Hooks
  const {
    closeLead,
    lead,
    leads,
    deleteLeadLocal,
    openLead,
    leadIsOpen,
    createLeads,
    selectedList,
    leadOptions,
    updateLead,
    uniqueLead: data,
    uniqueError: error,
    uniqueMutate: mutateLead,
    uniqueLoading: uniqueLoading
  } = useLead();
  const { setToast } = useCustomToasts();
  const { id_space } = useSpace();
  const router = useRouter();
  const isLoading = (mode === 'update' && (uniqueLoading || !data)) || loading;
  const centerRef = React.useRef(null);

  //get the height of the centerRef
  const [centerHeight, setCenterHeight] = useState(0);
  useEffect(() => {
    if (centerRef.current) {
      setCenterHeight(centerRef.current.clientHeight);

    }
  }, [centerRef.current]);

  useEffect(() => {
    if (arrowRight) {
      nextLead();
    }
    if (arrowLeft) {
      previousLead();
    }
  }, [arrowRight, arrowLeft]);

  useEffect(() => {
    if (router?.pathname?.startsWith('/campaigns/'))
      setCampaignId(router.query?.id);
    else
      setCampaignId(null);
  }, [router]);


  useEffect(() => {
    if (data && lead !== data?.id) {
      resetForm();
    }
  }, [lead, data]);

  useEffect(() => {
    if (leadOptions) {
      if (leadOptions?.activeTab) {
        if (leadOptions?.activeTab === 'icebreakers') {
          setSelectedTab('icebreakers');
        } else if (leadOptions?.activeTab === 'activities') {
          setSelectedTab('activities');
        } else if (leadOptions?.activeTab === 'connect') {
          setSelectedTab('connect');
        }
      }
    } else {
      setSelectedTab('connect');
    }
  }, [leadOptions]);


  function setFormData(data) {
    setFirstName(data?.first_name);
    setLastName(data?.last_name);
    setIceBreaker(data?.icebreaker);
    setIceBreaker2(data?.icebreaker_2);
    setIceBreaker3(data?.icebreaker_3);
    setIceBreaker4(data?.icebreaker_4);
    setNotes(data?.notes);
  }

  useEffect(() => {
    if (data && !error && !uniqueLoading && lead === data?.id) {
      setFormData(data);
    }
    if (error) {
      setToast({
        type: 'error',
        text: 'An error occured while getting the lead, please try again later.'
      });
      closeLead();
    }
  }, [data]);

  function handleCreate(e) {
    if (e?.stopPropagation) e?.stopPropagation();
    if (e?.preventDefault) e?.preventDefault();
    if (loading) return null;

    setLoading(true);
    leadService.createLead({
      ...createData,
      first_name: firstName,
      last_name: lastName,
      icebreaker: iceBreaker,
      icebreaker_2: iceBreaker2,
      icebreaker_3: iceBreaker3,
      icebreaker_4: iceBreaker4,
      notes: notes,
      id_campaign: campaignId,
      ...(selectedList?.id && { id_list: selectedList.id })
    }).then(res => {
      closeLead();
      createLeads([res]);
      resetForm();
      setToast({
        text: 'Your leads has been created',
        type: 'success'
      });

    }).catch(err => {
      setToast({
        text: err?.message ?? 'Something went wrong',
        type: 'error'
      });
    }).finally(() => {
      setLoading(false);
    });
  }

  function handleDelete() {
    setLoading(true);
    leadService.deleteLead(id_space, lead).then(r => {
      closeLead();
      deleteLeadLocal(lead);
      resetForm();
      setToast({
        text: 'Your leads has been deleted',
        type: 'success'
      });
    }).catch(e => {
      setToast({
        text: e?.message ?? 'There was an error deleting your leads',
        type: 'error'
      });
    }).finally(() => {
      closeLead();
      setLoading(false);
    });
  }


  async function handleUpdate(e, optimisticData) {
    if (e?.stopPropagation) e?.stopPropagation();
    if (e?.preventDefault) e?.preventDefault();

    const leadOptimistic = {
      id: lead,
      ...optimisticData,
      ...((optimisticData?.email && optimisticData?.email !== data?.email && !optimisticData?.email_bounce_score) && {
        email_bounce_score: null
      })
    };

    try {
      if (mode === 'create') {
        setCreateData({ ...createData, ...optimisticData });
        return null;
      }
      if (isLoading) return null;
      await updateLead(leadOptimistic);
    } catch (e) {
      setToast({
        text: 'Something went wrong',
        type: 'error'
      });
    }
  }

  function nextLead() {
    handleClose(true);
    //search lead index
    if (leads?.leads?.length <= 0 || !leads?.leads) {
      return closeLead();
    }
    ;
    const index = leads?.leads?.findIndex(l => l.id === lead);
    //if index is not the last one, get the next lead
    if (index !== leads.length - 1) {
      const nextLead = leads.leads[index + 1];
      if (!nextLead) return closeLead();
      openLead(nextLead?.id, 'update');
    } else {
      closeLead();
      //if index is the last one, get the first lead
      // const firstLead = leads.leads[0];
      // openLead(firstLead?.id, 'update');
    }
  }

  function previousLead() {
    handleClose(true);
    //search lead index
    if (leads?.leads?.length <= 0 || !leads?.leads) {
      return closeLead();
    }
    ;

    const index = leads?.leads?.findIndex(l => l.id === lead);
    //if index is not the first one, get the previous lead
    if (index !== 0) {
      const previousLead = leads?.leads[index - 1];
      if (!previousLead) return closeLead();
      openLead(previousLead?.id, 'update');
    } else {
      //if index is the first one, get the last lead
      closeLead();
      // const lastLead = leads.leads[leads.length - 1];
      // openLead(lastLead?.id, 'update');
    }

  }


  function resetForm() {
    setFirstName('');
    setLastName('');
    setIceBreaker('');
    setIceBreaker2('');
    setIceBreaker3('');
    setIceBreaker4('');
    setNotes('');
  }


  function countIceBreakers() {
    let count = 0;
    if (iceBreaker) count++;
    if (iceBreaker2) count++;
    if (iceBreaker3) count++;
    if (iceBreaker4) count++;
    return count;
  }

  function handleLeftNavChange(key) {
    setLefNavValue(key);
  }

  function handleClose(doNotClose = false) {
    handleUpdate();
    if (doNotClose) return;
    closeLead();
  }

  async function unTrackLead() {
    try {
      await leadService.unTrackLead(lead);
      await updateLead({
        id: lead,
        is_tracked: false
      });
      closeLead();
    } catch (e) {
      setToast({
        text: e?.message ?? 'Error while untracking lead',
        type: 'error'
      });
    }

  }

  function handleDropdown(key) {
    if (key === 'tracking')
      unTrackLead();
    if (key === 'delete')
      setShowDeleteModal(true);
  }


  function renderLastSyncText(lead, type) {

    if (type === 'linkedin') {
      if (lead?.linkedin?.last_sync_date) {
        return `Synced at: ${dayjs(lead?.linkedin?.last_sync_date).format('DD MMMM YYYY HH:mm')}`;
      }
      if (!lead?.linkedin && !lead?.linkedin_url) {
        return 'No LinkedIn account';
      }
      if (lead?.linkedin && !lead?.linkedin?.is_tracked || !lead?.linkedin) {
        return 'Account is not tracked';
      }
      if (lead?.linkedin && !lead?.linkedin?.last_sync_date) {
        return 'Not synced yet';
      }
    }
    if (type === 'twitter') {
      if (lead?.twitter?.last_sync_date) {
        return `Synced at: ${dayjs(lead?.twitter?.last_sync_date).format('DD MMMM YYYY HH:mm')}`;
      }
      if (!lead?.twitter) {
        return 'No Twitter account';
      }
      if (lead?.twitter && !lead?.twitter?.is_tracked) {
        return 'Account is not tracked';
      }
      if (lead?.twitter && !lead?.twitter?.last_sync_date) {
        return 'Not synced yet';
      }
    }
    if (type === 'twitter_company') {
      if (lead?.twitter_company?.last_sync_date) {
        return `Synced at: ${dayjs(lead?.twitter_company?.last_sync_date).format('DD MMMM YYYY HH:mm')}`;
      }
      if (!lead?.twitter_company) {
        return 'No Twitter account';
      }
      if (lead?.twitter_company && !lead?.twitter_company?.is_tracked) {
        return 'Account is not tracked';
      }
      if (lead?.twitter_company && !lead?.twitter_company?.last_sync_date) {
        return 'Not synced yet';
      }
    }
  }

  return (
    <Modal
      size={'full'}
      backdrop={'transparent'}
      isDismissable={false}
      hideCloseButton={true}
      classNames={{
        'wrapper': 'z-[10000]'
      }}
      isOpen={leadIsOpen}
      onOpenChange={handleClose}
    >
      <ModalContent>
        <div className={style.wrap}>
          <Modal
            closeButton
            backdrop={'opaque'}
            classNames={{
              'wrapper': 'z-[10002]',
              'backdrop': 'z-[10001]'
            }}
            aria-labelledby='modal-title'
            isOpen={showDeleteModal}
            onOpenChange={() => setShowDeleteModal(false)}
          >
            <ModalContent>
              <ModalHeader>
                Delete lead
              </ModalHeader>
              <ModalBody>
                <span>Are you sure you want to delete <b>{firstName} {lastName}</b>? This action cannot
                  be undone.</span>
              </ModalBody>
              <ModalFooter>
                <Button variant={'light'} onClick={() => setShowDeleteModal(false)}>
                  Cancel
                </Button>
                <Button isLoading={loading} onClick={handleDelete} color={'danger'}>
                  Confirm
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <div className={style.banner}>
            <div className={style.closeBanner}>
              {leads?.leads?.length > 0 && (
                <Tooltip placement={'bottom'} css={{ zIndex: 99999 }} content={'Previous lead'}>
                  <Button isIconOnly variant={'light'} size='sm' onClick={() => previousLead()}>
                    <Kbd keys={['left']} />
                  </Button>
                </Tooltip>
              )}
              {leads?.leads?.length > 0 && (
                <Tooltip placement={'bottom'} css={{ zIndex: 99999 }}
                         content={'Next lead'}>
                  <Button isIconOnly variant={'light'} size='sm' onClick={() => nextLead()}>
                    <Kbd keys={['right']} />
                  </Button>
                </Tooltip>
              )}
              <Tooltip placement={'bottom'} css={{ zIndex: 99999 }}
                       content={<div
                         style={{
                           alignItems: 'center',
                           justifyContent: 'center',
                           display: 'flex',
                           flexDirection: 'row'
                         }}>
                         <Kbd keys={['escape']} className={'mr-2'} />Close</div>}>
                <Button isIconOnly variant={'light'} size='sm' onClick={() => handleClose()}>
                  <X size={24} />
                </Button>
              </Tooltip>
            </div>
            <div className={style.contentHolder}>
              {mode === 'update' && (
                <div className={style.topLine}>
                  <div className={style.item}>
                    <ListsCell showContentLoader={isLoading} id_list={selectedList?.id} id_lead={data?.id}
                               lead_lists={data?.lists}
                               status={data?.lists?.find(tg => selectedList ? tg.id_list === selectedList?.id : tg.id_list === null)} />
                  </div>
                  <div className={style.separator}>/</div>
                  <div className={style.item}>
                    <TagsCell id_list={selectedList?.id} id_lead={data?.id} lead_tags={data?.tags}
                              status={data?.tags?.find(tg => selectedList ? tg.id_list === selectedList?.id : tg.id_list === null)} />
                  </div>
                </div>
              )}
              <div className={style.leadMainInfo}>
                <div className={style.leadAvatar}>
                  <div>
                    <Avatar className={'w-24 h-24 text-large'} src={data?.avatar_url}
                            name={`${data?.first_name ? data?.first_name[0] ?? '' : ''}${data?.last_name ? data?.last_name[0] ?? '' : ''}`} />
                  </div>
                </div>
                <div className={style.leadActionsWrap}>
                  <form className={style.leadNameWrap}>
                    <InputName id={'first_name'} onBlur={handleUpdate} autofocus width={'100%'} loading={isLoading}
                               value={firstName}
                               setValue={setFirstName}
                               placeholder={'First name'} />
                    <Spacer x={1.5} />
                    <InputName id='last_name' onBlur={handleUpdate} width={'100%'} value={lastName} loading={isLoading}
                               hideLoader
                               setValue={setLastName}
                               placeholder={'Last name'} />
                  </form>
                  <div className={style.leadQuicksActions}>
                    {mode === 'update' && (
                      <div className={style.statusWrap}>
                        <ReminderButton type={'popover'} id_list={selectedList?.id} id_lead={data?.id}
                                        lead_tags={data?.status} status={data?.status?.[0]} />
                      </div>
                    )}
                    {mode === 'update' && (
                      <div className={style.statusWrap}>
                        <StatusCell id_list={selectedList?.id} id_lead={data?.id} lead_status={data?.status}
                                    status={data?.status?.[0]} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(style.nav, 'px-4')}>
              <div className={style.left}>
                <Tabs
                  variant={'underlined'}
                  selectedKey={leftNavValue}
                  classNames={{
                    base: 'h-[41px]'
                  }}
                  onSelectionChange={handleLeftNavChange}
                  initialValue={leftNavValue}
                >
                  <Tab className={'h-[41px]'} title={'Personal'} key={'personal'} />
                  {mode !== 'create' && (
                    <Tab className={'h-[41px]'} title={'Company'} key={'company'} />
                  )}
                </Tabs>
              </div>
              <div className={style.center}>
                <Tabs
                  variant={'underlined'}
                  classNames={{
                    base: 'h-[41px]'
                  }}
                  selectedKey={selectedTab}
                  onSelectionChange={r => setSelectedTab(r)}
                  initialValue={selectedTab}
                >
                  <Tab className={'h-[41px]'} title={'Connect'} key={'connect'} />
                  {mode !== 'create' && (
                    <Tab className={'h-[41px]'} title={'Activities'} key={'activities'} />
                  )}
                  <Tab className={'h-[41px]'} title={`Icebreakers (${countIceBreakers()})`} key={'icebreakers'} />
                </Tabs>
              </div>
              <div className={style.right}>
                {mode === 'update' && (
                  <div className={style.leadValue}>
                    <LeadValue lead={data} />
                  </div>
                )}
                {mode === 'create' && (
                  <Button isLoading={loading} radius={'full'} color={'primary'} size={'sm'} onClick={handleCreate}>
                    Create
                  </Button>
                )}
                {mode === 'update' && (
                  <Dropdown>
                    <DropdownTrigger>
                      <div className={style.moreButton}>
                        <MoreHorizontal size={20} />
                      </div>
                    </DropdownTrigger>
                    <DropdownMenu onAction={handleDropdown} aria-label='Actions'>
                      <DropdownSection title="Feed last sync" showDivider>
                        <DropdownItem
                          key='feedLinkedin'
                          description={renderLastSyncText(data, 'linkedin')}
                        >
                          LinkedIn
                        </DropdownItem>
                        <DropdownItem
                          key='feedTwitter'
                          description={renderLastSyncText(data, 'twitter')}
                        >
                          Twitter
                        </DropdownItem>
                        <DropdownItem
                          key='feedTwitterCompany'
                          description={renderLastSyncText(data, 'twitter_company')}
                        >
                          Twitter Company
                        </DropdownItem>
                      </DropdownSection>
                      <DropdownSection title={`Action${data?.is_tracked ? 's' : ''}`}>
                        {data?.is_tracked && (
                          <DropdownItem key='tracking' color='danger'>
                            Remove from tracking
                          </DropdownItem>
                        )}
                        <DropdownItem key='delete' color='danger'>
                          Delete lead
                        </DropdownItem>
                      </DropdownSection>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
          </div>
          <div className={clsx(style.row, 'pl-4')}>
            <div className={style.left}>
              {leftNavValue === 'personal' && (
                <LeadForm isLoading={isLoading} mode={mode} onSave={handleUpdate} lead={data} />)}
              {leftNavValue === 'company' && (<CompanyProfile leadId={lead} />)}
            </div>
            <div ref={centerRef} className={style.center}>
              {mode === 'create' && (
                <div className={style.centerPlaceholder}>
                  <div className={style.centerPlaceholderText}>
                    <h3>Create lead first</h3>
                    <p>
                      You need to save the lead by clicking on 'create' to interact with it, add icebreakers or add
                      notes.
                    </p>
                  </div>
                </div>
              )}
              {(selectedTab === 'connect' || selectedTab === 'icebreakers') && (
                <div className={'p-4'}>
                <Feed
                  rightSide={selectedTab === 'icebreakers' && <TabIcebreakers
                    height={centerHeight}
                    onSave={(e, data) => handleUpdate(e, data)}
                    loading={isLoading}
                    icebreaker1={iceBreaker} icebreaker2={iceBreaker2} icebreaker3={iceBreaker3}
                    icebreaker4={iceBreaker4} setIcebreaker1={setIceBreaker}
                    setIcebreaker2={setIceBreaker2} setIcebreaker3={setIceBreaker3} setIcebreaker4={setIceBreaker4} />}
                  mutate_lead={mutateLead} hideCompany={true} leadId={lead} leadData={data} />
                </div>
              )}
              {(selectedTab === 'activities' && mode !== 'create') && <div className={'ml-4 h-full border-l-1 border-stone-200 dark:border-stone-900'}> <TabLeadActivity id_lead={lead} /></div>}
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}


function InputName({ id, loading, placeholder, value, setValue, onBlur, hideLoader }) {
  const { isDark } = useTheme();

  function handleChangeFn(r) {
    if (r?.target?.value?.length > 50) {
      return;
    }
    setValue(r?.target?.value);
  }

  function handleBlur(r) {
    if (onBlur) {
      onBlur(null, {
        [id]: r?.target?.value
      });
    }
  }

  //transform handle change with useCallback
  const handleChange = useCallback(handleChangeFn, []);


  // const onOptimisedHandleChange = debounce(handleChange, 500);

  if (hideLoader && loading)
    return null;
  else if (loading) {
    return (
      <ContentLoader speed={4}
                     width={200}
                     height={53}
                     backgroundColor={isDark ? '#171717' : '#e9ecef'}
                     foregroundColor={isDark ? '#343333' : '#c7c7c7'}
                     viewBox='0 0 200 41'>
        <rect x='-1' y='0' rx='10' ry='10' width='200' height='36' />
      </ContentLoader>
    );
  }
  return (
    <AutosizeInput
      onBlur={handleBlur}
      name='form-field-name'
      type='text'
      autoComplete={'false'}
      placeholder={placeholder}
      className={style.InputNameWrap}
      inputClassName={style.InputName}
      value={value ?? ''}
      onChange={handleChange}
    />
  );
}


export default LeadProfile;
