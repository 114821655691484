import notFound from "../public/animations/notFound.json";
import notFoundLight from "../public/animations/notFoundLight.json";
import invitationLoader from "../public/animations/invitation_loader.json";
import invitation from "../public/animations/invitation.json";
import Lottie from "react-lottie";
import React from 'react';
import useTheme from '@/utils/useTheme';

function Animation({height, width, animationData, type, loop, isPaused}){
  const { isDark } = useTheme();

  let dataAnim = animationData;

    if(type === 'notFound')
        dataAnim = isDark ? notFound : notFoundLight;
    else if(type === 'loaderLight')
        dataAnim = notFound;
    else if(type === 'invitationLoader')
        dataAnim = invitationLoader;
    else if(type === 'invitation')
        dataAnim = invitation;
    // else if(loader)
    //     dataAnim = lottieLoader;
  if(type === 'AiFlow'){
    return (
      <video style={{pointerEvents: 'none'}} autoPlay loop muted disablePictureInPicture width={width} height={height}>
        <source  src={'https://breakcold.s3.eu-west-1.amazonaws.com/AIFLOW.mp4'} type={'video/mp4'}/>
      </video>
    )
  }

    return (
        <Lottie
          isPaused={isPaused}
            isClickToPauseDisabled={true}
            options={{
                loop: loop !== undefined ? loop: true,
                autoplay: true,
                animationData: dataAnim
            }}
            height={height}
            width={width}
        />
    )
}


export default Animation;
