import getConfig from '../utils/getConfig';
import { fetchWrapper } from './fetch-wrapper';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/reminder`;

export const remindersService = {
  fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
  fetchWrapperGetEmpty: url => fetchWrapper.get(`${apiUrl}${url}`),
  createReminder,
  updateReminder,
  deleteReminder,
  getReminder,
  getReminders
};


function createReminder(data) {
  return fetchWrapper.post(`${baseUrl}s`, data);
}

function updateReminder(id, data) {
  return fetchWrapper.put(`${baseUrl}s/${id}`, data);
}

function deleteReminder(id) {
  return fetchWrapper.delete(`${baseUrl}s/${id}`);
}

function getReminder(id) {
  return fetchWrapper.get(`${baseUrl}s/${id}`);
}

function getReminders() {
  return fetchWrapper.get(`${baseUrl}s`);
}
