import style from '@/components/social/linkedin/style.module.css';
import { Carousel } from 'react-responsive-carousel';
import {useState, useEffect} from 'react';
import PlayerVideo from './video';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getImageUrl } from '@/utils/helpers';

export default function RenderContent({ data }){
    const [docPages, setDocPages] = useState([]);


    async function getDocPages(docs){
        let manifest = await fetch(docs?.manifestUrl).then(res => res.json());
        manifest = await fetch( manifest?.perResolutions[2]?.imageManifestUrl).then(res => res.json());
        setDocPages(manifest?.pages);
    }

    useEffect(() => {
        if(data?.['com.linkedin.voyager.feed.render.DocumentComponent']) {
            getDocPages(data?.['com.linkedin.voyager.feed.render.DocumentComponent']?.document)
        }
    }, [data])


    if(data?.['com.linkedin.voyager.feed.render.ArticleComponent']){
        let article = data?.['com.linkedin.voyager.feed.render.ArticleComponent'];
        return (
          <a target={"_blank"} href={article?.navigationContext?.actionTarget} className={style.article}>
              <div className={style.largeImage}>
                  <img src={getImageUrl(article?.largeImage?.attributes[0]?.vectorImage, 'HIGH')}/>
              </div>
              <div className={style.meta}>
                  <div className={style.title}>
                      {article?.title?.text}
                  </div>
                  <div className={style.subtitle}>
                      {article?.subtitle?.text}
                  </div>
              </div>
          </a>
        )
    }

    if(data?.['com.linkedin.voyager.feed.render.ImageComponent']){
        let images = data?.['com.linkedin.voyager.feed.render.ImageComponent']?.images;
        return (
          <div className={`${style.images} ${style[`size_${images?.length > 4 ? 4 : images?.length }`]}`}>
              {images?.map(image => {
                  let url = getImageUrl(image?.attributes[0]?.vectorImage, 'HIGH');
                  return (
                    <div key={`${url}_img`} className={style.image}>
                        <img src={url}/>
                    </div>
                  )})}
          </div>
        )
    }

    if(data?.['com.linkedin.voyager.feed.render.DocumentComponent'] && docPages?.length > 0){
        return (
          <div className={`${style.documents}`}>
              <Carousel showThumbs={false} autoPlay={false} stopOnHover={true} showArrows={true}>
                  {docPages?.map(page => {
                      return(
                        <div key={page} className={style.documents}>
                            <img src={page}/>
                        </div>
                      )
                  })}
              </Carousel>
          </div>
        )
    }

    if(data?.['com.linkedin.voyager.feed.render.LinkedInVideoComponent']){
        let video = data?.['com.linkedin.voyager.feed.render.LinkedInVideoComponent'];
        return (
          <div className={`${style.videoWrap}`}>
              <PlayerVideo video={video.videoPlayMetadata}/>
          </div>
        )
    }

    return null;
}
