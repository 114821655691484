import { useMemo, useRef } from 'react';
import style from './avatar.module.css';
import cn from 'classnames';


export default function Avatar({size, src, text, squared, isBreakcold, ...props}){
    const selfRef = useRef(null);
    let width = typeof size === 'string' ? {'sm':40, 'md':42}[size] : size?? 40;
    let height =  typeof size === 'string' ? {'sm':40, 'md':42}[size] : size?? 40;
    // src = src?? '/static/placeholder/avatar.png';

    function handleError(){
        // hide image
        selfRef.current.style.display = 'none';
    }


    function getInitials(){
        let initials = '';
        if(text){
            text = text.trim();
            //remove every special character except space
            text = text.replace(/[^a-zA-Z ]/g, "");
            let words = text.split(' ');
            if(words && words.length > 1){
                initials = words[0][0] + words[1][0];
            }else{
                initials = words[0][0];
            }

            //verify if initials contains an undefined value
            if(initials && initials?.includes('undefined')){
                initials = '';
            }
        }
        return initials;
    }

    const initials = useMemo(() => getInitials(), [text]);

    return (
      <div style={{width: width, height: height, minWidth: width, minHeight: height}} className={cn(style.wrap, squared && style.squared, isBreakcold && style.breakcold)}>
          {isBreakcold && <img src={'/breakcold_assets/breakcold_fill_blue_simple.svg'} height={height} width={width}/> }
          {(!isBreakcold && src) && <img onError={handleError} ref={selfRef} src={src} width={width} height={height} className={cn(style.img)} {...props}/>}
          {!isBreakcold && <div style={{fontSize: height/2.3}} className={style.initials}>{initials}</div>}
      </div>
    )
}
