import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAOJTLTb5TDQ9KSetGfsfOieqJnbbkawIE",
  authDomain: "auth.breakcold.com",
  projectId: "breakcold",
  storageBucket: "breakcold.appspot.com",
  messagingSenderId: "489575096070",
  appId: "1:489575096070:web:5b46dbb0841f775a2c8887",
  measurementId: "G-D42S072YJ9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);
const storage = getStorage(app);


export {
  auth,
  db,
  storage,
};
