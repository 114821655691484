import style from '@/styles/leadForm/email.module.css';
import formStyle from '@/styles/leadForm/leadForm.module.css';
import Loading from "@/components/ui/loading";
import { Copy, ExternalLink, Linkedin, Twitter, X } from 'react-feather';
import { copyTextToClipboard } from '@/components/ui/leadForm/leadForm';
import { memo, useEffect, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import { extractTwitterUsername, isValidTwitterUrl, isValidTwitterUsername, removeAt } from '@/utils/validator';
import lodash from 'lodash';
import {Avatar} from '@nextui-org/react';
import { Tooltip } from "@nextui-org/react";


const TwitterInput = memo(({ lead, label, accountType, id, onEvent, isCustom, deleteInput, value, twitter }) => {
    const { setToast } = useCustomToasts();
    const [inputValue, setInputValue] = useState(value ?? '');
    const [twitterAccount, setTwitterAccount] = useState(twitter ?? null);


    useEffect(() => {
        if(value){
            setInputValue(value);
        } else {
            setInputValue('');
        }
        if(twitter) {
            if(twitter?.username?.toLowerCase() !== value?.toLowerCase()) {
                setTwitterAccount(null);
            } else
                setTwitterAccount(twitter);
        } else {
            setTwitterAccount(null);
        }
    }, [value, twitter])


    function parseTwitter(username) {
        if (!username) return;
        let tmpUserName = username;

        if (isValidTwitterUrl(tmpUserName)) {
            return extractTwitterUsername(tmpUserName);
        } else {
            tmpUserName = removeAt(tmpUserName);
            if (!isValidTwitterUsername(tmpUserName)) {
                setToast({
                    text: 'Please enter a valid twitter username',
                    type: 'error'
                });
                return null;
            } else {
                return tmpUserName;
            }
        }
    }

    function handleOnBlur(r) {
        let twitterHandle = parseTwitter(r?.target?.value);
        if (onEvent) {
            onEvent({
                value: twitterHandle??'',
                id: accountType === 'personal' ? 'twitter_name' : 'twitter_company_name',
                type: 'twitter_name'
            });
        }
        setInputValue(twitterHandle);

    }

    function openInNewTab(url) {
        //if it's a domain add https://
        if(!url.includes('http')){
            url = `https://twitter.com/${url}`;
        }

        var win = window.open(url, '_blank');
        win.focus();
    }

    function handleBadgeChange() {
        if (onEvent) {
            onEvent({
                value: inputValue,
                id: accountType === 'personal' ? 'twitter_name' : 'twitter_company_name',
                type: 'twitter_company_name'
            });
        }
    }


    if ((accountType === 'personal' ? lead?.id_twitter : lead?.id_twitter_company) && !twitter) {
        return (
          <div className={style.wrap}>
              <div className={formStyle.inputWrap}>
                  <div className={`${formStyle.inputHead} ${isCustom && formStyle.editable}`}>
                      <div className={formStyle.inputHeadIcon}>
                          <Twitter size={14} />
                      </div>
                      <div className={formStyle.inputHeadLabel}>
                          {label}
                      </div>
                  </div>
                  <div className={formStyle.inputBody}>
                      <div className={formStyle.loadingInput}>
                          <Loading size={'sm'} type={'points'} />
                      </div>
                  </div>
              </div>
          </div>
        );
    }


    return (
      <div className={style.wrap}>
          <div className={formStyle.inputWrap}>
              <div className={`${formStyle.inputHead} ${isCustom && formStyle.editable}`}>
                  <div className={formStyle.inputHeadIcon}>
                      <Twitter size={14} />
                  </div>
                  <div className={formStyle.inputHeadLabel}>
                      {label}
                  </div>
              </div>
              {!twitterAccount && (
                <div className={formStyle.inputBody}>
                    <input type={'text'} autoComplete={'false'} onMouseLeave={handleOnBlur} onBlur={handleOnBlur}
                           value={inputValue??''} onChange={r => setInputValue(r?.target?.value)}
                           placeholder={'Add a Twitter @ or URL'} className={formStyle.input} />
                    <div className={formStyle.inputActions}>

                        <Tooltip css={{ zIndex: 999999 }} content={'Copy to clipboard'}>
                            <div onClick={() => copyTextToClipboard(inputValue, setToast)}
                                 className={formStyle.inputAction}>
                                <div className={formStyle.inputActionIcon}>
                                    <Copy size={14} />
                                </div>
                            </div>
                        </Tooltip>
                        {inputValue?.length > 0 && (
                          <Tooltip css={{ zIndex: 999999 }} content={'Open in new tab'}>
                              <div onClick={() => openInNewTab(inputValue)}
                                   className={formStyle.inputAction}>
                                  <div className={formStyle.inputActionIcon}>
                                      <ExternalLink size={14} />
                                  </div>
                              </div>
                          </Tooltip>
                        )}
                    </div>
                </div>
              )}
              {twitterAccount && (
                <div className={formStyle.inputBodySocial}>
                    <div className={formStyle.socialIcon}>
                        <Avatar radius={'sm'} size={'sm'} src={twitterAccount?.avatar_url} />
                    </div>
                    <div className={formStyle.socialCard}>
                        <div className={formStyle.socialName}>
                            {twitterAccount?.name}
                        </div>
                        <div className={formStyle.socialPosition}>
                            @{twitterAccount?.username}
                        </div>
                    </div>
                    <div onClick={() => setTwitterAccount(null)} className={formStyle.inputAction}>
                        <X size={18}/>
                    </div>
                    <Tooltip css={{ zIndex: 999999 }} content={'Open in new tab'}>
                        <div onClick={() => openInNewTab(twitterAccount?.username)}
                             className={formStyle.inputAction}>
                            <div className={formStyle.inputActionIcon}>
                                <ExternalLink size={14} />
                            </div>
                        </div>
                    </Tooltip>
                </div>
              )}
          </div>
      </div>
    );
}, lodash.isEqual);


export default TwitterInput;
