import style from '@/components/social/linkedin/style.module.css';
import {useState, useEffect, useRef} from 'react';



export default function RenderText({ data, small }) {
    const [seeMore, setSeeMore] = useState(false);
    const [hideSeeMore, setHideSeeMore] = useState(true);
    const textRef = useRef();
    const text = data?.text

    if (!text) {
        return null;
    }

    useEffect(() => {
        //check if the text is more than 4 lines
        if (textRef.current) {
            const height = textRef.current.clientHeight;
            if (height >= (small ? 72 : 84)) {
                setHideSeeMore(false);
            }
        }
    }, []);

    return (
      <div className={`${style.linkedinRenderWrap} ${seeMore && style.seeAll}`}>
          <div ref={textRef} className={style.linkedinRenderText} dangerouslySetInnerHTML={{ __html: RenderLinkedinText(data) }} />
          {!hideSeeMore && <div onClick={() => setSeeMore(!seeMore)}
                                className={style.more}>{seeMore ? 'see less' : '...see more'}</div>}
      </div>
    );
}


export function RenderLinkedinText({ text, attributes, removeAttributes }) {

    function formatText(text, attributes) {
        let formattedText = text;

        if (attributes && attributes?.length > 0) {
            attributes.forEach((attribute) => {
                if (attribute?.type === 'PROFILE_FULLNAME') {
                    let start = attribute.start;
                    let length = attribute.length;

                    let textBefore = formattedText.substring(start, start + length);
                    let textAfter = formattedText.substring(start + length);
                    if (removeAttributes) {
                        formattedText = formattedText.substring(0, start) + `` + textAfter;
                    } else {
                        formattedText = formattedText.substring(0, start) + `<a href='https://www.linkedin.com/in/${attribute?.miniProfile?.publicIdentifier}/' target='_blank'>${textBefore}</a>` + textAfter;
                    }
                }
                if(attribute?.type === 'PROFILE_MENTION'){
                    formattedText = formattedText.replace(text.substring(attribute.start, attribute.start + attribute.length +1), `<a href="https://www.linkedin.com/in/${attribute?.miniProfile?.publicIdentifier}" target="_blank">${text.substring(attribute.start, attribute.start + attribute.length + 1)}</a>`)
                }

            });
        }
        //replace the url with <a> but if it's start with href=" then it's already a link
        formattedText = formattedText.replace(/(https?:\/\/[^\s]+)/g, function (url) {
            if (!url.endsWith('"')) {
                return '<a href="' + url + '" target="_blank">' + url + '</a>';
            } else {
                return url;
            }
        });

        //replace the hashtag with <a>
        formattedText = formattedText.replace(/(^|\s)#(\w+)/g, function (match, p1, p2) {
            return p1 + '<a href="https://www.linkedin.com/feed/hashtag/?keywords=' + p2 + '" target="_blank">' + match + '</a>';
        });


        return formattedText;
    }

    if (!text) {
        return null;
    }

    return formatText(text, attributes);
}
