import React, { createContext, useContext, useEffect } from 'react';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import { accountService } from '../services/account.service';
import { leadService } from '../services/lead.service';

export const TwitterContext = createContext();

export const TwitterContextProvider = (props) => {
  const [selectedAccount, setSelectedAccount] = React.useState(null);

  const { id_space } = useSpace();

  const {
    data: accounts,
  } = useSWR(id_space ? `s/twitter?id_space=${id_space}` : null, accountService.fetchWrapperGet);


  useEffect(() => {
    //get the selected account from the local storage
    const selectedLinkedinAccount = localStorage.getItem('selectedTwitterAccount');
    if (selectedLinkedinAccount) {
      const selectedAccount = accounts?.find(account => account.id === selectedLinkedinAccount);
      setSelectedAccount(selectedAccount);
    }
  }, [accounts]);

  useEffect(() => {
    //store the selected account in the local storage
    if (selectedAccount) {
      localStorage.setItem('selectedTwitterAccount', selectedAccount?.id);
    }
  }, [selectedAccount])

  function sendDirectMessage(leadId, data){
    return leadService.sendDmTwitter(id_space, leadId, {
      ...data
    })
  }

  let value = {
    selectedAccount: selectedAccount ?? (accounts && accounts.length > 0 && accounts[0]),
    setSelectedAccount,
    accounts
  };
  return <TwitterContext.Provider value={value} {...props} />;
};

export const useTwitter = () => {
  const context = useContext(TwitterContext);
  if (context === undefined) {
    throw new Error(`useLead must be used within a LeadContextProvider.`);
  }
  return context;
};
