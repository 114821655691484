import style from './style.module.css';
import Loading from "@/components/ui/loading";
import useTheme from '@/utils/useTheme';
import { Check, Plus } from 'react-feather';
import React, { useMemo, useState } from 'react';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import useCustomToasts from '@/utils/useCustomToasts';
import { useLead } from '@/utils/useLead';
import { getContrastYIQ } from '@/utils/helpers';
import ContentLoader from 'react-content-loader';

import { Popover } from "@nextui-org/react";
import {PopoverTrigger, PopoverContent} from "@nextui-org/react";

export default function ListsCell({
                                    showContentLoader,
                                    selectMode,
                                    fullHeight,
                                    id_lead,
                                    lead_lists: props_lead_lists = [],
                                    status: propsStatus
                                  }) {
  const { id_space } = useSpace();
  const { updateLead } = useLead();

  //sort props_lead_lists by name asc
  const lead_lists = useMemo(() => props_lead_lists?.sort((a, b) => {
    return a?.name?.localeCompare(b?.name);
  }), [props_lead_lists]);

  //API
  const {
    data: lists,
    mutate: mutateLists
  } = useSWR(id_space ? `/lists` : null
    , { revalidateOnFocus: false });

  function handleUpdateLead(tag) {
    //check if lead has already this tag
    let hasTag = lead_lists?.find(t => t?.id === tag?.id);
    let listsToUpdate = lead_lists;
    if (hasTag) {
      listsToUpdate = listsToUpdate.filter(t => t?.id !== tag?.id);
    } else {
      listsToUpdate = [...listsToUpdate, tag];
    }

    //TODO THIS IS FOR BULK UPDATE
    // if ((selectedLeads?.length > 0 && selectedLeads?.find(l => l === id_lead)) || allSelectedLeads) {
    //   return updateManyLeads({ lists: [tag] }, { tagsDisconnect: !!hasTag, tagsConnect: !hasTag });
    // }

    return updateLead({ id: id_lead, lists: listsToUpdate });
  }

  function tagIsInLead(tag) {
    return lead_lists?.find(t => t?.id === tag?.id);
  }


  if (showContentLoader || !lists)
    return <TagsCellLoader />;

  return (
    <Popover>
      <PopoverTrigger>
        {lead_lists?.length > 0 ? (
          <div className={style.tagsWrap}>
            {lead_lists?.map(list => {
              return (
                <div key={`list_${list?.id}`}
                     className={`${style.statusTag} ${fullHeight && style.fullHeight} `}>
                  <div className={style.statusText}>
                    <span>{list?.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={`${style.statusTag} ${style.emptyStatus} ${fullHeight && style.fullHeight}`}>
            <div className={style.statusIcon}>
              <Plus size={14} />
            </div>
            <div className={style.statusText}>
              <span>Add lists</span>
            </div>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent className={'p-0'}>
        <div className={style.statusLists}>
          <div className={style.statusListHeader}>
            {selectMode ? 'Select' : 'Change'} lead lists
          </div>
          {!lists ? (
            <div className={style.loaderWrap}>
              <Loading type={'points'} size={'sm'} />
            </div>
          ) : (
            <>
              {lists?.length === 0 && (
                <div className={`${style.statusList} ${style.empty}`}>
                  No lists found
                </div>
              )}
              {lists && lists?.map(list => <StatusList isUsed={tagIsInLead(list)}
                                                      selectMode={selectMode}
                                                      key={list?.id}
                                                      handleUpdateLead={handleUpdateLead}
                                                      status={list} />)}
              {selectMode && (
                <div className={`${style.statusList} ${style.unselect}`}>
                  Unselect
                </div>
              )}
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function StatusList({
                      status,
                      handleUpdateLead,
                      isUsed
                    }) {
  const [editName, setEditName] = useState(status.name ?? '');
  const [editColor, setEditColor] = useState(status.color ?? '#000000');

  function handleAddTag() {
      handleUpdateLead(status);
  }

  return (
    <div onClick={handleAddTag} className={style.statusList}>
      <div className={`${style.statusTag}`}>
        {isUsed && <Check style={{ marginRight: 5 }} size={13} />}
        <div className={style.statusText}><span>{editName}</span>
        </div>
      </div>
    </div>
  );
}


function TagsCellLoader() {
  const { isDark } = useTheme();

  return (
    <div className={style.tagsCellLoader}>
      <ContentLoader
        speed={2}
        width={70}
        height={24}
        viewBox='0 0 70 24'
        backgroundColor={isDark ? '#171717' : '#e9ecef'}
        foregroundColor={isDark ? '#343333' : '#c7c7c7'}>
        <rect x='0' y='0' rx='4' ry='4' width='70' height='24' />
      </ContentLoader>
    </div>
  );
}
