import getConfig from '../utils/getConfig';

import { fetchWrapper } from './fetch-wrapper';
import { auth } from '@/utils/firebase';

const { apiUrl } = getConfig();

export const authenticationService = {
  user: getUserToken,
  twitterAuth,
  linkedinAuth,
  twitterCallback,
  linkedinCallback,
};

function linkedinAuth() {
  return fetchWrapper.get(`${apiUrl}/account/linkedin/auth`);
}




async function getUserToken() {
  try {
    const user = auth.currentUser;
    if (user) {
      let token = await user.getIdToken(true);
      return token;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

function twitterAuth(id_space, version) {
  let payload = `id_space=${id_space}`;
  if (version)
    payload += `&version=${version}`;
  return fetchWrapper.get(`${apiUrl}/account/twitter/auth?${payload}`);
}

function twitterCallback(id_space, data, version) {
  let payload = `id_space=${id_space}`;
  if (version)
    payload += `&version=${version}`;

  return fetchWrapper.post(`${apiUrl}/account/twitter/auth?${payload}`, data);
}


function linkedinCallback(id_space, data) {
  let payload = `id_space=${id_space}`;

  return fetchWrapper.post(`${apiUrl}/account/linkedin/auth?${payload}`, data);
}
