import style from '../styles/pageLoader.module.css';
import Animation from '@/components/animation';
import Breakcold from '../public/animations/breakcold_light_grey.json';
import BreakcoldDark from '../public/animations/breakcold_dark_grey.json';
import { useEffect, useState } from 'react';
import useTheme from '@/utils/useTheme';
import {Chip} from '@nextui-org/react';

const waitingTexts = [
  '',
  'Searching for your space',
  'Loading your space',
  'Searching for your leads',
  'Synchronizing their Linkedin and Twitter accounts',
  'We\'re almost there...',
  'Almost done...',
  '5 more seconds...',
  'It\'s long, but it\'s worth it...',
  'I promise it\'s almost ready...',
  'Now it\'s strange...',
  'Loading take too much time...',
  'Impossible to load your space, please contact the support'
];

export default function PageLoader({ show_onboarding }) {
  const { isDark } = useTheme();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => counter + 1);
    }, 4000);
    return () => clearInterval(interval);
  }, []);


  return (
    <div className={style.wrap}>
      <div className={style.logo}>
        <Animation loop={false} animationData={!isDark ? Breakcold : BreakcoldDark} height={250} width={400} />
      </div>
      {show_onboarding && (counter > 0) && (
        <div className={style.waitingText}>
          {waitingTexts[counter > waitingTexts.length - 1 ? waitingTexts.length - 1 : counter]}
          <Chip  className={'mt-4'} variant={'dot'} color={'success'} onClick={() => window.open('https://status.breakcold.com', '_blank')} as={'button'}>
            Status Page
          </Chip>
        </div>
      )}
    </div>
  );
}
