import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import getSuggestionItems from '@/components/editorV2/extensions/suggestion/items';
import { useLead } from '@/utils/useLead';
import CommandsList from '@/components/editorV2/extensions/suggestion/CommandsList';
import { useSpace } from '@/utils/useSpace';

const CommandMenu = ({ children, onSelect, excludes = ['signature'], customVariables: propsCustomVariables }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [commands, setCommands] = useState([]);
    const inputRef = useRef(null);
    const {selectedLead, uniqueLead: lead} = useLead();
    const [customVariables, setCustomVariables] = useState([]);
    const commandsRef = useRef(null);
    const {space} = useSpace();

    useEffect(() => {
        if (selectedLead?.custom_fields && !propsCustomVariables) {
            let parsedVariables = Object.keys(selectedLead?.custom_fields).map(key => {
                return { label: selectedLead?.custom_fields[key]?.label ?? key, id: key };
            });
            setCustomVariables(parsedVariables);
        }
        if (lead?.custom_fields && !propsCustomVariables) {
            let parsedVariables = Object.keys(lead?.custom_fields).map(key => {
                return { label: lead?.custom_fields[key]?.label ?? key, id: key };
            });
            setCustomVariables(parsedVariables);
        }
        if(space?.custom_fields && !propsCustomVariables){
            let parsedVariables = Object.keys(space?.custom_fields).map(key => {
                return { label: space?.custom_fields[key]?.label ?? key, id: key };
            });
            setCustomVariables(parsedVariables);
        }
        if(propsCustomVariables){
            setCustomVariables(propsCustomVariables);
        }

    }, [lead, selectedLead, propsCustomVariables])

    const handleKeyPress = async (event) => {
        if (event.key === '/') {
            setShowMenu(true);
            setSearchTerm('/');
            let items = await getSuggestionItems({ query: '' }, customVariables, excludes);
            setCommands(items);
            return;
        }
        //if is escape or space or enter close menu
        if (event.key === 'Escape' || event.key === ' ' || event.key === 'Enter') {
            setShowMenu(false);
            setSearchTerm('');
            event?.stopPropagation();
            return;
        }

        if (searchTerm?.startsWith('/')) {
            //filter the keys to only allow letters, numbers, special characters
            if (!/[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(event.key)) {
                event.preventDefault();
                return;
            }
            setSearchTerm(searchTerm + event.key);
            let query = searchTerm.replace('/', '');
            let items = await getSuggestionItems({ query: query }, customVariables, excludes);
            setCommands(items);
        }
    };

    const handleKeyDown = async (event) => {
        if (searchTerm?.startsWith('/')) {
            //if is backspace remove last char
            if (event.key === 'Backspace') {
                let query = searchTerm.slice(0, -1);
                if (!query) {
                    setShowMenu(false);
                    setSearchTerm('');
                    return;
                }
                setSearchTerm(query);
                let items = await getSuggestionItems({ query: query.replace('/', '') }, customVariables, excludes);
                setCommands(items);
                return;
            }
        }
        if (showMenu && event.key === 'Escape') {
            setShowMenu(false);
            setSearchTerm('');
            return;
        }
        if(showMenu && event.key === 'Enter'){
            event.preventDefault();
            const item = commands.find(item => item?.value === commandsRef?.current?.state?.selectedValue);
            selectItem(item);
            return;
        }

        if(showMenu) {
            commandsRef.current.onKeyDown({event: event});
            event.preventDefault();
            event.stopPropagation();
            return;
        }


    };

    function selectItem(item) {
        if(!showMenu){
            return;
        }
        // const item = commands[index];

        if (item) {
            let value = `{{${item?.value}}}`;
            if(onSelect){
                onSelect(value)
                setShowMenu(false);
                return;
            }
            let input = inputRef.current;
            let start = input.selectionStart;
            let end = input.selectionEnd;
            let text = input.value;
            let before = text.substring(0, start);
            let after = text.substring(end, text.length);

            //if before last char is / then remove it
            if (before?.endsWith('/')) {
                before = before.slice(0, -1);
            }
            input.value = before + value + after;
            input.selectionStart = input.selectionEnd = start + value.length;
            input.focus();
            setShowMenu(false);
            setSearchTerm('');

        }
    }



    return (
      <>
          <Tippy
            visible={showMenu}
            interactive
            popperOptions={{
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['bottom', 'right']
                        }
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            altAxis: true,
                            tether: false
                        }
                    }
                ]
            }}
            zIndex={999990}
            offset={[0, 10]}
            appendTo={document.body}
            content={<CommandsList ref={commandsRef} items={commands} command={selectItem}/> }
            // content={
            //     <>
            //         <ul ref={itemsRef} className={style.commands}>
            //             {commands?.length === 0 && (
            //               <div className={style.empty}>
            //                   <span>No variables found</span>
            //               </div>
            //             )}
            //             {commands.map((item, idx) => {
            //                 return (
            //                   <li
            //                     id={`slashCommand_${idx}`}
            //                     key={idx}
            //                     onClick={() => selectItem(idx)}
            //                     className={selectedIndex === idx ? `${style.command} ${style.active}` : style.command}
            //                   >
            //                       {item?.icon && (
            //                         <div className={'icon'}>
            //                             <img src={item.icon} />
            //                         </div>
            //                       )}
            //                       <div className={style.content}>
            //                           <h5>{item.title}</h5>
            //                       </div>
            //                   </li>
            //                 );
            //             })}
            //         </ul>
            //     </>
            // }
            placement='bottom-start'
            onClickOutside={() => setShowMenu(false)}
          >
              {React.cloneElement(children, {
                  ...(!onSelect && {ref: !onSelect && inputRef}),
                  onKeyDown: handleKeyDown,
                  onKeyPress: handleKeyPress
              })}
          </Tippy>
      </>
    );
};

export default CommandMenu;
