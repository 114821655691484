import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import posthog from 'posthog-js';

const CrispWithNoSSR = dynamic(() => import('@/components/crisp'), { ssr: false });

export default function AppWrapper({ children }) {
    const router = useRouter();
    const meta = {
        title: 'Build relationships to make easy sales',
        description: 'Breakcold is a platform that helps you build relationships with your customers and make easy sales.',
        cardImage: '/static/meta-breakcold.png'
    };

    useEffect(() => {
        "use strict";
        Router.events.on("routeChangeComplete", (path) => {
            setTimeout(() => {
                if (window.bento !== undefined) {
                    window.bento.view();
                };
                if(posthog){
                    posthog.capture('$pageview');
                }
            }, 0);
        });
        return () => {
            Router.events.off('routeChangeComplete', 0);
        }
    }, [Router]);

    return (
      <div>
          <Head>
              <title>{meta.title}</title>
              <meta name='robots' content='follow, index' />
              <link href='/favicon.png' rel='shortcut icon' />
              <meta content={meta.description} name='description' />
              <meta
                property='og:url'
                content={`https://app.breakcold.com${router.asPath}`}
              />
              <meta property='og:type' content='website' />
              <meta property='og:site_name' content={meta.title} />
              <meta property='og:description' content={meta.description} />
              <meta property='og:title' content={meta.title} />
              <meta property='og:image' content={meta.cardImage} />
              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:site' content='@breakcold_' />
              <meta name='twitter:title' content={meta.title} />
              <meta name='twitter:description' content={meta.description} />
              <meta name='twitter:image' content={meta.cardImage} />
          </Head>
          <Script
            src="https://hyperping.io/badge.js"
            strategy="afterInteractive"
          />
          <Script
            src='https://www.googletagmanager.com/gtag/js?id=GA_MEASUREMENT_ID'
            strategy='afterInteractive'
          />
          <Script id='bento-script' src={'https://fast.bentonow.com?site_uuid=b049549461f7133e9ddf88623d7dedc0'}
                  strategy='afterInteractive' />
          <Script async src='https://reflio.com/js/reflio.min.js' strategy='afterInteractive'
                  data-reflio='pn1ib1rd2ylfs7u' />
          <Script id='google-analytics' strategy='afterInteractive'>
              {`
          (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
        `}
          </Script>
          <Script id='google-analytics' strategy='afterInteractive'>
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-D42S072YJ9');
        `}
          </Script>
          <CrispWithNoSSR />
          {children}
      </div>
    );
}
