import style from '../style.module.css';
import { Bell, BellOff, Calendar, ChevronDown, ChevronUp, MoreVertical } from 'react-feather';
import dayjs from 'dayjs';
import { remindersService } from '@/services/reminders.service';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import { useLead } from '@/utils/useLead';
import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Tooltip
} from '@nextui-org/react';
import clsx from 'clsx';
import Loading from '@/components/ui/loading';
import calendar from 'dayjs/plugin/calendar';
import ActivityLine from '@/components/activity/lines/index';

dayjs.extend(calendar);
export default function LineReminder({ data: reminder, small, onRender, isSelected }) {
  const data = reminder.data;


  useEffect(() => {
    if (onRender) {
      onRender();
    }
  }, []);

  return (
    <ActivityLine
    icon={(
      <Tooltip css={{ zIndex: 9999999 }} content={'Task'}>
        <div className={`${style.iconWrap}`}>
          {!data?.is_done ? <Bell size={20} /> : <BellOff size={20} />}
        </div>
      </Tooltip>
    )}
    title={data?.name}
    date={data?.updated_at}
    >
      <div className={'flex flex-row'}>
        <div className={'font-md text-medium'}>
          {data?.name}
        </div>
        {data?.updated_at && (
          <div className={style.time}>
            {dayjs(data?.updated_at).format('DD MMM YYYY hh:mm A')}
          </div>
        )}
      </div>
    </ActivityLine>
  );
}


export function LineActiveReminder({ data, small, onClick, onUpdate, hideDate, isSelected }) {
  const dayRemain = data?.date && dayjs(data?.date).diff(dayjs(), 'day');
  const isWarning = data?.date && dayRemain < 3 && dayRemain >= 0;
  const isDanger = data?.date && dayRemain < 0;

  const [loading, setLoading] = useState(false);
  const { setToast } = useCustomToasts();
  const { updateLead, uniqueLead, openLead } = useLead();
  const [delayPopup, setDelayPopup] = useState(false);
  const leadName = useMemo(() => data?.lead?.first_name || data?.lead?.last_name ? `${data?.lead?.first_name ?? ''} ${data?.lead?.last_name ?? ''}` : 'No name', [data?.lead?.first_name, data?.lead?.last_name]);


  async function handleUpdate(data) {
    if (loading || !data) return;
    setLoading(true);

    if (onUpdate) {
      await onUpdate(data);
      setLoading(false);
      return;
    }

    const oldReminders = uniqueLead?.reminders;
    let optimisticDataReminders = uniqueLead?.reminders?.map(reminder => {
      if (reminder?.id === data?.id) {
        return {
          ...reminder,
          ...data
        };
      }
      return reminder;
    });

    updateLead({
      id: uniqueLead?.id,
      reminders: optimisticDataReminders
    });

    remindersService.updateReminder(data?.id, data).then(() => {
      setToast({
        text: 'Reminder updated',
        type: 'success'
      });

    }).catch(e => {
      setToast({
        text: 'Error updating reminder',
        type: 'error'
      });
      updateLead({
        id: uniqueLead?.id,
        reminders: oldReminders
      });
    }).finally(() => {
      setLoading(false);
    });
  }


  function addDelay(delay) {
    if (!delay) return;
    let date = data?.date ?? dayjs().toISOString();
    const newDate = dayjs(date).add(delay, 'day').toISOString();
    handleUpdate({
      id: data?.id,
      date: newDate
    });
    setDelayPopup(false);
  }


  function handleMoreSelect(key) {
    if (key === 'removeCron') {
      removeCron();
    }
    if (key === 'done') {
      handleDone();
    }
    if (key === 'delete') {
      deleteTask();
    } else {
      addDelay(key);
    }
  }

  function deleteTask() {
    handleUpdate({
      id: data?.id,
      is_deleted: true
    });
  }

  function removeCron() {
    handleUpdate({
      id: data?.id,
      cron: null
    });
  }

  function handleDone() {
    handleUpdate({
      id: data?.id,
      is_done: !data?.is_done
    });
  }

  function handleClick() {
    onClick && onClick(data);
  }

  return (
    <div onClick={handleClick} className={clsx(
      'border-1 flex relative z-10 items-center rounded-xl cursor-pointer',
      isSelected && 'border-primary-500 bg-primary-50 dark:border-primary-500 dark:bg-primary-500',
      !isSelected && 'border-stone-200 bg-stone-100 dark:border-stone-800 dark:bg-stone-900',
      small && 'px-2 py-1.5',
      !small && 'px-3 py-2.5'
    )}>
      {(data?.date && data?.cron) && (
        <div
          className={'bg-secondary-50 border-secondary-100 text-secondary-500 border-1 absolute left-0 -top-1.5 text-[10px] px-1.5 rounded-full'}>
          Repeat every {{
          '0 0 * * *': ' day',
          '0 0 * * 0': ' week',
          '0 0 1 * *': ' month',
          '1 0 1 * *': ' year'
        }[data.cron]
        }
        </div>
      )}
      <div className={'flex flex-row'}>
        {loading && (
          <div className={'w-[28px]'}>
            <Loading size={'sm'} />
          </div>
        )}
        {!loading && (
          <Checkbox isSelected={data?.is_done} onChange={handleDone} />
        )}
      </div>
      <div className={'flex flex-col w-full ml-1'}>
        <div className='grid grid-cols-4 flex items-center gap-4'>
          <div style={{ paddingRight: data?.users?.length * 22.5 }} className={'row-span-1 col-span-2'}>
            <div className={'flex'}>
              <div className={'text-sm font-medium flex-1 line-clamp-2'}>
                {data?.name}
              </div>
            </div>
          </div>
          <div className={'row-span-1 col-span-1 flex items-center'}>
            {data?.date && (
              <Tooltip delay={1000} content={dayjs(data?.date).format('DD MMM YYYY hh:mm A')}>
                <div
                  className={clsx('text-sm flex space-x-2 font-medium', dayjs(data?.date).diff(dayjs(), 'day') < 0 ? 'text-danger-500' : 'text-black/80 dark:text-white/80')}>
                  <Calendar size={18} />
                  <span>
                  Due {dayjs(data?.date).calendar(null, {
                    sameDay: '[Today]',
                    lastDay: '[Yesterday]',
                    lastWeek: 'MMM DD, YYYY',
                    sameElse: 'MMM DD, YYYY'
                  })}
                </span>
                </div>
              </Tooltip>
            )}
          </div>
          <div className={'row-span-1 col-span-1 flex items-center justify-end'}>
            <div className={'flex flex-1'}>
              {!small && data?.users?.map((us, idx) => {
                const user = us?.id_user ? us?.user : us;
                const handle = user?.handle || user?.full_name || user?.id || user?.email;

                if (us?.notify === false || idx > 3) return null;

                return (
                  <Tooltip className={'z-100'} content={handle}>
                    <Avatar classNames={{
                      base: 'w-6 h-6'
                    }} name={handle} color={'default'} isBordered src={user?.avatar_url} size={'sm'} radius={'sm'} />
                  </Tooltip>
                );
              })}
            </div>
            {data?.lead && (
              <div onClick={() => openLead(data?.lead?.id, 'update')}
                   className={'cursor-pointer hover:bg-primary-300 transition-all duration-100 hover:text-white flex border-1 h-7 px-1 text-primary-700 items-center space-x-1.5 border-primary-400 rounded-lg bg-primary-50 text-[10px]'}>
                <Avatar classNames={{
                  base: 'w-4 h-4',
                  name: 'text-[7px]'
                }} name={data?.lead?.first_name} color={'default'} src={data?.lead?.avatar_url} size={'sm'}
                        radius={'sm'} />
                <span className={'max-w-[100px] break-all line-clamp-1'}>
                {leadName}
              </span>
              </div>
            )}
            <div className={'flex'}>
              <Dropdown isOpen={delayPopup} onOpenChange={setDelayPopup}>
                <DropdownTrigger>
                  <Button variant={'light'} isIconOnly size={'sm'}>
                    <MoreVertical size={18} />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu variant={'faded'} onAction={handleMoreSelect} aria-label='Actions'>
                  <DropdownSection showDivider={true}>
                    <DropdownItem key={'done'}>
                      Mark as done
                    </DropdownItem>
                    {data?.cron && (
                      <DropdownItem key={'removeCron'}>
                        Do not repeat
                      </DropdownItem>
                    )}
                  </DropdownSection>
                  <DropdownSection title='Remind me in' showDivider={true}>
                    <DropdownItem key={1}>
                      1 day
                    </DropdownItem>
                    <DropdownItem key={3}>
                      3 days
                    </DropdownItem>
                    <DropdownItem key={7}>
                      1 week
                    </DropdownItem>
                    <DropdownItem key={14}>
                      2 weeks
                    </DropdownItem>
                    <DropdownItem key={30}>
                      1 month
                    </DropdownItem>
                    <DropdownItem key={365}>
                      1 year
                    </DropdownItem>
                  </DropdownSection>
                  <DropdownSection>
                    <DropdownItem className='text-danger' color={'danger'} key={'delete'}>
                      Delete
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export const Reminders = memo(({ small, data }) => {
  const [openMore, setOpenMore] = useState(false);

  const taskCount = useMemo(() => data?.filter(reminder => !reminder?.date && !reminder?.cron).length, [data]);
  const remindersCount = useMemo(() => data?.filter(reminder => reminder?.date && !reminder?.cron).length, [data]);
  const repeatableCount = useMemo(() => data?.filter(reminder => reminder?.cron).length, [data]);
  const totalCount = useMemo(() => data?.length, [data]);

  const toggleOpenMore = useCallback(() => {
    setOpenMore(prevState => !prevState);
  }, []);

  const RenderPreviewText = useCallback(() => {
    const remindersExpired = useMemo(() => data?.filter(reminder => dayjs(reminder?.date).diff(dayjs(), 'day') < 0), [data]);
    const remindersWarning = useMemo(() => data?.filter(reminder => dayjs(reminder?.date).diff(dayjs(), 'day') < 3 && dayjs(reminder?.date).diff(dayjs(), 'day') >= 0), [data]);

    if (remindersExpired?.length > 0 || remindersWarning?.length > 0) {
      return (
        <div className={style.reminderPreviewText}>
          {remindersExpired?.length > 0 && (
            <div className={style.reminderPreviewTextExpired}>
              {remindersExpired?.length} overdue
            </div>
          )}
          {remindersWarning?.length > 0 && (
            <div className={style.reminderPreviewTextWarning}>
              {remindersWarning?.length} due soon
            </div>
          )}
        </div>
      );
    }
    return null;
  }, [data]);

  const remindersList = useMemo(() => {
    return data?.map(reminder => (
      <div className={''}>
        <LineActiveReminder small={small} key={reminder?.id} data={reminder} />
      </div>
    ));
  }, [data, small]);

  return (
    <div className={'flex flex-col w-full'}>
      {totalCount === 1 && <div className={'p-2'}> <LineActiveReminder small={small} key={data[0]?.id} data={data[0]} /></div>}
      {totalCount > 1 && (
        <div onClick={toggleOpenMore} className={`flex w-full justify-between py-1.5 px-2 cursor-pointer hover:bg-stone-100 dark:hover:bg-stone-900`}>
          <div className={'flex'}>
            <div className={'text-sm'}>
              You have <b className={'font-semibold'}>{taskCount + remindersCount + repeatableCount} {(taskCount + remindersCount + repeatableCount) > 1 ? 'actions' : 'action'}</b> due
              ({taskCount} {taskCount > 1 ? 'tasks' : 'task'} and {remindersCount + repeatableCount} {remindersCount > 1 ? 'reminders' : 'reminder'})
            </div>
            <RenderPreviewText />
          </div>
          <div className={''}>
            {openMore ? (
              <ChevronUp size={20} onClick={() => setOpenMore(false)} />
            ) : (
              <ChevronDown size={20} onClick={() => setOpenMore(true)} />
            )}
          </div>
        </div>
      )}
      {openMore && totalCount > 1 && <div className={'flex flex-col gap-2 p-3'}>{remindersList}</div>}
    </div>
  );
});
