import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';
import useSWR from 'swr';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/list`;

export const listService = {
    createList,
    addLeadsToList,
    updateLeadList,
    removeLeadsToList,
    getAllLists,
    deleteLeadList,
    moveLeadLists,
    connectListToCampaign,
    disconnectListToCampaign,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
    fetchWrapperDelete: url => fetchWrapper.delete(`${baseUrl}${url}`),
}


function addLeadsToList(id_space, id_list, leadFilters){
    const payload = `id_space=${id_space}&id_list=${id_list}`;

    return fetchWrapper.post(`${baseUrl}s/list?${payload}`, {
        ...(leadFilters?.campaignId && {campaignId: leadFilters?.campaignId}),
        ...(leadFilters?.search && {search: leadFilters?.search}),
        ...(leadFilters?.noList && {noList: leadFilters?.noList}),
        ...(leadFilters?.bounceScore && {bounceScore: leadFilters?.bounceScore}),
        ...(leadFilters?.id_list && {id_list: leadFilters?.id_list}),
        ...(leadFilters?.leads && {leads: leadFilters?.leads}),
        ...(leadFilters?.allLeads && {allLeads: leadFilters?.allLeads}),
    });
}


function removeLeadsToList(id_space, id_list, leadFilters){
    const payload = `id_space=${id_space}&id_list=${id_list}`;

    return fetchWrapper.delete(`${baseUrl}s/list?${payload}`, {
        ...(leadFilters?.campaignId && {campaignId: leadFilters?.campaignId}),
        ...(leadFilters?.search && {search: leadFilters?.search}),
        ...(leadFilters?.noList && {noList: leadFilters?.noList}),
        ...(leadFilters?.bounceScore && {bounceScore: leadFilters?.bounceScore}),
        ...(leadFilters?.id_list && {id_list: leadFilters?.id_list}),
        ...(leadFilters?.leads && {leads: leadFilters?.leads}),
        ...(leadFilters?.allLeads && {allLeads: leadFilters?.allLeads}),
    });
}


function getAllLists(id_space, campaignId){
    let payload = `id_space=${id_space}`;
    payload = campaignId ? payload + `&campaignId=${campaignId}` : payload;

    return fetchWrapper.get(`${baseUrl}/getAllLists?${payload}`)
}

function createList(id_space, data){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.post(`${baseUrl}?${payload}`, {
        ...data,
    });
}

function updateLeadList(id_list, data){

    return fetchWrapper.patch(`${baseUrl}/${id_list}`, {
        ...data,
    });
}

function moveLeadLists(ids_list){
    return fetchWrapper.post(`${baseUrl}s/move`, {
        ids_list: ids_list,
    });
}


function deleteLeadList(id_space, id_list, delete_leads){
    const payload = `id_space=${id_space}&id_list=${id_list}&delete_leads=${delete_leads}`;

    return fetchWrapper.delete(`${baseUrl}/${id_list}?${payload}`, {
        ...(delete_leads && {delete_leads: delete_leads}),
    });
}


function connectListToCampaign(id_campaign, id_list){
    const payload = `id_campaign=${id_campaign}&id_list=${id_list}`;

    return fetchWrapper.post(`${baseUrl}/list-campaign?${payload}`);
}

function disconnectListToCampaign(id_space, campaignId, id_list){
    const payload = `id_space=${id_space}&campaignId=${campaignId}&id_list=${id_list}`;

    return fetchWrapper.post(`${baseUrl}/disconnectListToCampaign?${payload}`);
}

