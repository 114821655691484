import style from '@/styles/leadForm/new.module.css';
import { Plus } from 'react-feather';
import { useState } from 'react';
import {Button} from '@nextui-org/react';

export default function NewInput({ schema, update }) {
    const [isOpen, setIsOpen] = useState(false);

    function updateSchema(item) {
        //generate random id
        let id = Math.random().toString(36).substr(2, 9);
        switch (item) {
            case 'string':
                update([...schema, {
                    type: 'string',
                    label: 'Text input',
                    id: `string-${id}`,
                    hidden: false,
                    isCustom: true
                }]);
                break;
            case 'date':
                update([...schema, {
                    type: 'date',
                    label: 'Date input',
                    id: `date-${id}`,
                    hidden: false,
                    isCustom: true
                }]);
                break;
            case 'linkedin':
                update([...schema, {
                    type: 'linkedin',
                    label: 'Linkedin',
                    id: `date-${id}`,
                    hidden: false,
                    isCustom: false
                }]);
                break;
            case 'twitter':
                update([...schema, {
                    type: 'twitter',
                    label: 'Twitter',
                    id: `twitter-${id}`,
                    hidden: false,
                    isCustom: false
                }]);
                break;
        }
        setIsOpen(false);
    }


    return (
      <div className={style.wrapInput}>
          <Button fullWidth onClick={() => updateSchema('string')} variant={'bordered'} size={'sm'} startContent={<Plus size={16}/>}>New field</Button>
      </div>
    );
}
