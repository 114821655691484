import style from '@/styles/leadForm/email.module.css';
import formStyle from '@/styles/leadForm/leadForm.module.css';
import Loading from "@/components/ui/loading";
import { Copy, ExternalLink, Linkedin, X } from 'react-feather';
import { copyTextToClipboard } from '@/components/ui/leadForm/leadForm';
import { useEffect, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import { parseLinkedinUsername } from '@/utils/validator';
import React from 'react';
import lodash from 'lodash';
import {Avatar} from '@nextui-org/react';
import { Tooltip } from "@nextui-org/react";


const LinkedinInput = React.memo(({ label, lead, id, onEvent, isCustom, deleteInput, value, linkedin }) => {
    const { setToast } = useCustomToasts();
    const [inputValue, setInputValue] = useState(value ?? '');
    const [linkedinAccount, setLinkedinAccount] = useState(linkedin ?? null);
    const [linkedinAccountType, setLinkedinAccountType] = useState('personal');


    useEffect(() => {
        if (value) {
            setInputValue(value);
        } else {
            setInputValue('');
        }
        if (linkedin) {
            if (linkedin?.linkedin_username?.toLowerCase() !== parseLinkedinUsername(value?.toLowerCase())) {
                setLinkedinAccount(null);
            } else {
                setLinkedinAccount(linkedin);
            }
        } else {
            setLinkedinAccount(null);
        }
    }, [value, linkedin]);

    function handleOnBlur(r) {
        let linkedinUsername = parseLinkedinUsername(r?.target?.value);

        if (onEvent) {
            onEvent({
                value: linkedinUsername,
                id: linkedinAccountType === 'personal' ? 'linkedin_url' : 'linkedin_company_url',
                type: 'linkedin_url'
            });
        }
        setInputValue(linkedinUsername);

    }

    function openInNewTab(url) {
        console.log("URL", url)
        //if it's a domain add https://
        if (!url.includes('http')) {
            url = `https://www.linkedin.com/in/${url}`;
        }

        var win = window.open(url, '_blank');
        win.focus();
    }


    if (lead?.id_linkedin && !linkedin) {
        return (
          <div className={style.wrap}>
              <div className={formStyle.inputWrap}>
                  <div className={`${formStyle.inputHead} ${isCustom && formStyle.editable}`}>
                      <div className={formStyle.inputHeadIcon}>
                          <Linkedin size={14} />
                      </div>
                      <div className={formStyle.inputHeadLabel}>
                          {label}
                      </div>
                  </div>
                  <div className={formStyle.inputBody}>
                      <div className={formStyle.loadingInput}>
                          <Loading size={'sm'} type={'points'} />
                      </div>
                  </div>
              </div>
          </div>
        );
    }


    return (
      <div className={style.wrap}>
          <div className={formStyle.inputWrap}>
              <div className={`${formStyle.inputHead} ${isCustom && formStyle.editable}`}>
                  <div className={formStyle.inputHeadIcon}>
                      <Linkedin size={14} />
                  </div>
                  <div className={formStyle.inputHeadLabel}>
                      {label}
                  </div>
              </div>
              {!linkedinAccount && (
                <div className={formStyle.inputBody}>
                    <input type={'text'} autoComplete={'false'} onMouseLeave={handleOnBlur} onBlur={handleOnBlur}
                           value={inputValue??''} onChange={r => setInputValue(r?.target?.value)}
                           placeholder={'Add a Linkedin username or URL'} className={formStyle.input} />
                    <div className={formStyle.inputActions}>
                        <Tooltip css={{ zIndex: 999999 }} content={'Copy to clipboard'}>
                            <div onClick={() => copyTextToClipboard(inputValue, setToast)}
                                 className={formStyle.inputAction}>
                                <div className={formStyle.inputActionIcon}>
                                    <Copy size={14} />
                                </div>
                            </div>
                        </Tooltip>
                        {inputValue?.length > 0 && (
                          <Tooltip css={{ zIndex: 999999 }} content={'Open in new tab'}>
                              <div onClick={() => openInNewTab(inputValue)}
                                   className={formStyle.inputAction}>
                                  <div className={formStyle.inputActionIcon}>
                                      <ExternalLink size={14} />
                                  </div>
                              </div>
                          </Tooltip>
                        )}

                    </div>
                </div>
              )}
              {linkedinAccount && (
                <div className={formStyle.inputBodySocial}>
                    <div className={formStyle.socialIcon}>
                        <Avatar radius={'sm'} name={linkedinAccount?.full_name ?? '?'} size={'sm'}
                                src={linkedinAccount?.avatar_url} />
                    </div>
                    <div className={formStyle.socialCard}>
                        <div className={formStyle.socialName}>
                            {linkedinAccount?.full_name ?? 'Full name not found'}
                        </div>
                        {linkedinAccount?.username && (
                          <div className={formStyle.socialPosition}>
                              {linkedinAccount?.username ?? 'Position not found'}
                          </div>
                        )}
                    </div>
                    <div onClick={() => setLinkedinAccount(null)} className={formStyle.inputAction}>
                        <X size={18} />
                    </div>
                    <Tooltip css={{ zIndex: 999999 }} content={'Open in new tab'}>
                        <div onClick={() => openInNewTab(linkedinAccount?.linkedin_username)}
                             className={formStyle.inputAction}>
                            <div className={formStyle.inputActionIcon}>
                                <ExternalLink size={14} />
                            </div>
                        </div>
                    </Tooltip>
                </div>
              )}
          </div>
      </div>
    );
}, lodash.isEqual);

export default LinkedinInput;
