import { Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";

export const Commands = Extension.create({
    name: "mention",
    addOptions() {
        return {
            suggestion: {
                char: "/",
                allowedPrefixes: null,
                startOfLine: false,
                command: ({ editor, range, props }) => {
                    props.command({ editor, range, props });
                }
            }
        }
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion
            })
        ];
    }
});

export const CommandsAt = Extension.create({
    name: "mention",
    addOptions() {
        return {
            suggestion: {
                char: "@",
                allowedPrefixes: null,
                startOfLine: false,
                command: ({ editor, range, props }) => {
                    props.command({ editor, range, props });
                }
            }
        }
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                ...this.options.suggestion
            })
        ];
    }
});


export default Commands;
