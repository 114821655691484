import { useTheme as useNextThemes } from 'next-themes';

export default function useTheme(){
  const { theme, setTheme, resolvedTheme } = useNextThemes();

  return {
    setTheme,
    theme,
    isDark: resolvedTheme === 'dark',
  }
}
