const getSuggestionItems = async ({ query }, customVariables, excludes = [], isUser) => {


  if (isUser) {
    return customVariables?.map(variable => {
      const handle = variable?.handle ?? variable?.id;
      return {
        handle: handle,
        avatar_url: variable?.avatar_url,
        email: variable?.email,
        value: variable?.id,
        isUser: true,
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertContent(`@${handle}`)
            .run();
        }
      };
    });
  }

  return [
    ...((customVariables && customVariables?.length > 0) ? customVariables?.map(variable => {
      return {
        title: variable?.label,
        isCustom: true,
        subtitle: '',
        value: variable?.id,
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertContent(`{{${variable?.id}}}`)
            .run();
        }
      };
    }) : [{}]),
    {
      title: 'First Name',
      subtitle: 'Your lead First Name.',
      value: 'first_name',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{first_name}}')
          .run();
      }
    },
    {
      title: 'Last Name',
      subtitle: 'Lead First Name.',
      value: 'last_name',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{last_name}}')
          .run();
      }
    },
    {
      title: 'IceBreaker',
      subtitle: 'Your AI generated iceBreaker.',
      value: 'icebreaker',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{icebreaker}}')
          .run();
      }
    },
    {
      title: 'IceBreaker 2',
      subtitle: 'Your AI generated iceBreaker.',
      value: 'icebreaker_2',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{icebreaker_2}}')
          .run();
      }
    }, {
      title: 'IceBreaker 3',
      subtitle: 'Your AI generated iceBreaker.',
      value: 'icebreaker_3',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{icebreaker_3}}')
          .run();
      }
    },
    {
      title: 'IceBreaker 4',
      subtitle: 'Your AI generated iceBreaker.',
      value: 'icebreaker_4',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{icebreaker_4}}')
          .run();
      }
    },
    {
      title: 'Company',
      subtitle: 'The lead company name.',
      value: 'company',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{company}}')
          .run();
      }
    },
    {
      title: 'Company Role',
      subtitle: 'The lead company Role.',
      value: 'company_role',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{company_role}}')
          .run();
      }
    },
    {
      title: 'Phone',
      subtitle: 'The lead phone number.',
      value: 'phone',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{phone}}')
          .run();
      }
    },
    {
      title: 'Email',
      subtitle: 'The lead email address.',
      value: 'email',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{email}}')
          .run();
      }
    },
    {
      title: 'Linkedin',
      subtitle: 'The lead linkedin URL profile.',
      value: 'linkedin_url',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{linkedin_url}}')
          .run();
      }
    },
    {
      title: 'Twitter',
      subtitle: 'The lead username',
      value: 'twitter_name',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{twitter_name}}')
          .run();
      }
    },
    {
      title: 'Website',
      subtitle: 'The lead website url.',
      value: 'website_url',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{website_url}}')
          .run();
      }
    },
    {
      title: 'City',
      subtitle: 'The lead city.',
      value: 'city',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{city}}')
          .run();
      }
    },
    {
      title: 'Signature',
      subtitle: 'Email signature.',
      value: 'signature',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent('{{signature}}')
          .run();
      }
    }
  ]
    //remove the undefined items
    .filter((item) => excludes?.indexOf(item.value) === -1)
    .filter((item) => item.title)
    .filter((item) => item.title.toLowerCase().startsWith(query?.toLowerCase()));
};

export default getSuggestionItems;
