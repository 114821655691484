import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '@/utils/firebase';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider
} from 'firebase/auth';
import { useRouter } from 'next/router';
import PageLoader from '@/components/pageLoader';
import posthog from 'posthog-js';
import useSWR from 'swr';

export const UserContext = createContext();
const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();


const sendMagicLink = async (email) => {
  let redirect_link = window?.location?.hostname.includes('localhost') ? 'http://localhost:3000/signIntWithEmailLink' : `${process.env.NEXT_PUBLIC_APP_URL}/signIntWithEmailLink`;

  try {
    return await sendSignInLinkToEmail(auth, email, { url: redirect_link, handleCodeInApp: true }).then(r => {
      window?.localStorage.setItem('emailForSignIn', email);
    });
  } catch (err) {
    throw err;
  }
};


const signInPassword = async (email, password) => {

  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err;
  }
};

const signInWithGoogle = async () => {
  try {
    return await signInWithPopup(auth, googleProvider);
  } catch (err) {
    throw err;
  }
};
const signInWithTwitter = async () => {
  try {
    return await signInWithPopup(auth, twitterProvider);
  } catch (err) {
    throw err;
  }
};


export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  const router = useRouter();

  //API
  const {
    data: userDetails,
    isValidating,
    mutate: mutateUserDetails
  } = useSWR(user ? `/user/me` : null);


  useEffect(() => {
    let authUser = getAuth();
    const unsubscribe = onAuthStateChanged(authUser, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      window?.$crisp?.push(['set', 'user:email', [`${user.email}`]]);
      posthog.identify(user?.uid);
      posthog.people.set({ email: user?.email });
      window?.Reflio?.signup(user?.email);
      if(user.id_admin) posthog.opt_out_capturing();
    }
  }, [user]);

  async function signIn({ type, email, password }) {
    if (type === 'magicLink') {
      return await sendMagicLink(email);
    } else if (type === 'password') {
      return await signInPassword(email, password);
    } else if (type === 'google') {
      return await signInWithGoogle();
    } else if (type === 'twitter') {
      return await signInWithTwitter();
    }

    return null;
  }

  async function handleSignOut(redirectUrl) {
    setSubscription(null);
    posthog.reset();
    await signOut(auth);
    return router.push(redirectUrl ?? '/signin');
  }

  if (!userDetails && loading)
    return <PageLoader show_onboarding={true} />;

  const value = {
    user,
    whitelisted: userDetails?.whitelisted,
    active: userDetails?.active,
    userDetails,
    updateUserDetails: (data) => mutateUserDetails(data, false),
    is_admin: userDetails?.is_admin,
    userLoaded: !!userDetails,
    subscription: userDetails?.subscription,
    refetch: () => mutateUserDetails(),
    signIn: (options) => signIn(options),
    signOut: handleSignOut
  };
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
