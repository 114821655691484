import { Button, Tooltip } from '@nextui-org/react';
import { ArrowDown, ArrowUp } from 'react-feather';
import dayjs from 'dayjs';
import clsx from 'clsx';


export default function ActivityLine({ id, topContent, actions, icon, title, description, date, children, isOpen, setIsOpen }) {


  return (
    <>
      {topContent && topContent}
      <div id={id}
           className={clsx(`flex w-full hover:bg-stone-100 dark:hover:bg-stone-800 px-2 py-1.5 space-x-2 relative group`, '')}>
        <div className={'flex items-start justify-center'}>
          {icon}
        </div>
        <div className={`flex flex-col w-full`}>
          <div className={'flex flex-col'}>
            <div className={'flex flex-row space-x-2 items-center'}>
            <span className={'text-sm font-medium line-clamp-1'}>
              {title}
            </span>
              {date && (
                <span className={'text-xs text-black/80 dark:text-white/80 min-w-[70px]'}>
              {dayjs(date).format('hh:mm A')}
            </span>
              )}
            </div>
            <div className={'text-sm text-black/80 dark:text-white/80 line-clamp-1'}>
              {description}
            </div>
          </div>
          {isOpen && (
            <div className={''}>
              {children}
            </div>
          )}
          {(setIsOpen || actions) && (
            <div
              className={'hidden absolute right-4 shadow-sm -top-4 z-100 rounded-lg group-hover:flex bg-white dark:bg-black p-1 border-1 border-stone-200 dark:border-stone-700'}>
              {actions}
              {setIsOpen && (
                <Tooltip css={{ zIndex: 9999999 }} content={!isOpen ? 'Expand' : 'Reduce'}>
                  <Button className={'h-6 w-6 min-w-6'} onClick={() => setIsOpen(!isOpen)} isIconOnly variant={'light'}
                          size={'sm'}>
                    {!isOpen ? <ArrowDown size={14} /> : <ArrowUp size={14} />}
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
    </>);
}
