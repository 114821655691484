import {Button, Tooltip} from '@nextui-org/react';
import Markdown from 'react-markdown';
import Image from 'next/image';
import clsx from 'clsx';
import {Check} from 'react-feather';
import { useMemo } from 'react';

export default function PlanCard({isOldSub, products, subscription, loading, buy = () => null, product, quantity = 1, billingInterval, isFavorite, previousProduct, showAllFeatures}){


  const priceString = product?.price ? new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  }).format(billingInterval === 'year' ? product?.priceYear * quantity / 12 : product?.price * quantity) : 'Free';


  const currentProduct = useMemo(() => {
    if(!subscription){
      return null;
    }
    return products?.find(p => p?.stripe_price_id === subscription?.id_price);
  }, [subscription, products]);
  const isActive = useMemo(() => subscription && subscription.status === 'active', [subscription]);

  function isDowngrade(){
    if(!currentProduct || !isActive){
      return false;
    }
    if(currentProduct?.priceYear > product?.priceYear){
      return true;
    }
    if(currentProduct?.price > product?.price){
      return true;
    }
    return false;
  }

  function isUpgrade(){
    if(!currentProduct || !isActive){
      return false;
    }
    if(currentProduct?.priceYear < product?.priceYear){
      return true;
    }
    if(currentProduct?.price < product?.price){
      return true;
    }
    return false;
  }

  const isSamePrice = useMemo(() => subscription && subscription.status === 'active' && subscription?.id_price === product?.stripe_price_id, [subscription, product]);

  return (
    <div className={clsx(
      'bg-white dark:bg-black flex flex-col flex-1 border-1  rounded-xl',
      isFavorite && 'border-primary-500',
      !isFavorite && 'border-stone-200 dark:border-stone-700',
    )}>
      {isFavorite && (
        <div className={'bg-primary-500 px-2 py-1.5 text-center text-white text-sm leading-none rounded-t-lg'}>
          Most popular
        </div>
      )}
      <div className={'flex flex-col px-4 pt-4'}>
        <div className={'text-md font-semibold'}>
          {product?.name}
        </div>
        <div className={'mt-3 text-2xl font-semibold'}>
          {priceString}
        </div>
        <div className={'text-xs text-black/70 dark:text-white/70'}>
          per user/month, billed {billingInterval === 'year' ? 'annually' : 'monthly'}
        </div>
        <div className={'flex mt-4'}>
          <Button isDisabled={isSamePrice} isLoading={loading} onClick={() => buy(product?.stripe_price_id)} size={'sm'}
                  color= {isActive ? isSamePrice ? 'primary' : isDowngrade() ? 'default' : isUpgrade() ? 'primary' : 'primary' : 'primary'}
                  variant={isActive ? isSamePrice ? 'solid' : isDowngrade() ? 'ghost' : isUpgrade() ? 'solid' : 'solid' : isFavorite ? 'shadow' : 'ghost'}
                  fullWidth={true}>
            {isSamePrice ? 'Current plan' : isDowngrade() ? 'Downgrade' : isUpgrade() ? 'Upgrade' : isOldSub ? "Update my subscription" : 'Subscribe'}
          </Button>
        </div>
      </div>
      <div className={'flex flex-col w-full space-y-2 mt-4 pb-4'}>
        {product?.groups?.map((group, index) => {
          if (!showAllFeatures && index > 0) {
            return null;
          }
          return (
            <div key={`feature-group-${index}`} className={'flex flex-col'}>
                <div className={'font-medium text-sm text-stone-500 dark:text-white/80 px-4 uppercase'}>
                  {group.name??` ${previousProduct?.name} ALL-IN:`}
                </div>
              <div className={'flex flex-col px-2.5'}>
                {group.features.map((feature, indexF) => {
                  if(!showAllFeatures && indexF > 3){
                    return null;
                  }
                  return (
                    <div key={`${feature?.id}_${indexF}`} className={clsx(
                      feature?.tooltip && '',
                      'flex flex-row items-center space-x-2 hover:bg-stone-100 hover:dark:bg-stone-700 rounded-md px-1.5 py-1.5'
                    )}>
                      <div className={clsx(
                        'p-0.5 rounded-md',
                        isFavorite ? 'bg-primary-100 text-primary' : 'bg-stone-200 text-stone-500 dark:bg-stone-700 dark:text-stone-500',
                      )}>
                        <Check size={15}/>
                      </div>
                      {(feature?.tooltip?.text?.length > 0 || feature?.tooltip?.image) ? (
                        <Tooltip
                          className={'p-0'}
                          color={'default'}
                          placement={'left'}
                          content={<RenderTooltip tooltip={feature?.tooltip}/>}
                        >
                          <p className={'text-sm leading-none'}>{feature?.name}</p>
                        </Tooltip>
                      ) : (
                        <p className={'text-sm leading-none'}>{feature?.name}</p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {quantity && quantity > 1 && (
        <div className={'rounded-b-xl flex bg-stone-100 border-t-1 border-stone-200 px-4 py-2 mt-auto'}>
          <span className={'leading-none font-medium'}>Total for {quantity} users is <span className={'text-primary-500'}>{priceString} /{billingInterval === 'year' ? 'year' : 'month'}</span></span>
        </div>
      )}
    </div>
  )
}


function RenderTooltip({tooltip}){

  return (
    <div className={'flex flex-col p-2 max-w-md'}>
      {tooltip?.text && (
        <div className={'text-sm font-normal'}>
          <Markdown>{tooltip?.text}</Markdown>
        </div>
      )}
      {tooltip?.image && (
        <div className={clsx('border-1 border-stone-200 dark:border-stone-700 w-full rounded-lg overflow-hidden', tooltip?.text && 'mt-2')  }>
          <Image
            layout={'responsive'}
            height={tooltip?.image_height??300}
            width={tooltip?.image_width??300}
            src={tooltip?.image} />
        </div>
      )}
    </div>
  )
}
