import { Button, Link, Modal, ModalContent } from '@nextui-org/react';
import LogoAnimation from '@/components/animation/logo';
import useTheme from '@/utils/useTheme';
import { useState } from 'react';
import { ChevronLeft } from 'react-feather';
import PlansTable from '@/components/price/plans-table';
import { productService } from '@/services/product';
import useCustomToasts from '@/utils/useCustomToasts';
import { useSpace } from '@/utils/useSpace';
import SpaceSwitch from '@/components/spaceSwitch';


export default function SubscriptionEndModal({ isFreeTrialEnd, isPastDue, isCanceled }) {
  const { isDark } = useTheme();
  const [showPlan, setShowPlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setToast } = useCustomToasts();
  const { mainProduct } = useSpace();


  async function addPaymentMethod() {
    setLoading(true);
    try {
      const url = await productService.createCheckoutSession({
        id_price: 'breakcold_plus',
        successUrl: `/`,
        cancelUrl: `/`
      });
      if (!url) throw new Error('Something went wrong');
      window.location?.assign(url);
      setLoading(false);
    } catch (e) {
      setToast({
        text: e?.message ?? 'Something went wrong',
        type: 'error'
      });
      setLoading(false);
    }
  }


  return (
    <Modal
      isOpen={true}
      hideCloseButton={true}
      isDismissable={false}
      classNames={{
        wrapper: 'h-full',
        base: 'h-full'
      }}
      size={'full'}
    >
      <ModalContent>
        <div className={'flex-1 h-full flex flex-col items-center p-8'}>
          <div className={'flex w-full mb-12'}>
            <div className={'flex flex-1'}>
              {showPlan && (
                <Button size={'sm'} onClick={() => setShowPlan(false)} variant={'faded'}>
                  <ChevronLeft size={18} /> Go back
                </Button>
              )}
            </div>
            <div className={'flex flex-1 justify-center items-center'}>
              <LogoAnimation size={45} secondColor={isDark ? 'black' : 'white'} color={isDark ? 'white' : 'black'} />
            </div>
            <div className={'flex flex-1'} />
          </div>
          {showPlan && (
            <div className={'flex flex-1 flex-col items-center overflow-auto w-full'}>
              <PlansTable />
            </div>
          )}
          {!showPlan && (
            <div className={'flex flex-1 flex-col items-center justify-center'}>
              <div className={'mb-6 flex flex-col items-center justify-center '}>
                <h2 className={'text-3xl font-semibold text-center'}>
                  Your <span className={'text-primary-500 mr-2'}>{mainProduct?.name}</span>
                  {isFreeTrialEnd && 'trial is over'}
                  {isCanceled && 'subscription is canceled'}
                  {isPastDue && 'subscription have due bill'}
                </h2>
                <p className={'text-lg text-black/70 dark:text-white/70 text-center max-w-md mt-2'}>
                  {isPastDue && 'To continue using Breakcold, please update your payment method'}
                  {isFreeTrialEnd && 'To continue using Breakcold, please add a payment method to your account or change your plan.'}
                  {isCanceled && 'To continue using Breakcold, please choose a subscription'}
                </p>
              </div>
              <div className={'flex flex-col gap-3 p-4 w-full items-center justify-center max-w-sm'}>
                {(isPastDue || isFreeTrialEnd) && (
                  <Button isLoading={loading} fullWidth={true} onClick={addPaymentMethod} color={'primary'}>
                    {isPastDue && (
                      'Update payment method'
                    )}
                    {isFreeTrialEnd && (
                      'Add payment method'
                    )}
                  </Button>
                )}
                <Button color={(!isPastDue && !isFreeTrialEnd) && 'primary'} isDisabled={loading} onClick={() => setShowPlan(true)}
                        className={(isPastDue || isFreeTrialEnd) && 'hover:bg-white border-1 hover:dark:bg-stone-white'} fullWidth={true}
                        variant={(!isPastDue && !isFreeTrialEnd) ? 'solid' : 'faded'}>
                  Explore plans
                </Button>
                <Button isDisabled={loading} className={'hover:bg-white border-1 hover:dark:bg-stone-white'}
                        fullWidth={true}
                        variant={'faded'}
                        onClick={() => {
                          window?.$crisp?.push(['do', 'chat:open']);
                          window?.$crisp?.push(['set', 'message:text', [`Hi, I would like to book a meeting with a sale`]]);
                        }}
                >
                  Talk with a sale
                </Button>
                <div className={'w-full border-b-1 border-stone-200  dark:border-stone-800 my-2'} />
                {/*{isFreeTrialEnd && (*/}
                {/*  <>*/}
                {/*    <Link isDisabled={loading} size={'sm'} color={'foreground'} isBlock={true} as={'button'}*/}
                {/*          onClick={() => {*/}
                {/*            window?.$crisp?.push(['do', 'chat:open']);*/}
                {/*            window?.$crisp?.push(['set', 'message:text', [`Hi, I would like to extend my free trial`]]);*/}
                {/*          }}*/}
                {/*    >*/}
                {/*      Ask for an free trial extension*/}
                {/*    </Link>*/}
                {/*  </>*/}
                {/*)}*/}
                <SpaceSwitch allowNewSpace={false} fullWidth={true} />
              </div>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}


