import style from '@/styles/leadForm/email.module.css';
import formStyle from '@/styles/leadForm/leadForm.module.css';
import { Tooltip } from '@nextui-org/react';
import { Briefcase, Copy, ExternalLink, Home, MapPin, Phone, Trash, Type } from 'react-feather';
import { copyTextToClipboard } from '@/components/ui/leadForm/leadForm';
import { useEffect, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import React from 'react';
import lodash from 'lodash';


const StringInput = React.memo(({ label, id, onSchemaEvent ,onEvent, isCustom, deleteInput, value }) => {
    const { setToast } = useCustomToasts();
    const [inputValue, setInputValue] = useState(value ?? '');
    const [labelValue, setLabelValue] = useState(label);
    const [showOpenNewTab, setShowOpenNewTab] = useState(false);

    function handleOnBlur(r) {
        if (onEvent && r?.target?.value !== (value ?? '')) {
            onEvent({ value: r?.target?.value, id: id, type: 'string', label: labelValue });
        }
        if(isUrlOrDomain(r?.target?.value)){
            setShowOpenNewTab(true)
        } else
            setShowOpenNewTab(false)
    }

    function handleOnBlurSchema(r){
        if (onSchemaEvent && r?.target?.value !== (label ?? '')) {
            onSchemaEvent({ label: r?.target?.value, id: id, type: 'string', hidden: false });
        }
    }

    function openInNewTab(url) {
        //if it's a domain add https://
        if(!url.includes('http')){
            url = 'https://' + url;
        }

        var win = window.open(url, '_blank');
        win.focus();
    }

    //detect if it's an url or a domain
    function isUrlOrDomain(text){
        //make a regex to check if it's an url, need to work in safari
        const urlRegex = new RegExp(
          "^(http?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
          "i"
        );
        const urlRegexSecure = new RegExp(
          "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
          "i"
        );
        //make a regex to check if it's a domain name, need to work in safari
        const domainRegex = new RegExp(
            "^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$"
        );
        return urlRegex.test(text) || urlRegexSecure.test(text) || domainRegex.test(text);
    }


    useEffect(() => {
        if (value !== inputValue) {
            setInputValue(value);
        }
    }, [value]);


    return (
      <div className={style.wrap}>
          <div className={formStyle.inputWrap}>
              <div className={`${formStyle.inputHead} ${isCustom && formStyle.editable}`}>
                  <div className={formStyle.inputHeadIcon}>
                      {id === 'company' && <Home size={14} />}
                      {id === 'company_role' && <Briefcase size={14} />}
                      {id === 'city' && <MapPin size={14} />}
                      {id === 'phone' && <Phone size={14} />}
                      {(id !== 'company_role' && id !== 'company' && id !== 'phone' && id !== 'city') &&
                        <Type size={14} />}
                  </div>
                  <div className={formStyle.inputHeadLabel}>
                      {isCustom ? (
                        <input type={'text'} autoComplete={'false'} onMouseLeave={handleOnBlurSchema} onBlur={handleOnBlurSchema} className={formStyle.inputHeadLabelInput} value={labelValue}
                               onChange={r => setLabelValue(r.target?.value)} />
                      ) : label}
                  </div>
                  {isCustom && (
                    <div className={formStyle.inputHeadDelete}>
                        <Tooltip css={{ zIndex: 99999 }} content={'Delete field'}>
                            <div onClick={deleteInput} className={formStyle.inputHeadDelete}>
                                <Trash size={14} />
                            </div>
                        </Tooltip>
                    </div>
                  )}
              </div>
              <div className={formStyle.inputBody}>
                  <input type={'text'} autoComplete={'false'} onMouseLeave={handleOnBlur} onBlur={handleOnBlur} value={inputValue??''}
                         onChange={r => setInputValue(r?.target?.value)} placeholder={'Add a text'}
                         className={formStyle.input} />
                  <div className={formStyle.inputActions}>
                      <Tooltip css={{ zIndex: 999999 }} content={'Copy to clipboard'}>
                          <div onClick={() => copyTextToClipboard(inputValue, setToast)}
                               className={formStyle.inputAction}>
                              <div className={formStyle.inputActionIcon}>
                                  <Copy size={14} />
                              </div>
                          </div>
                      </Tooltip>
                      {showOpenNewTab && (
                        <Tooltip css={{ zIndex: 999999 }} content={'Open in new tab'}>
                            <div onClick={() => openInNewTab(inputValue)}
                                 className={formStyle.inputAction}>
                                <div className={formStyle.inputActionIcon}>
                                    <ExternalLink size={14} />
                                </div>
                            </div>
                        </Tooltip>
                      )}

                  </div>
              </div>
          </div>
      </div>
    );
}, lodash.isEqual)

export default StringInput;
