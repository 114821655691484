import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';
import useSWR from 'swr';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/tag`;

export const tagService = {
    createTag,
    updateTag,
    deleteTag,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
    fetchWrapperGetEmpty: url => fetchWrapper.get(`${apiUrl}/${url}`),
    fetchWrapperDelete: url => fetchWrapper.delete(`${baseUrl}${url}`),
}


function createTag({id_space, data}){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.post(`${baseUrl}?${payload}`, {
        ...data,
    });
}

function deleteTag({id_space, id_tag}){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.delete(`${baseUrl}/${id_tag}?${payload}`);
}

function updateTag({id_tag, data}){

    return fetchWrapper.patch(`${baseUrl}/${id_tag}`, {
        ...data,
    });
}
