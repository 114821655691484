import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useUser } from '@/utils/useUser';
import PageLoader from '@/components/pageLoader';
import { useCookies } from 'react-cookie';
import useSWR from 'swr';
import posthog from 'posthog-js';
import { spaceService } from '@/services/space.service';

export const SpaceContext = createContext();

export const SpaceContextProvider = (props) => {
    const [space, setSpace] = useState(null);
    const [spaces, setSpaces] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPremiumFeatureModal, setShowPremiumFeatureModal] = useState(false);

    const { userDetails, refetch } = useUser();
    const [cookies, setCookie, removeCookie] = useCookies(['breakcold']);

    const {
        data: dataLimits,
        error: errorLimit,
        isValidating: loadingLimit,
        mutate: mutateLimit
    } = useSWR((space?.id) ? `/${space?.id}/limits?id_space=${space?.id}` : null,
      spaceService.fetchWrapperGet
      , { revalidateOnFocus: false });

    useEffect(() => {
        setLoading(true);
        if (userDetails && userDetails.spaces) {
            posthog.people.set({ spaces: userDetails.spaces });
            setSpaces(userDetails.spaces);
            let tmpSpace;
            if (cookies?.breakcold_defaultSpace) {
                tmpSpace = userDetails.spaces.find(space => space.id === cookies.breakcold_defaultSpace) ?? searchForPremiumSpace(userDetails.spaces);
            } else {
                tmpSpace = searchForPremiumSpace(userDetails.spaces);
            }

            let cookieLocalStorage = localStorage.getItem('default_id_space');
            if (!cookieLocalStorage) {
                localStorage.setItem('default_id_space', tmpSpace?.id);
            } else if (cookieLocalStorage !== tmpSpace?.id) {
                localStorage.setItem('default_id_space', tmpSpace?.id);
            }

            let tmpSubscription = tmpSpace?.subscriptions;

            setSpace(tmpSpace);
            setSubscription({ ...tmpSubscription, features: parseFeatures(tmpSubscription) });
            setLoading(false);
        } else {
            setLoading(false);
            localStorage.removeItem('default_id_space');
        }

    }, [userDetails]);

    function searchForPremiumSpace(spaces){
        const space = spaces?.find(space => space?.subscriptions?.status === 'active');
        if(!space){
            //we return the first space if no match
            return spaces[0];
        }
        return space;
    }


    function parseFeatures(subscription) {
        let metadatas = subscription?.prices?.map(p => {
            let metadata = p?.product?.metadata;
            //search for key starting with "feature_"
            return Object.keys(metadata).filter(key => key.startsWith('feature_')).map(key => {
                return {
                    key: key,
                    value: metadata[key]
                };
            });
        }) ?? [];
        let features = metadatas.flat().reduce((acc, curr) => {
            acc[curr.key] = curr.value === '1';
            return acc;
        }, {});
        return features ?? {};
    }

    function updateLimits(toUpdate) {
        mutateLimit({
            ...dataLimits,
            ...toUpdate
        }, { revalidate: false });
    }

    const trialIsOver = useMemo(() =>  ((subscription?.status === 'trialing' && new Date(subscription?.trial_end) < new Date()) || (subscription.status === 'canceled' && new Date(subscription?.trial_end) < new Date())), [subscription?.status, subscription?.trial_end]);
    const subscriptionIsOver = useMemo(() => !trialIsOver && (subscription?.status === 'canceled' || subscription?.status === 'unpaid'), [subscription?.status, trialIsOver]);
    const mainProduct = useMemo(() => subscription?.prices?.find(price => price?.product?.metadata?.subscription_type === 'main')?.product, [subscription]);
    const mainPrice = useMemo(() => subscription?.prices?.find(price => price?.product?.metadata?.subscription_type === 'main'), [subscription]);
    const isLTD = useMemo(() => (space?.isLTD || mainProduct?.metadata?.is_lifetime === '1'), [space?.isLTD]);


    if (loading) return <PageLoader show_onboarding={true} />;

    const value = {
        updateLimits,
        id_space: space?.id,
        refetch,
        subscription,
        setSubscription,
        mainProduct,
        mainPrice,
        product: subscription?.price?.product,
        space,
        setSpace,
        limits: dataLimits,
        isLTD: isLTD,
        spaces,
        setSpaces,
        trialIsOver,
        subscriptionIsOver,
        showPremiumFeatureModal,
        openPremiumFeatureModal: () => setShowPremiumFeatureModal(true),
        closePremiumFeatureModal: () => setShowPremiumFeatureModal(false)
    };
    return <SpaceContext.Provider value={value} {...props} />;
};

export const useSpace = () => {
    const context = useContext(SpaceContext);
    if (context === undefined) {
        throw new Error(`useUser must be used within a UserContextProvider.`);
    }
    return context;
};
