import Inform from '@/components/ui/inform';
import {Button, Spacer, Link} from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { authenticationService } from '@/services/authentication.service';
import useCustomToasts from '@/utils/useCustomToasts';
import clsx from 'clsx';
import { useRouter } from 'next/router';
export default function LinkedinError({data, small, loading}){
  const [uiLoading, setUiLoading] = useState(false);
  const { setToast } = useCustomToasts();
  const router = useRouter();

  useEffect(() => {
    setUiLoading(loading);
  }, [loading]);


  async function oAuthLinkLinkedin() {
    setUiLoading(true);
    await localStorage.setItem('connect_redirect', `/accounts/linkedin/${data?.id}`);
    authenticationService.linkedinAuth().then(res => {
      window.location.href = res;
    }).catch(err => {
      console.log('ERROR IS', err);
      setUiLoading(false);
      setToast({
        type: 'error',
        text: err?.message??'Something went wrong, please try again later.'
      })
    });
  }

  return (
    <>
      {/*AUTH ERROR IN THE NEW AUTH SYSTEM*/}
      {(data.status === 'EXPIRED' || data.status === 'NOT_CONNECTED' || data.status === 'AUTH_FAILED') && (
        <>
          <Inform
            isWarning={true}
          >
            <div className={'flex flex-col w-full items-center text-center p-2'}>
              <span className={clsx('font-medium', small ? 'text-lg' : 'text-xl')}>
                LinkedIn Authentication expired
              </span>
              <span className={clsx('font-normal mt-2', small ? 'text-sm' : 'text-md')}>
                    Your authentication has expired. Please renew the authorization to keep the synchronisation working.
                </span>
              <Button className={clsx(small ? 'mt-4' : 'mt-6')} size={small ? 'sm': 'md'} color={'warning'} isLoading={uiLoading}
                      onClick={oAuthLinkLinkedin}>
                Renew the authorization
              </Button>
            </div>
          </Inform>
          <Spacer y={small ? 4 :6} />
        </>
      )}
      {/*AUTH ERROR IN THE NEW AUTH SYSTEM*/}
      {data.auth_method === 'EMAIL_PASSWORD' && data?.new_auth_status === 'AUTH_FAILED' && (
        <>
          <Inform
            isError={true}
          >
            <div className={'flex flex-col w-full items-center space-y-2 p-2 '}>
              <span className={clsx('font-medium', small ? 'text-lg' : 'text-xl')}>
                LinkedIn Authentication failed
              </span>
              <span className={clsx('font-normal mt-2', small ? 'text-sm' : 'text-md')}>
                Your credentials are not valid anymore. Please update theme to keep the synchronisation working
              </span>
              <Button className={clsx(small ? 'mt-4' : 'mt-6')} size={small ? 'sm': 'md'}  color={'danger'} isLoading={loading}
                      onClick={() => router.push(`/accounts/linkedin/${data?.id}/setup/location`)}>Update my
                credentials</Button>
            </div>
          </Inform>
          <Spacer y={small ? 4 :6} />
        </>
      )}
      {/*AUTH ERROR IN CHROME EXTENSION*/}
      {data.auth_method === 'EXTENSION' && data?.extension_status === 'AUTH_FAILED' && (
        <>
          <Inform
            isError={'danger'}
          >
            <div className={'flex flex-col w-full items-center p-2 text-center '}>
              <span className={clsx('font-medium', small ? 'text-lg' : 'text-xl')}>
                Chrome extension authentication failed
              </span>
              <span className={clsx('font-normal mt-2', small ? 'text-sm' : 'text-md')}>
                  We have been unable to authenticate you on Linkedin. Please read follow the instructions below to fix the issue. <br /> The problem persists? Try the new authentication method.
                </span>
              <Button className={clsx(small ? 'mt-4' : 'mt-6')} size={small ? 'sm': 'md'}  color={'danger'} isLoading={loading}
                      onClick={() => $crisp.push(['do', 'helpdesk:article:open', ['en', '81hxfm', 'How to install the Google Chrome Extension', 'Getting started']])}>
                Read the instructions
              </Button>
            </div>
          </Inform>
          <Spacer y={small ? 4 :6} />
        </>
      )}
      {/*AUTH NOT STARTED IN CHROME EXTENSION*/}
      {data.auth_method === 'EXTENSION' && data?.extension_status === 'NOT_CONNECTED' && (
        <>
          <Inform
            isInfo={true}
          >
            <div className={'flex flex-col w-full items-center p-2 text-center'}>
              <span className={clsx('font-medium', small ? 'text-lg' : 'text-xl')}>
                Chrome extension not connected or installed
              </span>
              <span className={clsx('font-normal mt-2', small ? 'text-sm' : 'text-md')}>
                  The Chrome extension is not installed or not connected to your Linkedin account. <br />Please read follow the instructions below on how to install and connect the extension.
                </span>
              <Button className={clsx(small ? 'mt-4' : 'mt-6')} size={small ? 'sm': 'md'}  color={'primary'} isLoading={loading}
                      onClick={() => $crisp.push(['do', 'helpdesk:article:open', ['en', '81hxfm', 'How to install the Google Chrome Extension', 'Getting started']])}>
                Read the installation instructions
              </Button>
              <Link size={small ? 'sm' : 'md'} className={'mt-2'} showAnchorIcon={true}
                    href={'https://chrome.google.com/webstore/detail/magic-by-breakcold-social/aleoomdhnjddjlmfocibikjdpkdpadko/related'}
                    isExternal={true} isBlock={true}>
                Install the extension
              </Link>
            </div>
          </Inform>
          <Spacer y={small ? 4 :6} />
        </>
      )}
      {/*AUTH NOT STARTED IN NEW AUTH SYSTEM*/}
      {data.auth_method === 'EMAIL_PASSWORD' && data?.new_auth_status === 'NOT_CONNECTED' && (
        <>
          <Inform
            isWarning={true}
          >
            <div className={'flex flex-col w-full items-center p-2 text-center'}>
              <span className={clsx('font-medium', small ? 'text-lg' : 'text-xl')}>
                LinkedIn Authentication not finished
              </span>
              <span className={clsx('font-normal mt-2', small ? 'text-sm' : 'text-md')}>
                    You have not finished the authentication process with Linkedin. Please finish it to make the synchronisation work.
                </span>
              <Button className={clsx(small ? 'mt-4' : 'mt-6')} size={small ? 'sm': 'md'}  color={'primary'} isLoading={loading}
                      onClick={() => router.push(`/accounts/linkedin/${data?.id}/setup/location`)}>
                Finish the authentication
              </Button>
            </div>
          </Inform>
          <Spacer y={small ? 4 :6} />
        </>
      )}
      {/*NO AUTH METHOD SELECTED*/}
      {!data.auth_method && (
        <>
          <Inform
            isWarning={true}
          >
            <div className={'flex flex-col w-full items-center p-2 text-center'}>
              <span className={clsx('font-medium', small ? 'text-lg' : 'text-xl')}>
               No authentication method selected
              </span>
              <span className={clsx('font-normal mt-2', small ? 'text-sm' : 'text-md')}>
                    You have not selected an authentication method. Please select one below to make the synchronisation work.
                </span>
            </div>
          </Inform>
          <Spacer y={small ? 4 :6} />
        </>
      )}
    </>
  )
}
