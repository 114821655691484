import style from '@/styles/leadForm/email.module.css';
import formStyle from '@/styles/leadForm/leadForm.module.css';
import { Tooltip } from '@nextui-org/react';
import { AlertTriangle, CheckCircle, Copy, UserCheck, Info, Mail, Search, Watch } from 'react-feather';
import { copyTextToClipboard } from '@/components/ui/leadForm/leadForm';
import { useEffect, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import validator from 'validator';
import React from 'react';
import lodash from 'lodash';
import { useLead } from '@/utils/useLead';

const EmailInput = React.memo(({ label, onEvent, id, value, lead }) => {
    const { setToast } = useCustomToasts();
    const [invalid, setInvalid] = useState(false);
    const [inputValue, setInputValue] = useState(value ?? '');
    const {updateLead} = useLead();


    useEffect(() => {
        if(value !== undefined) {
            setInputValue(value);
        }
    }, [value])

    function handleOnBlur(r) {
        if (!value || validator.isEmail(value)) {
            setInvalid(false);
        } else {
            setInvalid(true);
        }
        if (onEvent && r?.target?.value !== (value ?? '')) {
            onEvent({ value: r?.target?.value, id: id, type: 'email', label: label });
        }
        setInputValue(r?.target?.value);
    }


    function RenderEmailVerification({ lead }) {
        let content = null;
        let icon = null;
        let status = null;
        if(invalid)
            return null;

        if (lead?.email && lead?.email_bounce_score > 75) {
            status = 'success';
            content = 'Email have been verified and is valid';
            icon = <CheckCircle size={14}/>
        } else if (lead?.email && lead?.email_bounce_score < 75 && lead?.email_bounce_score >= 50) {
            status = 'warning';
            content = 'Email is valid but have an high risk of bounce';
            icon = <Info size={14}/>
        } else if (lead?.email && lead?.email_bounce_score < 50 && lead?.email_bounce_score >= 0 && lead?.email_bounce_score !== null) {
            status = 'error';
            content = 'Email will bounce';
            icon = <AlertTriangle size={14} />;
        } else if (lead?.email && (lead?.email_bounce_score === null || !lead?.email_bounce_score)) {
            status = 'invert';
            content = 'Email verification is still in progress, come back later';
            icon = <Watch size={14} />;
        }

        if (!content && !icon)
            return null;

        return (
          <Tooltip css={{ zIndex: 999999 }} color={status} content={content}>
              <div className={`${formStyle.inputAction} ${formStyle[status]}`}>
                  <div className={formStyle.inputActionIcon}>
                      {icon}
                  </div>
              </div>
          </Tooltip>
        );
    }
    function RenderEmailManualValidation({ lead }) {
        if(!lead || !lead?.id){
            return null;
        }


        function handleManualValidation() {
            updateLead({id:lead?.id, email_bounce_score: 100 });
        }

        if(lead?.email_bounce_score > 75)
            return null;

        if(invalid)
            return null;


        return (
          <Tooltip css={{ zIndex: 999999 }} color={'invert'} content={'Manually validate this email'}>
              <div onClick={handleManualValidation} className={`${formStyle.inputAction}`}>
                  <div className={formStyle.inputActionIcon}>
                      <UserCheck size={14}/>
                  </div>
              </div>
          </Tooltip>
        );
    }



    return (
      <div className={style.wrap}>
          <div className={formStyle.inputWrap}>
              <div className={formStyle.inputHead}>
                  <div className={formStyle.inputHeadIcon}>
                      <Mail size={14} />
                  </div>
                  <div className={formStyle.inputHeadLabel}>{label}</div>
              </div>
              <div className={formStyle.inputBody}>
                  <input type={'email'} onMouseLeave={handleOnBlur} onBlur={handleOnBlur} value={inputValue??''}
                         onChange={r => setInputValue(r?.target?.value)}
                         placeholder={'Add an email'} className={formStyle.input} />
                  <div className={formStyle.inputActions}>
                      <Tooltip css={{ zIndex: 999999 }} content={'Copy to clipboard'}>
                          <div onClick={() => copyTextToClipboard(inputValue, setToast)}
                               className={formStyle.inputAction}>
                              <div className={formStyle.inputActionIcon}>
                                  <Copy size={14} />
                              </div>
                          </div>
                      </Tooltip>
                  </div>
                  <RenderEmailVerification lead={lead} />
                  <RenderEmailManualValidation lead={lead} />
                  {invalid && (
                    <div className={formStyle.inputInfos}>
                        <Tooltip color={'error'} css={{ zIndex: 999999 }} content={'Email is not valid'}>
                            <div className={`${formStyle.inputInfo} ${invalid && formStyle.invalid}`}>
                                <div className={formStyle.inputInfoIcon}>
                                    <AlertTriangle size={14} />
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                  )}
              </div>
          </div>
      </div>
    );
}, lodash.isEqual);


export default EmailInput;
