export function filterObjToQuery({ filters, list, campaign, keepObject }) {
  let query = '';
  let obj = filters ?? {};

  if (list) {
    if (obj['lists']) {
      //if list is already in the array don't add it
      if (obj['lists'].indexOf(list) === -1) {
        obj['lists'].push(list);
      }
    } else {
      obj['lists'] = [list];
    }
  }

  if (campaign) {
    if (obj['campaigns']) {
      //if campaign is already in the array don't add it
      if (obj['campaigns'].indexOf(campaign) === -1){
        obj['campaigns'].push(campaign);
      }
    } else {
      obj['campaigns'] = [campaign];
    }
  }

  if(keepObject){
    return obj;
  }

  for (let key in obj) {
    //if it's an array split it by commas
    //if it's last key don't add the &
    //if value is undefined don't add it
    if (obj[key] === undefined) continue;

    if (Array.isArray(obj[key])) {
      query += `${key}=${obj[key].join(',')}&`;
    } else if (obj[key] !== '') {
      query += `${key}=${obj[key]}&`;
    }
    if (key === Object.keys(obj)[Object.keys(obj).length - 1]) {
      query = query.slice(0, -1);
    }
  }
  return query;
}


export function queryToFilterObj(query) {
  let obj = {};

  if(!query) return obj;

  // Split query string into key=value pairs
  let pairs = query.split('&');

  // Iterate over pairs and process each pair
  for (let i = 0; i < pairs.length; i++) {
    // Split each pair into key and value
    let [key, value] = pairs[i].split('=');

    // Check if value is an array (contains commas)
    if (value.includes(',')) {
      // Convert value string to array
      value = value.split(',');
    }

    // Add key-value pair to object
    obj[key] = value;
  }

  return obj;
}

export default filterObjToQuery;
