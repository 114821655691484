import style from '@/styles/social/post.module.css';
import { Avatar, Button, Dropdown, Tooltip } from '@nextui-org/react';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useLead } from '@/utils/useLead';
import LikeIcon from '@/components/icons/social/like';
import CommentIcon from '@/components/icons/social/comment';
import { Link, MoreVertical, Send } from 'react-feather';
import useCustomToasts from '@/utils/useCustomToasts';
import Image from "next/legacy/image";
import PostCardResponse from '@/components/social/postCard/response';
import { leadService } from '@/services/lead.service';
import { useLinkedin } from '@/utils/useLinkedin';
import ButtonIcon from '@/components/ui/buttonIcon';
import { useUser } from '@/utils/useUser';
import {
    DropdownTrigger,
    DropdownMenu,
    DropdownSection,
    DropdownItem
} from "@nextui-org/react";
export default function LinkedinPostCard({
                                             isLead,
                                             small,
                                             isCompany,
                                             data,
                                             noMaxWidth,
                                             onSelectLead,
                                         }) {

    const { openLead, uniqueLead } = useLead();
    const {userDetails} = useUser();
    const { setToast } = useCustomToasts();
    const { selectedAccount: selectedAccountLinkedin } = useLinkedin();

    const [postData, setPostData] = useState(data);
    const [commentLoading, setCommentLoading] = useState(false);
    const commentInteraction = postData?.interactions?.find(i => i?.type === 'COMMENT');

    const lead = data?.lead??uniqueLead


    useEffect(() => {
        setPostData(data);
    }, [data]);

    function handleMoreDropdown(key) {
        switch (key) {
            case 'copyId':
                navigator.clipboard.writeText(data.id);
                setToast({
                    type: 'success',
                    text: 'Copied to clipboard'
                });
                break;
            case 'link':
                window.open(`https://linkedin.com/in/${postData?.post?.lead_linkedin_account?.linkedin_username}/recent-activity/shares/`, '_blank');
        }
    }

    function canInteract() {
        if (!selectedAccountLinkedin) {
            setToast({
                text: "Connect a Linkedin account to interact with this post"
            })
            return false;
        }
        return true;
    }

    function handleComment(comment) {
        if (!canInteract())
            return;

        setCommentLoading(true);
        const interaction = {
            type: "COMMENT",
            id_post: postData?.id,
            id_linkedin_account: selectedAccountLinkedin?.id,
            linkedin_account: selectedAccountLinkedin,
            user: userDetails,
            created_at: new Date().toISOString(),
            id_space: postData?.id_space,
        }
        setPostData(prevState => {
            const interactions = prevState?.interactions ? [...prevState?.interactions, interaction] : [interaction];
            return {
                ...prevState,
                is_replied: !prevState.is_replied,
                reply: comment,
                interactions: interactions
            };
        });

        return leadService.replyLinkedinPost({
            id_linkedin_account: selectedAccountLinkedin?.id,
            id_post: postData?.id,
            id_lead: isCompany ? postData?.post?.lead_linkedin_account?.leads_linkedin_company[0]?.id : postData?.post?.lead_linkedin_account?.leads_linkedin[0]?.id,
            reply: comment
        }).then(r => {

        }).catch(e => {
            setToast({
                text: e?.message ?? 'Something went wrong',
                type: 'error'
            });
            setPostData(prevState => {
                return {
                    ...prevState,
                    is_replied: !prevState.is_replied
                };
            });
        }).finally(() => {
            setCommentLoading(false);
        });
    }

    function handleLike() {
        setPostData(prevState => {
            return {
                ...prevState,
                is_liked: !prevState.is_liked
            };
        });
        return leadService.likeLinkedinPost({
            id_linkedin_account: selectedAccountLinkedin?.id,
            id_post: postData?.id,
            id_lead: isCompany ? postData?.post?.lead_linkedin_account?.leads_linkedin_company[0]?.id : postData?.post?.lead_linkedin_account?.leads_linkedin[0]?.id
        }).then(r => {
            //update the post in the data
        }).catch(e => {
            setToast({
                text: e.message ?? 'Something went wrong',
                type: 'error'
            });
            setPostData(prevState => {
                return {
                    ...prevState,
                    is_liked: !prevState.is_liked
                };
            });
        });
    }

    return (
      <div className={`${style.wrapper} ${small && style.small} ${noMaxWidth && style.noMaxWidth}`}>
          <div className={style.post}>
              <div className={`${style.header} ${postData?.post?.metadata?.is_re_post && style.repost}`}>
                  <div className={`${style.lead} ${postData?.post?.metadata?.is_re_post && style.repost}`}>
                      <div className={style.avatar}>
                          <Avatar zoomed size={(small || postData?.post?.metadata?.is_re_post) ? 'sm' : 'md'}
                                  src={postData?.post?.metadata?.actor?.avatar_url ?? lead?.avatar_url} />
                      </div>
                      <div className={style.profile}>
                          <div className={style.profileTop}>
                              {(postData?.post?.metadata?.actor?.name || lead?.lastName) ? (
                                <div onClick={() => openLead(lead?.id, 'update')} className={style.name}>
                                    {postData?.post?.metadata?.actor?.name}
                                </div>
                              ) : (
                                <div onClick={() => openLead(lead?.id, 'update')} className={style.placeholder}>
                                    First Name Last Name
                                </div>
                              )}
                              <div className={style.separator}>
                                  <span>·</span>
                              </div>
                              {!postData?.post?.metadata?.is_re_post && (
                                <div className={style.postedAt}>
                                    {moment(postData?.post?.created_at??postData?.created_at).fromNow()}
                                </div>
                              )}
                              {postData?.post?.metadata?.is_re_post && (
                                <div className={style.repostText}>
                                    Reposted
                                </div>
                              )}
                              {postData?.post?.metadata?.is_re_post && (
                                <Tooltip css={{zIndex: 999999}} content={"Linkedin publication"} color={'invert'}>
                                    <div className={style.socialLogo}>
                                        <Image src={postData?.post?.lead_linkedin_account?.linkedin_premium ? '/static/linkedinFaviconPremium.svg' : '/static/linkedinFavicon.svg'} width={16} height={16} />
                                    </div>
                                </Tooltip>
                              )}
                          </div>
                          {!postData?.post?.metadata?.is_re_post && (
                            <div className={style.handle}>
                                <Tooltip css={{zIndex: 999999}} content={"Linkedin publication"} color={'invert'}>
                                <div className={style.socialLogo}>
                                    <Image src={postData?.post?.lead_linkedin_account?.linkedin_premium ? '/static/linkedinFaviconPremium.svg' : '/static/linkedinFavicon.svg'} width={16} height={16} />
                                </div>
                                </Tooltip>
                                <div className={style.description}>
                                    {postData?.post?.metadata?.actor?.description}
                                </div>
                            </div>
                          )}
                      </div>
                  </div>
                  <div className={style.actions}>
                      {(!small && !isLead) && (
                        <Tooltip content={'Send private message'}>
                            <Button isIconOnly variant={'light'} size={'sm'} onClick={() => onSelectLead(lead)}>
                                <Send strokeWidth={1} size={22} />
                            </Button>
                        </Tooltip>
                      )}
                      <Dropdown placement={'bottom-right'}>
                          <DropdownTrigger>
                              <div>
                                  <Button isIconOnly variant={'light'} size={'sm'} >
                                      <MoreVertical size={22} />
                                  </Button>
                              </div>
                          </DropdownTrigger>
                          <DropdownMenu onAction={handleMoreDropdown} aria-label='More'>
                              <DropdownItem startContent={<Link strokeWidth={1} size={20} />}
                                             key='link'>Linkedin Recent posts</DropdownItem>
                              {process.env.NODE_ENV !== 'production' && (
                                <DropdownItem startContent={<Link strokeWidth={1} size={20} />}
                                               key='copyId'>Copy post Id</DropdownItem>
                              )}
                          </DropdownMenu>
                      </Dropdown>
                  </div>
              </div>
              <div className={style.body}>
                  <RenderTweetText small={small} text={postData?.post?.text} />
                  {postData?.post?.children && (
                    <div className={style.children}>
                        <div className={style.header}>
                            <div className={`${style.lead} ${postData?.post?.metadata?.is_re_post && style.repost}`}>
                                <div className={style.avatar}>
                                    <Avatar zoomed size={(small || postData?.post?.metadata?.is_re_post) ? 'sm' : 'sm'}
                                            src={postData?.post?.children?.metadata?.actor?.avatar_url ?? lead?.avatar_url} />
                                </div>
                                <div className={style.profile}>
                                    <div className={style.profileTop}>
                                        {(postData?.post?.children?.metadata?.actor?.name || lead?.lastName) ? (
                                          <div onClick={() => openLead(lead?.id, 'update')} className={style.name}>
                                              {postData?.post?.children?.metadata?.actor?.name}
                                          </div>
                                        ) : (
                                          <div onClick={() => openLead(lead?.id, 'update')} className={style.placeholder}>
                                              First Name Last Name
                                          </div>
                                        )}
                                        {postData?.post?.children?.metadata?.is_re_post && (
                                          <div className={style.separator}>
                                            <span>·</span>
                                        </div>
                                        )}
                                        {postData?.post?.children?.metadata?.is_re_post && (
                                          <div className={style.repostText}>
                                              Reposted
                                          </div>
                                        )}
                                    </div>
                                    {!postData?.post?.metadata?.is_re_post && (
                                      <div className={style.handle}>
                                          {!postData?.post?.metadata?.is_re_post && (
                                            <div className={style.postedAt}>
                                                {moment(postData?.post?.created_at).fromNow()}
                                            </div>
                                          )}
                                          <div className={style.description}>
                                              {postData?.post?.children?.metadata?.actor?.description}
                                          </div>
                                      </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={style.body}>
                            <RenderTweetText text={postData?.post?.children?.text}/>
                        </div>
                        {postData?.post?.children?.media?.length > 0 && (
                          <div className={style.medias}>
                              {postData?.post?.children?.media?.map((media, index) => {
                                  if(!media?.url)
                                        return null;
                                  //if media.url contains the word playlist or mp4, it's a video
                                    if (media?.url?.includes('playlist') || media?.url?.includes('mp4')) {
                                        return (
                                            <div className={`${style.media} ${style.video}`} key={index}>
                                                <video
                                                    src={media.url}
                                                    width={'100%'}
                                                    height={'100%'}
                                                    autoPlay={false}
                                                    controls={true}
                                                    loop={false}
                                                    muted={false}
                                                    playsInline={false}
                                                    preload={'auto'}
                                                    className={style.video}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return (
                                          <a href={media.url} target={'_blank'} key={index} className={style.media}>
                                              <img src={media.url} />
                                          </a>
                                        );
                                    }
                              })}
                          </div>
                        )}
                    </div>
                  )}
              </div>
              {postData?.post?.media?.length > 0 && (
                <div className={style.medias}>
                    {postData?.post?.media?.map((media, index) => {
                        if (media?.url?.includes('playlist') || media?.url?.includes('mp4')) {
                            return (
                              <div className={`${style.media} ${style.video}`} key={index}>
                                  <video
                                    src={media.url}
                                    width={'100%'}
                                    height={'100%'}
                                    autoPlay={false}
                                    controls={true}
                                    loop={false}
                                    muted={false}
                                    playsInline={false}
                                    preload={'auto'}
                                    className={style.video}
                                  />
                              </div>
                            );
                        } else {
                            return (
                              <a href={media.url} target={'_blank'} key={index} className={style.media}>
                                  <img src={media.url} />
                              </a>
                            );
                        }
                    })}
                </div>
              )}
              <div className={style.footer}>
                  <div className={`${style.actions}`}>
                      <div onClick={handleLike} className={style.action}>
                          <div className={style.actionCount}>
                              {(postData?.post?.like_count + postData?.is_liked ? 1 : 0).toLocaleString('en-EN')}
                          </div>
                          <div className={style.actionIcon}>
                              <LikeIcon is_liked={postData?.is_liked} size={small ? 16 : 18} />
                          </div>
                          {!small && (
                            <div className={style.actionLabel}>
                                <span>{postData?.is_liked ? 'Like' : 'Like'}</span>
                            </div>
                          )}
                      </div>
                      <div className={style.action}>
                          <div className={style.actionCount}>
                              {(postData?.post?.reply_count + postData?.is_replied ? 1 : 0).toLocaleString('en-EN')}
                          </div>
                          <div className={style.actionIcon}>
                              <CommentIcon isCommented={postData?.is_replied} size={small ? 16 : 18} />
                          </div>
                          {!small && (
                            <div className={style.actionLabel}>
                                <span>Comment</span>
                            </div>
                          )}
                      </div>
                  </div>
              </div>
          </div>
          <PostCardResponse interaction={commentInteraction} type='linkedin' small={small} is_replied={postData?.is_replied} loading={commentLoading} submitText={'Reply'}
                            onSubmit={handleComment} account={selectedAccountLinkedin} />
      </div>

    );
}


function RenderTweetText({ text, small }) {
    const [textToRender, setTextToRender] = useState('');
    const [seeMore, setSeeMore] = useState(false);
    const [hideSeeMore, setHideSeeMore] = useState(true);
    const textRef = useRef();

    if (!text) {
        return null;
    }
    useEffect(() => {
        //convert text to html
        //Replace links with <a> tags
        let parsedText = text.replace(/\n/g, '<br>');
        //Search links that not end with .

        //Add target _blank on every <a>
        parsedText = parsedText.replace(/<a href=/g, '<a target="_blank" href=');
        // if (links) {
        //     links.forEach(link => {
        //           let linkText = link.replace(/<\/?a[^>]*>/g, '');
        //           let linkHref = link.replace(/<\/?a[^>]*>/g, '');
        //           linkText = linkText.replace(/\)/g, '');
        //           linkText = linkText.replace(/\.$/, '');
        //           linkHref = linkHref.replace(/\)/g, '');
        //           linkHref = linkHref.replace(/\.$/, '');
        //           parsedText = parsedText.replace(linkText, `<a target='_blank' href='${linkHref}'>${linkText}</a>`);
        //       }
        //     );
        // }
        //Replace hashtags with <a> tags
        const hashtags = parsedText.match(/#\w+/gi);
        if (hashtags) {
            hashtags.forEach(hashtag => {
                  const hashtagText = hashtag.replace(/<\/?a[^>]*>/g, '');
                  const hashtagHref = `https://twitter.com/hashtag/${hashtagText.replace(/#/g, '')}`;
                  parsedText = parsedText.replace(hashtagText, `<a target='_blank' href='${hashtagHref}'>${hashtagText}</a>`);
              }
            );
        }
        //Replace @mentions with <a> tags
        const mentions = parsedText.match(/@\w+/gi);
        if (mentions) {
            mentions.forEach(mention => {
                const mentionText = mention.replace(/<\/?a[^>]*>/g, '');
                const mentionHref = `https://twitter.com/${mentionText.replace(/@/g, '')}`;
                parsedText = parsedText.replace(mentionText, `<a target='_blank' href='${mentionHref}'>${mentionText}</a>`);
            });
        }

        setTextToRender(parsedText);
    }, [text]);

    useEffect(() => {
        //check if the text is more than 4 lines
        if (textRef.current) {
            const height = textRef.current.clientHeight;
            if (height >= (small ? 72 : 84)) {
                setHideSeeMore(false)
            }
        }
    }, [textToRender])

    return (
      <div className={`${style.linkedinRenderWrap} ${seeMore && style.seeAll}`}>
          <div ref={textRef} className={style.linkedinRenderText} dangerouslySetInnerHTML={{ __html: textToRender }} />
          {!hideSeeMore && <div onClick={() => setSeeMore(!seeMore)} className={style.more}>{seeMore ? "see less" : "...see more"}</div>}
      </div>
    );
}
