import style from '../styles/Emoji.module.css'
import { useState } from 'react';
import useTheme from '@/utils/useTheme';
import React from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { init } from 'emoji-mart'
import {Popover, PopoverContent, PopoverTrigger} from '@nextui-org/react';

init({ data })


export default function Emoji({emoji, picker, onChange, size}){
  const [visible, setVisible] = useState(false)
  const { isDark } = useTheme();


  function PickerComponent(){
    return <Picker data={data} onClick={r => {
      onChange(r);
      setVisible(false);
    }} theme={'dark'} set='apple' />
  }

  function handleChange(r){
    onChange && onChange(r);
    setVisible(false);
  }

  if(!picker){
    return (
      <em-emoji
        theme={isDark? 'dark' : 'light'}
        set={'apple'}
        native={emoji}
        size={size ? size: 24}
        fallback={(emoji, props) => {
          return emoji ? `:${emoji?.short_names[0]}:` : props.emoji
        }}
      />
    )
  }

  return (
    <div className={`${picker ? style.emojiPickable : style.emoji}`}>
      <Popover
        isOpen={visible}
        onOpenChange={() => setVisible(true)}
        onClose={() => setVisible(false)}
        showArrow
        placement="right"
        classNames={{
          base: "py-0 px-0 border border-default-200 bg-gradient-to-br from-white to-default-300 dark:from-default-100 dark:to-default-50",
          arrow: "bg-default-200",
        }}
      >
        <PopoverTrigger>
          <em-emoji
            theme={isDark? 'dark' : 'light'}
            set={'apple'}
            native={emoji}
            size={size ? size: 24}
            fallback={(emoji, props) => {
              return emoji ? `:${emoji?.short_names[0]}:` : props.emoji
            }}
          />
        </PopoverTrigger>
        <PopoverContent>
          <Picker data={data} theme={isDark ? 'dark' : 'light'} onEmojiSelect={handleChange} />
        </PopoverContent>

      </Popover>
    </div>
  )
}
