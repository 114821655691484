import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'

import findVariables from './findVariable'

export const VariableHighlighter = Extension.create({
    name: 'variableHighlighter',

    addProseMirrorPlugins() {
        let variables = this.options.customVariables
        let isUser = this.options.isUser

        return [
            new Plugin({
                state: {
                    init(_, { doc }) {
                        return findVariables(doc, variables)
                    },
                    apply(transaction, oldState) {
                        return transaction.docChanged ? findVariables(transaction.doc, variables, isUser) : oldState
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state)
                    },
                },
            }),
        ]
    },
})
