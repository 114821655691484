import React, { createContext, useContext, useEffect } from 'react';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import { accountService } from '../services/account.service';
import { leadService } from '../services/lead.service';

export const LinkedinContext = createContext();

export const LinkedinContextProvider = (props) => {
  const [selectedAccount, setSelectedAccount] = React.useState(null);

  const { id_space } = useSpace();

  //get the Linkedin accounts
  const {
    data: accounts,
    error,
    isValidating,
    mutate: mutateLeads
  } = useSWR(id_space ? `s/linkedin?id_space=${id_space}` : null, accountService.fetchWrapperGet);


  useEffect(() => {
    //get the selected account from the local storage
    const selectedLinkedinAccount = localStorage.getItem('selectedLinkedinAccount');
    if (selectedLinkedinAccount) {
      const selectedAccount = accounts?.find(account => account.id === selectedLinkedinAccount);
      setSelectedAccount(selectedAccount);
    }
  }, [accounts]);

  useEffect(() => {
    //store the selected account in the local storage
    if (selectedAccount) {
      localStorage.setItem('selectedLinkedinAccount', selectedAccount?.id);
    }
  }, [selectedAccount])
  function sendDirectMessage(leadId, data){
    return leadService.sendDmTwitter(id_space, leadId, {
      ...data
    })
  }

  let value = {
    selectedAccount: selectedAccount ?? (accounts && accounts.length > 0 && accounts[0]),
    setSelectedAccount,
    accounts,
    sendDirectMessage
  };
  return <LinkedinContext.Provider value={value} {...props} />;
};

export const useLinkedin = () => {
  const context = useContext(LinkedinContext);
  if (context === undefined) {
    throw new Error(`useLead must be used within a LeadContextProvider.`);
  }
  return context;
};
