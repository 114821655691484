import style from '@/styles/social/post.module.css';
import { Button, Dropdown, Tooltip } from '@nextui-org/react';
import PostCardResponse from '@/components/social/postCard/response';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLead } from '@/utils/useLead';
import LikeIcon from '@/components/icons/social/like';
import CommentIcon from '@/components/icons/social/comment';
import RetweetIcon from '@/components/icons/social/retweet';
import { Link, MoreVertical, Send, Twitter, Slash } from 'react-feather';
import useCustomToasts from '@/utils/useCustomToasts';
import { leadService } from '@/services/lead.service';
import { useTwitter } from '@/utils/useTwitter';
import Avatar from '@/components/ui/avatar';
import cn from 'classnames';
import { useUser } from '@/utils/useUser';
import {
    DropdownTrigger,
    DropdownMenu,
  Link as NextLink,
    DropdownItem
} from "@nextui-org/react";
import clsx from 'clsx';
export default function TwitterPostCard({
                                            isLead,
                                            small,
                                            data,
                                            noMaxWidth,
                                            isCompany,
                                            onSelectLead
                                        }) {

    const { openLead, uniqueLead } = useLead();
    const { setToast } = useCustomToasts();
    const { selectedAccount: selectedAccountTwitter } = useTwitter();
    const [postData, setPostData] = useState(data);
    const [commentLoading, setCommentLoading] = useState(false);
    const commentInteraction = postData?.interactions?.find(i => i?.type === 'COMMENT');
    const {userDetails} = useUser();
    const lead = data?.lead??uniqueLead;

    useEffect(() => {
        setPostData(data);
    }, [data]);

    function handleMoreDropdown(key) {
        switch (key) {
            case 'copyId':
                navigator.clipboard.writeText(postData.id);
                setToast({
                    type: 'success',
                    text: 'Copied to clipboard'
                });
                break;
            case 'link':
                window.open(`https://twitter.com/${postData.post?.lead_twitter_account?.username}/status/${postData?.post?.tweet_id}`, '_blank');
        }
    }

    function canInteract() {
        if (!selectedAccountTwitter) {
            setToast({
                text: 'Connect a Twitter account to interact with this tweet'
            });
            return false;
        }
        return true
    }


    function handleComment(comment) {
        if (!canInteract())
            return;

        setCommentLoading(true);

        const interaction = {
            type: "COMMENT",
            id_post: postData?.id,
            id_twitter_account: selectedAccountTwitter?.id,
            twitter_account: selectedAccountTwitter,
            user: userDetails,
            created_at: new Date().toISOString(),
            id_space: postData?.id_space,
        }

        setPostData(prevState => {
            const interactions = prevState?.interactions ? [...prevState?.interactions, interaction] : [interaction];
            return {
                ...prevState,
                is_replied: !prevState.is_replied,
                reply: comment,
                interactions: interactions
            };
        });

        return leadService.replyTweet({
            id_twitter_account: selectedAccountTwitter?.id,
            id_post: postData?.id,
            id_lead: lead?.id,
            reply: comment
        }).then(r => {

        }).catch(e => {
            setToast({
                text: e?.message ?? 'Something went wrong',
                type: 'error'
            });
            setPostData(prevState => {
                return {
                    ...prevState,
                    is_replied: !prevState.is_replied
                };
            });
        }).finally(() => {
            setCommentLoading(false);
        });
    }


    function handleLike() {
        setPostData(prevState => {
            return {
                ...prevState,
                is_liked: !prevState.is_liked
            };
        });
        return leadService.likeTweet({
            id_twitter_account: selectedAccountTwitter?.id,
            id_post: postData?.id,
            id_lead: lead?.id
        }).then(r => {
            //update the post in the data
        }).catch(e => {
            setToast({
                text: e.message ?? 'Something went wrong',
                type: 'error'
            });
            setPostData(prevState => {
                return {
                    ...prevState,
                    is_liked: !prevState.is_liked
                };
            });
        });
    }
    function handleRetweet() {
        setPostData(prevState => {
            return {
                ...prevState,
                is_retweeted: !prevState.is_retweeted
            };
        });
        return leadService.retweetTweet({
            id_twitter_account: selectedAccountTwitter?.id,
            id_post: postData?.id,
            id_lead: lead?.id
        }).then(r => {
            //update the post in the data
        }).catch(e => {
            setToast({
                text: e.message ?? 'Something went wrong',
                type: 'error'
            });
            setPostData(prevState => {
                return {
                    ...prevState,
                    is_retweeted: !prevState.is_retweeted
                };
            });
        });
    }

    return (
      <div className={`${style.wrapper} ${small && style.small} ${noMaxWidth && style.noMaxWidth}`}>
          <div className={style.post}>
              <div className={cn(style.header, isCompany && style.company)}>
                  <div className={style.lead}>
                      <div className={style.avatar}>
                          <Avatar size={(isCompany || small) ? 30 : 40} src={lead?.avatar_url} />
                      </div>
                      <div className={style.profile}>
                          <div className={style.profileTop}>
                              {(lead?.first_name || lead?.last_name) && !isCompany && (
                                <div onClick={() => openLead(lead?.id, 'update')} className={style.name}>
                                    {lead?.first_name} {lead?.last_name}
                                </div>
                              )}

                              {(lead?.company) && isCompany && (
                                <div onClick={() => openLead(lead?.id, 'update')} className={style.name}>
                                    {lead?.company}
                                </div>
                              )}

                              {(!lead?.company) && isCompany && (
                                <div onClick={() => openLead(lead?.id, 'update')} className={style.placeholder}>
                                    No company
                                </div>
                              )}
                              {(!lead?.first_name && !lead?.last_name) && !isCompany && (
                                <div onClick={() => openLead(lead?.id, 'update')} className={style.placeholder}>
                                    No name
                                </div>
                              )}
                              <div className={style.separator}/>
                              <div className={style.postedAt}>
                                  {moment(postData?.post?.created_at).fromNow(false)}
                              </div>
                          </div>
                          <div className={style.handle}>
                              <Tooltip content={'Twitter publication'} color={'invert'}>
                                  <div className={`${style.socialLogo} ${style.twitter}`}>
                                      <Twitter fill={'white'} stroke={0} size={14} />
                                  </div>
                              </Tooltip>
                              {isCompany ? lead?.twitter_company?.username : lead?.twitter?.username}
                          </div>
                      </div>
                  </div>
                  <div className={style.actions}>
                      {(!small && !isLead) && (
                        <Tooltip content={'Send private message'}>
                            <Button isIconOnly variant={'light'} size={'sm'} onClick={() => onSelectLead(lead)}>
                                <Send strokeWidth={1} size={22} />
                            </Button>
                        </Tooltip>
                      )}
                      <Dropdown placement={'bottom-right'}>
                          <DropdownTrigger>
                                  <Button isIconOnly variant={'light'} size={'sm'} onClick={() => onSelectLead(lead)}>
                                      <MoreVertical size={small ? 18 : 22} />
                                  </Button>
                          </DropdownTrigger>
                          <DropdownMenu onAction={handleMoreDropdown} aria-label='More'>
                              <DropdownItem startContent={<Link strokeWidth={1} size={20} />}
                                             key='link'>Open Tweet</DropdownItem>
                              {process.env.NODE_ENV !== 'production' && (
                                <DropdownItem startContent={<Link strokeWidth={1} size={20} />}
                                               key='copyId'>Copy post Id</DropdownItem>
                              )}
                          </DropdownMenu>
                      </Dropdown>
                  </div>
              </div>
              <div className={style.body}>
                  <RenderTweetText link={`https://twitter.com/${postData.post?.lead_twitter_account?.username}/status/${postData?.post?.tweet_id}`}  text={postData?.post?.text} />
              </div>
              {postData?.post?.media?.length > 0 && (
                <div className={style.medias}>
                    {postData?.post?.media?.map((media, index) => {
                        return (
                          <RenderImage media={media} key={index} />
                        );
                    })}
                </div>
              )}
              <div className={`${style.footer} post_footer`}>
                  <div className={style.actions}>
                      <div onClick={handleLike} className={style.action}>
                          <div className={style.actionCount}>
                              {(postData?.post?.like_count + (postData?.is_liked ? 1 : 0))?.toLocaleString('en-EN')}
                          </div>
                          <div className={style.actionIcon}>
                              <LikeIcon is_liked={postData?.is_liked} size={small ? 16 : 18} />
                          </div>
                          {!small && (
                            <div className={style.actionLabel}>
                                <span>{postData?.is_liked ? 'Like' : 'Like'}</span>
                            </div>
                          )}
                      </div>
                      <div className={style.action}>
                          <div className={style.actionCount}>
                              {(postData?.post?.reply_count + (postData?.is_replied ? 1 : 0))?.toLocaleString('en-EN')}
                          </div>
                          <div className={style.actionIcon}>
                              <CommentIcon isCommented={postData?.is_replied} size={small ? 16 : 18} />
                          </div>
                          {!small && (
                            <div className={style.actionLabel}>
                                <span>Comment</span>
                            </div>
                          )}
                      </div>
                      <div onClick={handleRetweet} className={clsx(style.action, postData?.is_retweeted && 'text-primary')}>
                          <div className={style.actionCount}>
                              {(postData?.post?.retweet_count + (postData?.is_retweeted ? 1 : 0))?.toLocaleString('en-EN')}
                          </div>
                          <div className={style.actionIcon}>
                              <RetweetIcon is_retweeted={postData?.is_retweeted} size={small ? 16 : 18} />
                          </div>
                          {!small && (
                            <div className={style.actionLabel}>
                                <span>Repost{postData?.is_retweeted && 'ed'}</span>
                            </div>
                          )}
                      </div>
                  </div>
              </div>
          </div>
          <PostCardResponse interaction={commentInteraction} type={'twitter'} small={small} is_replied={postData?.is_replied} loading={commentLoading}
                            submitText={'Reply'}
                            onSubmit={handleComment} account={selectedAccountTwitter} />
      </div>

    );
}


function RenderImage({ media }) {
    const [imgNotFound, setImgNotFound] = useState(false);

    if (imgNotFound) {
        return null;
    }

    return (
      <a onError={() => setImgNotFound(true)} href={media.url} target={'_blank'} className={style.media}>
          <img src={media.url} />
      </a>
    );
}


function RenderTweetText({ text, link }) {
    const [textToRender, setTextToRender] = useState('');

    useEffect(() => {
        //convert text to html
        if(!text) return;
        //Replace links with <a> tags
        let parsedText = text.replace(/\n/g, '<br>');
        //Search links that not end with .
        const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
        const links = parsedText.match(regex);

        // const links = parsedText.match(/\b(https?:\/\/\S+)/gi);
        if (links) {
            links.forEach(link => {
                  let linkText = link.replace(/<\/?a[^>]*>/g, '');
                  let linkHref = link.replace(/<\/?a[^>]*>/g, '');
                  linkText = linkText.replace(/\)/g, '');
                  linkText = linkText.replace(/\.$/, '');
                  linkHref = linkHref.replace(/\)/g, '');
                  linkHref = linkHref.replace(/\.$/, '');
                  parsedText = parsedText.replace(linkText, `<a target='_blank' href='${linkHref}'>${linkText}</a>`);
              }
            );
        }
        //Replace hashtags with <a> tags
        const hashtags = parsedText.match(/#\w+/gi);
        if (hashtags) {
            hashtags.forEach(hashtag => {
                  const hashtagText = hashtag.replace(/<\/?a[^>]*>/g, '');
                  const hashtagHref = `https://twitter.com/hashtag/${hashtagText.replace(/#/g, '')}`;
                  parsedText = parsedText.replace(hashtagText, `<a target='_blank' href='${hashtagHref}'>${hashtagText}</a>`);
              }
            );
        }
        //Replace @mentions with <a> tags
        const mentions = parsedText.match(/@\w+/gi);
        if (mentions) {
            mentions.forEach(mention => {
                const mentionText = mention.replace(/<\/?a[^>]*>/g, '');
                const mentionHref = `https://twitter.com/${mentionText.replace(/@/g, '')}`;
                parsedText = parsedText.replace(mentionText, `<a target='_blank' href='${mentionHref}'>${mentionText}</a>`);
            });
        }

        setTextToRender(parsedText);
    }, [text]);


    if(!textToRender){
        return (
          <div className={'flex flex-col items-center justify-center m-w-[80%] px-2 py-4'}>
              <div className={'text-gray-600 flex items-center justify-center mb-4'}>
                  <Slash size={32}/>
              </div>
                <div className={'text-gray-600 text-sm'}>Age-restricted adult content. This content might not be appropriate for people under 18 years old. To view this media, you’ll need to go on Twitter.</div>
              <NextLink isBlock href={link} isExternal size={'sm'} color={'primary'} className={'mt-2'}>
                  See on Twitter
              </NextLink>
          </div>
        )
    }

    return (
      <div className={style.tweetText}>
          <div dangerouslySetInnerHTML={{ __html: textToRender }} />
      </div>
    );
}
