import React, { useEffect, useState } from 'react';
import { useSpace } from '@/utils/useSpace';
import style from '@/styles/leadLayer.module.css';
import { FilePlus, Info, X } from 'react-feather';
import { useRouter } from 'next/router';
import { useLead } from '@/utils/useLead';
import { useUser } from '@/utils/useUser';
import Loading from "@/components/ui/loading";
import useSWR from 'swr';
import { spaceService } from '@/services/space.service';
import Script from 'next/script';


import { Button, Spacer} from "@nextui-org/react";
import { Switch } from "@nextui-org/react";
import { Tooltip } from "@nextui-org/react";


export default function LeadImport() {
    const [ignoreDuplicates, setIgnoreDuplicates] = useState(false);
    const [importCustom, setImportCustom] = useState(false);
    const { id_space } = useSpace();


    //API
    const {
        data: apiKeyData,
        isValidating,
        mutate: mutateKey
    } = useSWR((id_space) ? `/api-key` : null, spaceService.fetchWrapperGet);

    const [step, setStep] = useState(0);

    //HOOKS
    const { user } = useUser();
    const router = useRouter();
    const [importer, setImporter] = useState();

    const { id } = router.query;
    const { closeLead, isOpen, closeList, selectedList } = useLead();


    useEffect(() => {
        if (isOpen && !importer) {
            loadCSVBox();
        }
    }, [isOpen]);

    function callback(result, data) {
        if (result) {
            setStep(1);
        } else {
            setStep(1);
        }
    }

    function loadCSVBox(retry) {
        if (typeof CSVBoxImporter === 'function') {
            setImporter(new CSVBoxImporter('3kt5MG8eamTFKfGDqMI7JHxIDZLMjK', {}, callback));
        } else if(retry < 4) {
            setTimeout(() => {
                loadCSVBox(retry + 1);
            }, 2000);
        }
    }

    function openImportModal() {
        if (!importer || !apiKeyData) {
            return;
        }
        if (importer) {
            importer.setUser({
                user_id: user?.uid,
                id_space: id_space,
                options: `ignore_duplicates=${ignoreDuplicates ? 1 : 0}&import_custom_variables=${importCustom ? 1 : 0}`,
                ...(selectedList?.id && { id_list: selectedList?.id }),
                ...(router?.pathname?.startsWith('/campaigns/') && { id_campaign: id }),
                api_key: apiKeyData?.key
            });
            importer?.openModal();
        } else {
            loadCSVBox();
        }
    }


    if (!isOpen)
        return null;


    if (apiKeyData && step === 1) {
        return (
          <div className={style.customModal}>
              <div className={style.backDrop} />
              <div className={style.customModalContent}>
                  <div className={style.stepWrapper}>
                      <h4>File has been upload</h4>
                      <span>You will be notified when your file has been fully imported.</span>
                      <Spacer y={4} />
                      <Button rounded auto type='success' onClick={closeLead}>Back to my leads</Button>
                  </div>
              </div>
          </div>
        );
    }

    return (
      <div className={style.customModal}>
          <Script type='text/javascript' onLoad={loadCSVBox} src='https://js.csvbox.io/script.js'
                  strategy={'afterInteractive'} />
          <div className={style.backDrop} />
          <div className={style.customModalContent}>
              <div className={style.customModalHeader}>
                  <div className={style.customModalClose} onClick={closeLead}>
                      <X />
                  </div>
              </div>
              <div className={style.contentWrap}>
                  <div className={style.stepWrapper}>
                      <h3>Import options</h3>
                      {step === 0 && (
                        <>
                            <div className={style.optionRow}>
                                <div className={style.optionLabel}>
                                    <span>Do not import lead with the same email</span>
                                    <Tooltip content={"Lead that have the same email will be ignored"}>
                                        <Info size={18}/>
                                    </Tooltip>
                                </div>
                                <div className={style.optionSwitch}>
                                    <Switch color={'primary'} isSelected={ignoreDuplicates} onValueChange={setIgnoreDuplicates} />
                                </div>
                            </div>
                            <div className={style.optionRow}>
                                <div className={style.optionLabel}>
                                    <span>Import not mapped CSV columns as custom fields</span>
                                    <Tooltip content={"Import the CSV columns not mapped as a custom variable that can be used in Campaigns or Dm's"}>
                                        <Info size={18}/>
                                    </Tooltip>
                                </div>
                                <div className={style.optionSwitch}>
                                    <Switch color={'primary'} isSelected={importCustom} onValueChange={setImportCustom} />
                                </div>
                            </div>
                            <div className={`${style.actions}`}>
                                <Button isLoading={(!importer || !apiKeyData)} radius={'full'} color='primary' startContent={<FilePlus size={18} />} style={{width: '100%'}} data-csvbox='true' onClick={openImportModal}>
                                      Upload file
                                </Button>
                            </div>
                        </>
                      )}
                  </div>
              </div>
          </div>
      </div>
    );
}
