import React, { useEffect, useState } from 'react';
import Twitter from '@/components/icons/Twitter';
import { Mail, Send } from 'react-feather';
import InputSocial from '@/components/feed/inputSocial';
import { useLead } from '@/utils/useLead';
import Linkedin from '@/components/icons/Linkedin';
import { useTwitter } from '@/utils/useTwitter';
import useCustomToasts from '@/utils/useCustomToasts';
import { leadService } from '@/services/lead.service';
import { useSpace } from '@/utils/useSpace';
import dynamic from 'next/dynamic';
import { useLinkedin } from '@/utils/useLinkedin';
import useSWR from 'swr';
import LeadActivity from '@/components/activity';
import { TextLimitWrap } from '@/components/feed/textLimit';
import LeadCard from '@/components/feed/leadCard';
import { Button, Spacer, Tab, Tabs } from '@nextui-org/react';
import clsx from 'clsx';
import { useEmail } from '@/utils/useEmail';

const InputEmail = dynamic(() => import('@/components/feed/inputEmail'));

export default function Messages({ quickContact, id_lead, hideActivity, lead, activeTab }) {
  const [messageProvider, setMessageProvider] = useState('linkedin');
  const [messageValue, setMessageValue] = useState('');
  const [linkedinMessageValue, setLinkedinMessageValue] = useState('');
  const [twitterLoading, setTwitterLoading] = useState(false);
  const [linkedinLoading, setLinkedinLoading] = useState(false);

  const { selectedLead, openLead, setSelectedLead } = useLead();
  const { selectedAccount } = useTwitter();
  const { selectedAccount: selectedAccountLinkedin } = useLinkedin();
  const { setToast } = useCustomToasts();
  const { id_space, subscription } = useSpace();
  const { selectedAccount: selectedAccountMail, selectedEmail } = useEmail();
  const { setSelectedEmail } = useEmail();


  const {
    data: messagesData,
    mutate: mutateMessages
  } = useSWR(id_space && (id_lead || selectedLead) ? `/${id_lead ?? selectedLead?.id}/messages?id_space=${id_space}` : null, leadService.fetchWrapperGet, { revalidateOnFocus: true });


  useEffect(() => {
    if (selectedEmail) {
      setMessageProvider('email');
    }
  }, [selectedEmail]);

  function handleSendLinkedinDm() {
    if (linkedinLoading) return;
    setLinkedinLoading(true);

    return leadService.sendDmLinkedin(id_lead ?? selectedLead?.id, {
      message: linkedinMessageValue,
      id_linkedin_account: selectedAccountLinkedin?.id
    }).then(message => {
      setLinkedinMessageValue('');
      setToast({
        type: 'success',
        text: 'Message will be sent as soon as possible '
      });
      mutateMessages([message, ...messagesData], { rollbackOnError: true });
    }).catch(r => {
      setToast({
        type: 'error',
        text: r?.message ?? 'Error sending message'
      });
    }).finally(() => {
      setLinkedinLoading(false);
    });
  }

  function handleSendTwitterDm() {
    if (twitterLoading) return;

    setTwitterLoading(true);
    return leadService.sendDmTwitter(id_lead ?? selectedLead?.id, {
      message: messageValue,
      id_twitter_account: selectedAccount?.id
    }).then(r => {
      setToast({
        type: 'success',
        text: 'Message sent successfully'
      });
      setMessageValue('');
    }).catch(e => {
      setToast({
        text: e?.message ?? 'Error sending message',
        type: 'error'
      });
    }).finally(() => {
      setTwitterLoading(false);
    });
  }

  function handleClose() {
    setSelectedEmail(null);
    setSelectedLead(null);
  }

  return (
    <div className={'flex flex-col'}>
      {!id_lead && <div
        className={clsx('', !quickContact && 'border-stone-200 dark:border-stone-800 overflow-hidden border-1 rounded-lg', quickContact && 'mb-2 border-b-1 border-stone-200')}>
        <LeadCard hideCross={quickContact} onClose={handleClose} emptyText={activeTab === 'inbox' && {
          title: 'Select a email to reply',
          subtitle: 'To select a email, click on the avatar or one email action button'
        }} /></div>}
      {!quickContact && (
        <Spacer y={2} />
      )}
      <div className={clsx(quickContact && 'pt-2 px-2')}>
        <Tabs selectedKey={messageProvider} onSelectionChange={setMessageProvider} fullWidth classNames={{
          tabList: 'w-full relative border-1 border-stone-200 dark:border-stone-800 bg-white dark:bg-black',
          // cursor: "w-full text-white",
          // tab: "",
          tabContent: 'group-data-[selected=true]:text-white text-black dark:text-white ',
          // tabContent: "group-data-[selected=true]:text-white",
          cursor: clsx(
            {
              'linkedin': 'bg-[#0a66c2] dark:bg-[#0a66c2]',
              'twitter': 'bg-[#1da1f2] dark:bg-[#1da1f2]',
              'email': 'bg-primary dark:bg-primary'
            }[messageProvider]
          )
        }}>
          <Tab key={'linkedin'} title={<div className={'flex gap-2'}>
            <Linkedin size={18} />
            <span>LinkedIn</span>
          </div>}
          />
          <Tab key={'twitter'} title={<div className={'flex gap-2'}>
            <Twitter size={18} />
            <span>Twitter</span>
          </div>}
          />
          <Tab key={'email'} title={<div className={'flex gap-2'}>
            <Mail strokeWidth={1} size={18} />
            <span>Email</span>
          </div>}
          />
        </Tabs>
      </div>
      <Spacer y={2} />
      <div className={clsx(quickContact && 'p-2')}>
        <>
          {messageProvider === 'twitter' && (
            <>
              <InputSocial socialType={'twitter'} value={messageValue} onChange={setMessageValue} minRows={3}
                           placeholder={'Send message'} />
              <div className={'mt-2'}>
                <TextLimitWrap text={messageValue} socialType={'twitter_private_message'}>
                  <Button isDisabled={!messageValue || (!selectedLead && !id_lead) || messageValue?.length > 10000}
                          onClick={handleSendTwitterDm}
                          radius={'full'}
                          className={'min-w-[150px]'}
                          color={'primary'}
                          isLoading={twitterLoading}
                          startContent={<Send size={18} />}
                          size={'sm'}>
                    Send
                  </Button>
                </TextLimitWrap>
              </div>
            </>
          )}
          {messageProvider === 'linkedin' && (
            <>
              <InputSocial socialType={'linkedin'} value={linkedinMessageValue} onChange={setLinkedinMessageValue}
                           minRows={3}
                           placeholder={'Send message'} />
              <div className={'mt-2'}>
                <TextLimitWrap text={linkedinMessageValue} socialType={'linkedin'}>
                  <Button
                    isDisabled={!linkedinMessageValue || (!selectedLead && !id_lead) || linkedinMessageValue?.length > 1900}
                    onClick={handleSendLinkedinDm}
                    radius={'full'}
                    className={'min-w-[150px]'}
                    color={'primary'}
                    startContent={<Send size={18} />}
                    isLoading={linkedinLoading}
                    size={'sm'}>
                    Send
                  </Button>
                </TextLimitWrap>
              </div>
            </>
          )}
          {messageProvider === 'email' && (
            <InputEmail lead={lead} id_lead={id_lead} />
          )}
        </>
      </div>
      <Spacer y={4} />
      {(!hideActivity && (id_lead || selectedLead?.id)) &&
        <LeadActivity collapse={true} small={true} id_lead={id_lead ?? selectedLead?.id} messages={messagesData} />}
    </div>
  );
}
