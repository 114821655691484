import React, { createContext, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import { emailService } from '../services/mail.service';
import useCustomToasts from '@/utils/useCustomToasts';
import { useLead } from '@/utils/useLead';
import { leadService } from '@/services/lead.service';

export const EmailContext = createContext();

export const EmailContextProvider = (props) => {
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const [selectedEmail, setSelectedEmail] = React.useState(null);
  const { setToast } = useCustomToasts();
  const { setSelectedLead, selectedLead, lead } = useLead();
  const [action, setAction] = React.useState('new');
  const [cc, setCc] = React.useState([]);
  const [bcc, setBcc] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [to, setTo] = React.useState([]);
  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null,
  });
  const [filterSelectedAccounts, setFilterSelectedAccounts] = React.useState([]);

  const { id_space } = useSpace();

  //get the twitter accounts
  const {
    data: accounts,
    error,
    isValidating,
    mutate: mutateLeads
  } = useSWR(id_space ? `s/mail?id_space=${id_space}` : null, emailService.fetchWrapperGet);


  let value = {
    sendEmail,
    selectedAccount: selectedAccount ?? (accounts && accounts.length > 0 && accounts[0]),
    setSelectedAccount,
    selectedEmail,
    setSelectedEmail: handleEmailSelect,
    filterSelectedAccounts,
    setFilterSelectedAccounts,
    accounts,
    action,
    setAction,
    cc,
    addCc,
    removeCc,
    bcc,
    addBcc,
    loading,
    removeBcc,
    to,
    addTo,
    dateRange,
    setDateRange,
    removeTo
  };

  useEffect(() => {
    if (accounts?.length === 1) {
      setSelectedAccount(accounts[0]);
    }
  }, [accounts]);

  useEffect(() => {
    if ((selectedLead) && !selectedEmail) {
      const email = selectedLead?.email;
      setTo([email]);
    }
    if(!selectedLead){
      setTo([]);
    }
  }, [selectedLead]);




  function addCc(email) {
    //check if the email is already in the cc list
    if (cc.includes(email)) {
      return;
    }
    setCc([...cc, email]);
  }

  function addBcc(email) {
    //check if the email is already in the cc list
    if (bcc.includes(email)) {
      return;
    }
    setBcc([...bcc, email]);
  }

  function removeCc(email) {
    setCc(cc.filter(e => e !== email));
  }

  function removeBcc(email) {
    setBcc(bcc.filter(e => e !== email));
  }

  function addTo(email, reset) {
    if(reset){
      setTo([email]);
      return;
    }
    //check if the email is already in the cc list
    if (to.includes(email)) {
      return;
    }
    setTo([...to, email]);
  }

  function removeTo(email) {
    setTo(to.filter(e => e !== email));
  }

  function handleEmailSelect(email, type, parent) {
    if (!email) {
      setSelectedEmail(null);
      return;
    }
    //We search in the accounts the one that has the same email as the sender
    const account = accounts?.find(a => {
      //email.to is an array of objects with the email and the name of the account, we need to search in the array
      const emailFrom = a.email;
      return email.to.some(e => e.address === emailFrom);
    });
    if (type === 'reply_all') {
      const emailsTo = email.to.map(e => e.address);
      //remove the account email from the list
        const emailsToWithoutAccount = emailsTo.filter(e => e !== account.email);
      //we add the other recipients to the to list
      setTo([email.from.address, ...emailsToWithoutAccount]);
    }
    if (type === 'reply') {
      //we add the sender to the to list
      setTo([email.from.address]);
    }
    if (type === 'transfer') {
      //we add the sender to the to list
      setTo([]);
    }
    setAction(type);
    setSelectedAccount(account);
    setSelectedLead(parent ? parent?.from?.lead??email.from.lead : email.from.lead);
    setSelectedEmail(email);
  }

  async function sendEmail({ id_lead, subject, content }) {
    try {
      if (loading)
        return null;
      if (!subject) {
        setToast({
          type: 'error',
          text: 'Please enter a subject'
        });
        return null;
      }

      setLoading(true);
      const email = await leadService.sendEmail(id_lead, {
        from: selectedAccount?.id ?? (accounts && accounts.length > 0 && accounts[0]?.id),
        to: to,
        subject: subject,
        content: content,
        cc: cc,
        bcc: bcc,
        id_message: selectedEmail?.id
      });

      setToast({
        text: 'Email sent successfully',
        type: 'success'
      });
      setCc([]);
      setBcc([]);
      //We are not reseting the to field if a lead was selected
      if(!lead && !selectedLead){
        setTo([]);
      }
      setLoading(false);
      setSelectedEmail(null);

    } catch (e) {
      setLoading(false);
      setToast({
        type: 'error',
        text: e?.message ?? 'Error sending email'
      });
    }
  }


  return <EmailContext.Provider value={value} {...props} />;
};

export const useEmail = () => {
  const context = useContext(EmailContext);
  if (context === undefined) {
    throw new Error(`useEmail must be used within a EmailContextProvider.`);
  }
  return context;
};
