import { productService } from '@/services/product';
import useSWR from 'swr';
import Loading from '@/components/ui/loading';
import PlanCard from '@/components/price/plan-card';
import { useMemo, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import { Button, Spacer } from '@nextui-org/react';
import { useSpace } from '@/utils/useSpace';
import Inform from '@/components/ui/inform';
import clsx from 'clsx';
import { spaceService } from '@/services/space.service';

export default function PlansTable({align = 'center'}) {
  const [loading, setLoading] = useState(false);
  const { subscription, mainProduct, mainPrice } = useSpace();

  //API
  const {
    data: products,
    error,
    isValidating: detailsLoading,
    mutate
  } = useSWR(`/products`,
    productService.fetchWrapperGetEmpty
    , { revalidateOnFocus: true });

  const {
    data: members
  } = useSWR(`/space/members`, spaceService.fetchWrapperGetEmpty, { revalidateOnFocus: true });


  const [billingInterval, setBillingInterval] = useState('month');
  const { setToast } = useCustomToasts();
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  function buyProduct(id_price) {
    if (loading || !id_price) return;

    setLoading(true);
    productService.createCheckoutSession({
      id_price: id_price,
      quantity: members?.length,
      // slots: slots
    }).then(res => {
      //if it's an url redirect to it
      if (res.startsWith('http'))
        window.location.href = res;
      else {
        setToast({
          text: res ?? 'Subscription updated successfully',
          type: 'success'
        });
        // refetch();
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
      setToast({
        text: 'Something went wrong. Please try again later.',
        type: 'error'
      });
    });
  }

  // console.log("mainProduct", products)

  const isOldSubscription = useMemo(() => products && !products.find(p => p.stripe_price_id === mainPrice?.id), [products, mainPrice?.id]);



  return (
    <div className={'flex w-full justify-center items-center flex-col max-w-3xl'}>
      {products && subscription?.status === 'canceled' && (
        <>
          <Inform fullWidth={true} isWarning={true} showIcon={true} title={'Your subscription has been cancelled'}
                  subtitle={'You don\'t have an active subscription. Choose a plan to keep using Breakcold.'} />
          <Spacer y={8}/>
        </>
      )}
      {products && subscription?.status === 'incomplete' && (
        <>
          <Inform fullWidth={true} isWarning={true} showIcon={true} title={'Your purchase was not completed'}
                  subtitle={'You don\'t have an active subscription. Choose a plan to keep using Breakcold.'} />
          <Spacer y={8}/>
        </>
      )}
      {(isOldSubscription && subscription.status === 'active') && (
        <>
          <Inform fullWidth={true} isWarning={true} showIcon={true} title={'Your subscription is outdated'}
                  subtitle={'The plan you are using is no longer available. You need more slots? Choose one of our new plans with unlimited slots.'} />
          <Spacer y={8}/>
        </>
      )}
      {!products && <div><Loading /></div>}
      {products && (
        <div className={clsx('flex flex-col justify-center w-full',
          align === 'center' && 'items-center',
          align === 'left' && 'items-start',
          align === 'right' && 'items-end'
        )}>
          <div className={clsx('flex flex-col mb-4',
            align === 'center' && 'items-center text-center',
            align === 'left' && 'items-start text-left',
            align === 'right' && 'items-end text-right'
          )}>
            <div className={'text-3xl font-semibold'}>
              Choose your plan
            </div>
            <span className={'text-lg text-black/80 dark:text-white/80'}>
            Choose the plan that best suits your needs
          </span>
          </div>
          <div className={clsx('flex flex-row gap-2 w-full max-w-3xl justify-center', showAllFeatures ? 'items-stretch' : 'items-end')}>
            {products?.map((product, idx) => {
              return <PlanCard subscription={subscription}
                               showAllFeatures={showAllFeatures}
                               loading={loading}
                               buy={buyProduct}
                               previousProduct={idx > 0 && products[idx - 1]}
                               isFavorite={idx === 0}
                               key={product?.id}
                               idx={idx}
                               isOldSub={isOldSubscription}
                               products={products}
                               quantity={members?.length}
                               product={product}
                               billingInterval={billingInterval} />;
            })}
          </div>
          <div className={'max-w-3xl'}>

          </div>
          <Button variant={'bordered'} fullWidth onClick={() => setShowAllFeatures(!showAllFeatures)} className={'bg-white dark:bg-black border-1 mt-2'}>
            {showAllFeatures ? 'Hide features' : 'Show all features'}
          </Button>
        </div>
      )}
    </div>
  );
}
