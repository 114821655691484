import React from 'react';
import cn from 'classnames';
import style from './style.module.css';
import { Popover } from "@nextui-org/react";
import { Check, ChevronDown } from 'react-feather';
import {useMemo} from 'react';
import Avatar from '@/components/ui/avatar';
import clsx from 'clsx';
import {PopoverTrigger, PopoverContent} from "@nextui-org/react";

export default function Select({ border = true, helpText, multiple = true, size, showAvatar, label, unit = 'emails', disabled, items, onChange, value = [], emptyLabel = 'No items selected' }) {
    const [isOpen, setIsOpen] = React.useState(false);

    function handleChange(item){
        if(!multiple){
            //if is already selected, unselect it
            if(value && (typeof value === 'object' ? value.includes(item?.id) : value === item?.id)){
                onChange([]);
            } else {
                onChange([item?.id]);
            }
            setIsOpen(false);
            return;
        }
        if(value.includes(item?.id)){
            onChange(value.filter((i) => i !== item?.id));
        } else {
            onChange([...value, item?.id]);
        }
    }

    const renderButtonLabel = useMemo(() => {
        if (!value || value.length === 0) {
            return emptyLabel;
        }
        if (typeof value === 'object' ? value?.length === 1 : value) {
            return items.find((item) => item.id === (typeof value === 'object' ? value[0] : value))?.name;
        }
        return `${value.length} ${unit}`;
    }, [value, items]);

    function handleOpenChange(open){
        if(disabled) return;
        setIsOpen(open);
    }




    return (
      <Popover placement={'bottom'}  isOpen={isOpen} onOpenChange={handleOpenChange}>
          <PopoverTrigger>
              <div className={clsx(style.wrap, style[size], border && style.border)}>
                  {label && <label className={style.label}>{label}</label>}
                  <div className={`${style.button} ${disabled && style.disabled}`}>
                      {renderButtonLabel}
                      <div className={`${style.chevron} ${isOpen && style.open} pl-2`}>
                          <ChevronDown size={18} strokeWidth={2}/>
                      </div>
                  </div>
                  {helpText && <span className={style.helpText}>{helpText}</span> }
              </div>
          </PopoverTrigger>
          <PopoverContent className={'p-0'}>
              <div className={style.items}>
                  {(!items || items?.length === 0) && <div className={style.item}>No items</div>}
                    {items && items?.map((item, idx) => {
                        const isSelected = typeof value === 'object' ? value?.includes(item?.id) : value === item?.id;
                        return (
                          <div
                            key={`${item.id}_${idx}`}
                            className={cn(style.item, {
                                [style.active]: isSelected,
                                'opacity-30 cursor-not-allowed': item.disabled,
                            })}
                            onClick={() => {
                                !item.disabled && handleChange(item);
                            }}
                          >
                              {showAvatar && (
                              <div className={style.avatar}>
                                  <Avatar
                                    src={item?.avatar}
                                    size={24}
                                    text={item?.name}
                                  />
                              </div>
                                )}
                            <span className={style.value}>
                                {item.name}
                            </span>
                              <span className={style.check}>
                                {isSelected && <Check size={14}/>}
                            </span>
                          </div>
                        )
                    })}
              </div>
          </PopoverContent>
      </Popover>
    )
}
