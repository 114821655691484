
//CHeck if mail si valid
export function isValidEmail(email) {
    //remove all spaces
    email = email?.replace(/\s/g, '');
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

//check if its a twitter url is valid if true return the username
export function isValidTwitterUrl(url) {
    //remove query params
    url = url?.split('?')[0];
    var re = /^(https?:\/\/)?(www\.)?twitter\.com\/(#!\/)?[a-zA-Z0-9_]+$/;
    return re.test(url);
}

export function isValidTwitterUsername(username){

    return !!username.match(/^[A-Za-z0-9_]{1,15}$/);

}


export function parseLinkedinUsername(url){
    if(!url) return null;

    //if http:// replace with https://
    if(url?.startsWith('http://')){
        url = url.replace('http://', 'https://');
    }
    const regex = /(https?:\/\/(www.|de.|fr.|en.)?linkedin.com\/(mwlite\/|m\/)?in\/([a-zA-Z0-9À-ž%_.-]+)\/?)/;
    const match = regex.exec(url);
    if(match){
        //remove trailing slash
        return match[4].replace(/\/$/, '');
    }
    return url;
}

//extraxt the username from twitter url
export function extractTwitterUsername(url) {
    var re = /^(https?:\/\/)?(www\.)?twitter\.com\/(#!\/)?([a-zA-Z0-9_]+)/;
    return url.match(re)[4];
}

//if username start with @ return the username without
export function removeAt(username) {
    var re = /^@/;
    return username?.replace(re, '');
}

//check if its a url is valid if true return the username
// export function isValidUrl(url) {
//     var re = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_]+\.[a-zA-Z]{2,}$/;
//     return re.test(url);
// }

export function isValidUrl(str, linkedin) {
    let pattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    //Check if the url is a linkedin url
    if(pattern.test(str)){
        if(linkedin){
            if(!str.includes('linkedin.com'))
                return true
        }
        return false
    } else {
        return true;
    }
}

// export function
