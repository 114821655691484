import { useEffect } from 'react';
import '@/assets/main.css';
import { UserContextProvider } from '@/utils/useUser';
import { SpaceContextProvider } from '@/utils/useSpace';
import AppWrapper from '@/components/appWrapper';
import { LeadContextProvider } from '@/utils/useLead';
import Layer from '@/components/layer/layer';
import { TemplateContextProvider } from '@/utils/useTemplate';
import { CookiesProvider } from 'react-cookie';
import { NextUIProvider } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import dynamic from 'next/dynamic';
import { SWRConfig } from 'swr';
import { fetchWrapper } from '@/services/fetch-wrapper';
import getConfig from '@/utils/getConfig';
import { AnnouncementsContextProvider } from '@/utils/useAnnouncements';
import { Toaster } from '@/utils//useCustomToasts';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { LinkedinContextProvider } from '@/utils/useLinkedin';
import { TwitterContextProvider } from '@/utils/useTwitter';
import { EmailContextProvider } from '@/utils/useEmail';
import { MemberContextProvider } from '@/utils/useMember';

const POSTHOG_CONFIG = {
  api_host: 'https://yoda.breakcold.com',
  'opt_in_site_apps': true
};
// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init('phc_sHzLXW3UOOKlDYQVAAOqrLLImuuqYtlz5bImD5jqYiB', {
    ...POSTHOG_CONFIG,
    // Enable debug mode in development
    debug: false,
    capture_pageview: false // Disable automatic pageview capture, as we capture manually
  });
  posthog.debug(false);
}


const Maintenance = dynamic(() => import('@/components/maintenance'), { ssr: false });
const { apiUrl } = getConfig();

export default function MyApp({ Component, pageProps }) {

  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);


  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return (
      <AppWrapper>
        <Maintenance />
      </AppWrapper>
    );
  }
  return (
    <PostHogProvider client={posthog}>
      <SWRConfig
        value={{
          fetcher: (resource, init) => fetchWrapper.get(`${apiUrl}${resource}`, true)
        }}>
        <NextUIProvider>
          <NextThemesProvider attribute='class' defaultTheme='system'>
            <CookiesProvider>
              <Toaster />
              <AppWrapper>
                <div className='main-bg'>
                  <UserContextProvider>
                    <SpaceContextProvider>
                      <MemberContextProvider>
                        <TemplateContextProvider>
                        <AnnouncementsContextProvider>
                          <LeadContextProvider>
                            <LinkedinContextProvider>
                              <TwitterContextProvider>
                                <EmailContextProvider>
                                <Layer />
                                <Component {...pageProps} />
                                </EmailContextProvider>
                              </TwitterContextProvider>
                            </LinkedinContextProvider>
                          </LeadContextProvider>
                        </AnnouncementsContextProvider>
                      </TemplateContextProvider>
                      </MemberContextProvider>
                    </SpaceContextProvider>
                  </UserContextProvider>
                </div>
              </AppWrapper>
            </CookiesProvider>
          </NextThemesProvider>
        </NextUIProvider>
      </SWRConfig>
    </PostHogProvider>
  );
}
