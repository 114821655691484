import style from '@/styles/feed/feed.module.css';
import { Check, ChevronRight, X } from 'react-feather';
import { useLead } from '@/utils/useLead';
import Link from 'next/link';
import Loading from "@/components/ui/loading";
import { leadService } from '@/services/lead.service';
import { useState } from 'react';
import { useSpace } from '@/utils/useSpace';
import useCustomToasts from '@/utils/useCustomToasts';
import { Tooltip, Button } from "@nextui-org/react";

export default function EmptyFeed({refreshFeed}) {
    const { selectedList, uniqueLead, uniqueMutate } = useLead();
    const [loading, setLoading] = useState(false);
    const { setToast } = useCustomToasts();


    const startTrack = async () => {
        if (loading) return;
        setLoading(true);

        return leadService.trackLead(uniqueLead.id).then(r => {
            uniqueMutate({ ...uniqueLead, ...r, is_tracked: true }, { rollbackOnError: false, revalidate: true });
            refreshFeed && refreshFeed();
            setToast({
                text: 'Lead is now being tracked, it can take up to 5 minutes to see the first posts in the feed.',
                type: 'success',
            });
        }).catch(e => {
            console.log('ERROR', e?.message);
            setToast({
                text: e?.message ?? 'An error occurred',
                type: 'error'
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    const steps = [
        {
            title: 'Add prospects',
            description: `Add prospects in the ${selectedList?.name ?? 'Leads'} section`,
            link: `/leads${selectedList ? `?list=${selectedList?.id}` : ''}`
        },
        {
            title: 'Add their socials',
            description: 'Add their LinkedIn URL and/or Twitter URL',
            link: '/leads'
        },
        {
            title: 'Start the tracking',
            description: 'Start tracking their activity on LinkedIn and Twitter',
            link: '/leads'
        }
    ];

    if (uniqueLead) {
        steps.shift();
    }


    return (
        <div className={style.emptyFeedWrap}>
            <div className={style.title}>
                {uniqueLead && 'Build relationships faster with this lead 👇'}
                {!uniqueLead && <>Hey there! 👋 <br />Welcome to your Prospecting Feed.</>}
            </div>
            <div className={style.subtitle}>
                When you track people, you'll see their activity here.
            </div>
            <div className={style.checks}>
                {steps.map((step, idx) => (
                  (<Link
                      key={`empty_step_${idx}`}
                      href={uniqueLead ? "#" : step.link}
                      className={style.startCard}>

                      <div className={style.icon}>
                          {idx + 1}
                      </div>
                      <div className={style.content}>
                          <div className={style.name}>
                              {step.title}
                          </div>
                          <div className={style.description}>
                              {step.description}
                          </div>
                      </div>
                      {!uniqueLead && (
                        <div className={style.linkIcon}>
                            <ChevronRight size={14} />
                        </div>
                      )}

                  </Link>)
                ))}
            </div>
            {uniqueLead && (
              <div className={style.trackWrap}>
                  <Button isLoading={loading} isDisabled={!((uniqueLead?.twitter_name || uniqueLead?.twitter_company_name) || uniqueLead?.linkedin_url)}
                          color={'primary'} fullWidth radius={'full'} onClick={startTrack}>
                     Start the track
                  </Button>
                  <div className={'flex flex-row gap-2 mt-4'}>
                          <Tooltip css={{ zIndex: 99999 }}
                                   content={'Your lead must have a Twitter account or Linkedin account'}
                                   placement='bottom'>
                              <Button variant={'light'} startContent={!uniqueLead?.twitter_name && !uniqueLead?.twitter_company_name ? <X size={16} /> :
                                <Check size={16} />} size={'sm'}>
                                  Twitter
                              </Button>
                          </Tooltip>
                              <Tooltip css={{ zIndex: 99999 }}
                                       content={'Your lead must have a Linkedin account or Twitter account'}
                                       placement='bottom'>
                                  <Button variant={'light'} startContent={!uniqueLead?.linkedin_url ? <X size={16} /> :
                                    <Check size={16} />} size={'sm'}>
                                      LinkedIn
                                  </Button>
                              </Tooltip>
                  </div>
              </div>
            )}
        </div>
    );
}
