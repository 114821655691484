import {Chip} from '@nextui-org/react';
import {Plus} from 'react-feather';

export default function SubscriptionBadge({ isLTD, space }) {
  if (space?.subscriptions) {
    if (space.subscriptions?.status === 'canceled') {
      return (
        <Chip color={'warning'} size={'sm'} classNames={{
          content: ' text-[10px] text-white line-clamp-1 break-all',
          base: 'h-5'
        }}>
          Canceled
        </Chip>
      );
    }
    if (space?.subscriptions?.prices?.find(p => p?.product?.metadata?.subscription_type === 'main')) {
      return (
        <Chip color={isLTD ? 'secondary' : 'primary'} size={'sm'}
              variant={space?.subscriptions.status === 'trialing' ? 'faded' : 'solid'}
              classNames={{
          content: ' text-[10px] line-clamp-1 break-all',
          base: 'h-5'
        }}>
          {space?.subscriptions.status === 'trialing' && 'Trial'} {space?.subscriptions?.prices?.find(p => p?.product?.metadata?.subscription_type === 'main')?.product?.name}
        </Chip>
      );
    }
    if (space.subscriptions?.status === 'active' && !space?.subscriptions?.id_price) {
      return (
        <Chip color={'primary'} size={'sm'} classNames={{
          content: 'text-[10px] text-white line-clamp-1 break-all',
          base: 'h-5'
        }}>
          Breakcold+
        </Chip>
      );
    } else if (isLTD && space?.subscriptions?.status === 'active') {
      return (
        <Chip color={'secondary'} size={'sm'} classNames={{
          content: 'text-[10px] text-white line-clamp-1 break-all',
          base: 'h-5'
        }}>
          Lifetime Deal
        </Chip>
      );
    } else if (space.subscriptions?.status === 'active') {
      return (
        <Chip color={'primary'} size={'sm'} classNames={{
          content: 'text-[10px] text-white line-clamp-1 break-all',
          base: 'h-5'
        }}>
          Trial {space?.subscriptions?.price?.product?.name}
        </Chip>
      );
    } else if (space.subscriptions?.status === 'past_due')
      return (
        <Chip color={'danger'} size={'sm'} classNames={{
          content: 'text-[10px] text-white line-clamp-1 break-all',
          base: 'h-5'
        }}>
          Due bill
        </Chip>
      );
    else
      return (
        <Chip variant={'flat'} color={'primary'} size={'sm'} classNames={{
          content: 'text-[10px] line-clamp-1 break-all',
          base: 'h-5'
        }}>
          Free plan
        </Chip>
      );
  }
  return null;
}
