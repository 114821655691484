import style from '@/styles/feed/feed.module.css';
import TwitterPostCard from '@/components/social/postCard/twitter';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import { leadService } from '@/services/lead.service';
import { Fragment, useEffect, useState } from 'react';
import Loading from '@/components/ui/loading';
import useTheme from '@/utils/useTheme';
import { useLead } from '@/utils/useLead';
import Image from 'next/legacy/image';
import { ChevronDown, Linkedin, Twitter } from 'react-feather';
import dynamic from 'next/dynamic';
import LinkedinPostCard from '@/components/social/postCard/linkedin';
import StartTrackCard from '@/components/feed/startTrackCard';
import LinkedinPostCardV2 from '@/components/social/linkedin';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Button, Tooltip } from '@nextui-org/react';
import clsx from 'clsx';
import { useTwitter } from '@/utils/useTwitter';
import { useLinkedin } from '@/utils/useLinkedin';
import LinkedinError from '@/components/account/linkedinError';

dayjs.extend(relativeTime);

const FeedFilters = dynamic(() => import('@/components/feed/filters'));
const FeedAccounts = dynamic(() => import('@/components/feed/accounts'));
const Messages = dynamic(() => import('@/components/feed/messages'));
const EmptyFeed = dynamic(() => import('@/components/feed/empty'));

function FeedWrap({
                    leadData,
                    leadId,
  orderBy: propsOrderBy,
  socials: propsSocials,
  hideHeader,
                    hideLeadFeed,
                    hideMessage,
                    rightSide,
                    mutate_lead,
                  }) {
  const { selectedLead, selectedList, selectedTag } = useLead();
  const [activeTab, setActiveTab] = useState('feed');
  const [localOrderBy, setOrderBy] = useState('desc');
  const [localSocials, setSocials] = useState(['twitter', 'linkedin']);
  const { selectedAccount: twitterSelectedAccount } = useTwitter();
  const { selectedAccount: linkedinSelectedAccount } = useLinkedin();

  const orderBy = propsOrderBy ?? localOrderBy;
  const socials = propsSocials ?? localSocials;


  return (
    <Fragment>
      <div className={clsx(style.wrap)}>
        <div className={`${style.feedWrap} ${style[activeTab]} ${leadId && style.two}`}>
          {(activeTab === 'feed' && !hideLeadFeed) && (<Feed hideHeader={hideHeader} socials={socials} setSocials={setSocials} orderBy={orderBy} setOrderBy={setOrderBy} mutate_lead={mutate_lead} lead_data={leadData} id_status={selectedTag?.id} id_list={selectedList?.id} id_lead={leadId} />)}
          {(!hideMessage || rightSide) && (
            <div className={style.side}>
              <FeedAccounts hideAccounts={true}/>
              <div id={'feed_side_messages'} className={clsx(style.sticky, leadId && style.lead)}>
                {rightSide ? rightSide :
                  <Messages activeTab={activeTab} hideActivity={activeTab === 'tasks'} lead={leadData}
                            id_lead={leadId} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export function Feed({ orderBy = 'desc', hideHeader, setOrderBy, socials = ['twitter', 'linkedin'], setSocials, lead_data, id_list, id_status, small, id_lead, company, mutate_lead }) {
  const [cnt, setCnt] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  // const [orderBy, setOrderBy] = useState('desc');
  const [isPagesEnd, setIsPagesEnd] = useState(false);
  // const [socials, setSocials] = useState(['twitter', 'linkedin']);
  const [forceRefresh, setForceRefresh] = useState(false);


  const pages = [];

  for (let i = 0; i < cnt; i++) {
    pages.push(<FeedPage hideHeader={hideHeader} forceRefresh={forceRefresh} setForceRefresh={setForceRefresh} socials={socials}
                         setSocials={setSocials} setIsPagesEnd={setIsPagesEnd} orderBy={orderBy}
                         setOrderBy={setOrderBy} lead={lead_data} id_list={id_list} id_status={id_status}
                         small={small} company={company}
                         nextPage={nextPage}
                         setLoadingMore={setLoadingMore} id_lead={id_lead} index={i}
                         key={i} />);
  }


  function nextPage() {
    setLoadingMore(true);
    setCnt(cnt + 1);
  }

  return (
    <div>
      {lead_data &&
        <StartTrackCard refreshFeed={() => setForceRefresh(true)} mutateLead={mutate_lead} lead={lead_data} />}
      {pages}
      {!isPagesEnd && (
        <div className={style.loadMoreWrap}>
          <Button endContent={<ChevronDown size={18} />} variant={'light'} isLoading={loadingMore} radius={'full'}
                  onClick={nextPage}>
            Show More posts
          </Button>
        </div>
      )}
    </div>
  );
}

function FeedPage({
                    setForceRefresh,
                    forceRefresh,
                    setIsPagesEnd,
                    id_list,
                    id_status,
                    socials,
                    setSocials,
                    small,
                    company,
                    hideHeader,
                    index,
                    id_lead,
                    lead,
                    setOrderBy,
                    setLoadingMore,
                    orderBy
                  }) {
  const { id_space } = useSpace();

  //API
  const {
    data,
    error,
    mutate
  } = useSWR((id_space) ? `s/feed?page=${index}${id_lead ? `&id_lead=${id_lead}` : ''}${socials ? `&socials=${socials}` : ''}${orderBy ? `&order_by=${orderBy}` : ''}${id_list ? `&id_list=${id_list}` : ''}${id_status ? `&id_status=${id_status}` : ''}${company ? `&company=true` : ''}` : null,
    leadService.fetchWrapperGet
    , { revalidateOnFocus: false });


  const { setSelectedLead, selectedLead } = useLead();
  const { isDark } = useTheme();


  useEffect(() => {
    if (forceRefresh) {
      //set data to null
      mutate(null, { revalidate: true });
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (!data && index === 0) {
      setIsPagesEnd(true);
    } else if (!data) {
      setIsPagesEnd(false);
    }

    if (data && data?.length === 0) {
      setIsPagesEnd(true);
      setLoadingMore(false);
    } else if (data?.length > 0) {
      setIsPagesEnd(false);
      setLoadingMore(false);
    }
  }, [data]);


  if (error && index === 0) {
    return (
      <div className={style.emptyFeed}>
        <div className={isDark && style.isDark}>
          <Image width={400} height={250} src={'/static/broken-connection.svg'} />
        </div>
        <div className={style.emptyFeedText}>
          Oops! Something went wrong.
        </div>
        <div className={style.emptyFeedTextSmall}>
          Please try again later.
        </div>
      </div>
    );
  }


  if (selectedLead && !selectedLead?.twitter_company_name && company)
    return (
      <div className={style.emptyFeed}>
        <div className={style.emptyFeedText}>
          Add company twitter
        </div>
        <div className={style.emptyFeedTextSmall}>
          Add company twitter to see company tweets
        </div>
      </div>
    );


  if (!data && index === 0 && lead && !lead?.is_tracked) {
    return null;
  }

  if (!data && index === 0) {
    return (
      <>
        {!hideHeader && <FeedPageHeader company={company} setSocials={setSocials} socials={socials} lead={lead} orderBy={orderBy}
                         setOrderBy={setOrderBy} />}
        <div className={style.emptyFeed}>
          <Loading type={'spinner'} color={'primary'} />
        </div>
      </>
    );
  }

  if (!data || (lead && !lead?.is_tracked && data?.length === 0)) {
    return null;
  }

  if (!lead && data?.length === 0 && !company && socials?.length === 2) {
    return index === 0 ? <EmptyFeed /> : null;
  }

  if (data?.length === 0 && index === 0) {
    return (
      <Fragment>
        {!hideHeader && <FeedPageHeader company={company} setSocials={setSocials} socials={socials} lead={lead} orderBy={orderBy}
                         setOrderBy={setOrderBy} />}
        {lead?.is_tracked && lead?.linkedin && !lead?.linkedin?.last_sync_date && !company && (
          <LastSync type={'linkedin'} />
        )}
        {lead?.is_tracked && lead?.twitter && !lead?.twitter?.last_sync_date && !company && (
          <LastSync type={'twitter'} />
        )}
        {lead?.is_tracked && lead?.twitter_company && !lead?.twitter_company?.last_sync_date && company && (
          <LastSync type={'twitter'} />
        )}
        <div className={style.emptyFeed}>
          <div className={`${style.emptyFeedImage} ${isDark && style.isDark}`}>
            <Image layout={'responsive'} height={250} width={300} src={'/static/search.svg'} />
          </div>
          <div className={style.emptyFeedText}>
            No posts
          </div>
          <div className={style.emptyFeedTextSmall}>
            {id_lead ? (
              'Your lead doesn\'t have any posts'
            ) : (
              'Your search didn\'t match any posts'
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  if (error)
    return null;

  return (
    <Fragment>
      {(index === 0 && !hideHeader) && (
        <FeedPageHeader company={company} setSocials={setSocials} socials={socials} lead={lead} orderBy={orderBy}
                        setOrderBy={setOrderBy} />
      )}
      {index === 0 && lead?.is_tracked && lead?.linkedin && !lead?.linkedin?.last_sync_date && !company && (
        <LastSync type={'linkedin'} />
      )}
      {index === 0 && lead?.is_tracked && lead?.twitter && !lead?.twitter?.last_sync_date && !company && (
        <LastSync type={'twitter'} />
      )}
      {index === 0 && lead?.is_tracked && lead?.twitter_company && !lead?.twitter_company?.last_sync_date && company && (
        <LastSync type={'twitter'} />
      )}
      {data?.map((x, i) => {
        return <RenderPost isCompany={company} children={x.children} key={x?.id} isLead={id_lead} small={small}
                           onSelectLead={setSelectedLead} data={x} noMaxWidth />;
      })}
    </Fragment>
  );
}


function FeedPageHeader({ company, orderBy, setOrderBy, socials, setSocials }) {

  function handleSocialsChange(value) {
    if (socials?.includes(value)) {
      setSocials(socials.filter(x => x !== value));
    } else {
      setSocials([...socials, value]);
    }
  }

  return (
    <div className={style.feedHeader}>
      <div className={style.feedHeaderSide}>
        {!company && (
          <div className={style.feedHeaderFilterSocials}>
            <div onClick={() => handleSocialsChange('linkedin')}
                 className={`${style.feedHeaderFilterSocial} ${socials?.includes('linkedin') && style.selected}`}>
              <div className={style.feedHeaderFilterSocialIcon}>
                <Linkedin strokeWidth={1.5} size={18} />
              </div>
              <div className={style.feedHeaderFilterSocialText}>
                LinkedIn
              </div>
              <div className={style.line} />
            </div>
            <div onClick={() => handleSocialsChange('twitter')}
                 className={`${style.feedHeaderFilterSocial} ${socials?.includes('twitter') && style.selected}`}>
              <div className={style.feedHeaderFilterSocialIcon}>
                <Twitter strokeWidth={1.5} size={18} />
              </div>
              <div className={style.feedHeaderFilterSocialText}>
                Twitter
              </div>
              <div className={style.line} />
            </div>
          </div>
        )}
      </div>
      <div className={style.feedHeaderSide}>
        <div onClick={() => setOrderBy(orderBy === 'asc' ? 'desc' : 'asc')}
             className={`${style.feedHeaderOrderBy} ${orderBy === 'asc' && style.asc}`}>
          <div className={style.feedHeaderOrderByText}>
            {orderBy === 'desc' ? 'Most recent' : 'Oldest first'}
          </div>
          <div className={style.feedHeaderOrderByIcon}>
            <svg width={20} height={20} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'
                 id='sort-ascending'>
              <rect width='256' height='256' fill='none'></rect>
              <polyline fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round'
                        strokeWidth='12' points='144 167.994 184 207.994 223.999 168'></polyline>
              <line x1='184' x2='184' y1='111.993' y2='207.993' fill='none' stroke='#000'
                    strokeLinecap='round' strokeLinejoin='round' strokeWidth='12'></line>
              <line x1='48' x2='119.999' y1='127.994' y2='127.994' fill='none' stroke='#000'
                    strokeLinecap='round' strokeLinejoin='round' strokeWidth='12'></line>
              <line x1='48' x2='183.999' y1='63.994' y2='63.994' fill='none' stroke='#000'
                    strokeLinecap='round' strokeLinejoin='round' strokeWidth='12'></line>
              <line x1='48' x2='104' y1='191.994' y2='191.994' fill='none' stroke='#000'
                    strokeLinecap='round' strokeLinejoin='round' strokeWidth='12'></line>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}


export function RenderPost(props) {
  const [showXPost, setShowXPost] = useState(1);
  const { uniqueLead } = useLead();
  const lead = props?.data?.lead ?? uniqueLead;

  function ShowMore() {
    setShowXPost(showXPost + 2);
  }

  if (props?.data?.post?.social_type === 'linkedinPost' && props?.data?.post?.post_data)
    return <LinkedinPostCardV2 {...props} key={props?.data?.id} metadata={props?.data?.post?.post_data} />;

  if (props?.data?.post?.social_type === 'linkedinPost')
    return <LinkedinPostCard {...props} />;


  return (
    <Fragment>
      <TwitterPostCard {...props} />
      {props?.children?.map((data, idx) => {
        if (idx >= showXPost)
          return null;

        if (data?.post?.social_type === 'linkedinPost' && data?.post?.post_data)
          return <LinkedinPostCardV2 {...props} key={data?.id} data={data} metadata={data?.post?.post_data} />;

        if (data?.post?.social_type === 'linkedinPost')
          return <LinkedinPostCard {...props} key={data?.id} data={data} />;

        return <TwitterPostCard {...props} key={data?.id} data={data} />;
      })}
      {props?.children?.length >= showXPost && (
        <div className={style.showMore} onClick={ShowMore}>
          <div className={style.label}>
            See more of <b>{lead?.first_name} {lead?.last_name}</b> posts
          </div>
        </div>
      )}
    </Fragment>
  );
}


function LastSync({ type }) {

  return (
    <div className={`${style.lastSyncWrapper} ${style[type ?? 'linkedin']}`}>
      <Tooltip css={{ zIndex: 999999 }} placement={'bottom'} color={'invert'}
               content={type === 'linkedin' ? 'Linkedin synchronisation can take few hours' : 'Come back later to see posts'}>
        <div className={style.lastSync}>
          <div className={style.lastSyncLogo}>
            {type === 'twitter' && (
              <Twitter strokeWidth={1} size={20} />
            )}
            {type === 'linkedin' && (
              <Linkedin strokeWidth={1} size={26} />
            )}
          </div>
          {type === 'twitter' && (
            <div className={style.lastSyncText}>
              Synchronisation is still in progress...
            </div>
          )}
          {type === 'linkedin' && (
            <div className={style.lastSyncTextWrap}>
              <div className={style.lastSyncText}>
                Synchronisation is still in progress...
              </div>
              <div className={style.lastSyncTextSmall}>
                Linkedin synchronisation can take few hours
              </div>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
}


export default FeedWrap;
