import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/account`;

export const emailService = {
    newEmailAccount,
    getAllEmailAccounts,
    refreshAlias,
    autoConnectAccount,
    deleteEmailAccount,
    microsoftAuthCallback,
    getEmailAttachment,
    manualConnectAccount,
    updateAlias,
    getMicrosoftAuthUrl,
    discover,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
}

function updateAlias({ id_alias, id, display_name, is_default, is_primary, daily_limit, signature, signature_is_html }){

    return fetchWrapper.patch(`${baseUrl}/mail/${id}/alias/${id_alias}/`, {
        display_name,
        is_default,
        signature,
        signature_is_html,
        is_primary,
        daily_limit
    });
}

function autoConnectAccount(id_space, email, password, display_name){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.post(`${baseUrl}/mail/auto?${payload}`, {email, password, display_name});
}

function getMicrosoftAuthUrl(id_account){
    let payload = id_account ?  `id_account=${id_account}` : '';

    return fetchWrapper.get(`${baseUrl}/mail/auth/microsoft${payload}`);
}

function microsoftAuthCallback(code){
    return fetchWrapper.post(`${baseUrl}/mail/auth/microsoft?id_account=${code}`);
}

function discover(id_space, email){
    const payload = `id_space=${id_space}&email=${email}`;

    return fetchWrapper.get(`${baseUrl}/mail/discover?${payload}`);
}

function manualConnectAccount(id_space, data){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.post(`${baseUrl}/mail/manual?${payload}`, {...data});
}

function newEmailAccount() {
    return fetchWrapper.get(`${baseUrl}/account/new`);
}

function getAllEmailAccounts(id_space) {
    const payload = `id_space=${id_space}`;

    return fetchWrapper.get(`${baseUrl}/accounts?${payload}`);
}


function refreshAlias(accountId){
    const payload = `accountId=${accountId}`;
    return fetchWrapper.get(`${baseUrl}/account/refreshAlias?${payload}`);
}

function deleteEmailAccount(accountId){

    return fetchWrapper.delete(`${baseUrl}/mail/${accountId}`);
}


function getEmailAttachment(id_account, id_attachment){

    return fetchWrapper.get(`${apiUrl}/inbox/${id_account}/${id_attachment}`);
}
