import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';
import useSWR from 'swr';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/status`;

export const statusService = {
    createStatus,
    updateStatus,
    updateManyStatus,
    deleteStatus,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
    fetchWrapperGetEmpty: url => fetchWrapper.get(`${apiUrl}/${url}`),
    fetchWrapperDelete: url => fetchWrapper.delete(`${baseUrl}${url}`),
}


function createStatus({id_space, data}){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.post(`${baseUrl}?${payload}`, {
        ...data,
    });
}

function deleteStatus({id_space, id_status}){
    const payload = `id_space=${id_space}`;

    return fetchWrapper.delete(`${baseUrl}/${id_status}?${payload}`);
}

function updateStatus({id, data}){

    return fetchWrapper.patch(`${baseUrl}/${id}`, {
        ...data,
    });
}

function updateManyStatus({data}){
    return fetchWrapper.patch(`${baseUrl}/update-many`, {
        status: data,
    });
}
