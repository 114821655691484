import { Fragment } from 'react';
import LeadLayer from '@/components/layer/leadLayer';
import ListLayer from '@/components/layer/ListLayer';
import { useTemplate } from '@/utils/useTemplate';
import dynamic from 'next/dynamic';
import { useAnnouncements } from '@/utils/useAnnouncements';
import { useSpace } from '@/utils/useSpace';
import FreeTrialEndModal from '@/components/subscription/free-trial-end-modal';
import QuickContact from '@/components/layer/quick-contact';
import { useUser } from '@/utils/useUser';
const TemplateLayer = dynamic(() => import('@/components/layer/TemplateLayer'), { ssr: false });
const AnnouncementsLayer = dynamic(() => import('@/components/layer/announcements'), { ssr: false });
const OnboardingLayer = dynamic(() => import('@/components/layer/onboarding'), { ssr: false });
const PayWallLayer = dynamic(() => import('@/components/layer/paywall'), { ssr: false });


export default function Layer(){
    const {
        templateExplorerIsOpen,
    } = useTemplate();
    const {showPremiumFeatureModal, trialIsOver, subscriptionIsOver, subscription} = useSpace();
    const {isOpen: announcementsIsOpen} = useAnnouncements();
  const { userDetails } = useUser();


  return(
    <Fragment>
      <QuickContact/>
      <LeadLayer/>
      <ListLayer/>
      {(userDetails && (!userDetails?.handle || !userDetails?.full_name)) && (
        <OnboardingLayer/>
      )}
      {(trialIsOver || subscriptionIsOver || subscription?.status === 'past_due') && (
            <FreeTrialEndModal isFreeTrialEnd={trialIsOver} isCanceled={subscriptionIsOver} isPastDue={subscription?.status === 'past_due'} />
        )}

        {templateExplorerIsOpen && (
            <TemplateLayer />
        )}
      {showPremiumFeatureModal && (
            <PayWallLayer />
        )}
      {announcementsIsOpen && (
            <AnnouncementsLayer />
        )}
    </Fragment>
  )
}
