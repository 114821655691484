import style from './tagsCell.module.css';
import Loading from "@/components/ui/loading";
import useTheme from '@/utils/useTheme';
import { Check, Edit, MoreVertical, Plus, Trash } from 'react-feather';
import React, { useEffect, useState, useMemo } from 'react';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import { tagService } from '@/services/tag.service';
import useCustomToasts from '@/utils/useCustomToasts';
import moment from 'moment';
import { useLead } from '@/utils/useLead';
import { TwitterPicker } from 'react-color';
import { getContrastYIQ } from '@/utils/helpers';
import ContentLoader from 'react-content-loader';
import {
    Dropdown,
    DropdownItem,
    Button,
    DropdownMenu,
    DropdownTrigger,
    Popover,
    PopoverContent,
    PopoverTrigger
} from '@nextui-org/react';

export default function TagsCell({ showContentLoader, selectMode, fullHeight, id_lead, lead_tags: props_lead_tags = [], id_list, status: propsStatus }) {
    const [addName, setAddName] = useState('');
    const [status, setStatus] = useState(null);
    const { id_space } = useSpace();
    const { setToast } = useCustomToasts();
    const [loading, setLoading] = useState(false);
    const { updateLeads, updateManyLeads, updateLead, leads, selectedTag, selectedLeads, allSelectedLeads } = useLead();

    //sort props_lead_tags by name asc
    const lead_tags = useMemo(() => props_lead_tags?.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
    }), [props_lead_tags]);

    //API
    const {
        data: tags,
        mutate: mutateTags
    } = useSWR(id_space ? `tags?id_space=${id_space}` : null, tagService.fetchWrapperGetEmpty);



    useEffect(() => {
        if (selectMode)
            setStatus(selectedTag);
        else
            setStatus(propsStatus);
    }, [propsStatus, selectedTag]);

    function orderByDate(tags) {
        //sort tags by createAt asc
        return tags.sort((a, b) => {
            return new Date(b?.created_at) - new Date(a?.created_at);
        });
    }


    let color = '#000000';


    function handleAddStatus(e) {
        if (e)
            e.preventDefault();
        if(!addName || addName?.length <= 0)
            return;
        setLoading(true);
        let data = { id: 'new', name: addName, color: '#000000', created_at: moment().toISOString() };
        // let newTags = [...tags, data];
        const options = { rollbackOnError: true, revalidate: false };
        return mutateTags(addStatus(data, tags), options);
    }

    function handleUpdateStatus(data) {
        let newTags = [...tags];
        //search status in new tags to update
        let toUpdateIndex = newTags.findIndex(r => r?.id === data?.id);
        if (toUpdateIndex > -1) {
            newTags[toUpdateIndex] = data;
        }

        let newLeads = leads?.leads?.map(l => {
            if (l?.tags[0]?.id === data?.id) {
                return { ...l, tags: [{ ...l?.tags[0], ...data }] };
            }
            return l;
        });
        updateLeads(newLeads);

        const options = { optimisticData: newTags, rollbackOnError: true };
        return mutateTags(updateStatus(data, tags), options);
    }


    function handleDeleteStatus(data) {
        let newTags = [...tags];
        //search status in new tags to update
        newTags = newTags.filter(r => r?.id !== data?.id);

        const options = { optimisticData: newTags, rollbackOnError: true };

        let newLeads = leads?.leads?.map(l => {
            if (l?.tags[0]?.id === data?.id) {
                return { ...l, tags: [] };
            }
            return l;
        });
        updateLeads(newLeads);
        return mutateTags(deleteStatus(data, tags), options);
    }


    function deleteStatus({ id }, tags) {
        return tagService.deleteTag({
            id_space,
            id_tag: id
        }).then(r => {
            return tags.filter(t => t.id !== id);
        }).catch(e => {
            console.log(e);
            setToast({
                type: 'error',
                text: e?.message ?? 'Error deleting tag'
            });
        });
    }

    function addStatus({ name, id }, tags) {
        setAddName('');
        return tagService.createTag({
            id_space: id_space,
            data: {
                id_list: id_list,
                name: name
            }
        }).then(r => {
            const filteredTags = tags.filter(tag => tag?.id !== id);
            return orderByDate([...filteredTags, r]);
        }).catch(err => {
            console.log(err);
            setToast({
                type: 'error',
                text: 'An error occurred while creating the tag'
            });
        }).finally(() => setLoading(false));
    }


    function updateStatus({ id, name, color }, tags) {

        return tagService.updateTag({ id_tag: id, data: { name, color } }).then(r => {
            const filteredTags = tags.filter(tag => tag?.id !== id);

            return orderByDate([...filteredTags, r]);
        }).catch(err => {
            console.log(err);
            setToast({
                type: 'error',
                text: 'An error occurred while updating the tag'
            });
        });
    }


    function handleUpdateLead(tag) {
        //check if lead has already this tag
        let hasTag = lead_tags?.find(t => t?.id === tag?.id);
        let tagsToUpdate = lead_tags;
        if (hasTag){
            tagsToUpdate = tagsToUpdate.filter(t => t?.id !== tag?.id);
        } else {
            tagsToUpdate = [...tagsToUpdate, tag];
        }

        if((selectedLeads?.length > 0 && selectedLeads?.find(l => l === id_lead)) || allSelectedLeads){
            return updateManyLeads({tags: [tag]}, {tagsDisconnect: !!hasTag, tagsConnect: !hasTag});
        }

        return updateLead({id: id_lead, tags: tagsToUpdate})
    }

    function tagIsInLead(tag) {
        return lead_tags?.find(t => t?.id === tag?.id);
    }

    function handleSelect(status) {
        setStatus(status);
        // setSelectedTag(status);
    }

    function handleUnselect() {
        // setSelectedTag(null);
        setStatus(null);
    }


    if(showContentLoader)
        return <TagsCellLoader/>

    return (
      <Popover showArrow>
          <PopoverTrigger>
              {lead_tags?.length > 0 ? (
                <div className={style.tagsWrap}>
                    {lead_tags?.map(tag => {
                        return (
                          <div key={`tag_${tag?.id}`} style={{ background: tag?.color ?? color }}
                               className={`${style.statusTag} ${fullHeight && style.fullHeight} `}>
                              <div style={{ color: getContrastYIQ(tag?.color ?? color) }} className={style.statusText}>
                                  <span>{tag?.name}</span>
                              </div>
                          </div>
                        )
                    })}
                    <div className={style.addTag}>
                        <Plus size={14}/>
                    </div>
                </div>
              ) : (
                <div className={`${style.statusTag} ${style.emptyStatus} ${fullHeight && style.fullHeight}`}>
                    <div className={style.statusIcon}>
                        <Plus size={14} />
                    </div>
                    <div className={style.statusText}>
                        <span>Add tags</span>
                    </div>
                </div>
              )}
          </PopoverTrigger>
          <PopoverContent className={'p-0'}>
              <div className={style.statusLists}>
                  <div className={style.statusListHeader}>
                      {selectMode ? 'Select' : 'Change'} lead tags
                  </div>
                  {loading ? (
                    <div className={style.loaderWrap}>
                        <Loading type={'points'} size={'sm'} />
                    </div>
                  ) : (
                    <div>
                        {tags?.length === 0 && (
                          <div className={`${style.statusList} ${style.empty}`}>
                              No tags found
                          </div>
                        )}
                        {tags && tags?.map(tag => <StatusList isUsed={tagIsInLead(tag)} handleSelect={handleSelect}
                                                              selectMode={selectMode}
                                                              selectedStatus={status} key={tag?.id}
                                                              handleUpdateLead={handleUpdateLead}
                                                              handleDelete={handleDeleteStatus}
                                                              handleUpdate={handleUpdateStatus} status={tag} />)}
                        {selectMode && status && (
                          <div onClick={handleUnselect} className={`${style.statusList} ${style.unselect}`}>
                              Unselect
                          </div>
                        )}
                        {!selectMode && (
                          <form onSubmit={handleAddStatus} className={`${style.statusList} ${style.addStatus}`}>
                              <div className={style.inputAction}>
                                  <input maxLength={15} value={addName} onChange={r => setAddName(r.target.value)}
                                         placeholder={'Tag name'} />
                              </div>
                              <Button isDisabled={addName?.length <= 0} isIconOnly={true} size={'sm'} className={'h-7'} variant={'light'} onClick={handleAddStatus}>
                                  <Plus size={16} />
                              </Button>
                          </form>
                        )}
                    </div>
                  )}
              </div>
          </PopoverContent>
      </Popover>
    );
}

function StatusList({
                        selectMode,
                        handleSelect,
                        status,
                        handleUpdateLead,
                        isUsed,
                        handleUpdate,
                        handleDelete,
                        ...props
                    }) {
    const [editName, setEditName] = useState(status.name ?? '');
    const [editColor, setEditColor] = useState(status.color ?? '#000000');
    const [editMode, setEditMode] = useState(false);

    function handleUpdateStatus(e) {
        if (e)
            e.preventDefault();

        handleUpdate({ name: editName, color: editColor, id: status?.id }).then(() => {
            setEditMode(false);
        });

        setEditMode(false);
    }


    function handleDeleteStatus(e) {
        if (e) e.preventDefault();
        if (e?.stopPropagation)
            e.stopPropagation();

        handleDelete({ id: status?.id });
    }

    function toggleEditMode(e) {
        if (e)
            e.preventDefault();
        if (e?.stopPropagation)
            e.stopPropagation();

        if (editMode && (editName !== status.name || editColor !== status.color)) {
            handleUpdateStatus();
        }
        setEditMode(!editMode);
    }

    function handleColorChange(color) {
        setEditColor(color.hex);
    }

    function handleAddTag() {
        if (selectMode) {
            handleSelect(status);
            return;
        }
        if (!editMode)
            handleUpdateLead(status);
    }

    function handleOnActionDropdown(event){
        if(event === 'edit'){
            toggleEditMode();
        }
        if(event === 'delete'){
            handleDeleteStatus();
        }
    }

    return (
      <div onClick={handleAddTag} className={style.statusList}>
          {editMode ? (
            <form onSubmit={toggleEditMode} className={style.statusEditWrap}>
                <div className={style.inputAction}>
                    <input autoFocus maxLength={15} value={editName} onChange={r => setEditName(r.target.value)}
                           placeholder={'Tag name'} />
                </div>
                <Popover>
                    <PopoverTrigger>
                        <div style={{ background: editColor }} className={style.inputActionColor} />
                    </PopoverTrigger>
                    <PopoverContent className={'p-0'}>
                        <TwitterPicker onChange={handleColorChange} />
                    </PopoverContent>
                </Popover>
            </form>
          ) : (
            <div style={{ background: editColor }} className={`${style.statusTag}`}>
                {isUsed && <Check style={{ marginRight: 5, color: getContrastYIQ(editColor) }} size={13} />}
                <div style={{ color: getContrastYIQ(editColor) }} className={style.statusText}><span>{editName}</span>
                </div>
            </div>
          )}
          {editMode && (
            <Button className={'h-7'} onClick={toggleEditMode} size={'sm'} isIconOnly={true} variant={'light'}>
                <Check size={16} />
            </Button>
          )}
          {(!editMode && !selectMode) && (
            <Dropdown
              showArrow
              radius='sm'
              placement={'right'}
              classNames={{
                  base: 'p-0 border-small border-divider bg-background',
                  arrow: 'bg-default-200'
              }}
            >
                <DropdownTrigger>
                    <Button className={'h-7'} size={'sm'} isIconOnly={true} variant={'light'}>
                        <MoreVertical size={16} />
                    </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label='Choose a space'
                  className='p-2'
                  onAction={handleOnActionDropdown}
                >
                    <DropdownItem
                      key={'edit'}
                      className='gap-2 text-black dark:text-white'
                      startContent={<Edit size={18}/>}
                    >
                        Edit
                    </DropdownItem>
                    <DropdownItem
                      key={'delete'}
                      className='gap-2 text-black dark:text-white'
                      startContent={<Trash size={18}/>}
                      color={'danger'}
                    >
                        Delete
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
          )}
      </div>
    );
}


function TagsCellLoader(){
    const { isDark } = useTheme();

    return (
      <div className={style.tagsCellLoader}>
          <ContentLoader
                         speed={2}
                         width={140}
                         height={22}
                         viewBox="0 0 140 22"
                         backgroundColor={isDark ? '#171717' : '#e9ecef'}
                         foregroundColor={isDark ? '#343333' : '#c7c7c7'}>
              <rect x="0" y="0" rx="4" ry="4" width="70" height="22" />
              <rect x="74" y="0" rx="4" ry="4" width="22" height="22" />
          </ContentLoader>
      </div>
    )
}
