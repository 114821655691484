import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import CommandsList from './CommandsList';
import 'tippy.js/themes/light.css';

const renderItems = (isUser) => {
    let component;
    let popup;

    return {
        onStart: (props) => {
            component = new ReactRenderer(CommandsList, {
                props,
                editor: props.editor
            });

            popup = tippy("body", {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                zIndex: 999990,
                maxWidth: 400,
                placement: isUser ? 'right-start' : "bottom-start",
                popperOptions: {
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: isUser ? ['right', 'top'] :  ['bottom', 'right']
                            }
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                altAxis: true,
                                tether: false
                            }
                        }
                    ]
                }
            });
        },
        onUpdate(props) {
            component.updateProps(props);

            popup[0].setProps({
                getReferenceClientRect: props.clientRect
            });
        },
        onKeyDown(props) {
            if(props.event.key === 'Enter' && isUser) {
                popup[0].hide();
                component.ref?.onKeyDown(props);
                return true;
            }
            if (props.event.key === "Escape") {
                popup[0].hide();

                return true;
            }

            return component.ref?.onKeyDown(props);
        },
        onExit() {
            if(popup && popup[0]){
                popup[0].destroy();
            }
            if(component)
                component.destroy();
        }
    };
};

export default renderItems;
