import { ChevronDown, ChevronUp, Plus, Users } from 'react-feather';
import { useSpace } from '@/utils/useSpace';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from '@nextui-org/react';
import SubscriptionBadge from '@/components/ui/subscriptionBadge';
import clsx from 'clsx';

export default function SpaceSwitch({ fullWidth, allowNewSpace = true }) {
  const { spaces, space, setSpace, isLTD } = useSpace();
  const [cookies, setCookie, removeCookie] = useCookies(['breakcold']);

  const router = useRouter();

  function switchSpace(space) {
    setSpace(space);
    setCookie('breakcold_defaultSpace', space?.id, { path: '/' });
    localStorage.setItem('default_id_space', space?.id);
    window.location.href = '/';
  }

  function handleDropdownAction(event) {
    if (event === 'new_space') {
      router.push('/space/new');
    } else {
      switchSpace(spaces.find(s => s.id === event));
    }
  }

  return (
    <Dropdown
      showArrow
      radius='sm'
      classNames={{
        base: 'p-0 border-small border-divider bg-background',
        arrow: 'bg-default-200'
      }}
    >
      <DropdownTrigger>
        <div
          className={clsx('space-x-4 cursor-pointer rounded-full justify-between hover:border-stone-200 dark:hover:border-stone-700 border-1 border-transparent flex items-center px-2 py-1.5 transition-all duration-100', fullWidth && 'w-full')}>
      <span className={'text-sm line-clamp-1'}>
        {space ? space?.name?.length > 0 ? space.name.substring(0, 12) : `Space ${space.id?.substring(0, 6)}` : 'Select Space'}
      </span>
         <div className={'flex items-center justify-center '}>
           <div className={'mr-2 flex'}>
             <SubscriptionBadge isLTD={isLTD} space={space} />
           </div>
           <div
             className={'flex items-center justify-center flex-col cursor-pointer rounded-lg'}>
             <ChevronUp style={{ marginBottom: -2 }} size={14} />
             <ChevronDown size={14} />
           </div>
         </div>
        </div>
      </DropdownTrigger>
      <DropdownMenu
        aria-label='Choose a space'
        className='p-2'
        onAction={handleDropdownAction}
        itemClasses={{
          base: [
            'rounded-md',
            'text-default-500',
            'transition-opacity',
            'data-[hover=true]:text-foreground',
            'data-[hover=true]:bg-default-100',
            'dark:data-[hover=true]:bg-default-50',
            'data-[pressed=true]:opacity-70',
            'data-[focus-visible=true]:ring-default-500'
          ]
        }}
      >
        <DropdownSection aria-label='Profile & settings' showDivider={allowNewSpace}>
          {spaces && spaces?.length > 0 && spaces?.map(space => {
            if (!space?.id)
              return null;
            let shortId = space?.id?.substring(0, 6) ?? '';
            const subscription = space?.subscriptions;

            return (
              <DropdownItem
                key={space.id}
                className='gap-2 text-black dark:text-white'
                endContent={<SubscriptionBadge isLTD={isLTD} space={space} />}
                startContent={<Avatar as='button'
                                      size={'sm'}
                                      icon={<Users />}
                                      classNames={{
                                        base: 'bg-white dark:bg-black border-1 border-stone transition-transform h-8 w-8 transform hover:scale-110',
                                        icon: 'text-black/80'
                                      }}
                                      name={space?.name?.length > 0 ? space.name.substring(0, 2) : `${shortId && shortId?.substring(0, 2)}`} />}

              >
                {space?.name?.length > 0 ? space.name : `Space ${shortId}`}
                <br />
                <span className={'text-xs text-black/80 dark:text-white/80'}>
                    {subscription?.cancel_at_period_end && `Subscription will end on ${new Date(subscription?.cancel_at).toLocaleDateString()}`}
                  {subscription?.trial_end && `Your free trial end on ${new Date(subscription?.trial_end).toLocaleDateString()}`}
                  </span>
              </DropdownItem>
            );
          })}
        </DropdownSection>
        {(!isLTD && allowNewSpace) && (
          <DropdownSection className={'p-0 mb-0'}>
            <DropdownItem
              key='new_space'
              // className='h-14 gap-2'
              className='opacity-100 text-black/80 dark:text-white/80 '
              startContent={<Avatar as='button'
                                    size={'sm'}
                                    icon={<Plus size={16} />}
                                    classNames={{
                                      base: 'bg-white border-1 border-gray transition-transform h-8 w-8 transform hover:scale-110',
                                      icon: 'text-black/80'
                                    }} />}
            >
              Create new workspace
            </DropdownItem>
          </DropdownSection>
        )}
      </DropdownMenu>
    </Dropdown>
  )
    ;
}


