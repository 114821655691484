import style from './style.module.css';
import dynamic from 'next/dynamic';
import ActivityInput from '@/components/activity/input';
import { Reminders } from '@/components/activity/lines/reminder';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLead } from '@/utils/useLead';
import Loading from '@/components/ui/loading';
import { ArrowDown, ChevronDown, ChevronUp, Filter, Hash } from 'react-feather';
import { notesService } from '@/services/notes.service';
import useSWR from 'swr';
import { leadService } from '@/services/lead.service';
import useCustomToasts from '@/utils/useCustomToasts';
import { sequenceService } from '@/services/sequence.service';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Tab,
  Tabs
} from '@nextui-org/react';
import clsx from 'clsx';

const LineSocials = dynamic(() => import('./lines/socials'), { ssr: false });
const LineEmail = dynamic(() => import('./lines/email'), { ssr: false });
const LineEmailExternal = dynamic(() => import('./lines/email-external'), { ssr: false });
const LineEmailActivity = dynamic(() => import('./lines/email-activity'), { ssr: false });
const LineNote = dynamic(() => import('./lines/note'), { ssr: false });
const LineReminder = dynamic(() => import('./lines/reminder'), { ssr: false });
const LineSocialsInteraction = dynamic(() => import('./lines/socials-interaction'), { ssr: false });


export default function Activity({ small, defaultOpen = false }) {
  const [filter, setFilter] = useState('all');
  const [activities, setActivities] = useState(null);
  const [activeReminder, setActiveReminder] = useState([]);
  const { setToast } = useCustomToasts();
  const [linesLoading, setLinesLoading] = useState([]);
  const [selectedLine, setSelectedLine] = useState(null);
  const [showLines, setShowLines] = useState(defaultOpen);
  const [filterPopIsOpen, setFilterPopIsOpen] = useState(false);
  const router = useRouter();
  const linesRef = useRef();
  const {
    selectedLead,
    uniqueLead
  } = useLead();


  const {
    data,
    mutate: mutateActivities
  } = useSWR(((uniqueLead || selectedLead) && (small && showLines || !small)) ? `/${uniqueLead?.id ?? selectedLead?.id}/activities` : null, leadService.fetchWrapperGet);


  useEffect(() => {
    if (data?.length > 0) {
      const newData = handleFilterChange(filter, data, false, true);
      setActivities(newData);
      //if there is no selected line, scroll to the bottom
      if (!selectedLine) {
        scrollBottom();
      }
    } else if (data?.length === 0) {
      setActivities([]);
    } else {
      setActivities(null);
    }
  }, [data]);

  useEffect(() => {
    //if activities is the same size as previous, scroll to the bottom
    if (activities && (Object.values(activities).flat().length !== data?.length)) {
      scrollBottom();
    }
  }, [activities]);


  useEffect(() => {
    if (uniqueLead?.reminders?.length > 0) {
      const reminders = uniqueLead?.reminders?.filter(item => !item.is_done).sort((a, b) => new Date(a.date) - new Date(b.date));
      setActiveReminder(reminders);
    }
  }, [uniqueLead]);


  useEffect(() => {
    if (router?.query?.lineId) {
      const anchor = document.getElementById(router?.query?.lineId);
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [router]);


  function addActivities(newActivity) {
    mutateActivities(prev => {
      let newActivities;
      if (prev && prev?.length > 0) {
        newActivities = [newActivity, ...prev];
      } else {
        newActivities = [newActivity];
      }
      return newActivities;
    }, false);
    scrollBottom();
  }

  function updateActivities(updatedActivity) {
    mutateActivities(prev => {
      return prev.map(item => {
        if (item?.data?.id === updatedActivity?.data.id) {
          if (item?.data?.id_parent) {
            return {
              ...item,
              data: {
                ...item.data,
                ...updatedActivity.data
              }
            };
          }
          return updatedActivity;
        }
        return item;
      });
    }, false);
    setSelectedLine(null);
  }

  function scrollBottom() {
    setTimeout(() => {
      linesRef?.current?.scrollTo({
        top: linesRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }, 300);
  }

  function deleteActivities(id) {
    mutateActivities(prev => {
      let newActivities = prev.filter(item => ((item?.data?.id && item?.data?.id !== id) || (item.id && item.id !== id)));
      newActivities = handleFilterChange(null, newActivities, false, false);
      return newActivities;
    }, false);
  }


  function handleDeleteNote(id_note) {
    addLineLoading(id_note);
    return notesService.deleteNote(id_note).then(res => {
      setSelectedLine(null);
      return deleteActivities(id_note);
    }).catch(err => {
      console.log('err', err);
      setToast({
        text: err?.message || 'Something went wrong',
        type: 'error'
      });
    }).finally(() => {
      removeLineLoading(id_note);
    });
  }

  function handleEditNote(text) {
    return notesService.updateNote(selectedLine?.data?.id, {
      content: text
    }).then(res => {
      console.log("RES NOTE", res)
      //do local update of the lead
      return updateActivities({
        type: 'note',
        data: res
      });
    }).catch(err => {
      console.log('err', err);
      setToast({
        text: err?.message || 'Something went wrong',
        type: 'error'
      });
    });
  }

  function handleCreateNote(text, id_parent) {
    return notesService.createNote({
      id_lead: uniqueLead?.id,
      ...(id_parent && { id_parent: id_parent }),
      content: text
    }).then(res => {
      if (res.id_parent) {
        //search for the parent
        const parent = data.find(item => item?.data?.id === res?.id_parent);
        if (parent) {
          //update the parent
          setSelectedLine(null);
          return addActivities({
            type: 'note',
            data: res
          });
        }
      }
      setSelectedLine(null);
      //do local update of the lead
      return addActivities({
        type: 'note',
        data: res
      });
    }).catch(err => {
      console.log('err', err);
      setToast({
        text: err?.message || 'Something went wrong',
        type: 'error'
      });
    });
  }


  async function updateSequenceStepUser({ line, id_campaign, id_step, data }) {
    addLineLoading(line?.data?.id);

    return sequenceService.updateSequenceUserStep({
      id_campaign: id_campaign,
      id_step: id_step,
      data: data
    }).then(res => {
      return updateActivities({
        ...line,
        type: 'campaign.email.to_send',
        is_future: true,
        data: {
          ...line?.data,
          status: res?.status
        }
      });
    }).catch(err => {
      console.log('err', err);
      setToast({
        text: err?.message || 'Something went wrong',
        type: 'error'
      });
    }).finally(() => {
      removeLineLoading(line?.data?.id);
    });
  }

  function handleSubmit(text) {
    if (!selectedLine || (selectedLine?.type === 'note' && selectedLine?.action === 'reply')) {
      return handleCreateNote(text, selectedLine?.data?.id);
    }
    if (selectedLine?.type !== 'note')
      return;

    console.log('selectedLine', selectedLine)
    if (selectedLine.action === 'edit') {
      return handleEditNote(text);
    }
    if (selectedLine?.action === 'delete') {
      return handleDeleteNote();
    }
  }

  const handleFilterChange = useCallback((newFilter, localData, setState = true, groupValue = true) => {
    if (newFilter) {
      setFilter(newFilter);
      setFilterPopIsOpen(false);
    } else {
      newFilter = filter;
    }

    let newData = localData || data;

    if (!newData || newData?.length === 0) {
      return [];
    }

    if (newFilter === 'all' && !localData) {
      newData = data;
    }
    if (newFilter === 'campaigns') {
      newData = newData.filter(item => item.type.startsWith('campaign.'));
    }
    if (newFilter === 'reminders') {
      newData = newData.filter(item => item.type === 'reminder');
    }
    if (newFilter === 'interactions') {
      newData = newData.filter(item => item.type === 'interaction');
    }
    if (newFilter === 'messages') {
      newData = newData.filter(item => item.type.startsWith('message.'));
    }
    if (newFilter === 'notes') {
      newData = newData.filter(item => item.type === 'note');
    }
    if (newFilter === 'emails') {
      newData = newData.filter(item => item.type.startsWith('email.'));
    }

    //order by date
    newData = newData.sort((a, b) => {
      return new Date(a?.type.startsWith('email.') ? a?.data?.date : a?.data?.created_at || a?.date) - new Date(b?.type.startsWith('email.') ? b?.data?.date : b?.data?.created_at || b?.date);
    });

    if (localData && !groupValue) {
      return newData;
    }

    // Group by date
    let groupedData = {};
    newData.forEach((item, index) => {
      let date = new Date(item?.type.startsWith('email.') ? item?.data?.date : item?.data?.created_at || item?.date);
      let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

      if (index === newData.length - 1) {
        item.isLast = true;
      }

      if (!groupedData[dateString]) {
        groupedData[dateString] = [item];
      } else {
        groupedData[dateString].push(item);
      }

    });

    newData = groupedData;

    if (setState) {
      setActivities(newData);
    }
    return newData;
  }, [filter, activities]);


  function handleSelectLine(line) {
    if (line?.type === 'note' && line?.action === 'delete') {
      return handleDeleteNote(line?.data?.id);
    } else {
      setSelectedLine(line);
    }
  }


  function addLineLoading(id) {
    //search if the line is already loading
    if (linesLoading.includes(id)) {
      return;
    }
    setLinesLoading(prev => [...prev, id]);
  }

  function removeLineLoading(id) {
    setLinesLoading(prev => prev.filter(item => item !== id));
  }

  return (
    <div
      className={clsx('h-full w-full flex flex-col', small && 'max-h-[30VH] overflow-auto', 'bg-stone-50 dark:bg-stone-950 dark:border-stone-700')}>
      {activities && (Object.values(activities).flat().length >= 0 || activeReminder?.length > 0) && (
        <div className={'flex flex-col z-20 rounded-lg w-full'}>
          {!small && Object.values(activities).flat().length >= 0 && (
            <div
              className={'justify-between px-2 py-1 items-center border-b-1 border-stone-200 dark:border-stone-700 sticky top-0 flex bg-white dark:bg-black w-full'}>
              <div className={'min-w-[150px] text-sm text-black dark:text-white font-semibold'}>
                {activities && Object.values(activities).flat().length} {Object.values(activities).flat().length <= 1 ? 'activity' : 'activities'}
              </div>
              <Tabs classNames={{
                base: 'overflow-auto',
                tabContent: 'text-black/80 dark:text-white/80'
              }} variant={'light'} color={'primary'} size={'sm'} selectedKey={filter}
                    onSelectionChange={handleFilterChange} radius={'md'} aria-label='Tabs radius'>
                <Tab key='all' title='All' />
                <Tab key='notes' title='Notes' />
                <Tab key='reminders' title='Tasks' />
                <Tab key='campaigns' title='Campaigns' />
                <Tab key='messages' title='DMs' />
                <Tab key='emails' title='Emails' />
                <Tab key='interactions' title='Interactions' />
              </Tabs>
            </div>
          )}
          {activeReminder?.length > 0 && (
            <div className={'border-b-1 '}>
              <Reminders small={small} data={activeReminder} />
            </div>
          )}
        </div>
      )}

      {small && (
        <div className={'bg-white dark:black-black border-1 border-stone-200 rounded-lg dark:border-stone-800'}>
          <div onClick={() => setShowLines(!showLines)} className={clsx(!showLines && 'border-b-1 border-transparent', showLines && 'border-b-1 border-stone-200 dark:border-stone-700', 'rounded-lg flex items-center justify-between bg-white dark:bg-black z-20 px-2 py-1 sticky top-0 transition-all hover:bg-stone-100 dark:hover:bg-stone-800 cursor-pointer')}>
            <div className={'text-sm font-semibold'}>
              <span>{(activities && Object.values(activities).flat().filter(a => !a?.is_future)?.length) ?? ''} {activities && Object.values(activities).flat().filter(a => !a?.is_future)?.length <= 1 ? 'Activity' : 'Activities'}</span>
              <span className={'text-sm text-black/70 dark:text-white/70 ml-1'}>({filter})</span>
            </div>
            <div className={'flex items-center justify-center ml-auto'}>
              {showLines && <ChevronUp size={16} />}
              {!showLines && <ChevronDown size={16} />}
            </div>
          </div>
          {showLines && (
            <>
              <Dropdown placement={'left'}>
                <DropdownTrigger>
                  <div
                    className={'hover:bg-stone-100 dark:hover:bg-stone-800 w-full flex px-2 py-1 cursor-pointer items-center justify-between text-xs transition-all '}>
                    <span>Filter</span>
                    <Filter size={14} style={{ marginRight: 0 }} />
                  </div>
                </DropdownTrigger>
                <DropdownMenu onAction={handleFilterChange} aria-label='Filter by'>
                  <DropdownSection title='Filters activity by'>
                    {['all', 'notes', 'reminders', 'campaigns', 'messages', 'emails', 'interactions'].map(action => {
                      return (
                        <DropdownItem
                          key={action}
                          classNames={{
                            title: 'capitalize'
                          }}
                        >
                          {action}
                        </DropdownItem>
                      );
                    })}
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
              <div className={'flex flex-col overflow-auto'}>
                {!activities && <LoadingLine small={small} />}
                {activities && Object.keys(activities).length === 0 && <EmptyLine filter={filter} small={small} />}
                {activities && Object.keys(activities).length > 0 && Object.keys(activities).map(date => (
                  <>
                    <div className={'pb-2 pt-4 flex w-full items-center justify-center relative'}>
                      <div className={'w-full h-[1px] bg-stone-200 dark:bg-stone-700 absolute'} />
                      <Chip classNames={{
                        base: 'text-xs border-1 border-stone-300 dark:border-stone-600 bg-white dark:bg-black'
                      }} variant={'bordered'} size={'sm'}>
                        {dayjs(date).format('dddd D MMMM - YYYY')}
                      </Chip>
                    </div>
                    {activities[date].filter(it => !it?.is_future).map((item, index) =>
                      <div style={{ zIndex: index }}>
                        <RenderLines
                          focus={scrollBottom}
                          isFutur={false}
                          small={small}
                          refresh={mutateActivities}
                          lineNum={index}
                          linesNum={activities[date]?.length}
                          lead={uniqueLead}
                          updateSequenceStepUser={updateSequenceStepUser}
                          loading={linesLoading.includes(item?.data?.id || item?.id)}
                          onSelect={handleSelectLine}
                          msg={item}
                          key={index}
                        />
                      </div>
                    )}
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {!small && (
        <div ref={linesRef} className={clsx('flex overflow-auto flex-col flex-1')}>
          {!activities && <LoadingLine />}
          {activities && Object.keys(activities).length === 0 && <EmptyLine filter={filter} small={small} />}
          {activities && Object.keys(activities).length > 0 && Object.keys(activities).map(date => (
            <>
              <div className={'pb-2 pt-4 flex w-full items-center justify-center relative'}>
                <div className={'w-full h-[1px] bg-stone-200 dark:bg-stone-700 absolute'} />
                <Chip classNames={{
                  base: 'text-xs border-1 border-stone-300 dark:border-stone-600 bg-white dark:bg-black'
                }} variant={'bordered'} size={'sm'}>
                  {dayjs(date).format('dddd D MMMM - YYYY')}
                </Chip>
              </div>
              {activities[date].filter(it => it?.is_future).map((item, index) =>
                <div style={{ zIndex: index }}>
                  <RenderLines
                    isFutur={true}
                    small={small}
                    focus={scrollBottom}
                    lineNum={index}
                    refresh={mutateActivities}
                    linesNum={activities[date]?.length}
                    updateSequenceStepUser={updateSequenceStepUser}
                    lead={uniqueLead}
                    loading={linesLoading.includes(item?.data?.id || item?.id)}
                    onSelect={handleSelectLine}
                    msg={item}
                    key={index} />
                </div>
              )}
              {activities[date].find(it => it?.is_future) &&
                <div className={'pb-2 pt-4 flex w-full items-center justify-center relative'}>
                  <div className={'w-full h-[1px] bg-stone-200 dark:bg-stone-700 absolute'} />
                  <Chip classNames={{
                    base: 'text-xs'
                  }} variant={'faded'} color={'primary'} size={'sm'}>
                    Coming activities
                  </Chip>
                </div>
              }
              {activities[date].filter(it => !it?.is_future).map((item, index) =>
                <div style={{ zIndex: index }}>
                  <RenderLines
                    isFutur={false}
                    small={small}
                    focus={scrollBottom}
                    lineNum={index}
                    refresh={mutateActivities}
                    linesNum={activities[date]?.length}
                    lead={uniqueLead}
                    updateSequenceStepUser={updateSequenceStepUser}
                    loading={linesLoading.includes(item?.data?.id || item?.id)}
                    onSelect={handleSelectLine}
                    msg={item}
                    key={index} />
                </div>
              )}
            </>
          ))}
        </div>
      )}

      {!small && (
        <div className={'p-2'}>
          <ActivityInput onRemoveReplyNote={() => setSelectedLine(null)}
                         replyNote={selectedLine?.type === 'note' && selectedLine?.action === 'reply' && selectedLine}
                         editNote={selectedLine?.type === 'note' && selectedLine?.action === 'edit' && selectedLine}
                         onSubmit={handleSubmit} />
        </div>
      )}
    </div>
  );
}


function LoadingLine({ small }) {
  return (
    <div className={clsx('flex flex-1 items-center justify-center p-4', small && 'p-4')}>
      <Loading size={small ? 'md' : 'lg'} />
    </div>
  );
}

function EmptyLine({ small, filter }) {
  return (
    <div className={clsx(small && '', 'flex flex-1 items-start justify-end flex-col p-2')}>
      <div className={'p-2 bg-primary-50 text-primary-500 rounded-lg flex items-center justify-center'}>
        <Hash size={small ? 25 : 40} />
      </div>
      <div className={'font-semibold text-xl'}>
        No activities yet
      </div>
      <div className={'text-md text-black/80 dark:text-white/80 mt-1'}>
        Here you will see all the activities related to this lead, including notes, reminders, emails, campaigns and
        social media messages.
      </div>
      {(!small && filter === 'all') && (
        <div className={'text-sm flex items-center mt-4 rounded-lg'}>
          <ArrowDown size={16} style={{ marginRight: 5 }} /> Start by adding a note
        </div>
      )}
    </div>
  );
}

const RenderLines = React.memo(({
                                  focus,
                                  updateSequenceStepUser,
                                  small,
                                  lead,
                                  loading,
                                  onSelect,
                                  msg,
                                  refresh
                                }) => {
  if (!msg || !msg?.type) return null;
  const ref = useRef(null);

  function onRender() {
    if (focus && msg?.isLast) {
      // ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      focus && focus();
    }
    return false;
  }


  if (msg.type === 'note') {
    return <div ref={ref}><LineNote onRender={onRender} small={small} loading={loading} onSelect={onSelect}
                                    data={msg} /></div>;
  }
  if (msg.type === 'reminder') {
    return <div ref={ref}><LineReminder onRender={onRender} small={small} loading={loading} data={msg} /></div>;
  }
  if (msg.type === 'interaction') {
    return <div ref={ref}><LineSocialsInteraction onRender={onRender} small={small} loading={loading} data={msg} />
    </div>;
  }
  if (msg.type.startsWith('message.email')) {
    return <div ref={ref}><LineEmail onRender={onRender} small={small} lead={lead} loading={loading} data={msg} />
    </div>;
  }
  if (msg.type.startsWith('email.')) {
    return <div ref={ref}><LineEmailExternal onRender={onRender} small={small} lead={lead} loading={loading}
                                             data={msg} /></div>;
  }
  if (msg.type.startsWith('campaign.email')) {
    return <div ref={ref}><LineEmailActivity onRender={onRender} updateSequenceStepUser={updateSequenceStepUser}
                                             lead={lead} small={small}
                                             loading={loading} data={msg} /></div>;
  }
  if (msg.type.startsWith('message.')) {
    return <div ref={ref}><LineSocials refresh={refresh} onRender={onRender} small={small} loading={loading}
                                       data={msg} /></div>;
  }

  return null;
});
