import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';
import { auth } from '@/utils/firebase';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/account`;
// const userSubject = new BehaviorSubject(process.browser && isJson(localStorage.getItem('user')));

export const accountService = {
  fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
  deleteTwitterAccount,
  deleteLinkedinAccount,
  updateLinkedinAccount,
  confirmLinkedinAccount,
  resendLinkedinAccountConfirmationCode,
  resyncAccount,
};


function deleteTwitterAccount(id_space, accountId) {
  const payload = `id_space=${id_space}`
  return fetchWrapper.delete(`${baseUrl}/twitter/${accountId}?${payload}`);
}

function deleteLinkedinAccount(id_space, accountId) {
  const payload = `id_space=${id_space}`
  return fetchWrapper.delete(`${baseUrl}/linkedin/${accountId}?${payload}`);
}


function updateLinkedinAccount(id, data){

  return fetchWrapper.patch(`${baseUrl}/linkedin/${id}`, data);
}

function confirmLinkedinAccount(id, data){

  return fetchWrapper.post(`${baseUrl}/linkedin/${id}/confirm-code`, data);
}


function resendLinkedinAccountConfirmationCode(id, data){

  return fetchWrapper.post(`${baseUrl}/linkedin/${id}/resend-confirm-email`, data);
}

function resyncAccount(id_space, accountId) {
  const payload = `id_space=${id_space}`
  return fetchWrapper.post(`${baseUrl}/twitter/${accountId}/resync?${payload}`);
}




