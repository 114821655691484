import React, { createContext, useContext, useEffect, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import { leadService } from '../services/lead.service';
import { useRouter } from 'next/router';
import { useSpace } from '@/utils/useSpace';

export const TemplateContext = createContext();

export const TemplateContextProvider = (props) => {
  const [templateExplorerIsOpen, setTemplateExplorerIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [type , setType] = useState('create');
  const { setToast } = useCustomToasts();
  const [chosenTemplate, setChosenTemplate] = useState(false);
  const [loading, setLoading] = useState(true);

  //HOOKS
  const router = useRouter();
  const {id} = router.query;
  const {id_space} = useSpace();

  useEffect(() => {
    setSelectedTemplate(null);
  }, [router])


  function openTemplateExplorer(id, type, templateChosen) {
    setType(type);
    setTemplateExplorerIsOpen(true)
  }

  function closeTemplateExplorer(reset){
    if(reset){
      setSelectedTemplate(null);
    } else{
      setChosenTemplate(true)
    }
    setTemplateExplorerIsOpen(false);
  }


  const value = {
    loading,
    type,
    templateExplorerIsOpen,
    openTemplateExplorer,
    closeTemplateExplorer,
    chosenTemplate,
    setChosenTemplate,
    setSelectedTemplate,
    selectedTemplate
  };
  return <TemplateContext.Provider value={value} {...props} />;
};

export const useTemplate = () => {
  const context = useContext(TemplateContext);
  if (context === undefined) {
    throw new Error(`use Template must be used within a TemplateContextProvider.`);
  }
  return context;
};
