import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/product`;

export const productService = {
  getAllProducts,
  createCheckoutSession,
  createPortalLink,
  fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
  fetchWrapperGetEmpty: url => fetchWrapper.get(`${apiUrl}${url}`),
}

function getAllProducts() {
  return fetchWrapper.get(`${baseUrl}s`);
}



function createCheckoutSession({ id_price, mode, cancelUrl, successUrl, slots }) {
  return fetchWrapper.get(`${baseUrl}/${id_price}/buy?${cancelUrl? `&cancelUrl=${cancelUrl}` : ''}${slots? `&slots=${slots}` : ''}${successUrl? `&successUrl=${successUrl}` : ''}${mode? `&mode=${mode}` : ''}`);
}

function createPortalLink({ returnUrl, type }) {
  const payload = `${returnUrl? `&returnUrl=${returnUrl}` : ''}${type? `&type=${type}` : ''}`;
  return fetchWrapper.post(`${baseUrl}/create-portal-link?${payload}`);
}

