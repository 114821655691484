import throttle from 'lodash.throttle';


export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ''
      ? process.env.URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
        ? process.env.VERCEL_URL
        : 'http://localhost:3000';
  return url.includes('http') ? url : `https://${url}`;
};

export const postData = async ({ url, token, data = {} }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (res.error) {
    throw error;
  }

  return res.json();
};

export const toDateTime = (secs) => {
  var t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};


export function throttleEvent(func) {
  if (!func) return;

  return throttle(func, 1000, { leading: true, trailing: false });
}


export function formatNumberCurrency(number, currency) {
  number = number ? number : 0;
  return number?.toLocaleString('en-US', { style: 'currency', currency: currency ?? 'USD' });
}


export function labelToId(label) {
  // Remove all non-alphanumeric characters (anything other than a-z, A-Z, 0-9, _) and replace the spaces with underscores
  return label?.replace(/ /g, '_')?.replace(/[^a-z0-9_]/gi, '');
}


export function parseLeadCustomVariables(lead, emailAccount) {
  const customVariables = lead?.custom_fields;
  if (!customVariables) return { ...lead, signature: emailAccount?.signature ?? '' };
  const newLead = { ...lead };

  // iterate through object
  Object.keys(customVariables).forEach((key) => {
    // Check if the lead object has the same key as the customVariable object
    if (Object.prototype.hasOwnProperty.call(newLead, customVariables[key]?.id)) {
      // If it does, do nothing as the value is already in newLead
    } else {
      newLead[customVariables[key]?.id] = customVariables[key]?.value;
    }
  });

  return {
    ...newLead,
    signature: lead?.signature ?? emailAccount?.signature ?? '',
  };
}



export function getImageUrl(vectorImage, quality = 'LOW') {
  let imagesDef = vectorImage?.artifacts;
  if (!imagesDef)
    return null;
  //order by width
  imagesDef = imagesDef.sort((a, b) => {
    return a.width - b.width;
  });
  //find the image with the closest width
  if (quality === 'LOW') {
    //find the image with the smallest width
    imagesDef = imagesDef[0];
  } else {
    //find the image with the biggest width
    imagesDef = imagesDef[imagesDef.length - 1];
  }
  return vectorImage?.rootUrl + imagesDef?.fileIdentifyingUrlPathSegment;
}

export const debounce = (func, delay) => {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer =
      setTimeout(() => func.apply(context, args), delay);
  };
};

export function getContrastYIQ(hexcolor) {
  if (!hexcolor)
    return 'black';
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? 'black' : 'white';
}


function findSlash(idx, value) {
  if (idx < 0) return -1;
  if (value[idx] === '/') return idx;
  return findSlash(idx - 1, value);
}

export function typeInTextarea({ newText, ref = document.activeElement, removeSlash, onChange = () => null }) {
  try {
    if (!ref || !newText) return;
    let [start, end] = [ref.selectionStart, ref.selectionEnd];
    start = removeSlash ? findSlash(start, ref?.value) : start;

    if (start > end) return;
    // end = removeSlash ? end - 1 : end;
    ref?.setRangeText(newText, start, end, 'select');
    //get the content of the textarea
    onChange && onChange(ref?.value);
    ref?.focus();
    //remove the text selection
    ref?.setSelectionRange(start + newText.length, start + newText.length);
  } catch (error) {
    console.log(error);
  }
}


export function numberWithSpaces(x) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function getUserDisplayName(user) {
  if (!user) return '';

  if (user?.handle)
    return user?.handle;
  if (user?.full_name) {
    return user?.full_name;
  }
  if (user?.email)
    return user?.email;
  if (user?.id)
    return user?.id;
}
