import React, { useEffect, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import style from './note.module.css';
import { CommandsAt } from './extensions/suggestion/index';
import Link from '@tiptap/extension-link';
import getSuggestionItems from './extensions/suggestion/items';
import renderItems from './extensions/suggestion/renderItems';
import { VariableHighlighter } from './extensions/variableHighlighter';
import useSWR from 'swr';
import { spaceService } from '@/services/space.service';
import { AtSign, Check, Send, Smile } from 'react-feather';
import EmojiPicker from '@/components/feed/emojiPicker';
import { Button, Tooltip } from '@nextui-org/react';

export default function ViewEditor({
                                     onReset,
                                     isEditMode,
                                     onSubmit,
                                     onBlur,
                                     onLeaveEditor,
                                     autofocus,
                                     content,
                                     readOnly,
                                     onUpdate,
                                     onCreate
                                   }) {
  const [loading, setLoading] = useState(false);
  let isMac = navigator.platform.toUpperCase().includes('MAC');


  //API
  const {
    data: members,
    error,
    isValidating: detailsLoading
  } = useSWR(`/space/members`, spaceService.fetchWrapperGetEmpty, { revalidateOnFocus: true });

  const editor = useEditor({
    extensions: [
      StarterKit,
      // TipTapCustomImage,
      VariableHighlighter.configure({
        customVariables: members ?? [],
        isUser: true
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          return 'Write your note here and @ to mention someone';
        }
      }),
      Link.configure({
        openOnClick: false
      }),
      CommandsAt.configure({
        char: '@',
        suggestion: {
          items: (data) => getSuggestionItems(data, members ?? [], undefined, true),
          render: () => renderItems(true)
        }
      })
    ],
    editable: !readOnly,
    customVariables: members ?? [],
    autofocus: autofocus,
    injectCSS: true,
    content: formatContent(content),
    onUpdate({ editor }) {
      if (editor.isEmpty) {
        onReset && onReset();
      }
      if (onUpdate)
        onUpdate(editor);
    },
    onCreate({ editor }) {
      if (onCreate)
        onCreate(editor);
    },
    onBlur({ editor, event }) {
      if (onBlur)
        onBlur(editor, 'bindOrignal');
    }
  }, [members]);

  useEffect(() => {
    if (editor && members) {
      editor?.setOptions({
        customVariables: members
      });
    }
  }, [members]);

  useEffect(() => {
    if (editor && content) {
      console.log("content", content)
      editor?.commands?.setContent(stringToHTML(content));
    } else if (editor && !editor.isDestroyed) {
      editor?.commands?.setContent('<p></p>');
    }
  }, [content]);

  function handleSubmit() {
    if (loading || editor?.isEmpty) {
      return;
    }
    if (onSubmit) {
      setLoading(true);
      onSubmit(getJSON()).then(() => {
        editor?.commands?.setContent('<p></p>');
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        setLoading(false);
      });
    }
  }


  function stringToHTML(str) {
    return str.replace(/\n/g, '<br/>');
  }


  function formatContent(content) {
    let result = content;

    return result;
  }

  // useEffect(() => {
  //   document.addEventListener('keydown', checkShortCut);
  //   return () => document.removeEventListener('keydown', checkShortCut);
  // }, []);

  function handleMouseLeave() {
    if (onLeaveEditor) {
      return onLeaveEditor(getJSON());
    }
  }

  function updateContent(content) {
    if (editor && !editor?.isDestroyed) {
      if (content) {
        let tmpContent = formatContent(content);
        editor.commands.setContent(tmpContent);
      } else {
        editor?.commands?.setContent('<p></p>');
      }
      editor.off('blur', null);
      editor.on('blur', ({ editor, event }) => {
        if (onBlur)
          onBlur(editor);
      });
    }
  }


  function addEmoji(emoji) {
    if (emoji && editor) {
      return editor.chain().focus().insertContent(emoji?.native).run();
    }
  }

  function openAt() {
    if (editor) {
      return editor.chain().focus().insertContent('@').run();
    }
  }

  function getJSON() {
    if (editor) {
      return editor.getText();
    }
    return '';
  }

  if (!editor)
    return null;

  return (
    <div className={style.wrap}>
      <div onMouseLeave={handleMouseLeave} className={`${style.editorWrap}`}>
        <EditorContent style={{ padding: '10px 0', width: '100%' }} editor={editor} />
      </div>
      {!readOnly && (
        <div className={style.actions}>
          <EmojiPicker placement={'top'} onEmojiSelect={addEmoji}>
                <Button variant={'light'} size={'sm'} isIconOnly>
                  <Smile size={14} />
                </Button>
          </EmojiPicker>
          <Tooltip content={'Users'}>
            <Button onClick={openAt} variant={'light'} size={'sm'} isIconOnly>
              <AtSign size={14} />
            </Button>
          </Tooltip>
          <div className={style.submit}>
            <Button size={'sm'} fullWidth radius={'full'} endContent={isEditMode ? <Check size={14} /> : <Send size={14} />} color={'primary'}
                    isLoading={loading} onClick={handleSubmit} isDisabled={editor.isEmpty}>
              {isEditMode ? 'Update' : 'Send'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}



