// Import necessary hooks and components
import { useState } from 'react';
import clsx from 'clsx';
import Messages from '@/components/feed/messages';
import { Avatar, Button } from '@nextui-org/react';
import { useLead } from '@/utils/useLead';
import { Activity, ChevronDown, ChevronUp, Maximize2, Minimize2, X } from 'react-feather';
import { useRouter } from 'next/router';
import LeadActivity from '@/components/activity';

// Define your component
export default function QuickContact() {
  // Add a state variable for tracking the minimized state
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { selectedLead, setSelectedLead } = useLead();
  const router = useRouter();

  // Rest of your component code...
  const leadName = selectedLead?.first_name || selectedLead?.last_name ? `${selectedLead?.first_name ?? ''} ${selectedLead?.last_name ?? ''}` : 'No name';

  function handleMaximize(e) {
    if (e) {
      e.stopPropagation();
    }
    setIsMaximized(!isMaximized);
  }

  function handleExpand(e) {
    if (e) {
      e.stopPropagation();
    }
    setIsExpanded(!isExpanded);
  }

  const isFeed = router.pathname === '/feed';
  const isInbox = router.pathname === '/inbox';

  if (!selectedLead || isFeed || isInbox) return null;


  // Wrap your component in a div with appropriate classes
  return (
    <div
      className={clsx(
        'flex-row items-end max-w-[70VW] flex border-0 border-t-0 rounded-t-xl border-stone-100 fixed bottom-0 left-64 z-50 transition-width',
      )}
    >
      <div className={clsx(
        'bg-white rounded-t-xl z-10 min-w-[300px] overflow-auto transition-all dark:bg-black flex flex-col shadow-2xl ',
        { 'w-72 bg-stone-200 max-h-[44px]': isMinimized },
        { 'w-[300px]': !isExpanded && isMinimized && !isMaximized },
        { 'w-[800px] h-[80VH] max-h-[80VH] ': !isExpanded && !isMinimized && isMaximized },
        { 'w-[400px] h-[750px] max-h-[80VH]': isExpanded && !isMinimized && !isMaximized },
        { 'w-[400px] h-[750px] max-h-[80VH]': !isExpanded && !isMinimized && !isMaximized },
        { 'w-[500px] h-[80VH] max-h-[80VH]': isExpanded && !isMinimized && isMaximized }
      )}>
        <div onClick={() => setIsMinimized(!isMinimized)}
             className={clsx(
               'justify-between text-white flex items-center cursor-pointer py-1.5 px-2 rounded-t-xl',
               isMinimized && 'bg-stone-300 dark:bg-stone-700',
               !isMinimized && 'bg-primary-500'
             )}>
          <div className={'flex items-center justify-start'}>
            <Avatar name={selectedLead?.first_name} classNames={{ base: 'min-w-[32px]' }} src={selectedLead?.avatar_url}
                    size={'sm'} />
            <span className={'ml-2 break-all leading-normal font-semibold line-clamp-1'}>
              {leadName}
            </span>
          </div>
          <div className={'flex flex-row ml-2 gap-0 text-white/80'}>
            {!isMinimized && (
              <Button color={'white'} variant={isExpanded ? 'ghost' : 'light'} isIconOnly={false} size={'sm'}
                      onClick={handleExpand} className={clsx(
                isExpanded && 'text-white',
                !isExpanded && 'text-white/80',
                'mr-2 rounded-lg p-1.5 cursor-pointer hover:bg-stone-100/30 hover:text-white dark:hover:bg-stone-900'
              )}>
                {!isExpanded && 'Show activity'}
                {isExpanded && 'Hide activity'}
                <Activity size={18} />
              </Button>
            )}
            {!isMinimized && (
              <Button color={'white'} isIconOnly={true} size={'sm'} variant={'light'} onClick={handleMaximize}
                      className={'rounded-lg p-1.5 cursor-pointer hover:bg-stone-100/30 hover:text-white dark:hover:bg-stone-900'}>
                {!isMaximized && <Maximize2 size={18} />}
                {isMaximized && <Minimize2 size={18} />}
              </Button>
            )}
            <Button onClick={() => setIsMinimized(!isMinimized)} color={'white'} isIconOnly={true} size={'sm'}
                    variant={'light'}
                    className={'rounded-lg p-1.5 cursor-pointer hover:bg-stone-100/30 hover:text-white dark:hover:bg-stone-900'}>
              {isMinimized && <ChevronUp size={18} />}
              {!isMinimized && <ChevronDown size={18} />}
            </Button>
            <Button color={'white'} isIconOnly={true} size={'sm'} variant={'light'}
                    onClick={() => setSelectedLead(null)}
                    className={'rounded-lg p-1.5 cursor-pointer hover:bg-stone-100/30 hover:text-white dark:hover:bg-stone-900'}>
              <X size={18} />
            </Button>
          </div>
        </div>
        <div className={clsx(
          'grid transition-all overflow-auto',
          isMinimized && 'grid-rows-[0fr]',
          !isMinimized && 'grid-rows-[1fr]'
        )}>
          <div className={'h-full'}>
            <div className={''}>
              <Messages quickContact={true} hideActivity={true} />
            </div>
          </div>
        </div>
      </div>
      {(isExpanded && !isMinimized) && (
        <div
          className={clsx(
            isMinimized && 'h-[0px] max-h-[0VH]',
            isMaximized && 'h-[706px] max-h-[80VH]',
            !isMaximized && 'h-[706px] max-h-[80VH]',
            'shadow-2xl transition-all flex flex-1 bg-transparent rounded-tr-2xl overflow-hidden')}>
          <div className={'h-full overflow-auto'}>
            {(selectedLead?.id) && <LeadActivity  collapse={false} small={false} id_lead={selectedLead?.id} />}
          </div>
        </div>
      )}
    </div>
  )
    ;
}
