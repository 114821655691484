import { authenticationService } from './authentication.service';

export const fetchWrapper = {
    get,
    post,
    put,
    patch,
    delete: _delete
};


function addSpaceToUrl(url){
    let modifiedUrl = url;
    let default_id_space = localStorage.getItem('default_id_space');
    if(default_id_space){
        // if url already has a query string, append the id space to the end
        if(url.indexOf('?') > -1){
            // if url already has an id_space query string, replace it
            if(url.indexOf('id_space') > -1){
                modifiedUrl = url.replace(/id_space=[^&]*/, 'id_space=' + default_id_space);
            } else {
                modifiedUrl = url + '&id_space=' + default_id_space;
            }
        } else {
            modifiedUrl += '?id_space=' + default_id_space;
        }
    }
    return modifiedUrl;
}

async function get(url, isSWR) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    let modifiedUrl = addSpaceToUrl(url);

    return fetch(modifiedUrl, requestOptions).then(handleResponse).catch(err => {
        console.log(err)
        if(isSWR) {
            throw err;
        }
    });
}

async function post(url, body) {
    let authHead  = await authHeader();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHead },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    let modifiedUrl = addSpaceToUrl(url);

    return fetch(modifiedUrl, requestOptions).then(handleResponse);
}

async function put(url, body) {
    let authHead  = await authHeader();

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHead },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    let modifiedUrl = addSpaceToUrl(url);

    return fetch(modifiedUrl, requestOptions).then(handleResponse);
}

async function patch(url, body) {
    let authHead  = await authHeader();

    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', ...authHead },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    let modifiedUrl = addSpaceToUrl(url);

    return fetch(modifiedUrl, requestOptions).then(handleResponse);
}

async function _delete(url, body) {
    let authHead  = await authHeader();
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authHead },
        credentials: 'include',
        body: JSON.stringify(body)
    };

    let modifiedUrl = addSpaceToUrl(url);

    return fetch(modifiedUrl, requestOptions).then(handleResponse);
}

export function authHeader() {
    return authenticationService.user().then(token => {
        return { Authorization: `Bearer ${token}` };
    }).catch(err => {
        console.log(err)
        return {};
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && IsJsonString(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && authenticationService.user) {

            }

            const error = { code: response.status, message: data };
            return Promise.reject(error);
        }

        return data;
    });
}


function IsJsonString(str) {
    let response = str
    try {
        response = JSON.parse(str);
    } catch (e) {
        return str;
    }
    return response;
}
