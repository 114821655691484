const IconAddReminder = ({ size, fill, ...props }) => {

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size} height={size} {...props}>
      <path fill={'currentColor'}
        d='M 10 2 C 9.172 2 8.5 2.672 8.5 3.5 L 8.5 4.1972656 C 5.913 4.8642656 4 7.205 4 10 L 4 16 L 2.4628906 17.15625 L 2.46875 17.175781 C 2.19375 17.353781 2 17.648 2 18 C 2 18.552 2.448 19 3 19 L 10 19 L 10.068359 19 C 10.027359 18.672 10 18.339 10 18 C 10 14.274 12.551 11.151719 16 10.261719 L 16 10 C 16 7.205 14.087 4.8642656 11.5 4.1972656 L 11.5 3.5 C 11.5 2.672 10.828 2 10 2 z M 18 12 C 14.7 12 12 14.7 12 18 C 12 21.3 14.7 24 18 24 C 21.3 24 24 21.3 24 18 C 24 14.7 21.3 12 18 12 z M 18 14 C 18.552 14 19 14.448 19 15 L 19 17 L 21 17 C 21.552 17 22 17.448 22 18 C 22 18.552 21.552 19 21 19 L 19 19 L 19 21 C 19 21.552 18.552 22 18 22 C 17.448 22 17 21.552 17 21 L 17 19 L 15 19 C 14.448 19 14 18.552 14 18 C 14 17.448 14.448 17 15 17 L 17 17 L 17 15 C 17 14.448 17.448 14 18 14 z M 8 20 C 8 21.1 8.9 22 10 22 C 10.34 22 10.6565 21.906859 10.9375 21.755859 C 10.6455 21.206859 10.420719 20.616 10.261719 20 L 8 20 z' />
    </svg>
  );
};

export default IconAddReminder;
