import React, { useEffect, useState } from 'react';
import { useLead } from '@/utils/useLead';
import PostCardResponse from '@/components/social/postCard/response';
import { leadService } from '@/services/lead.service';
import { useLinkedin } from '@/utils/useLinkedin';
import style from './style.module.css';
import Loading from '@/components/ui/loading';
import Avatar from '@/components/ui/avatar';
import moment from 'moment';
import LikeIcon from '@/components/icons/social/like';
import CommentIcon from '@/components/icons/social/comment';
import { Link, MoreVertical, Send } from 'react-feather';
import useCustomToasts from '@/utils/useCustomToasts';
import Image from 'next/legacy/image';


import LikeLI from './icon/like.svg';
import LoveLI from './icon/love.svg';
import InterestLI from './icon/interest.svg';
import PraiseLI from './icon/praise.svg';
import SupportLI from './icon/support.svg';
import LinkedinLogo from '../../../public/static/linkedinFavicon.svg';


import RenderText, { RenderLinkedinText } from './text';
import RenderContent from './content';
import { getImageUrl } from '@/utils/helpers';
import { useUser } from '@/utils/useUser';
import dayjs from 'dayjs';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Tooltip } from '@nextui-org/react';

const reactions = [
  'LIKE',
  'INTEREST',
  'PRAISE',
  'EMPATHY',
  'APPRECIATION',
  'ENTERTAINMENT'
];

export default function LinkedinPostCardV2({
                                             isLead,
                                             small,
                                             isCompany,
                                             isReshare,
                                             data,
                                             metadata,
                                             noMaxWidth,
                                             onSelectLead
                                           }) {

  const { openLead, uniqueLead } = useLead();
  const { setToast } = useCustomToasts();
  const { selectedAccount: selectedAccountLinkedin } = useLinkedin();
  const { userDetails } = useUser();
  const [postData, setPostData] = useState(data);
  const [commentLoading, setCommentLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const commentInteraction = postData?.interactions?.find(i => i?.type === 'COMMENT');

  const lead = data?.lead ?? uniqueLead;


  useEffect(() => {
    setPostData(data);
  }, [data]);


  function searchLink(post) {
    if (post?.post_data?.updateMetadata?.updateActions?.actions) {
      //search for the SHARE_VIA action
      const shareAction = post?.post_data?.updateMetadata?.updateActions?.actions.find(a => a?.actionType === 'SHARE_VIA');
      if (shareAction) {
        return shareAction?.url;
      }

    }
  }

  function handleMoreDropdown(key) {
    switch (key) {
      case 'copyId':
        navigator.clipboard.writeText(data.id);
        setToast({
          type: 'success',
          text: 'Copied to clipboard'
        });
        break;
      case 'link':
        let link = searchLink(postData?.post);
        if (link) {
          window.open(link, '_blank');
        }
    }
  }

  function canInteract() {
    if (!selectedAccountLinkedin) {
      setToast({
        text: 'Connect a Linkedin account to interact with this post'
      });
      return false;
    }
    return true;
  }

  function handleComment(comment) {
    if (!canInteract())
      return;
    setCommentLoading(true);
    const interaction = {
      type: 'COMMENT',
      id_post: postData?.id,
      id_linkedin_account: selectedAccountLinkedin?.id,
      linkedin_account: selectedAccountLinkedin,
      user: userDetails,
      created_at: new Date().toISOString(),
      id_space: postData?.id_space
    };
    setPostData(prevState => {
      const interactions = prevState?.interactions ? [...prevState?.interactions, interaction] : [interaction];
      return {
        ...prevState,
        is_replied: !prevState.is_replied,
        reply: comment,
        interactions: interactions
      };
    });

    return leadService.replyLinkedinPost({
      id_linkedin_account: selectedAccountLinkedin?.id,
      id_post: postData?.id,
      id_lead: lead?.id,
      reply: comment
    }).then(r => {

    }).catch(e => {
      setToast({
        text: e?.message ?? 'Something went wrong',
        type: 'error'
      });
      setPostData(prevState => {
        return {
          ...prevState,
          is_replied: !prevState.is_replied
        };
      });
    }).finally(() => {
      setCommentLoading(false);
    });
  }

  function handleLike() {
    setActionLoading(true);
    setPostData(prevState => {
      return {
        ...prevState,
        is_liked: !prevState.is_liked
      };
    });
    return leadService.likeLinkedinPost({
      id_linkedin_account: selectedAccountLinkedin?.id,
      id_post: postData?.id,
      id_lead: lead?.id
    }).then(r => {
      //update the post in the data
    }).catch(e => {
      setToast({
        text: e.message ?? 'Something went wrong',
        type: 'error'
      });
      setPostData(prevState => {
        return {
          ...prevState,
          is_liked: !prevState.is_liked
        };
      });
    }).finally(() => {
      setActionLoading(false);
    });
  }


  function getPostAvatar(metadata, forceLead) {
    if ((!metadata.header && !isReshare) || forceLead) {
      if (lead?.avatar_url) {
        return lead?.avatar_url;
      } else {
        return metadata?.actor?.image?.attributes[0]?.miniProfile?.picture?.['com.linkedin.common.VectorImage']?.rootUrl + metadata?.actor?.image?.attributes[0]?.miniProfile?.picture?.['com.linkedin.common.VectorImage']?.artifacts[0]?.fileIdentifyingUrlPathSegment;
      }
    }


    if (metadata?.actor?.image?.attributes[0]?.miniCompany) {
      if (metadata?.actor?.image?.attributes[0]?.miniCompany?.logo) {
        return getImageUrl(metadata?.actor?.image?.attributes[0]?.miniCompany?.logo?.['com.linkedin.common.VectorImage']);
      }
      return metadata?.actor?.image?.attributes[0]?.miniCompany?.picture?.['com.linkedin.common.VectorImage']?.rootUrl + metadata?.actor?.image?.attributes[0]?.miniCompany?.picture?.['com.linkedin.common.VectorImage']?.artifacts[0]?.fileIdentifyingUrlPathSegment;
    }
    return metadata?.actor?.image?.attributes[0]?.miniProfile?.picture?.['com.linkedin.common.VectorImage']?.rootUrl + metadata?.actor?.image?.attributes[0]?.miniProfile?.picture?.['com.linkedin.common.VectorImage']?.artifacts[0]?.fileIdentifyingUrlPathSegment;
  }

  return (
    <div className={`${style.wrapper} ${small && style.small} ${noMaxWidth && style.noMaxWidth}`}>
      <div className={style.post}>
        {metadata.header && (
          <div className={`${style.header} ${style.repost}`}>
            <div className={`${style.lead} ${style.repost}`}>
              <div className={style.avatar}>
                <Avatar size={small ? 37 : 30} text={lead?.first_name} src={getPostAvatar(metadata, true)} />
              </div>
              <div className={style.profile}>
                <div className={style.profileTop}>
                  <div onClick={() => openLead(lead?.id, 'update')} className={style.name}>
                    {lead?.first_name} {lead?.last_name}
                  </div>
                  <div className={style.text}>
                    {RenderLinkedinText({ ...metadata.header?.text, removeAttributes: true })}
                  </div>
                  <Tooltip css={{ zIndex: 999999 }} content={'Linkedin post'} color={'invert'}>
                    <div className={style.socialLogo}>
                      <LinkedinLogo className={'w-[16px] h-[16px]'}/>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={`${style.header} ${postData?.post?.metadata?.is_re_post && style.repost}`}>
          {(metadata.header || isReshare) && (
            <div className={`${style.lead}`}>
              <div className={style.avatar}>
                <Avatar size={(small) ? 37 : 40} text={lead?.first_name}
                        src={getPostAvatar(metadata)} />
              </div>
              <div className={style.profile}>
                <div className={style.profileTop}>
                  <a href={metadata?.actor?.navigationContext?.actionTarget} target={'_blank'} className={style.name}>
                    {metadata?.actor?.name?.text}
                  </a>
                </div>
                <div className={style.handle}>
                  <div className={style.description}>
                    {metadata?.actor?.description?.text}
                  </div>
                </div>
              </div>
            </div>
          )}
          {(!metadata.header && !isReshare) && (
            <div className={`${style.lead}`}>
              <div className={style.avatar}>
                <Avatar size={small ? 30 : 40} text={lead?.first_name} src={getPostAvatar(metadata)} />
              </div>
              <div className={style.profile}>
                <div className={style.profileTop}>
                  <div onClick={() => openLead(lead?.id, 'update')} className={style.name}>
                    {lead?.first_name} {lead?.last_name}
                  </div>
                  <div className={style.separator}>
                    <span>·</span>
                  </div>
                  <div className={style.postedAt}>
                    {moment(postData?.post?.created_at ?? postData?.created_at).fromNow()}
                  </div>
                </div>
                <div className={style.handle}>
                  <Tooltip css={{ zIndex: 999999 }} content={'Linkedin publication'} color={'invert'}>
                    <div className={style.socialLogo}>
                      <LinkedinLogo className={'w-[16px] h-[16px]'}/>
                    </div>
                  </Tooltip>
                  <div className={style.description}>
                    {metadata?.actor?.description?.text}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isReshare && (
            <div className={style.actions}>
              {(!small && !isLead) && (
                <Tooltip content={'Send private message'}>
                  <Button isIconOnly variant={'light'} size={'sm'} onClick={() => onSelectLead(lead)}>
                    <Send strokeWidth={1} size={22} />
                  </Button>
                </Tooltip>
              )}
              <Dropdown placement={'bottom-right'}>
                <DropdownTrigger>
                  <Button isIconOnly variant={'light'} size={'sm'}>
                    <MoreVertical size={small ? 18 : 22} />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu onAction={handleMoreDropdown} aria-label='More'>
                  <DropdownItem startContent={<Link strokeWidth={1} size={20} />}
                                key='link'>Open post in Linkedin</DropdownItem>
                  {process.env.NODE_ENV !== 'production' && (
                    <DropdownItem startContent={<Link strokeWidth={1} size={20} />}
                                  key='copyId'>Copy post Id</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
        {(metadata?.commentary?.text) && (
          <div className={style.body}>
            <RenderText small={small} data={metadata?.commentary?.text} />
          </div>
        )}
        {metadata?.resharedUpdate && (
          <div className={style.body}>
            <LinkedinPostCardV2 isLead={isLead}
                                isReshare={true}
                                small={small}
                                isCompany={isCompany}
                                data={postData?.post}
                                metadata={metadata?.resharedUpdate}
                                noMaxWidth={noMaxWidth}
                                onSelectLead={onSelectLead} />
          </div>
        )}
        <div className={style.content}>
          <RenderContent data={metadata?.content} />
        </div>
        <div className={style.footer}>
          <div className={style.stats}>
            <Tooltip className={'p-2'} content={<SocialTooltip metadata={metadata} reactions={reactions}/>} placement={'left'}>
              <div className={style.reactions}>
                {metadata.socialDetail?.totalSocialActivityCounts?.reactionTypeCounts?.map(reaction => {
                  if (!reactions.includes(reaction.reactionType)) {
                    return null;
                  }
                  return (
                    <div className={style.reaction}>
                      <div className={style.reactionIcon}>
                        {reaction.reactionType === 'LIKE' &&
                          <LikeLI />
                        }
                        {reaction.reactionType === 'PRAISE' &&
                          <PraiseLI/>
                        }
                        {reaction.reactionType === 'EMPATHY' &&
                          <LoveLI/>
                        }
                        {reaction.reactionType === 'INTEREST' &&
                          <InterestLI/>
                        }
                        {reaction.reactionType === 'ENTERTAINMENT' &&
                          <InterestLI/>
                        }
                        {reaction.reactionType === 'APPRECIATION' &&
                          <SupportLI/>
                        }
                      </div>
                    </div>
                  );
                })}
                {metadata.socialDetail?.totalSocialActivityCounts?.reactionTypeCounts?.length === 0 && (
                  <div className={style.reaction}>
                    <div className={style.reactionIcon}>
                      <LikeLI/>
                    </div>
                    <div className={style.reactionCount}>
                      0
                    </div>
                  </div>
                )}
                <div className={style.totalReactions}>
                  {metadata.socialDetail?.totalSocialActivityCounts?.numLikes ?? 0}
                </div>
              </div>
            </Tooltip>
            <div className={style.comments}>
              {metadata.socialDetail?.totalSocialActivityCounts?.numViews && (
                <div className={style.commentCount}>
                  {metadata.socialDetail?.totalSocialActivityCounts?.numViews ?? 0} view{metadata.socialDetail?.totalSocialActivityCounts?.numViews > 1 ? 's' : ''}
                </div>
              )}
              <div className={style.commentCount}>
                {metadata.socialDetail?.totalShares ?? 0} share{metadata.socialDetail?.totalShares > 1 ? 's' : ''}
              </div>
              <div className={style.commentCount}>
                {metadata.socialDetail?.totalSocialActivityCounts?.numComments ?? 0} comment{metadata.socialDetail?.totalSocialActivityCounts?.numComments > 1 ? 's' : ''}
              </div>
            </div>
          </div>
          {!isReshare && (
            <div className={`${style.actions}`}>
              <div onClick={handleLike} className={style.action}>
                {actionLoading ? (
                  <div className={style.actionLabel}>
                    <Loading type={'points'} size={'sm'} />
                  </div>
                ) : (
                  <Tooltip css={{ zIndex: 999999 }}
                           content={postData?.is_liked_at ? `Liked at ${dayjs(postData?.is_liked_at).format('DD MMM YY, hh:mm A')}` : 'Like'}>
                    <div onClick={handleLike} className={'flex'}>
                      <div className={style.actionIcon}>
                        <LikeIcon is_liked={postData?.is_liked} size={small ? 16 : 18} />
                      </div>
                      <div className={style.actionLabel}>
                        <span>{postData?.is_liked ? 'Like' : 'Like'}</span>
                      </div>
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className={style.action}>
                <div className={style.actionIcon}>
                  <CommentIcon isCommented={postData?.is_replied} size={small ? 16 : 18} />
                </div>
                <div className={style.actionLabel}>
                  <span>Comment</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isReshare && (
        <PostCardResponse interaction={commentInteraction} type='linkedin' small={small}
                          is_replied={postData?.is_replied} reply={postData?.reply}
                          is_replied_at={postData?.is_replied_at} reply_by={postData?.reply_by} loading={commentLoading}
                          submitText={'Reply'}
                          onSubmit={handleComment} account={selectedAccountLinkedin} />
      )}
    </div>

  );
}



function SocialTooltip({metadata, reactions}){


  return (
    <div className={'flex flex-col space-y-0.5'}>
      {metadata.socialDetail?.totalSocialActivityCounts?.reactionTypeCounts?.map(reaction => {
        if (!reactions.includes(reaction.reactionType)) {
          return null;
        }
        return (
          <div className={'flex'}>
            <div className={'flex mr-2 items-center justify-center'}>
              {reaction.reactionType === 'LIKE' &&
                <LikeLI className={'w-4 h-4'}  />
              }
              {reaction.reactionType === 'PRAISE' &&
                <PraiseLI className={'w-4 h-4'}/>
              }
              {reaction.reactionType === 'EMPATHY' &&
                <LoveLI className={'w-4 h-4'}/>
              }
              {reaction.reactionType === 'INTEREST' &&
                <InterestLI className={'w-4 h-4'}/>
              }
              {reaction.reactionType === 'ENTERTAINMENT' &&
                <InterestLI className={'w-4 h-4'}/>
              }
              {reaction.reactionType === 'APPRECIATION' &&
                <SupportLI className={'w-4 h-4'}/>
              }
            </div>
            <div className={'text-md font-semibold'}>
              {reaction.count}
            </div>
          </div>
        );
      })}
    </div>
  )
}


