import style from '@/styles/feed/accountPicker.module.css';
import { Avatar, Popover } from '@nextui-org/react';
import React, { useState } from 'react';
import { useTwitter } from '@/utils/useTwitter';
import { useLinkedin } from '@/utils/useLinkedin';
import {PopoverTrigger, PopoverContent} from "@nextui-org/react";


export default function AccountPicker({children, type}){
    const [isOpen, setIsOpen] = useState(false);

    const {selectedAccount, accounts: twitterAccounts, setSelectedAccount: setSelectedAccountTwitter} = useTwitter();
    const { selectedAccount: selectedAccountLinkedin, setSelectedAccount: setSelectedAccountLinkedin, accounts: linkedinAccounts } = useLinkedin();



    return (
      <div className={style.wrap}>
          <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
              <PopoverTrigger>
                  {children}
              </PopoverTrigger>
              <PopoverContent className={'p-0 overflow-hidden'}>
                  <div className={style.accounts}>
                      {type === 'twitter' && (
                        <div className={style.accountsWrap}>
                            {twitterAccounts && twitterAccounts?.map((account) => (
                              <div
                                key={account.id}
                                className={style.account}
                                onClick={() => {
                                    setSelectedAccountTwitter(account);
                                    setIsOpen(false);
                                }}
                              >
                                  <Avatar size={'sm'} src={account?.avatar_url} text={account?.name??'?'} />
                                  <div className={style.accountInfo}>
                                      <div className={style.accountFullName}>{account.name}</div>
                                      <div className={style.accountId}>@{account.username}</div>
                                  </div>
                              </div>
                            ))}
                        </div>
                      )}
                      {type === 'linkedin' && (
                        <div className={style.accountsWrap}>
                            {linkedinAccounts && linkedinAccounts?.map((account) => (
                              <div
                                key={account.id}
                                className={style.account}
                                onClick={() => {
                                    setSelectedAccountLinkedin(account);
                                    setIsOpen(false)
                                }}
                              >
                                  <Avatar size={'sm'} src={account?.avatar_url} text={account?.first_name??'?'} />
                                  <div className={style.accountInfo}>
                                      <div className={style.accountFullName}>{account.full_name}</div>
                                      <div className={style.accountId}>#{account.linkedin_id}</div>
                                  </div>
                              </div>
                            ))}
                        </div>
                      )}

                  </div>
              </PopoverContent>
          </Popover>
      </div>
    )
}
