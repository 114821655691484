import style from '@/components/social/linkedin/style.module.css';
import { Play } from 'react-feather';
import { useState } from 'react';
import { getImageUrl } from '@/utils/helpers';

export default function PlayerVideo({video}){
    const [hideThumb, setHideThumb] = useState(false);


    return (
      <div className={style.playerVideo}>
          {hideThumb && (
            <video autoPlay={true} controls>
                <source src={video?.progressiveStreams?.[0]?.streamingLocations?.[0]?.url} type={video?.progressiveStreams?.[0]?.mediaType}/>
            </video>
          )}
          {!hideThumb && (
            <div className={style.thumb}>
                <img src={getImageUrl(video?.thumbnail)}/>
                <div className={style.playHoverlay}>
                    <div onClick={() => setHideThumb(true)} className={style.playIcon}>
                        <Play fill={'white'} size={30}/>
                    </div>
                </div>
            </div>
          )}
      </div>
    )
}
