import { useLead } from '@/utils/useLead';
import style from '@/styles/leadLayer.module.css';
import { ExternalLink, ArrowRight} from 'react-feather';
import React, { Fragment, useMemo, useState } from 'react';
import LeadImport from '@/components/layer/leadLayerImport';
import LeadProfile from '@/components/layer/leadProfile';
import useCustomToasts from '@/utils/useCustomToasts';
import Loading from '@/components/ui/loading';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  Link,
  ModalFooter,
  ModalHeader,
  Spacer
} from '@nextui-org/react';
import { useLinkedin } from '@/utils/useLinkedin';
import Message from '@/components/message';
import NextLink from "next/link";


export default function LeadLayer() {
  const [loading, setLoading] = useState(false);
  const [remove_tracking, setRemove_Tracking] = useState(false);
  const [confirm, setConfirm] = useState('');
  const { accounts: linkedinAccounts } = useLinkedin();

  //HOOKS
  const {
    closeLead,
    leadIsOpen,
    type,
    selectedLeads,
    isOpen,
    selectedList,
    deleteLeads,
    allSelectedLeads,
    bulkTrack
  } = useLead();
  const { setToast } = useCustomToasts();
  const hasAuthenticatedLinkedInAccount = useMemo(() => {
    const account = linkedinAccounts?.find((a) =>
      a?.auth_method === 'EXTENSION'
        ? a?.extension_status === 'AUTH_SUCCESS'
        : a?.new_auth_status === 'AUTH_SUCCESS'
    );
    return Boolean(account);
  }, [linkedinAccounts]);

  if (!leadIsOpen) return null;

  async function handleBulkTracking() {
    if(!hasAuthenticatedLinkedInAccount){
      return setToast({
        text: 'You need to have a LinkedIn account connected and synchronized to track leads',
        type: 'error'
      });
    }
    setLoading(true);

    let findLeadsEmail = await bulkTrack(remove_tracking);

    if (findLeadsEmail) {
      setToast({
        text: remove_tracking ? 'The tracking will be removed shortly' : 'The tracking will be added shortly',
        type: 'success'
      });
    }
    closeLead();
    setRemove_Tracking(false);
    setLoading(false);
  }

  async function handleDeleteLeads() {
    if ((allSelectedLeads || selectedLeads?.length > 1) && confirm !== 'delete leads') {
      return setToast({
        text: 'Please enter the phrase "delete leads" to confirm deletion',
        type: 'error'
      });
    }
    setLoading(true);
    setConfirm('');

    let deletedLeads = await deleteLeads();

    if (deletedLeads) {
      setToast({
        text: 'Your leads has been deleted',
        type: 'success'
      });
    } else {
      setToast({
        text: 'There was an error deleting your leads',
        type: 'error'
      });
    }
    closeLead();
    setLoading(false);
  }

  function handleCloseModal() {
    closeLead();
    setConfirm('');
  }

  if (type === 'tracking') {
    return (
      <Modal isOpen={leadIsOpen} onOpenChange={closeLead}>
        <ModalContent>
          <ModalHeader>{remove_tracking ? 'Remove from tracking' : 'Track'} {allSelectedLeads ? 'all' : selectedLeads?.length} leads</ModalHeader>
          <ModalBody>
            <form onSubmit={handleBulkTracking}>
              {!hasAuthenticatedLinkedInAccount && (
                <>
                  <Message className={'text-left'} type={'error'} content={<span>You need to have a LinkedIn account connected and synchronized to track leads <Link as={NextLink} className={'text-sm text-danger-700 font-semibold'} size={'sm'} href={'/accounts/'}>Connect your LinkedIn <ArrowRight style={{marginLeft: 5}} size={16}/> </Link></span>} />
                  <Spacer y={4} />
                </>
              )}
              <span>Are you sure to {remove_tracking ? 'remove from tracking' : 'track'} {allSelectedLeads ? 'All' : selectedLeads?.length} leads?</span>
              <Spacer y={4} />
              <Checkbox size='sm' isSelected={remove_tracking} onValueChange={setRemove_Tracking}>Remove from
                tracking
              </Checkbox>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeLead}>Cancel</Button>
            <Button color='primary' isLoading={loading} variant={'solid'} isDisabled={(!selectedLeads || !hasAuthenticatedLinkedInAccount)}
                    onClick={handleBulkTracking}>{remove_tracking ? 'Remove' : 'Track'} leads</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (type === 'delete') {
    return (
      <Modal
        isOpen={true}
        onOpenChange={closeLead}
      >
        <ModalContent>
          <ModalHeader justify={'flex-start'}>
            Delete {allSelectedLeads ? 'all your' : selectedLeads?.length} lead{(allSelectedLeads || selectedLeads?.length > 1) ? 's' : ''} {selectedList ? 'from the list' : ''} {selectedList ? selectedList?.name : ''}
          </ModalHeader>
          <ModalBody>
            <span>
              Are you sure you want to delete <b>{allSelectedLeads ? 'all your' : selectedLeads?.length} lead{(allSelectedLeads || selectedLeads?.length > 1) ? 's' : ''}</b> {selectedList ? 'from the list' : ''}
              <b>{selectedList ? selectedList?.name : ''}</b> permanently? <b className={'text-error-500'}>You can't undo this action</b>
            <br />
              {(allSelectedLeads || selectedLeads?.length > 1) && (
                <div className={style.wrapConfirm}>
                        <span className={'text-semibold'}>To confirm deletion, enter the phrase <b>delete leads</b></span>
                  <Input variant={'bordered'}
                         color='danger'
                         type='text' value={confirm} onChange={r => setConfirm(r.target.value)}
                         placeholder='delete leads' />
                </div>
              )}
            </span>
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={loading} variant={'light'} onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button onClick={handleDeleteLeads}
                    isLoading={loading}
                    isDisabled={(allSelectedLeads || selectedLeads?.length > 1) && confirm !== 'delete leads'}
                    color={'danger'}>Delete lead{(allSelectedLeads || selectedLeads?.length > 1) ? 's' : ''}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (type === 'import' && isOpen) {
    return <LeadImport />;
  }

  if (type === 'create')
    return <LeadProfile mode={'create'} />;

  if (type === 'preload')
    return null;

  return <LeadProfile mode={'update'} />;
}
