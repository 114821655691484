import clsx from 'clsx';


export default function Message({ type, content, className }) {

  if (!content) return null;

  return (
    <div className={clsx(
      'p-2 rounded-lg border-1 text-sm',
      {
        'success': 'bg-success-100 text-success-700 border-success-200',
        'error': 'bg-danger-50 text-danger-700 border-danger-200'
      }[type],
        className
    )}>
      <p>{content}</p>
    </div>
  );
}
