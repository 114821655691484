import getConfig from '../utils/getConfig';
import { fetchWrapper } from './fetch-wrapper';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/note`;

export const notesService = {
  fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
  fetchWrapperGetEmpty: url => fetchWrapper.get(`${apiUrl}${url}`),
  createNote,
  updateNote,
  deleteNote,
  getNote,
  getNotes
};


function createNote(data) {
  return fetchWrapper.post(`${baseUrl}s`, data);
}

function updateNote(id, data) {
  return fetchWrapper.put(`${baseUrl}s/${id}`, data);
}

function deleteNote(id) {
  return fetchWrapper.delete(`${baseUrl}s/${id}`);
}

function getNote(id) {
  return fetchWrapper.get(`${baseUrl}s/${id}`);
}

function getNotes() {
  return fetchWrapper.get(`${baseUrl}s`);
}
