import style from '@/styles/feed/feed.module.css';
import Loading from "@/components/ui/loading";
import { leadService } from '@/services/lead.service';
import React, { useState } from 'react';
import { Check, X } from 'react-feather';
import useCustomToasts from '@/utils/useCustomToasts';
import { useSpace } from '@/utils/useSpace';
import { parseLinkedinUsername } from '@/utils/validator';
import EmptyFeed from '@/components/feed/empty';


import { Button } from "@nextui-org/react";
import { Spacer } from "@nextui-org/react";


export default function StartTrackCard({ lead, mutateLead, refreshFeed }) {
    const [loading, setLoading] = useState(false);
    const { setToast } = useCustomToasts();

    const startTrack = async () => {
        if (loading) return;
        setLoading(true);

        return leadService.trackLead(lead.id).then(r => {
            mutateLead({ ...lead, ...r, is_tracked: true }, { rollbackOnError: false, revalidate: true });
            setToast({
                text: 'Lead is now being tracked, it can take up to 5 minutes to see the first posts in the feed.',
                type: 'success',
            });
        }).catch(e => {
            console.log('ERROR', e?.message);
            setToast({
                text: e?.message ?? 'An error occurred',
                type: 'error'
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    if(!lead){
        return null;
    }


    if (lead?.is_tracked && ((lead?.twitter_company_name && lead?.twitter_company_name.toLowerCase() !== lead?.twitter_company?.username?.toLowerCase()) || (lead?.twitter_name && lead?.twitter_name?.toLowerCase() !== lead?.twitter?.username?.toLowerCase()) || (lead?.linkedin_url && parseLinkedinUsername(lead?.linkedin_url?.toLowerCase()) !== lead?.linkedin?.linkedin_username?.toLowerCase())) && (lead?.id_linkedin && lead?.linkedin || lead?.id_twitter && lead?.twitter || lead?.id_twitter_company && lead?.twitter_company)) {
        return (
          <div className={'mb-8 bg-warning-50 p-4 rounded-xl border-1 border-warning-400 flex flex-col'}>
              <h4 className={'text-xl font-medium'}>You changed or added a new social account</h4>
              <p className={'text-sm font-normal'}>You need to resync the feed to take account of the changes.</p>
              <Spacer y={4} />
              <Button isLoading={loading} onClick={startTrack} rounded size={'sm'} color={'warning'}>Sync the feed</Button>
          </div>
        );
    }


    if(lead?.is_tracked)
        return null;

    return (
      <EmptyFeed refreshFeed={refreshFeed} />
    )
}
