import style from './statusCell.module.css';
import Loading from '@/components/ui/loading';
import useTheme from '@/utils/useTheme';
import { Check, Edit, MoreVertical, Plus, Trash } from 'react-feather';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import useCustomToasts from '@/utils/useCustomToasts';
import moment from 'moment';
import { useLead } from '@/utils/useLead';
import { TwitterPicker } from 'react-color';
import { statusService } from '@/services/status.service';
import { getContrastYIQ } from '@/utils/helpers';
import ContentLoader from 'react-content-loader';
import {
  Dropdown,
  DropdownItem,
    Button,
  DropdownMenu,
  DropdownTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@nextui-org/react';


export default function StatusCell({
                                     noLocalUpdate,
                                     showContentLoader,
                                     selectMode,
                                     fullHeight,
                                     id_lead,
                                     lead_status,
                                     status: propsStatus
                                   }) {
  const [addName, setAddName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const { id_space } = useSpace();
  const { setToast } = useCustomToasts();
  const [loading, setLoading] = useState(false);
  const {
    updateLeads,
    updateLead,
    leads,
    setSelectedTag,
    selectedTag,
    updateManyLeads,
    selectedLeads,
    allSelectedLeads
  } = useLead();

  const dataKey = useMemo(() => id_space ? `status` : null, [id_space]);
  const {
    data,
    mutate: mutateStatus
  } = useSWR(dataKey, statusService.fetchWrapperGetEmpty);

  useEffect(() => {
    if (selectMode)
      setStatus(selectedTag);
    else
      setStatus(propsStatus);
  }, [propsStatus, selectedTag, lead_status]);

  const orderByOrder = useCallback((data) => {
    //sort data by createAt asc
    return data.sort((a, b) => {
      return new Date(a?.order) - new Date(b?.order);
    });
  }, []);

  const handleAddStatus = useCallback((e) => {
    if (e)
      e.preventDefault();
    setLoading(true);
    let toAdd = { id: 'new', name: addName, color: '#000000', created_at: moment().toISOString() };
    // let newTags = [...data, data];
    const options = { rollbackOnError: true, revalidate: false };
    return mutateStatus(addStatus(toAdd, data), options);
  }, [addName, data, mutateStatus]);

  const handleUpdateStatus = useCallback((toUpdate) => {
    let newTags = [...data];
    //search status in new data to update
    let toUpdateIndex = newTags.findIndex(r => r?.id === data?.id);
    if (toUpdateIndex > -1) {
      newTags[toUpdateIndex] = toUpdate;
    }

    let newLeads = leads?.leads?.map(l => {
      if (l?.status?.[0]?.id === toUpdate?.id) {
        return { ...l, status: [{ ...l?.status?.[0], ...toUpdate }] };
      }
      return l;
    });
    updateLeads(newLeads);

    const options = { optimisticData: newTags, rollbackOnError: true };
    return mutateStatus(updateStatus(toUpdate, data), options);
  }, [data, leads?.leads, mutateStatus, updateLeads]);

  const handleDeleteStatus = useCallback((toDelete) => {
    let newTags = [...data];
    //search status in new data to update
    newTags = newTags.filter(r => r?.id !== toDelete?.id);

    const options = { optimisticData: newTags, rollbackOnError: true };

    let newLeads = leads?.leads?.map(l => {
      if (l?.status?.[0]?.id === toDelete?.id) {
        return { ...l, status: [] };
      }
      return l;
    });
    updateLeads(newLeads);
    return mutateStatus(deleteStatus(toDelete, data), options);
  }, [data, leads?.leads, mutateStatus, updateLeads]);

  const deleteStatus = useCallback(({ id }, data) => {
    return statusService.deleteStatus({
      id_space,
      id_status: id
    }).then(r => {
      return data.filter(t => t.id !== id);
    }).catch(e => {
      console.log(e);
      setToast({
        type: 'error',
        text: e?.message ?? 'Error deleting status'
      });
    });
  }, [id_space, setToast]);

  const addStatus = useCallback(({ name, id }, data) => {
    setAddName('');
    return statusService.createStatus({
      id_space: id_space,
      data: {
        name: name
      }
    }).then(r => {
      const filteredStatus = data.filter(status => status?.id !== id);
      return orderByOrder([...filteredStatus, r]);
    }).catch(err => {
      console.log(err);
      setToast({
        type: 'error',
        text: 'An error occurred while creating the status'
      });
    }).finally(() => setLoading(false));
  }, [id_space, orderByOrder, setToast]);

  const updateStatus = useCallback(({ id, name, color }, data) => {
    return statusService.updateStatus({ id: id, data: { name, color } }).then(r => {
      const filteredStatus = data.filter(status => status?.id !== id);

      return orderByOrder([...filteredStatus, r]);
    }).catch(err => {
      console.log(err);
      setToast({
        type: 'error',
        text: 'An error occurred while updating the status'
      });
    });
  }, [orderByOrder, setToast]);

  const handleUpdateLead = useCallback((status) => {
    let hasStatus = lead_status?.find(s => s?.id === status?.id);

    setIsOpen(false);
    if ((selectedLeads?.length > 0 && selectedLeads?.find(l => l === id_lead)) || allSelectedLeads) {
      return updateManyLeads({ status: [status] }, { statusDisconnect: !!hasStatus });
    }

    //check if status is already set
    if (lead_status?.[0]?.id === status?.id) {
      updateLead({ id: id_lead, status: [] });
      if (noLocalUpdate) {
        setStatus(null);
      }
      return;
    }

    if (noLocalUpdate) {
      setStatus(status);
    }
    return updateLead({ id: id_lead, status: [status] });
  }, [allSelectedLeads, id_lead, lead_status, selectedLeads, updateLead, updateManyLeads]);

  const handleSelect = useCallback((status) => {
    setStatus(status);
    setSelectedTag(status);
    setIsOpen(false);
  }, [setSelectedTag]);

  const handleUnselect = useCallback(() => {
    setSelectedTag(null);
    setStatus(null);
  }, [setSelectedTag]);

  const changeHandler = useCallback((next) => {
    setIsOpen(next);
  }, []);

  let color = '#000000';

  if (showContentLoader) {
    return <StatusCellLoader />;
  }
  return (
    <Popover showArrow={true} isOpen={isOpen} onOpenChange={changeHandler}>
      <PopoverTrigger>
        {status ? (
          <div style={{ background: status?.color ?? color }}
               className={`w-full ${style.statusTag} ${fullHeight && style.fullHeight} `}>
            <div style={{ color: getContrastYIQ(status?.color ?? color) }} className={style.statusText}>
              <span>{status?.name}</span>
            </div>
          </div>
        ) : (
          <div className={`${style.statusTag} ${style.emptyStatus} ${fullHeight && style.fullHeight}`}>
            <div className={style.statusIcon}>
              <Plus size={16} />
            </div>
            <div className={style.statusText}>
              <span>Select status</span>
            </div>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent className={'p-0'}>
        <div className={style.statusLists}>
          <div className={style.statusListHeader}>
            {selectMode ? 'Select' : 'Change'} lead status
          </div>
          {loading ? (
            <div className={style.loaderWrap}>
              <Loading type={'points'} size={'sm'} />
            </div>
          ) : (
            <>
              {data?.length === 0 && (
                <div className={`${style.statusList} ${style.empty}`}>
                  No data found
                </div>
              )}
              {data && data?.map(tag => <StatusList handleSelect={handleSelect} selectMode={selectMode}
                                                    selectedStatus={status} key={tag?.id}
                                                    handleUpdateLead={handleUpdateLead}
                                                    handleDelete={handleDeleteStatus}
                                                    handleUpdate={handleUpdateStatus} status={tag} />)}
              {selectMode && status && (
                <div onClick={handleUnselect} className={`${style.statusList} ${style.unselect}`}>
                  Unselect
                </div>
              )}
              {!selectMode && (
                <form onSubmit={handleAddStatus} className={`border-t-1 border-gray ${style.statusList} ${style.addStatus}`}>
                  <div className={style.inputAction}>
                    <input maxLength={15} value={addName} onChange={r => setAddName(r.target.value)}
                           placeholder={'Status name'} />
                  </div>
                  <Button isIconOnly={true} size={'sm'} className={'h-7'} variant={'light'} onClick={handleAddStatus}>
                    <Plus size={16} />
                  </Button>
                </form>
              )}
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function StatusList({
                      selectMode,
                      handleSelect,
                      selectedStatus,
                      status,
                      handleUpdateLead,
                      handleUpdate,
                      handleDelete,
                      ...props
                    }) {
  const [editName, setEditName] = useState(status.name ?? '');
  const [editColor, setEditColor] = useState(status.color ?? '#000000');
  const [editMode, setEditMode] = useState(false);

  function handleUpdateStatus(e) {
    if (e)
      e.preventDefault();

    handleUpdate({ name: editName, color: editColor, id: status?.id }).then(() => {
      setEditMode(false);
    });

    setEditMode(false);
  }


  function handleDeleteStatus(e) {
    if (e) e.preventDefault();
    if (e?.stopPropagation)
      e.stopPropagation();

    handleDelete({ id: status?.id });
  }

  function toggleEditMode(e) {
    if (e)
      e.preventDefault();
    if (e?.stopPropagation)
      e.stopPropagation();

    if (editMode && (editName !== status.name || editColor !== status.color)) {
      handleUpdateStatus();
    }
    setEditMode(!editMode);
  }

  function handleColorChange(color) {
    setEditColor(color.hex);
  }

  function handleAddTag() {
    if (selectMode) {
      handleSelect(status);
      return;
    }
    if (!editMode)
      handleUpdateLead(status);
  }

  function handleOnActionDropdown(event){
    if(event === 'edit'){
      toggleEditMode();
    }
    if(event === 'delete'){
      handleDeleteStatus();
    }
  }

  return (
    <div onClick={handleAddTag} className={style.statusList}>
      {editMode ? (
        <form onSubmit={toggleEditMode} className={style.statusEditWrap}>
          <div className={style.inputAction}>
            <input autoFocus maxLength={15} value={editName} onChange={r => setEditName(r.target.value)}
                   placeholder={'Status name'} />
          </div>
          <Popover>
            <PopoverTrigger>
              <div style={{ background: editColor }} className={style.inputActionColor} />
            </PopoverTrigger>
            <PopoverContent className={'p-0'}>
              <TwitterPicker onChange={handleColorChange} />
            </PopoverContent>
          </Popover>
        </form>
      ) : (
        <div style={{ background: editColor }} className={`${style.statusTag}`}>
          {selectedStatus?.id === status?.id &&
            <Check style={{ marginRight: 5, color: getContrastYIQ(editColor) }} size={13} />}
          <div style={{ color: getContrastYIQ(editColor) }} className={style.statusText}>
            <span>{editName}</span>
          </div>
        </div>
      )}
      {editMode && (
        <Button className={'h-7'} onClick={toggleEditMode} size={'sm'} isIconOnly={true} variant={'light'}>
          <Check size={16} />
        </Button>
      )}
      {(!editMode && !selectMode) && (
        <Dropdown
        showArrow
        radius='sm'
        placement={'right'}
        classNames={{
          base: 'p-0 border-small border-divider bg-background',
          arrow: 'bg-default-200'
        }}
      >
        <DropdownTrigger>
          <Button className={'h-7'} size={'sm'} isIconOnly={true} variant={'light'}>
              <MoreVertical size={16} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label='Choose a space'
          className='p-2'
          onAction={handleOnActionDropdown}
        >
            <DropdownItem
              key={'edit'}
              className='gap-2 text-black dark:text-white'
              startContent={<Edit size={18}/>}
            >
                Edit
            </DropdownItem>
            <DropdownItem
              key={'delete'}
              className='gap-2 text-black dark:text-white'
              startContent={<Trash size={18}/>}
              color={'danger'}
            >
                Delete
            </DropdownItem>
        </DropdownMenu>
      </Dropdown>
        )}
    </div>
  );
}

function StatusCellLoader() {
  const { isDark } = useTheme();

  return (
    <div className={style.statusCellLoader}>
      <ContentLoader
        speed={2}
        width={140}
        height={22}
        viewBox='0 0 140 22'
        backgroundColor={isDark ? '#171717' : '#e9ecef'}
        foregroundColor={isDark ? '#343333' : '#c7c7c7'}>
        <rect x='0' y='0' rx='12' ry='12' width='70' height='22' />
        <rect x='74' y='0' rx='12' ry='12' width='22' height='22' />
      </ContentLoader>
    </div>
  );
}
