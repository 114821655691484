import style from '@/styles/feed/textLimit.module.css';
import { useEffect, useState } from 'react';



export function socialMessageIsValid(socialType, message){
    if(socialType === 'facebook'){
        return message.length <= 500;
    }
    if(socialType === 'twitter'){
        return message.length <= 280;
    }
    if(socialType === 'linkedin'){
        return message.length <= 1900;
    }
    return false;
}
export function TextLimitWrap({socialType, text, children}){


    return (
      <div className={style.wrapLine}>
          <div className={style.wrapLineText}>
              <TextLimit socialType={socialType} text={text}/>
          </div>
          <div className={style.wrapLineButton}>
                {children}
          </div>
      </div>
    )
}


export function TextLimit({socialType, text}){
    const [maxTextLength, setMaxTextLength] = useState(0);



    useEffect(() => {
        if(socialType === 'twitter'){
            setMaxTextLength(280);
        } if(socialType === 'twitter_private_message'){
            setMaxTextLength(10000);
        } else if(socialType === 'facebook'){
            setMaxTextLength(5000);
        } else if (socialType === 'linkedin'){
            setMaxTextLength(1900);
        }
    }, [socialType]);


    return (
      <div className={`${style.wrap} ${text?.length > maxTextLength && style.invalid}`}>
          <div className={style.textLengthWrap}>
              {text?.length}
          </div>
          <div className={style.textSeparator}>
              /
          </div>
          <div className={style.textMaxLenghtWrap}>
              {maxTextLength}
          </div>
      </div>
    )
}
