import { useLead } from '@/utils/useLead';
import React, { Fragment, useEffect, useState } from 'react';
import style from '../../styles/listLayer.module.css';
import { leadService } from '@/services/lead.service';
import { useSpace } from '@/utils/useSpace';
import { useRouter } from 'next/router';
import useCustomToasts from '@/utils/useCustomToasts';
import { listService } from '@/services/list.service';
import useSWR from 'swr';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spacer
} from '@nextui-org/react';
import Inform from '@/components/ui/inform';
import Selector from '@/components/ui/Select/select';
import EmojiPicker from '@/components/feed/emojiPicker';
import { typeInTextarea } from '@/utils/helpers';
import Emoji from '@/components/emoji';
import { DEFAULT_LIST_EMOJI } from '../../config/app.constant';


export default function ListLayer() {
  const {
    updateLists,
    mutateLeads,
    deleteLists,
    createLists,
    addLeadsToList,
    allSelectedLeads,
    removeLeadsToList,
    list,
    closeList,
    lists,
    listIsOpen,
    listType: type,
    selectedLeads,
    selectedList,
    setSelectedList
  } = useLead();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [chosenList, setChosenList] = useState(null);
  const { id_space } = useSpace();
  const { setToast } = useCustomToasts();
  const [deleteLeadInList, setDeleteLeadInList] = useState(false);
  const router = useRouter();
  const { id } = router.query;
  const [emoji, setEmoji] = useState(DEFAULT_LIST_EMOJI);

  //Get leads in a list
  const {
    data: allLists,
    mutate: mutateLists
  } = useSWR(listIsOpen && (type === 'add' || type === 'connect') ? `/lists` : null
    , { revalidateOnFocus: false });


  const LISTS_FORMAT = allLists?.map(l => ({name: l.name, id: l.id}));

  useEffect(() => {
    if (listIsOpen && list && type === 'update') {
      setName(list?.name);
      setEmoji(list?.emoji);
    } else if (listIsOpen && type === 'create') {
      setName('');
      setEmoji(DEFAULT_LIST_EMOJI);
    }

  }, [list, type]);


  if (!listIsOpen) return null;

  function handleListCreate(e) {
    if (e)
      e.preventDefault();
    setLoading(true);
    listService.createList(id_space, { name, emoji: emoji }).then(r => {
      createLists([r]);
      setSelectedList(r);
      setToast({
        text: 'Your list has been created',
        type: 'success'
      });

    }).catch(e => {
      setToast({
        text: 'There was an error creating your list',
        type: 'error'
      });
    }).finally(() => {
      setName('');
      setEmoji(DEFAULT_LIST_EMOJI)
      closeList();
      setLoading(false);
    });
  }

  function handleUpdate(e) {
    if (e)
      e.preventDefault();
    setLoading(true);
    listService.updateLeadList(list.id, { name, emoji: emoji }).then(r => {
      updateLists(r, true);
      setToast({
        text: 'Your list has been updated',
        type: 'success'
      });
    }).catch(e => {
      setToast({
        text: 'There was an error updating your list',
        type: 'error'
      });
    }).finally(() => {
      setName('');
      setEmoji(DEFAULT_LIST_EMOJI)
      closeList();
      setLoading(false);
    });
  }

  async function handleAddLeadToList(e) {
    if (e)
      e.preventDefault();
    setLoading(true);

    let updatedList = await addLeadsToList(chosenList);

    if (updatedList) {
      setToast({
        text: 'Your leads has been added to the list',
        type: 'success'
      });
    } else {
      setToast({
        text: 'There was an error adding your leads to the list',
        type: 'error'
      });
    }
    closeList();
    setLoading(false);
  }

  async function handleRemoveLeadToList(e) {
    if (e)
      e.preventDefault();
    setLoading(true);

    let removedLeads = await removeLeadsToList();

    if (removedLeads) {
      setToast({
        text: 'Your leads has been removed from the list',
        type: 'success'
      });
    } else {
      setToast({
        text: 'There was an error removing your leads from the list',
        type: 'error'
      });
    }
    closeList();
    setLoading(false);
  }

  function handleDeleteList(e) {
    if (e?.preventDefault)
      e.preventDefault();

    setLoading(true);
    listService.deleteLeadList(id_space, list.id, deleteLeadInList).then(r => {
      deleteLists([list.id]);
      setSelectedList(null);
      setToast({
        text: 'Your list has been deleted',
        type: 'success'
      });
    }).catch(e => {
      setToast({
        text: 'There was an error deleting your list',
        type: 'error'
      });
    }).finally(() => {
      closeList();
      setDeleteLeadInList(false);
      setLoading(false);
    });
  }

  function handleConnectToCampaign(e) {
    if (e)
      e.preventDefault();
    setLoading(true);
    leadService.connectListToCampaign(id, chosenList).then(r => {
      updateLists(r, true);
      setSelectedList(r);
      // getLists()
      setToast({
        text: 'Your list has been connected to the campaign',
        type: 'success'
      });
    }).catch(e => {
      setToast({
        text: 'There was an error connecting your list to the campaign',
        type: 'error'
      });
    }).finally(() => {
      closeList();
      setLoading(false);
    });
  }

  function handleDisconnectToCampaign(e) {
    if (e)
      e.preventDefault();
    setLoading(true);
    leadService.disconnectListToCampaign(id, list.id).then(r => {
      let tmpLists = lists;
      tmpLists.splice(tmpLists.indexOf(list), 1);
      setSelectedList(null);
      setToast({
        text: 'Your list has been disconnected from the campaign',
        type: 'success'
      });
      mutateLeads();
    }).catch(e => {
      console.log(e);
      setToast({
        text: 'There was an error disconnecting your list from the campaign',
        type: 'error'
      });
    }).finally(() => {
      closeList();
      setLoading(false);
    });
  }


  if (type === 'add') {
    return (
      <Modal isOpen={listIsOpen} onOpenChange={closeList}>
        <ModalContent>
          <ModalHeader>Add {allSelectedLeads ? 'all' : selectedLeads?.length} leads to a list</ModalHeader>
          <ModalBody>
            <form onSubmit={handleAddLeadToList}>
              <Selector value={chosenList} onChange={(r) => setChosenList(r[0])} showAvatar={false} unit={'List'} multiple={false} items={LISTS_FORMAT}
                        emptyLabel={'Select List'}/>
              {/*<Select width={'100%'} placeholder='Choose one' onChange={(r) => setChosenList(r)}>*/}
              {/*  {allLists?.map(l => (*/}
              {/*    <Select.Option key={l.id} value={l.id}>{l.name}</Select.Option>*/}
              {/*  ))}*/}
              {/*</Select>*/}
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeList}>Cancel</Button>
            <Button color='primary' isLoading={loading || !allLists} variant={'solid'} isDisabled={!chosenList}
                    onClick={handleAddLeadToList}>Add to the list</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (type === 'connect') {
    return (
      <Modal isOpen={listIsOpen} onOpenChange={closeList}>
        <ModalContent>
          <ModalHeader>
            <div className={'flex flex-col'}>
              <span className={'text-lg font-semibold'}>Connect a list to the campaign</span>
              <span className={'text-sm font-normal text-black/80 dark:text-white/80'}>Chose a list to connect on your campaign, leads added to this list will be automatically added to the campaign</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleConnectToCampaign}>
              <Selector value={chosenList} onChange={(r) => setChosenList(r[0])} showAvatar={false} unit={'List'} multiple={false} items={LISTS_FORMAT}
                        emptyLabel={'Select List'}/>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeList}>Cancel</Button>
            <Button color='primary' isLoading={loading || !allLists} variant={'solid'} isDisabled={!chosenList}
                    onClick={handleConnectToCampaign}>Connect to the campaign</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
  }

  if (type === 'disconnect') {
    return (
      <Modal isOpen={listIsOpen} onOpenChange={closeList}>
        <ModalContent>
          <ModalHeader>
            <div className={'flex flex-col'}>
              <span>Disconnect a list</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleDisconnectToCampaign}>
              <p>Are you sure you want to disconnect this list from the campaign?</p>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeList}>Cancel</Button>
            <Button color='primary' isLoading={loading} variant={'solid'} isDisabled={false}
                    onClick={handleDisconnectToCampaign}>Disconnect the list</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (type === 'delete') {
    return (
      <Modal isOpen={listIsOpen} onOpenChange={closeList}>
        <ModalContent>
          <ModalHeader>
            <div className={'flex flex-col'}>
              <span>Delete a list</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleDeleteList}>
              <div className={style.lists}>
                <div className={style.checkBox}>
                  <span>Are you sure to delete the list <b>{list ? list?.name : selectedList?.name}</b></span>
                </div>
                <div className={style.checkBox}>
                  <Checkbox  onValueChange={r => setDeleteLeadInList(r)} size='md' isSelected={deleteLeadInList}>Delete all leads in the list</Checkbox>
                </div>
                {deleteLeadInList && (
                  <Fragment>
                    <Spacer y={4} />
                    <Inform showIcon={true} isWarning={true} title={'Leads present in other list will also be deleted'} subtitle={'If you delete a lead in a list, it will be deleted in all other lists'} />
                  </Fragment>
                )}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeList}>Cancel</Button>
            <Button color='danger' isLoading={loading} variant={'solid'} isDisabled={(!selectedList && !list)}
                    onClick={handleDeleteList}>Delete the list</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (type === 'remove') {
    return (
      <Modal isOpen={listIsOpen} onOpenChange={closeList}>
        <ModalContent>
          <ModalHeader>
            <div className={'flex flex-col'}>
              <span>Remove {allSelectedLeads ? 'all' : selectedLeads?.length} leads</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleRemoveLeadToList}>
              <span>Are you sure to remove <b>{allSelectedLeads ? 'all' : selectedLeads?.length} leads</b> from the list <b>{selectedList?.name}</b></span>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeList}>Cancel</Button>
            <Button color='danger' isLoading={loading} variant={'solid'} isDisabled={(!selectedList)}
                    onClick={handleRemoveLeadToList}>Remove from the list</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (type === 'create') {
    return (
      <Modal isOpen={listIsOpen} onOpenChange={closeList}>
        <ModalContent>
          <ModalHeader>
            <div className={'flex flex-col'}>
              <span>Create a list</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <form className={'flex space-x-2 items-center leading-none'} onSubmit={handleListCreate}>
              <Emoji emoji={emoji} picker onChange={(r) => setEmoji(r?.id)} size={44} />
              <Input variant={'bordered'} label={'List name'} placeholder={'Sales to contact'} value={name} onChange={e => setName(e.target.value)} />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeList}>Cancel</Button>
            <Button color='primary' isLoading={loading} variant={'solid'} isDisabled={!name?.length > 0}
                    onClick={handleListCreate}>Create</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  if (type === 'update') {
    return (
      <Modal isOpen={listIsOpen} onOpenChange={closeList}>
        <ModalContent>
          <ModalHeader>
            <div className={'flex flex-col'}>
              <span>Rename list</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <form className={'flex items-center space-x-2'} onSubmit={handleUpdate}>
              <Emoji emoji={emoji} picker onChange={(r) => setEmoji(r?.id)} size={44} />
              <Input variant={'bordered'} label={'List name'} placeholder={'CEO YC 2020'} value={name} onChange={e => setName(e.target.value)} />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant={'light'} onClick={closeList}>Cancel</Button>
            <Button color='primary' isLoading={loading} variant={'solid'} isDisabled={!name?.length > 0}
                    onClick={handleUpdate}>Rename</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  return null;
}
