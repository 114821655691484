import style from '@/styles/pipeline/leadValue.module.css';
import { Input, Button, Chip } from '@nextui-org/react';
import { formatNumberCurrency } from '@/utils/helpers';
import { Check } from 'react-feather';
import React, { useEffect, useState } from 'react';
import { useLead } from '@/utils/useLead';
import useCustomToasts from '@/utils/useCustomToasts';
import {Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react";

export default function LeadValue({ lead, noLocalUpdate }) {
    const { updateLead } = useLead();
    const [leadValue, setLeadValue] = useState(0);
    const [leadValueIsOpen, setLeadValueIsOpen] = useState(false);
    const { setToast } = useCustomToasts();


    useEffect(() => {
        if(lead?.contract_value)
            setLeadValue(lead.contract_value)
    }, [lead])

    function handleSubmitLeadValue(e) {
        if (e?.preventDefault)
            e?.preventDefault();


        if (leadValue >= 0) {
            setLeadValueIsOpen(false);
            if(noLocalUpdate){
                setLeadValue(parseInt(leadValue));
            }
            return updateLead({ id: lead?.id, contract_value: parseInt(leadValue) });
        }
    }

    //handle lead value change useCallback
    const onLeaveValueChange = React.useCallback((value) => {
        //check if value is bigger than 0 and smaller than 9999999999
        if (value >= 0 && value <= 9999999999)
            setLeadValue(value);
        else{
            setToast({
                type: 'error',
                text: 'Lead value must be between 0 and 9,999,999,999'
            })
        }
    }, []);


    return (
      <div className={style.leadValueWrapper}>
          <Popover isOpen={leadValueIsOpen} onOpenChange={setLeadValueIsOpen}>
              <PopoverTrigger>
                      <Chip as={'button'} size={'sm'} color={'success'} classNames={{content: 'font-semibold'}} variant={'flat'}>
                          {formatNumberCurrency(leadValue??lead?.contract_value ?? 0)}
                      </Chip>
              </PopoverTrigger>
              <PopoverContent className={'p-0'}>
                  <form className={style.contractValueForm} onSubmit={handleSubmitLeadValue}>
                      <Input
                        autofocus
                        variant={'bordered'}
                        size='sm' name={'contract_value'} defaultValue={lead?.contract_value ?? 0} type={'number'}
                        contentRightStyling={false}
                        onChange={(e) => onLeaveValueChange(e.target.value)}
                        value={leadValue}
                        max={9999999999}
                        placeholder='Enter lead value...'
                        endContent={<Button variant={'light'} size={'sm'} isIconOnly onClick={handleSubmitLeadValue}>
                            <Check size={18} />
                        </Button>}
                      />
                  </form>
              </PopoverContent>
          </Popover>
      </div>
    );
}
