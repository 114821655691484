import style from '@/styles/leadForm/email.module.css';
import formStyle from '@/styles/leadForm/leadForm.module.css';
import { Tooltip } from '@nextui-org/react';
import { Calendar, Copy, Trash } from 'react-feather';
import { copyTextToClipboard } from '@/components/ui/leadForm/leadForm';
import { useEffect, useState } from 'react';
import useCustomToasts from '@/utils/useCustomToasts';
import dayjs from 'dayjs';

export default function DateInput({ label, value, id, onEvent, isCustom, deleteInput, onSchemaEvent }) {
    const { setToast } = useCustomToasts();
    const [inputValue, setInputValue] = useState(dayjs(value).format('YYYY-MM-DD') ?? '');
    const [labelValue, setLabelValue] = useState(label);

    function handleOnBlur(r) {
        if (onEvent && dayjs(r?.target?.value).format('YYYY-MM-DD') !== (dayjs(value).format('YYYY-MM-DD') ?? '')) {
            onEvent({ label: label, value: dayjs(r?.target?.value).toISOString(), id: id, type: 'date' });
        }
    }

    function handleOnBlurSchema(r) {
        if (onSchemaEvent && r?.target?.value !== (label ?? '')) {
            onSchemaEvent({ label: r?.target?.value, id: id, type: 'date', hidden: false });
        }
    }

    useEffect(() => {
        if (dayjs(value).format('YYYY-MM-DD') !== dayjs(inputValue).format('YYYY-MM-DD')) {
            setInputValue(dayjs(value).format('YYYY-MM-DD'));
        }
    }, [value]);


    return (
      <div className={style.wrap}>
          <div className={formStyle.inputWrap}>
              <div className={`${formStyle.inputHead} ${isCustom && formStyle.editable}`}>
                  <div className={formStyle.inputHeadIcon}>
                      <Calendar size={14} />
                  </div>
                  <div className={formStyle.inputHeadLabel}>
                      {isCustom ? (
                        <input onMouseLeave={handleOnBlurSchema} onBlur={handleOnBlurSchema}
                               className={formStyle.inputHeadLabelInput} value={labelValue}
                               onChange={r => setLabelValue(r.target?.value)} />
                      ) : label}
                  </div>
                  {isCustom && (
                    <div onClick={deleteInput} className={formStyle.inputHeadDelete}>
                        <Tooltip css={{ zIndex: 99999 }} content={'Delete field'}>
                            <div className={formStyle.inputHeadDelete}>
                                <Trash size={14} />
                            </div>
                        </Tooltip>
                    </div>
                  )}
              </div>
              <div className={formStyle.inputBody}>
                  <input type={'date'} onMouseLeave={handleOnBlur} onBlur={handleOnBlur}
                         onChange={r => {
                             setInputValue(r?.target?.value)
                         }} value={inputValue??''} placeholder={'Add a date'}
                         className={formStyle.input} />
                  <div className={formStyle.inputActions}>
                      <Tooltip css={{ zIndex: 999999 }} content={'Copy to clipboard'}>
                          <div onClick={() => copyTextToClipboard(inputValue, setToast)} className={formStyle.inputAction}>
                              <div className={formStyle.inputActionIcon}>
                                  <Copy size={14} />
                              </div>
                          </div>
                      </Tooltip>

                  </div>
              </div>
          </div>
      </div>
    );
}
