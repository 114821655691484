import { Decoration, DecorationSet } from 'prosemirror-view'
import { labelToId } from '@/utils/helpers';

export default function (doc, variables, isUser){
    const doubleCurly = /{{(.*?)}}/gi
    //make a regex to search for @words in the text
    const handleRegex = /@(\w+)/gi

    const decorations = []

    doc.descendants((node, position) => {
        if (!node.text) {
            return
        }

        Array.from(node.text.matchAll(isUser ? handleRegex : doubleCurly)).forEach(match => {
            const color = match[0]
            const index = match.index || 0
            const from = position + index
            const to = from + color.length
            //search for the variable in the variables array
            const variable = isUser ? variables?.find(variable => `@${variable?.handle??variable.email}` === color) : variables?.find(variable => `{{${variable?.id}}}` === color)
            let decoration = Decoration.inline(from, to, {
                class: `variable ${isUser && 'user'}`,
                ...(!isUser && variable &&  {'data-sexy-name': `{{${labelToId(variable.label)}}}`}),
                ...(isUser && variable &&  {'data-sexy-user': `${variable?.handle??variable.email}`})
            })
            decorations.push(decoration)
        });
    })

    return DecorationSet.create(doc, decorations)
}
