import style from '../style.module.css';
import Avatar from '@/components/ui/avatar';
import dayjs from 'dayjs';
import { CornerDownLeft, Edit, Trash } from 'react-feather';
import { useUser } from '@/utils/useUser';
import { useEffect } from 'react';
import { Button, Tooltip } from '@nextui-org/react';
import ActivityLine from '@/components/activity/lines/index';

export function renderNoteContent(note) {
  if (!note) return;
  //search in the content the mentions, can also be @CmNED6FVSlb3xQZinSAFmGN5Fml2
  const regex = /@[a-zA-Z0-9]+/g;
  let content = note?.content ?? '';

  const matches = content?.match(regex);
  if (matches) {
    matches.forEach((match) => {
      const handle = match.replace('@', '');
      const user = note?.users?.find((item) => item.user.handle === handle || item.user.id === handle)?.user;
      if (user) {
        content = content.replace(match, `<a href="#">${match}</a>`);
      }
    });
  }
  return content;
}

export default function LineNote({ data: note, onSelect, loading, small, onRender }) {
  const { user } = useUser();
  const data = note.data;
  const author = data?.users?.find((item) => item.is_author)?.user;
  const authorDisplayName = author?.handle || author?.full_name || author?.email || 'Breakcold';
  const parentAuthor = data?.parent?.users?.find((item) => item.is_author)?.user;
  const parentAuthorDisplayName = parentAuthor?.handle || parentAuthor?.full_name || parentAuthor?.email;


  useEffect(() => {
    if (onRender) {
      onRender();
    }
  }, []);


  return (
    <ActivityLine
      icon={(
        <Tooltip css={{ zIndex: 9999999 }}
                 content={author ? (author?.full_name || author?.email) : 'Breakcold notifications'}>
          <div className={style.authorImg}>
            <Avatar squared={true} size={small ? 24 : 30} isBreakcold={!author} src={author?.avatar_url}
                    text={authorDisplayName} />
          </div>
        </Tooltip>
      )}
      id={data?.id}
      isOpen={true}
      title={authorDisplayName}
      date={data?.created_at}
      topContent={data?.parent && (
        <a href={`#${data?.parent?.id}`}
           className={'hover:bg-stone-100 dark:hover:bg-stone-800 flex flex-row py-1 px-2 items-center transition-all duration-100 overflow-hidden'}>
          <div className={'w-[40px] flex items-center justify-center pt-[4px] text-black/60 dark:text-white/60'}>
            <svg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M1 13V11C1 5.47715 5.47715 1 11 1H20' stroke='currentColor' strokeWidth='2'
                    strokeLinecap='round' />
            </svg>
          </div>
          <div className={'font-semibold text-xs text-black/60 dark:text-white/60 items-center flex mr-2'}>
            <Avatar squared={false} size={small ? 14 : 18} src={parentAuthor?.avatar_url}
                    text={parentAuthorDisplayName} />
            <div className={'ml-2'}>
              @{parentAuthorDisplayName}
            </div>
          </div>
          <div className={'text-xs text-black/60 dark:text-white/60 line-clamp-1 whitespace-pre-wrap break-all'}>
            <span dangerouslySetInnerHTML={{ __html: renderNoteContent(data?.parent) }} />
          </div>
        </a>
      )}
      actions={(
        <>
          {loading && (
            <Button isLoading={true} className={'h-6 w-6 min-w-6'} variant={'light'} isIconOnly size={'sm'}>

            </Button>
          )}
          {!small && !loading && (author?.id === user?.uid) && (
            <Tooltip css={{ zIndex: 9999999 }} content={'Edit'}>
              <Button className={'h-6 w-6 min-w-6'} onClick={() => onSelect({ ...note, action: 'edit' })}
                      variant={'light'} isIconOnly size={'sm'}>
                <Edit size={14} />
              </Button>
            </Tooltip>
          )}
          {!small && !loading && (!data?.id_parent) && (
            <Tooltip css={{ zIndex: 9999999 }} content={'Reply'}>
              <Button className={'h-6 w-6 min-w-6'} onClick={() => onSelect({ ...note, action: 'reply' })}
                      variant={'light'} isIconOnly size={'sm'}>
                <CornerDownLeft size={14} />
              </Button>
            </Tooltip>
          )}
          {!small && !loading && (author?.id === user?.uid || !author) && (
            <Tooltip css={{ zIndex: 9999999 }}
                     content={'Delete note'}>
              <Button className={'h-6 w-6 min-w-6'} onClick={() => onSelect({ ...note, action: 'delete' })}
                      variant={'light'} isIconOnly size={'sm'}>
                <Trash size={14} />
              </Button>
            </Tooltip>
          )}
        </>
      )}
    >
      <span className={style.renderContent} dangerouslySetInnerHTML={{ __html: renderNoteContent(data) }} />
      {data?.is_edited && (
        <div className={'text-xs mt-2 text-black/80 dark:text-white/80'}>(Edited
          at {dayjs(data?.is_edited_at).format('DD MMM YYYY hh:mm A')})</div>
      )}
    </ActivityLine>
  );
}
