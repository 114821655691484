import React, { createContext, useMemo, useContext, useEffect, useState } from 'react';
import { leadService } from '@/services/lead.service';
import { useRouter } from 'next/router';
import { useSpace } from '@/utils/useSpace';
import useSWR, { useSWRConfig } from 'swr';
import { listService } from '@/services/list.service';
import useCustomToasts from '@/utils/useCustomToasts';
import { filterObjToQuery } from '@/utils/filter';
import { spaceService } from '@/services/space.service';


export const MemberContext = createContext();

export const MemberContextProvider = (props) => {
    const {
        data: members
    } = useSWR(`/space/members`, spaceService.fetchWrapperGetEmpty, { revalidateOnFocus: true });

    const formatMembers = useMemo(() => {
        if (!members) return [];
        return members.map(m => ({ name: m?.handle || m?.full_name || m?.email, id: m.id, avatar: m?.avatar_url }));
    }, [members]);



    const value = {
        members,
        UIMembers: formatMembers,
    }
    return <MemberContext.Provider value={value} {...props} />;
};

export const useMember = () => {
    const context = useContext(MemberContext);
    if (context === undefined) {
        throw new Error(`useMember must be used within a MemberContextProvider.`);
    }
    return context;
};
