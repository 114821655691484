import toast, {Toaster as ToasterHot} from 'react-hot-toast';
import useTheme from '@/utils/useTheme';

export default function useCustomToasts(){

  function setToast({ type, text }){
    if(!text) return toast.error("An unmanaged error has occurred, please contact the support")

    if(type === "success") return toast.success(text)
    if(type === "error") return toast.error(text)
    if(type === "loading") return toast.loading(text)
    return toast(text)
  }

  return {
    setToast,
  }
}

export function Toaster(){
  const { isDark } = useTheme();

  return (
    <ToasterHot
      position="bottom-left"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{zIndex: 9999999}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          zIndex: 9999999,
          background: isDark ? '#18181b': '#fafafa',
          color: isDark ? '#FFF' : '#000',
          fontWeight: '500',
          fontSize: 14,
          borderRadius: '10px'
        },

        // Default options for specific types
        success: {
          style: {
            background: '#E8FAF0',
            border:  '2px solid #17C964',
            color: '#095028'
          },
        },
        error: {
          style: {
            background: '#fef2f2',
            border: '2px solid #ef4444',
            color: '#991b1b',
          }
        }
      }}
    />
  )
}


