import style from '@/styles/leadForm/leadForm.module.css';
import EmailInput from '@/components/ui/leadForm/email';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicator from '@/components/icons/dragIndicator';
import Loading from "@/components/ui/loading";
import { useEffect, useState } from 'react';
import StringInput from '@/components/ui/leadForm/string';
import DateInput from '@/components/ui/leadForm/date';
import HideInput from '@/components/ui/leadForm/hide';
import NewInput from '@/components/ui/leadForm/new';
import LinkedinInput from '@/components/ui/leadForm/linkedin';
import TwitterInput from '@/components/ui/leadForm/twitter';
import { useLead } from '@/utils/useLead';
import { useSpace } from '@/utils/useSpace';
import useCustomToasts from '@/utils/useCustomToasts';
import { spaceService } from '@/services/space.service';
import { Spacer } from "@nextui-org/react";
import { Tooltip } from "@nextui-org/react";

const formSchema = [
    {
        'type': 'email',
        'label': 'Email',
        'id': 'email',
        hidden: false
    },
    {
        'type': 'twitter_name',
        'label': 'twitter',
        'id': 'twitter_name',
        hidden: false
    },
    {
        'type': 'twitter_company_name',
        'label': 'twitter Company',
        'id': 'twitter_company_name',
        hidden: false
    },
    {
        'type': 'linkedin_url',
        'label': 'Linkedin',
        'id': 'linkedin_url',
        hidden: false
    },
    {
        'type': 'string',
        'label': 'Website URL',
        'id': 'website_url',
        hidden: false
    },
    {
        'type': 'string',
        'label': 'Company Name',
        'id': 'company',
        hidden: false
    },
    {
        'type': 'string',
        'label': 'Company Role',
        'id': 'company_role',
        hidden: false
    },
    {
        'type': 'string',
        'label': 'Phone',
        'id': 'phone',
        hidden: false
    },
    {
        'type': 'string',
        'label': 'City',
        'id': 'city',
        hidden: false
    }
];


export default function LeadForm({ onSave, mode, isLoading }) {
    const [schema, setSchema] = useState([]);
    const [inputSchema, setInputSchema] = useState(null);

    const { selectedList, updateLists } = useLead();
    const { id_space, space, setSpace } = useSpace();
    const { setToast } = useCustomToasts();
    const {
        updateLead,
        uniqueLead: lead,
    } = useLead();
    const uniqueFields = ['email', 'company', 'company_role', 'phone', 'city', 'website_url', 'twitter_name', 'twitter_company_name', 'linkedin_url'];

    function leadToInputSchema(lead) {
        const inputSchema = {};
        for (const key in lead) {
            if(key === 'custom_fields'){
                for (const customKey in lead.custom_fields) {
                    //if it's a unique field ignore it
                    if(uniqueFields.includes(customKey)){
                        continue;
                    }
                    inputSchema[customKey] = lead.custom_fields[customKey];
                }
            } else if (uniqueFields.includes(key)) {
                inputSchema[key] = {
                    value: lead[key],
                    ...inputSchema[key]
                };
            }
        }
        return inputSchema;
    }

    function addNewInputToSchema(schema, inputSchema){
        //check if schema is iterable
        if(!schema || schema?.length === 0){
            return [];
        }
        const newSchema = [...schema]
        //check if schema items are not using unique fields id
        schema.forEach((item) => {
            if(uniqueFields.includes(item.id) && item.isCustom){
                //item index
                let itemIndex = newSchema.findIndex((it) => it.id === item?.id);
                newSchema.splice(itemIndex, 1);
            }
        });

        //check if inputSchema have duplicate fields id, inputSchema is an array of objects
        let schemaIds = [];
        newSchema.forEach((item) => {
            if(schemaIds.includes(item.id)){
                //item index
                let itemIndex = newSchema.findIndex((it) => it.id === item?.id);
                newSchema.splice(itemIndex, 1);
            } else {
                schemaIds.push(item.id);
            }
        });

        ///iterate on the inputSchema
        for (const key in inputSchema) {
            let keyIs = inputSchema[key]?.id ?? key;
            if(!newSchema.find((item) => item.id === keyIs) && !uniqueFields.includes(keyIs)){
                newSchema.push({
                    type: inputSchema[key]?.type??'string',
                    label: inputSchema[key]?.label ? inputSchema[key]?.label : keyIs,
                    id: keyIs,
                    isCustom: true,
                    hidden: false
                })
            }
        }
        return newSchema;
    }

    function migrateSchema(schema, inputSchema) {
        //check if schema items are in the formSchema array if they not add them
        let newSchema;
        if (!schema || schema?.length === 0) {
            newSchema = formSchema;
        } else {
            return addNewInputToSchema(schema, inputSchema)
        }
        return addNewInputToSchema(newSchema, inputSchema);
    }

    function initSchema(formSchema, lead_custom_variables){
        if(!lead_custom_variables){
            return formSchema;
        }
        const newSchema = [...formSchema];
        for (const key in lead_custom_variables) {
            newSchema.push({
                type: lead_custom_variables[key]?.type??'string',
                label: key,
                id: key,
                isCustom: true,
                hidden: true
            })
        }
        return newSchema;
    }

    useEffect(() => {
        let tmpInputSchema;
        if (lead && Object.keys(lead).length > 0) {
            tmpInputSchema = leadToInputSchema(lead);
        } else {
            tmpInputSchema = {}
        }
        if (selectedList?.schema) {
            // console.log("SET SCHEMA FROM LIST", selectedList?.schema, tmpInputSchema);
            setSchema(migrateSchema(selectedList?.schema, tmpInputSchema));
        } else if (schema.length === 0) {
            if(space?.leads_schema){
                // console.log("SET SCHEMA FROM SPACE");
                setSchema(migrateSchema(space?.leads_schema, tmpInputSchema));
            } else {
                // console.log("SET SCHEMA FROM FORM SCHEMA");
                setSchema(initSchema(formSchema, lead?.custom_fields));
            }
        }
        //search in the inputSchema if some fields have the same id
        setInputSchema(tmpInputSchema)
    }, [lead]);

    function deleteInput(id) {
        const newSchema = schema.filter((input) => input.id !== id);
        updateSchema(null, newSchema);
        //remove from inputSchema
        let newInputSchema = {...inputSchema};
        delete newInputSchema[id];
        saveSchemaInput(newInputSchema);
        setInputSchema(newInputSchema);
        // return handleSaveInputSchema({id, remove: true, schema: newSchema});
    }

    function updateSchema(schemaRow, fullSchema) {
        let newSchema = fullSchema??schema.map((row) => {
            if (row.id === schemaRow?.id) {
                return schemaRow;
            }
            return row;
        });
        // console.log("SET SCHEMA FROM UPDATESCHEMA FCT");
        setSchema(newSchema);

        if(selectedList?.id)
            return updateLists({ id: selectedList?.id, schema: newSchema });

        setSpace({ ...space, leads_schema: newSchema });
        return spaceService.updateSpace(id_space, { leads_schema: newSchema }).catch((err) => {
            setToast({
                type: 'error',
                text: 'Error updating space'
            });
        });
    }

    function onDragEnd(result) {
        if (!result.destination)
            return;
        // dropped outside the list
        const items = reorder(
          schema,
          result.source.index,
          result?.destination?.index
        );
        //Update the SCHEMA in LIST
        // console.log("SET SCHEMA FROM ONDRAGEND FCT");
        setSchema(items);
        if(selectedList?.id)
            return updateLists({ id: selectedList?.id, schema: items });

        setSpace({ ...space, leads_schema: items });
        return spaceService.updateSpace(id_space, { leads_schema: items }).catch((err) => {
            setToast({
                type: 'error',
                text: 'Error updating space'
            });
        });
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function saveSchemaInput(inputSchema) {
        if (onSave) {
            //remove from inputSchema the uniqueFields
            const newInputSchema = {};

            for (const key in inputSchema) {
                if (!uniqueFields.includes(key)) {
                    newInputSchema[key] = inputSchema[key];
                }
            }

            onSave(null, {
                custom_fields: newInputSchema
            });
        }
    }


    function updateInput(id, value){
        if(onSave){
            return onSave(null, {
                [id]: value
            })
        }
    }

    function handleSaveInputSchema({ id, label, value, type, remove, schema }) {
        let copySchemaInput = inputSchema;
        if (inputSchema[id]) {
            if(remove){
                delete copySchemaInput[id];
            } else {
                copySchemaInput[id] = {
                    id,
                    type,
                    label,
                    value: value??''
                };
            }
        } else {
            copySchemaInput = {
                ...copySchemaInput,
                [id]: {
                    id,
                    type,
                    label,
                    value: value??''
                }
            };
        }
        if(uniqueFields.includes(id))
            updateInput(id, value);
        else {
            saveSchemaInput(copySchemaInput);
        }
        setInputSchema(copySchemaInput);
    }

    function handleSaveSchema(r) {
        let copySchema = schema;
        //search in the schema if the id exists
        const index = schema.findIndex((input) => input.id === r.id);
        if (index !== -1) {
            copySchema[index] = {
                ...copySchema[index],
                ...r
            };
        } else {
            copySchema = [...copySchema, r];
        }
        // console.log("SET SCHEMA FROM HANDLESAVESCHEMA FCT");
        setSchema(copySchema);
        //remove from inputSchema the uniqueFields

        if(selectedList?.id)
            return updateLists({ id: selectedList?.id, schema: copySchema });

        setSpace({ ...space, leads_schema: copySchema });
        return spaceService.updateSpace(id_space, { leads_schema: copySchema }).catch((err) => {
            setToast({
                type: 'error',
                text: 'Error updating space'
            });
        });
    }

    if(!lead && mode !== 'create'){
        return (
          <div className={style.wrap}>
              <Spacer />
              <Loading size={'sm'} type={'gradient'}/>
          </div>
        )
    }

    return (
      <div className={style.wrap}>
          <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={style.list}
                    >
                        {inputSchema && schema?.map(({ type, label, id, hidden, isCustom }, idx) => {
                            if (!hidden) {
                                return (
                                  <Draggable key={id} draggableId={id} index={idx}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={style.formInputWrap}
                                        >
                                            <Tooltip css={{ zIndex: 999999 }} content={'Drag to reorder'}>
                                                <div className={style.dragIndicator}>
                                                    <DragIndicator size={16} />
                                                </div>
                                            </Tooltip>
                                            {type === 'email' && (
                                              <EmailInput disabled={isLoading} value={inputSchema[id]?.value} id={id} lead={lead}
                                                          onSchemaEvent={handleSaveSchema} onEvent={handleSaveInputSchema}
                                                          label={label} />
                                            )}
                                            {type === 'string' && (
                                              <StringInput disabled={isLoading} value={inputSchema[id]?.value} onSchemaEvent={handleSaveSchema}
                                                           onEvent={handleSaveInputSchema}
                                                           deleteInput={() => deleteInput(id)} isCustom={isCustom} id={id}
                                                           label={label} />
                                            )}
                                            {type === 'date' && (
                                              <DateInput disabled={isLoading} value={inputSchema[id]?.value} onSchemaEvent={handleSaveSchema}
                                                         onEvent={handleSaveInputSchema} id={id}
                                                         deleteInput={() => deleteInput(id)} isCustom={isCustom}
                                                         label={label} />
                                            )}
                                            {type === 'linkedin_url' && (
                                              <LinkedinInput disabled={isLoading} lead={lead} value={inputSchema[id]?.value} linkedin={lead?.linkedin} onEvent={handleSaveInputSchema}
                                                             id={id} label={label} />
                                            )}
                                            {type === 'twitter_name' && (
                                              <TwitterInput disabled={isLoading} lead={lead} accountType={'personal'} twitter={lead?.twitter} value={inputSchema[id]?.value} onEvent={handleSaveInputSchema}
                                                            id={id} label={label} />
                                            )}
                                            {type === 'twitter_company_name' && (
                                              <TwitterInput disabled={isLoading} lead={lead} accountType={'company'} twitter={lead?.twitter_company} value={inputSchema[id]?.value} onEvent={handleSaveInputSchema}
                                                            id={id} label={label} />
                                            )}

                                        </div>
                                      )}
                                  </Draggable>
                                );
                            }
                        })}
                        {provided.placeholder}
                    </div>)}
              </Droppable>
          </DragDropContext>
          {mode !== 'create' && (
            <>
                <Spacer y={1} />
                <div className={style.formActions}>
                    <NewInput schema={schema} update={(schema) => updateSchema(null, schema)} />
                    <HideInput schema={schema} update={updateSchema} />
                </div>
            </>
          )}
      </div>
    );
}


export function copyTextToClipboard(str, setToast) {
    if (!str) return;

    navigator.clipboard.writeText(str);
    setToast({
        text: 'Copied to clipboard',
        type: 'success'
    });
}

