import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/space`;

export const spaceService = {
    deleteMember,
    getMembers,
    createSpace,
    revokeApiKey,
    updateSpace,
    checkDomainRecord,
    getCheckListConditions,
    resetTrackingDomain,
    createWebhookEndpoint,
    deleteWebhookEndpoint,
    updateWebhookEndpoint,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
    fetchWrapperGetEmpty: url => fetchWrapper.get(`${apiUrl}${url}`)
}



function getMembers(id_space){
    let payload = `id_space=${id_space}`;
    return fetchWrapper.get(`${baseUrl}/members?${payload}`);
}

function deleteMember(id_space, memberId){
    let payload = `id_space=${id_space}&memberId=${memberId}`;
    return fetchWrapper.delete(`${baseUrl}/member?${payload}`);
}

function createSpace(data){
    return fetchWrapper.post(`${baseUrl}`, data);
}

function updateSpace(id_space, data){

    return fetchWrapper.patch(`${baseUrl}/${id_space}`, data);
}


function resetTrackingDomain(id_space){
    return fetchWrapper.delete(`${baseUrl}/${id_space}/tracking-domain`);
}


function checkDomainRecord(id_space, domain){
    let payload = `domain=${domain}`;

    return fetchWrapper.post(`${baseUrl}/${id_space}/tracking-domain?${payload}`);
}


function getCheckListConditions(id_space) {
    const payload = `id_space=${id_space}`
    return fetchWrapper.get(`${baseUrl}/checklist?${payload}`);
}



function revokeApiKey(id_space, data){
    const payload = `id_space=${id_space}`

    return fetchWrapper.delete(`${baseUrl}/api-key?${payload}`, data);
}


function createWebhookEndpoint(id_space, data){
    const payload = `id_space=${id_space}`

    return fetchWrapper.post(`${baseUrl}/webhook?${payload}`, data);
}

function deleteWebhookEndpoint(id_space, id_webhook){
    const payload = `id_space=${id_space}`

    return fetchWrapper.delete(`${baseUrl}/webhook/${id_webhook}?${payload}`);
}

function updateWebhookEndpoint(id_space, id_webhook, data){
    const payload = `id_space=${id_space}`

    return fetchWrapper.patch(`${baseUrl}/webhook/${id_webhook}?${payload}`, data);
}
