import style from '@/styles/social/post.module.css';
import Loading from "@/components/ui/loading";
import { Linkedin, Twitter } from 'react-feather';
import React, { useState } from 'react';
import InputSocial from '@/components/feed/inputSocial';
import { useTwitter } from '@/utils/useTwitter';
import { useRouter } from 'next/router';
import { useLinkedin } from '@/utils/useLinkedin';
import { socialMessageIsValid, TextLimitWrap } from '@/components/feed/textLimit';
import AccountPicker from '@/components/feed/accountPicker';
import { getUserDisplayName } from '@/utils/helpers';
import dayjs from 'dayjs';

import { Button } from "@nextui-org/react";
import Avatar from '@/components/ui/avatar';
import { Tooltip } from "@nextui-org/react";

export default function PostCardResponse({interaction, reply, small, loading, submitText, onSubmit, account, is_replied, type}) {
  const [text, setText] = useState('');

  const {selectedAccount} = useTwitter();
  const { selectedAccount: selectedAccountLinkedin } = useLinkedin();
  const router = useRouter();



  if(!selectedAccount && type === 'twitter'){
    return (
      <div className={`${style.responseWrap}`}>
        <div onClick={() => router.push('/accounts/connect?provider=twitter&autoconnect=true&redirect=/feed')} className={style.connectCard}>
          <div className={style.action}>
            <Twitter fill strokeWidth={0} size={18}/>
          </div>
          <div className={style.text}>
            Connect your Twitter to interact
          </div>
        </div>
      </div>
    )
  }

  if(!selectedAccountLinkedin && type === 'linkedin'){
    return (
      <div className={`${style.responseWrap}`}>
        <div onClick={() => router.push('/accounts/connect?provider=linkedin&autoconnect=true&redirect=/feed')} className={`${style.connectCard} ${style.linkedin}`}>
          <div className={style.action}>
            <Linkedin fill strokeWidth={0} size={18}/>
          </div>
          <div className={style.text}>
            Connect your Linkedin to interact
          </div>
        </div>
      </div>
    )
  }


  if(is_replied){
    const account = type === 'twitter' ? interaction?.twitter_account : interaction?.linkedin_account;

    return (
      <div className={`${style.responseWrap} ${style.static} ${style.small}`}>
        <div className={style.top}>
          <Tooltip css={{zIndex:999999}} content={type === 'twitter' ? `Reply by @${account?.username}` : `Reply by ${account?.full_name}`}>
            <div className={`${style.responseAvatar} ${style.static}`}>
              <Avatar size={small ? 'sm' : 'md'} src={account?.avatar_url} text={account?.full_name??account?.name} />
            </div>
          </Tooltip>
          <div className={style.response}>
            <div className={style.renderAuthor}>
                {type === 'twitter' ? `@${account?.username}` : account?.full_name} <span className={style.date}>{dayjs(interaction?.created_at).format('DD MMM YYYY hh:mm A')}</span>
            </div>
            <div className={style.renderText}>
              {reply}
            </div>
          </div>
        </div>
        {interaction?.user && (
          <div className={style.bottom}>
            Sent by <b>{getUserDisplayName(interaction?.user)}</b>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={style.responseWrap}>
      <div className={style.top}>
        <AccountPicker type={type}>
          <div className={style.responseAvatar}>
            <Avatar size={small ? 'sm' : 'md'} src={account?.avatar_url} text={account?.first_name??'?'} />
          </div>
        </AccountPicker>
        <div className={style.response}>
          <InputSocial small={small} onChange={r => setText(r)} value={text} rounded/>
        </div>
      </div>
      {text?.length > 0 && (
        <div className={style.submit}>
          <TextLimitWrap text={text} socialType={type}>
            <Button color={'primary'} isLoading={loading} isDisabled={!socialMessageIsValid(type, text)} radius={'full'} size={'sm'} onClick={() => onSubmit(text)}>
              {submitText??"Post"}
            </Button>
          </TextLimitWrap>
        </div>
        )}
    </div>
  );
}
