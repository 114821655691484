import dynamic from 'next/dynamic';
import clsx from 'clsx';

const ErrorIcon = dynamic(() => import('react-feather/dist/icons/alert-triangle'), {
  ssr: false
});

const SuccessIcon = dynamic(() => import('react-feather/dist/icons/check-circle'), {
  ssr: false
});

const InfoIcon = dynamic(() => import('react-feather/dist/icons/info'), {
  ssr: false
});

const WarningIcon = dynamic(() => import('react-feather/dist/icons/alert-circle'), {
  ssr: false
});

export default function Inform({
                                 size = 'md',
                                 isWarning,
                                 isSuccess,
                                 isError,
                                 isInfo,
                                 showIcon,
                                 title,
                                 subtitle,
                                 children,
                                 fullWidth
                               }) {
  const iconSize = {
    sm: 18,
    md: 22,
    lg: 26
  }[size];

  return (
    <div className={clsx(
      'flex items-start justify-start p-2 rounded-xl border-1',
      fullWidth && 'w-full',
      isInfo && 'border-primary-100 bg-primary-50',
      isWarning && 'border-warning-100 bg-warning-50',
      isError && 'border-danger-100 bg-danger-50',
        isSuccess && 'border-success-100 bg-success-50'
    )}>
      {showIcon && (
        <div className={clsx(
          'mr-2 flex items-center justify-center rounded-full w-8 h-8',
          isInfo && 'text-primary-500',
          isWarning && 'text-warning-500',
          isError && 'text-danger-500',
          isSuccess && 'text-success-500'
        )}>
          {isWarning && <WarningIcon size={iconSize} />}
          {isInfo && <InfoIcon size={iconSize} />}
          {isError && <ErrorIcon size={iconSize} />}
          {isSuccess && <SuccessIcon size={iconSize} />}
        </div>
      )}
      <div className={'flex flex-col items-start w-full'}>
        {title && <span className={'text-md font-medium'}>{title}</span>}
        {subtitle && <span className={'text-sm text-black/80 dark:text-white/80'}>{subtitle}</span>}
        {children}
      </div>
    </div>
  );


}
