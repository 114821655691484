import style from '@/styles/leadForm/hide.module.css';
import { Dropdown, Button, DropdownItem, DropdownMenu, DropdownTrigger, Switch } from '@nextui-org/react';
import { Eye } from 'react-feather';

export default function HideInput({ schema, update }) {


  function updateSchema(item, r) {
    if (r && r?.stopPropagation) {
      r.stopPropagation();
    }
    update({ ...item, hidden: !item.hidden });
  }


  return (
    <div className={style.wrapInput}>
      <Dropdown classNames={{
        base: "overflow-y-auto max-h-[80VH] justify-start"
      }} placement={'top'}>
        <DropdownTrigger>
          <Button fullWidth variant={'light'} startContent={<Eye size={16} />} size='sm'>
            Visible fields
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
            {schema?.map((item, idx) => {
              return (
                <DropdownItem
                  isReadOnly
                  key={`schema_hide_${idx}`}
                  endContent={<Switch isSelected={!item?.hidden} onValueChange={(r) => updateSchema(item, r)}
                                      size='sm' />}
                  className='cursor-default'>
                  {item?.label}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
