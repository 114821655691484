import { Button, Tooltip, Avatar } from '@nextui-org/react';
import { Fragment, useEffect, useMemo } from 'react';
import { useLead } from '@/utils/useLead';
import { Globe, Mail, Phone, X } from 'react-feather';
import TagsCell from '@/components/ui/tagsCell';
import StatusCell from '@/components/ui/statusCell/statusCell';
import ReminderButton from '@/components/ui/reminderButton/reminderButton';
import Linkedin from '@/components/icons/Linkedin';
import Twitter from '@/components/icons/Twitter';
import LeadValue from '@/components/pipeline/leadValue';
import clsx from 'clsx';

export default function LeadCard({ lead: propsLead, onClose, emptyText, hideCross }) {
  const { selectedLead, setSelectedLead, leadIsOpen, openLead, selectedList, uniqueLead, listIsOpen } = useLead();
  const { tags } = uniqueLead ?? {};

  const lead = useMemo(() => propsLead ?? selectedLead, [propsLead, selectedLead]);


  useEffect(() => {
    if (lead?.id && !listIsOpen && !leadIsOpen)
      openLead(lead?.id, 'preload');
  }, [lead]);


  function handleClose() {
    if (onClose) {
      onClose();
    } else {
      setSelectedLead(null);
    }
  }


  function openLink(url, type) {
    if (!url?.startsWith('http')) {
      if (type === 'linkedin') {
        url = `https://www.linkedin.com/in/${url}`;
      }
      if (type === 'twitter') {
        url = `https://twitter.com/${url}`;
      }
      if (type === 'website') {
        url = `https://${url}`;
      }
    }
    window.open(url, '_blank');
  }

  const leadTags = useMemo(() => tags?.find(tg => selectedList ? tg.id_list === selectedList?.id : tg.id_list === null), [tags, selectedList]);
  const status = useMemo(() => uniqueLead?.status?.[0], [uniqueLead?.status]);

  return (
    <div className={clsx(`bg-white dark:bg-black border-stone-100 dark:border-stone-900 rounded-lg flex flex-col`)}>
      {lead ? (
        <Fragment>
          <div className={'flex p-2 w-full items-center bg-stone-50 dark:bg-stone-950 space-x-2 justify-between border-stone-100 dark:border-stone-800 border-b-1'}>
            <div className={'flex space-x-2'}>
              {uniqueLead && (
                <div className={''}>
                  <StatusCell id_list={selectedList?.id} id_lead={lead?.id} lead_status={uniqueLead?.status}
                              status={status} />
                </div>
              )}
              <TagsCell showContentLoader={!uniqueLead} id_list={selectedList?.id} id_lead={lead?.id} lead_tags={tags}
                        status={leadTags} />
            </div>
            {!hideCross && (
              <div className={'ml-auto flex items-center justify-center'}>
                <Button isIconOnly={true} onClick={handleClose} variant={'light'} size={'sm'} className={'h-6 w-6 min-w-6'}>
                  <X size={18} />
                </Button>
              </div>
            )}
          </div>
          <div className={'p-2 flex relative space-x-2 items-center dark:border-stone-800 border-b-1'}>
            <div className={'flex'}>
              <Avatar text={`${lead?.first_name} ${lead?.last_name}`} size={'sm'}
                      src={lead?.avatar_url ?? lead?.twitter_avatar_url} />
            </div>
            <div className={'flex flex-col justify-center'}>
              {(lead?.first_name?.length > 0 || lead?.last_name?.length > 0) && (
                <div onClick={() => openLead(lead?.id, 'update')} className={'text-sm font-semibold cursor-pointer line-clamp-1'}>
                  {lead?.first_name} {lead?.last_name}
                </div>
              )}
              {(!lead?.first_name && !lead?.last_name) && (
                <div onClick={() => openLead(lead?.id, 'update')} className={'text-sm text-gray-400 cursor-pointer dark:text-stone-600 line-clamp-1'}>
                  No name
                </div>
              )}
              <div className={'text-xs text-black/80 dark:text-white/80 line-clamp-1'}>
                {lead?.company_role ?? lead?.email}
              </div>
            </div>
          </div>
          <div className={'flex flex-row items-center px-2 py-2 space-x-1 justify-between'}>
            <div className={'space-x-1 flex flex-row items-center'}>
              <Tooltip color='invert' content={lead?.email}>
                <Button className={'h-6 w-6 min-w-6'} isDisabled={!lead?.email} variant={'light'} isIconOnly size={'sm'}
                        startContent={<Mail size={16} strokeWidth={1} />}
                        onClick={() => openLink(`mailto:${lead?.email}`)} />
              </Tooltip>
              <Tooltip color='invert' content={lead?.twitter_name}>
                <Button className={'h-6 w-6 min-w-6'} isDisabled={!lead?.twitter_name} variant={'light'} isIconOnly size={'sm'}
                        startContent={<Twitter size={16} strokeWidth={1} />}
                        onClick={() => openLink(lead?.twitter_name, 'twitter')} />
              </Tooltip>
              <Tooltip color='invert' content={lead?.linkedin_url}>
                <Button className={'h-6 w-6 min-w-6'} isDisabled={!lead?.linkedin_url} variant={'light'} isIconOnly size={'sm'}
                        startContent={<Linkedin size={16} strokeWidth={1} />}
                        onClick={() => openLink(lead?.linkedin_url, 'linkedin')} />
              </Tooltip>
              <Tooltip color='invert' content={lead?.website_url}>
                <Button className={'h-6 w-6 min-w-6'} isDisabled={!lead?.website_url} variant={'light'} isIconOnly size={'sm'}
                        startContent={<Globe size={16} strokeWidth={1} />}
                        onClick={() => openLink(lead?.website_url, 'website')} />
              </Tooltip>
              <Tooltip color='invert' content={lead?.phone}>
                <Button className={'h-6 w-6 min-w-6'} isDisabled={!lead?.phone} variant={'light'} isIconOnly size={'sm'}
                        startContent={<Phone size={16} strokeWidth={1} />}
                        onClick={() => openLink(`tel:${lead?.phone}`)} />
              </Tooltip>
              <ReminderButton iconOnly={true} showLabel={false} type={'popover'} />
            </div>
            <LeadValue lead={uniqueLead} />
          </div>
        </Fragment>
      ) : (
        <div className={'flex flex-col items-center justify-center p-6 text-center bg-stone-100'}>
          <div className={'text-lg font-semibold'}>
            {emptyText?.title ?? 'Select a lead to start messaging'}
          </div>
          <div className={'text-md text-black/80 dark:text-white/80'}>
            {emptyText?.subtitle ?? 'To select a lead, click on it or on the paper plane icon in the top right corner of a post'}
          </div>
        </div>
      )}
    </div>
  );
}
