import React, { createContext, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useSpace } from '@/utils/useSpace';
import dayjs from 'dayjs';

export const AnnouncementsContext = createContext();

export const AnnouncementsContextProvider = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [announcements, setAnnouncements] = useState(JSON.parse(localStorage.getItem('announcements')) ?? []);
  const { subscription } = useSpace();
  const {
    data: announcement
  } = useSWR(`/app/announcement`, { revalidateOnFocus: true });

  useEffect(() => {
    const storedAnnouncements = JSON.parse(localStorage.getItem('announcements'));
    //we remove all the announcement that don't have date
    //THIS IS A TEMPORARY FIX TO REMOVE THE OLD ANNOUNCEMENTS
    if (storedAnnouncements?.length > 0) {
      setAnnouncements(storedAnnouncements.filter(a => !(a.date && a.id?.startsWith('sub_'))));
    }
  }, []);

  useEffect(() => {
    if (announcement) {
      addAnnouncement(announcement);
    }
  }, [announcement]);

  useEffect(() => {
    if (subscription && subscription?.status === 'active' && subscription?.cancel_at_period_end && dayjs(subscription?.cancel_at_period_end).isBefore(dayjs())) {
      addAnnouncement({
        id: `sub_${subscription?.id}_cancel_at_period_end`,
        color: 'warning',
        date: dayjs().toISOString(),
        hideClose: true,
        id_space: subscription?.id_space,
        title: `Your subscription is about to end ${dayjs(subscription?.cancel_at).fromNow(false)}`,
        description: `Your subscription is going to expire the ${dayjs(subscription.cancel_at)}, please renew your subscription to continue using Breakcold.`
      });
    }
  }, [subscription]);


  useEffect(() => {
    //save in the localstorage the announcements
    if (announcements.length === 0) {
      return;
    }
    localStorage.setItem('announcements', JSON.stringify(announcements));
  }, [announcements]);

  function open() {
    setModalIsOpen(true);
  }

  function close() {
    setModalIsOpen(false);
  }

  function generateRandomID() {
    var id = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 10; i++) {
      id += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return id;
  }

  function addAnnouncement(announcement) {
    //we check before if the announcement is already in the list
    const isAnnouncementAlreadyInList = announcements.find(a => a.id === announcement.id);
    if (isAnnouncementAlreadyInList) {
      return;
    }
    const newAnnouncement = {
      id: generateRandomID(),
      ...announcement
    };
    setAnnouncements([...announcements, newAnnouncement]);
  }

  function removeAnnouncement(announcement) {
    setAnnouncements(announcements.filter(a => a.id !== announcement.id));
  }

  function hideAnnouncement(announcement) {
    const newAnnouncement = {
      ...announcement,
      hidden: true
    };
    setAnnouncements([...announcements.filter(a => a.id !== announcement.id), newAnnouncement]);
  }


  const value = {
    open,
    close,
    addAnnouncement,
    removeAnnouncement,
    hideAnnouncement,
    announcements: announcements.filter(a => (!a.id_space || a.id_space === subscription?.id_space) && !a.hidden),
    isOpen: modalIsOpen
  };
  return <AnnouncementsContext.Provider value={value} {...props} />;
};

export const useAnnouncements = () => {
  const context = useContext(AnnouncementsContext);
  if (context === undefined) {
    throw new Error(`useAnnouncements must be used within a AnnouncementsContextProvider.`);
  }
  return context;
};
