import TextareaAutosize from 'react-textarea-autosize';
import style from '@/styles/feed/inputSocial.module.css';
import { Smile } from 'react-feather';
import React, { useRef } from 'react';
import EmojiPicker from '@/components/feed/emojiPicker';
import CommandMenu from '@/components/editorV2/extensions/suggestion/menu';
import { typeInTextarea } from '@/utils/helpers';
import { Button } from '@nextui-org/react';

export default function InputSocial({ small, rounded, socialType, placeholder, onChange, value, minRows, maxRows }) {
    const inputRef = useRef();

    return (
      <div className={`${style.wrap} ${small && style.small} ${rounded && style.rounded}`}>
          <CommandMenu
            onSelect={r => typeInTextarea({
              newText: r,
              ref: inputRef?.current,
              onChange: onChange,
              removeSlash: true
          })}>
          <TextareaAutosize ref={inputRef} style={{ height: small ? 28 : 42 }} onChange={r => onChange(r.target.value)}
                            value={value}
                            minRows={minRows}
                            placeholder={placeholder ?? 'Add a comment...'} className={style.textArea} />
          </CommandMenu>
          <div className={style.responseHoverActions}>
              <EmojiPicker placement={'bottom'} onEmojiSelect={r => typeInTextarea({
                  newText: r.native,
                  ref: inputRef?.current,
                  onChange: onChange
              })}>
                <Button size={'sm'} isIconOnly variant={'light'}>
                  <Smile size={18} />
                </Button>
              </EmojiPicker>
          </div>
      </div>
    );
}
