import React, { createContext, useMemo, useContext, useEffect, useState } from 'react';
import { leadService } from '@/services/lead.service';
import { useRouter } from 'next/router';
import { useSpace } from '@/utils/useSpace';
import useSWR, { useSWRConfig } from 'swr';
import { listService } from '@/services/list.service';
import useCustomToasts from '@/utils/useCustomToasts';
import { filterObjToQuery } from '@/utils/filter';


export const LeadContext = createContext();

export const LeadContextProvider = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [leadOptions, setLeadOptions] = useState(null);
    const [listIsOpen, setListIsOpen] = useState(false);
    const [list, setList] = useState(null);
    const [selectedList, setSelectedList] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedLeads, setSelectedLeads] = useState([]);
    const [lead, setLead] = useState(null);
    const [type, setType] = useState('create');
    const [listType, setListType] = useState('create');
    const [listToUpdate, setListToUpdate] = useState(null);
    const [campaignId, setCampaignId] = useState(null);
    const [dontHaveList, setDontHaveList] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [allSelectedLeads, setAllSelectedLeads] = useState(false);
    const [leadFilters, setLeadFilters] = useState(null);
    const [totalLeads, setTotalLeads] = useState(0);
    const [selectedLead, setSelectedLead] = useState(null);
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const [selectedListsIds, setSelectedListsIds] = useState([]);
    const [pipeline, setPipeline] = useState(false);

    //HOOKS
    const router = useRouter();
    const { id } = router.query;
    const { id_space } = useSpace();
    const { setToast } = useCustomToasts();

    const filterQuery = useMemo(() => filterObjToQuery({ filters :leadFilters, list: selectedList?.id, campaign: campaignId }), [leadFilters, selectedList, campaignId]);
    const filterQueryObj = useMemo(() => filterObjToQuery({  keepObject: true, filters :leadFilters, list: selectedList?.id, campaign: campaignId }), [leadFilters, selectedList, campaignId]);

    //Get leads
    const {
        data: leads,
        isValidating,
        mutate: mutateLeads
    } = useSWR(id_space ? `/leads?${pageIndex ? `&page=${pageIndex}` : ''}${pipeline ? `&pipeline=${pipeline}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${filterQuery ? `&${filterQuery}` : ''}${`&extended=true`}` : null);


    //API
    const {
        data: uniqueLead,
        error: uniqueError,
        isValidating: uniqueLoading,
        mutate: uniqueMutate
    } = useSWR((id_space && (lead && lead !== '123') && type !== 'create') ? `/lead/${lead}` : null, { revalidateOnFocus: false });

    //Get leads in a list
    const {
        data: lists,
        mutate: mutateLists
    } = useSWR(id_space ? `/lists?${campaignId ? `&id_campaign=${campaignId}` : ''}` : null
      , { revalidateOnFocus: false });

    useEffect(() => {
        if(router?.query?.list && lists){
            const list = lists?.find(l => l?.id === router?.query?.list);
            if(list)
                setSelectedList(list);
        }
    }, [lists, router])

    useEffect(() => {
        if (router?.pathname?.startsWith('/campaigns/')) {
            setCampaignId(id);
        } else {
            setCampaignId(null);
        }

        if (router?.pathname?.startsWith('/leads')) {

        } else {
            setSelectedList(null);
            setSelectedTag(null);
            setLeadFilters(null);
            setSelectedLeads([]);
        }
    }, [router]);


    // useEffect(() => {
    //     setLeadFilters(null);
    // }, [router.pathname])


    useEffect(() => {
        setPageIndex(0);
        setLeadFilters(null);
        setSelectedLeads([]);
        setAllSelectedLeads(false);
        setSelectedTag(null);
        setSelectedLead(null);
    }, [selectedList]);

    useEffect(() => {
        if (leads)
            setTotalLeads(leads.total);
    }, [leads]);

    useEffect(() => {
        if(lead && leads?.leads?.length > 0 && uniqueLead?.id !== lead){
            const leadInCache = leads?.leads?.find(l => l?.id === lead);
            if(leadInCache){
                uniqueMutate(() => leadInCache, { populateCache: true, optimisticData: leadInCache });
            }
        }
    }, [lead])

    function openLead(id, type, options) {
        setLead(id);
        setType(type);
        if(type !== 'preload'){
            setIsOpen(true);
        }
        if(options)
            setLeadOptions(options);
    }

    function closeLead() {
        setIsOpen(false);
        setLead(null);
        setLeadOptions(null);
        setType(null);
        setSelectedLead(null);
    }

    function openList(data, type) {
        setListIsOpen(true);
        setList(data);
        setListType(type);
    }

    function closeList() {
        setListIsOpen(false);
        setList(null);
    }


    function updateLocalLeads(leads, lead, options) {
        return leads?.map(l => {
            if (l.id === lead.id) {
                let tmpTags = (!options?.tagsDisconnect && !options?.tagsConnect) ? l?.tags : lead?.tags;
                let tmpStatus = (!options?.statusDisconnect && !options?.statusConnect) ? l?.status : lead?.status
                if(options?.tagsDisconnect){
                    tmpTags = l.tags.filter(t => !lead.tags.find(t2 => t2.id === t.id));
                }
                if(options?.tagsConnect){
                    //check if tag is already in the list
                    const tags = lead?.tags?.filter(t => !l.tags?.find(lt => lt.id === t.id));
                    tmpTags = [...l.tags, ...tags];
                }
                if(options?.statusDisconnect){
                    tmpStatus = [];
                }
                if(options?.statusConnect){
                    tmpStatus = lead?.status;
                }
                return {
                    ...l,
                    ...lead,
                    ...(options?.tagsDisconnect || options?.tagsConnect && { tags: tmpTags }),
                    ...(options?.statusDisconnect || options?.statusConnect && { status: tmpStatus })
                };
            }
            return l;
        });
    }

    async function deleteLeadsLocal(leadsToUpdate) {
        let newLeads = leads?.leads;

        if (leadsToUpdate) {
            newLeads = newLeads?.filter(l => !leadsToUpdate.includes(l.id));
        }
        await mutateLeads(newLeads, { revalidate: false });
    }

    async function updateLeads(leadsToUpdate, populate = true) {
        let newLeads = leads?.leads;

        if (newLeads) {
            leadsToUpdate?.forEach(lead => {
                newLeads = updateLocalLeads(newLeads, lead);
            });
            await mutateLeads({ ...leads, leads: newLeads }, { revalidate: false, populateCache: populate });
        }
    }

    async function updateLeadQuery(data) {
        return leadService.updateLead(data?.id, data, true).then(lead => {
            return { ...leads, leads: updateLocalLeads(leads?.leads, lead) };
        }).catch(err => {
            setToast({
                type: 'error',
                text: err?.message ?? 'Something went wrong when updating the lead, please try again later.'
            });
            console.log(err);
            throw err;
        })

    }

    async function updateLeadsQuery({ filter, all_leads, leads_ids, data, options }) {
        return leadService.updateManyLeads({filter: filter, leads_ids: leads_ids, all_leads, data: data, options: options}).then(lead => {
            setToast({
                delay: 10000,
                type: 'secondary',
                text: "We are updating your leads, it may take a few minutes. for large amount of leads. You can continue to work on the app."
            })
            return { ...leads};
        }).catch(err => {
            setToast({
                type: 'error',
                text: err?.message ?? 'Something went wrong when updating the lead, please try again later.'
            });
            console.log(err);
            throw err;
        })
    }

    function updateManyLeads(data, options) {
        let newLeads = leads?.leads;

        if(selectedLeads?.length > 0 || allSelectedLeads){

            if(allSelectedLeads){
                newLeads?.forEach(lead => {
                    newLeads = updateLocalLeads(newLeads, { id: lead?.id, ...data }, options);
                });
            } else {
                selectedLeads?.forEach(id => {
                    newLeads = updateLocalLeads(newLeads, { id: id, ...data }, options);
                });
            }
        }

        return mutateLeads(updateLeadsQuery({
                filter: filterQueryObj,
                leads_ids: selectedLeads,
                all_leads: allSelectedLeads,
                data: data,
                options: options
            }), {
                optimisticData: { ...leads, leads: newLeads },
                revalidate: false,
                populateCache: false,
                rollbackOnError: true
            });
    }

    function updateLead(leadToUpdate, populate = true) {
        let newLeads = leads?.leads;

        if(uniqueLead?.id === leadToUpdate?.id) {
            uniqueMutate(old => ({...old, ...leadToUpdate}), false);
        }

        if (newLeads) {
            newLeads = updateLocalLeads(newLeads, leadToUpdate);
            if(selectedLead?.id === leadToUpdate?.id){
                setSelectedLead({ ...selectedLead, ...leadToUpdate });
            }
            return mutateLeads(updateLeadQuery(leadToUpdate), {
                optimisticData: { ...leads, leads: newLeads },
                revalidate: false,
                populateCache: populate,
                rollbackOnError: true
            });
        }
    }

    async function addLeadsToList(id_list) {

        return leadService.addLeadsToList({
            id_space: id_space,
            id_list: id_list,
            leads_ids: selectedLeads,
            filter: filterQueryObj,
            all_leads: allSelectedLeads
        }).then(() => {
            setAllSelectedLeads(false);
            setSelectedLeads([]);
            return 200;
        }).catch(() => {
            return null;
        });
    }

    async function removeLeadsToList() {
        return leadService.removeLeadsToList({
            id_space: id_space,
            id_list: list?.id,
            filter: filterQueryObj,
            all_leads: allSelectedLeads,
            leads_ids: selectedLeads
        }).then(() => {
            const newLeads = leads?.leads?.filter(lead => {
                return !selectedLeads.includes(lead.id);
            });
            mutateLeads(newLeads);
            setAllSelectedLeads(false);
            setSelectedLeads([]);
            return 200;
        }).catch(() => {
            return null;
        });
    }

    async function bulkTrack(remove_tracking) {
        return leadService.bulkTrack({
            filter: filterQueryObj,
            all_leads: allSelectedLeads,
            leads_ids: selectedLeads,
            remove_tracking: remove_tracking
        }).then(() => {
            setAllSelectedLeads(false);
            setSelectedLeads([]);
            mutateLeads();
            return 200;
        }).catch(err => {
            setToast({
                text: err?.message ?? (remove_tracking ? 'There was an error removing the tracking' : 'There was an error adding the tracking'),
                type: 'error'
            });
            return null;
        });
    }

    async function deleteLeads() {
        return leadService.deleteAllLeads({
            filter: filterQueryObj,
            all_leads: allSelectedLeads,
            leads_ids: selectedLeads
        }).then(() => {
            const newLeads = leads?.leads?.filter(lead => {
                return !selectedLeads.includes(lead.id);
            });
            setAllSelectedLeads(false);
            setSelectedLeads([]);
            mutateLeads(newLeads);
            return 200;
        }).catch(() => {
            return null;
        });
    }

    async function deleteLeadLocal(leadId) {
        const newLeads = leads?.leads?.filter(lead => lead.id !== leadId);
        mutateLeads(newLeads);
    }

    function createLeads(leadsToCreate) {
        const newLeads = leadsToCreate.concat(leads?.leads);
        mutateLeads({ ...leads, leads: newLeads });
    }

    function updateLists(listToUpdate, noUpdate) {
        let newList;
        const newLists = lists.map(l => {
            if (l.id === listToUpdate.id) {
                newList = { ...l, ...listToUpdate };
                return newList;
            }
            return l;
        });

        //if listToUpdate is not in the lists array, add it
        if (!newList) {
            newList = listToUpdate;
            newLists.push(newList);
        }

        if (listToUpdate?.id === selectedList?.id) {
            setSelectedList(newList);
        }

        //we need to re-order the lists if no order field on it do it by name
        newLists.sort((a, b) => {
            if (a.order && b.order) {
                return a.order - b.order;
            } else {
                return a.name.localeCompare(b.name);
            }
        });

        const options = { optimisticData: newLists, rollbackOnError: true, populateCache: true, revalidate: false };

        return mutateLists(noUpdate ? newLists : updateListFn(listToUpdate, newLists), options);
    }

    async function updateListFn(data, lists) {

        return listService.updateLeadList(data?.id, {
            name: data?.name,
            order: data?.order,
            emoji: data?.emoji,
            description: data?.description,
            schema: data?.schema
        }).then(list => {
            return lists;
        });
    }


    function deleteLists(listToDelete) {
        const newLists = lists.filter(list => {
            return !listToDelete.includes(list.id);
        });
        mutateLists(newLists, false);
    }

    function createLists(listsToCreate) {
        const newLists = lists.concat(listsToCreate);
        mutateLists(newLists, false);
    }


    function getLeads(dontHList) {
        setDontHaveList(dontHList);
        mutateLeads();
    }


    function addLeadsToSelectedLeads(leads) {
        const newSelectedLeads = selectedLeads.concat(leads.filter(lead => {
            return !selectedLeads.find(l => l === lead);
        }));
        setSelectedLeads(newSelectedLeads);
    }

    function removeLeadsFromSelectedLeads(leads) {
        const newSelectedLeads = selectedLeads.filter(lead => {
            return !leads.find(l => l === lead);
        });
        setSelectedLeads(newSelectedLeads);
    }


    const hasActiveFilters = useMemo(() => {
        return selectedList || leadFilters?.status?.length > 0 || leadFilters?.tags?.length > 0 || leadFilters?.search?.length > 0 || leadFilters?.date?.length > 0 || leadFilters?.campaign?.length > 0;
    }, [leadFilters, selectedList])

    const resetFilters = () => {
        setSelectedList(null);
        setLeadFilters(null);
        setSelectedLeads([]);
        setAllSelectedLeads(false);
        setSelectedTag(null);
        setSelectedLead(null);
    }

    const value = {
        openLead,
        leadIsOpen: isOpen,
        listIsOpen: listIsOpen,
        list,
        openList,
        closeList,
        selectedList,
        setSelectedList,
        setSelectedTag,
        selectedTag,
        selectedLeads,
        setSelectedLeads,
        type,
        listType,
        closeLead,
        isOpen,
        lead,
        listToUpdate,
        updateLocalLeads,
        setListToUpdate,
        lists,
        resetFilters,
        leadOptions,
        getLeads,
        hasActiveFilters,
        mutateLists,
        totalLeads,
        setPageIndex,
        pageIndex,
        pageSize,
        dontHaveList,
        leadsIsLoading: !leads,
        updateLeads,
        deleteLeadsLocal,
        deleteLeads: () => deleteLeads(),
        deleteLeadLocal: (leadId) => deleteLeadLocal(leadId),
        createLeads,
        addLeadsToSelectedLeads,
        removeLeadsFromSelectedLeads,
        removeLeadsToList,
        addLeadsToList,
        bulkTrack,
        allSelectedLeads,
        campaignId,
        setAllSelectedLeads,
        leadFilters,
        setLeadFilters: (filters) => {
            setPageIndex(0);
            setLeadFilters(filters);
        },
        filterIsOpen,
        setFilterIsOpen,

        //LEADS STATE
        leadLoading: isValidating,
        setSelectedLead,
        selectedLead,
        leads,

        //FORCE UPDATE
        mutateLeads,

        //LISTS STATE
        setSelectedListsIds,
        selectedListsIds,

        //LISTS FUNCTIONS
        updateLists,
        deleteLists,
        createLists,

        //LEAD CRUD
        updateLead,
        updateManyLeads,

        //UNIQUE LEAD
        uniqueLead: uniqueLead,
        uniqueMutate: uniqueMutate,
        uniqueError: uniqueError,
        uniqueLoading: uniqueLoading,

        //PIPELINE
        pipeline,
        setPipeline,

        //Filter
        filterQuery


    };
    return <LeadContext.Provider value={value} {...props} />;
};

export const useLead = () => {
    const context = useContext(LeadContext);
    if (context === undefined) {
        throw new Error(`useLead must be used within a LeadContextProvider.`);
    }
    return context;
};
